import React, {useEffect, useRef, useState} from 'react';

// styles
import styles from './addContent.module.css';

// actions
import {fetchOrganizations} from "../../../../store/actions/organizationActions";

// components
import {ReactComponent as TimesIcon} from '../../../../images/times-solid.svg';
import {ReactComponent as FilterIcon} from '../../../../images/filter-icon.svg';
import {BoxContainer} from "../../../containers/boxContainer/boxContainer";
import AvailableContent from "../availableContent/availableContent";
import Filter from '../filter/filter';

const AddContent = props => {
    // props
    const {categories, search, assigned, setSecondaryFilter, setStateSearch, assignCategory} = props;
    // local
    const track = useRef(null);
    const [overflow, setOverflow] = useState(false);
    const [showPanel, setShowPanel] = useState(false);
    const [orgs, setOrgs] = useState([]);
    const filterBtn = useRef();


    useEffect(() => {
        showOverflow();
    }, [categories]);

    useEffect(() => {
        try{
            fetchOrganizations()
                .then(organizations => {
                    const reshaped = organizations.map(org => ({
                        name: `${org.name} (${org.id})`,
                        value: org.id,
                    }));
                    setOrgs([{name: 'No Organization', value: ''}, ...reshaped]);
                })
        } catch (e) {
            console.log(e);
        }
    }, []);

    function showOverflow() {
        setOverflow(isOverflown());
    }

    function isOverflown() {
        if (!track || !track.current) return;
        return track.current.scrollHeight > track.current.clientHeight || track.current.scrollWidth > track.current.clientWidth;
    }

     return (
         <BoxContainer className={styles.container}>
             <div className={styles.filter}>
                 <form className={styles.search}>
                     <input
                         value={search.current}
                         onChange={event => {
                             setStateSearch(event.target.value);
                             search.current = event.target.value;
                         }}
                         className={styles.searchText}
                         type="text"
                         placeholder='Search categories'/>
                     <TimesIcon className={styles.iconTimes} onClick={()=>{
                         setStateSearch('');
                         search.current = '';
                     }}/>
                 </form>
                 <div className='pos:r'>
                     <FilterIcon ref={filterBtn} onClick={() => setShowPanel(!showPanel)} className={styles.iconFilter}/>
                     <div className={`${styles.panel} ${showPanel ? styles.show : ''}`}>
                         <Filter
                             callback={setSecondaryFilter}
                             organizations={orgs}
                             close={() => setShowPanel(false)}
                             filterRef={filterBtn}
                         />
                     </div>
                 </div>
             </div>
             <div className={styles.categories} ref={track}>
                 {categories.map(category => (
                     <div key={category.id}>
                         <AvailableContent
                             category={category}
                             isOverflown={overflow}
                             checkOverflow={showOverflow}
                             assignCategory={assignCategory}
                             assigned={assigned}
                             search={search}
                         />
                     </div>
                 ))}
             </div>
         </BoxContainer>
     )
}

export default AddContent;