import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import StateSelect from './StateSelect';

// styles
import styles from './ModifyEquipment.module.css';

// components
import {PrimaryButton} from "../../buttons/buttons";

class NewGym extends React.Component {
    constructor(props){
        super(props);
          this.state={
            name:this.props.name,
            street:this.props.street,
            city: this.props.city,
            zipcode:this.props.zipcode,
            gymState:this.props.gymState,
            website:this.props.website,
            id:this.props.id,
            show: false,
            alertStyle: true,
            alert: '',
        }
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  nameChange = (e) => {
      this.setState({name:e.target.value})
  };      

  streetChange = (e) => {
      this.setState({street:e.target.value})
  };

  cityChange = (e) => {
      this.setState({city:e.target.value})
  };

  zipcodeChange = (e) => {
      this.setState({zipcode:e.target.value})
  };

  websiteChange = (e) => {
      this.setState({website:e.target.value})
  };

  stateChangeHandler = (selectedState) => {
    this.setState({gymState:selectedState})
  }

  addEquip(e) {
        e.preventDefault();
      axios.post(`/user/client-gym/${this.props.username}/add`, {
        name: this.state.name,
        street: this.state.street,
        city: this.state.city,
        state: this.state.gymState,
        zipcode: this.state.zipcode,
        website: this.state.website,
      })
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.setState({showAdd:true})
                this.setState({ show: true });
                this.setState({ alert: 'success' });
                this.setState({ alertStyle: true });
                this.setState({ update: true })
                this.props.updateChangeHandler(this.state.update);
            }})
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
    }

  cancelEquip = () =>{
    this.setState({name:''});
    this.setState({street:''});
    this.setState({city:''});
    this.setState({state:''});
    this.setState({zipcode:''});
    this.setState({website:''});
    this.props.updateChangeHandler(true);
  }

	render() {
    return (
      <div>
      <div className="row">
        <div className="col-md-6">
          <div className="col-xs-12">
            <div className='form-group'>
                <label>GYM NAME</label>       
                <input 
                type="text"
                value={this.state.name}
                onChange={(e)=>this.nameChange(e)}
                required
                 />
              </div>
          </div>           
          <div className="col-xs-12">
            <div className='form-group'>
                <label>STREET</label>       
                <input 
                type="text"
                value={this.state.street}
                placeholder={this.props.street}
                onChange={(e)=>this.streetChange(e)}
                required
                 />
              </div>
          </div>   
          <div className="col-xs-12">
              <div className='form-group'>
                <label>CITY</label>       
                <input 
                type="text"
                value={this.state.city}
                placeholder={this.props.city}
                onChange={(e)=>this.cityChange(e)}
                required
                 />
              </div>
          </div> 
          <div className="col-xs-6">
            <div className='form-group'>
                <label>STATE</label>       
                <StateSelect
                gymState={this.props.gymState}
                stateChangeHandler={this.stateChangeHandler}
                required
                 />
              </div>
          </div> 
          
          <div className="col-xs-6">
              <div className='form-group'>
                <label>ZIPCODE</label>  
                <NumberFormat 
                value={this.state.zipcode}
                format="#####"
                onChange={(e)=>this.zipcodeChange(e)}
                required
                />     
              </div>
          </div> 
          <div className="col-xs-12">
              <div className='form-group'>
                <label>WEBSITE</label>       
                <input 
                value={this.state.website}
                onChange={(e)=>this.websiteChange(e)}
                 />
              </div>
          </div> 
        </div>
          {/*<div className="col-md-6">  
              <div className="col-xs-12 gym--map">
                  <img src="http://via.placeholder.com/850x450" />             
              </div>
          </div>*/}
        </div>
        <div className="row">
                <div className={styles.gymBtnContainer}>
                     <div className="col-xs-6 col-md-2">
                      <PrimaryButton className="btn add--icon add-equip-btn" onClick={(e)=>this.addEquip(e)}>ADD GYM</PrimaryButton>
                     </div>
                     <div className="col-xs-6 col-md-2 col-md-offset-1">
                      <PrimaryButton className="btn delete-equip-btn" onClick={(e)=>this.cancelEquip(e)}>CANCEL</PrimaryButton>
                     </div>
                </div>
          </div>  
      </div>
    );
  }
}

export default withRouter(NewGym);


