import React, {Component} from 'react';
import axios from 'axios';
import {withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchTile, clearTile, clearPlans, fetchSinglePlan} from "../../../actions/action_tile";
import {fetchSelf} from "../../../actions/action_user";
import {clearPlan} from "../../../actions/action_tile";
import _ from 'lodash';
import HeaderClient from '../clientDetail/headerClient';
import {ContentViewContainer} from '../../Admin/Content/contentViewContainer';

class SingleTileIntroDisplay extends Component{
    constructor(props){
        super(props);
        this.state= {
            title:'',
            content_type:'',
            content:'',
            hero_image:'',
            id:'',
            intro:true,
            redirect: false,
            status:'',
        }
        this.goBack = this.goBack.bind(this);
        this.forwardToTile = this.forwardToTile.bind(this);

    }

    goBack(){
        this.props.history.push(`/member/clientDash/${this.props.user.username}`);
    }

    forwardToTile(){
        let plans = this.props.plans;
        if(plans.length > 1){
            //forward to screen where client decides which plan to select
            this.props.history.push({
                pathname: '/member/addActivity/tile/'+ this.props.tile.id,
                state: { intro: true }

            });
        }else{
            //immediately forward to goal bar screen
            this.props.fetchSinglePlan(plans[0].id);
            this.props.history.push({
                pathname: '/member/addActivity/tile/'+this.props.tile.id+'/plan/'+plans[0].id,
                state: { intro: true }

            });
        }
    }

      setRedirect = () => {
        this.setState({
          redirect: true
        })
      }

      renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/member/onboard-assessment/' />
        }
      }

    componentDidMount(){
        this.props.fetchSelf()
          .then(() => this.setState({status: this.props.user.user_status_id}))
          .then(() => this.state.status === 3 ? this.setRedirect() : null)
        this.setState({tileId:this.props.match.params.tileId});
        let tileId = this.props.match.params.tileId;
        this.props.fetchTile(tileId);
        if(this.props.tile){
            this.getContentAssignedToTile(this.props.tile.id);
        }
        window.scrollTo(0, 0)

    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(prevProps.tile, this.props.tile)){
            this.getContentAssignedToTile(this.props.tile.id);
        }
    }

    componentWillUnmount(){
        this.props.clearTile();
        this.props.clearPlans();
        // this.props.clearPlan();
    }

    getContentAssignedToTile(tile_id){

            axios.get(`/content/tile/${tile_id}`)
                .then(result=>{
                    this.setState({title:result.data.result[0].title});
                    this.setState({content:result.data.result[0].content});
                    this.setState({id:result.data.result[0].id});
                    this.setState({hero_image:result.data.result[0].hero_image});
                    this.setState({content_type:result.data.result[0].content_type});
                });
        
    }

    render(){
        // sets the bg color based on category // do we need this block?
        // let categoryClass = 'noCategory';
        // if (this.props.tile && (this.props.tile.tile_category === 'what you do' || 'introduction')) {
        //     categoryClass = 'wydBG';
        // } else if (this.props.tile && this.props.tile.tile_category === 'what you eat') {
        //     categoryClass = 'wyeBG';
        // } else if (this.props.tile && this.props.tile.tile_category === 'when you eat') {
        //     categoryClass = 'whenyeBG';
        // }

        var scoreClass = 'low';
        if (this.state.wellnessScore >= 51 && this.state.wellnessScore < 75 ) {
            scoreClass = 'average';
        } else if (this.state.wellnessScore >= 75 && this.state.wellnessScore < 90 ) {
            scoreClass = 'high';
        } else if (this.state.wellnessScore >= 90) {
            scoreClass = 'highest';
        }

        return(
            <div>
                <div className={"wellness-bg header-only " + scoreClass}>
                <div className="client-container">
                    <HeaderClient />
                </div>
            </div>
                {this.renderRedirect()}
                <div className="wellness-bg intro-tile">
                    <div className="client-container">
                        <div className="container">
                            <div className={this.state.content_type !== 'link' ? 'flex-center content-modal' : 'margin-center'}>
                                {this.props.tile
                                    ?  <ContentViewContainer
                                        title={this.state.title}
                                        content_type={this.state.content_type}
                                        content={this.state.content}
                                        hero_image={this.state.hero_image}
                                        id={this.state.id}
                                        intro={true}
                                    />
                                    :null}
                            </div>
                        </div>
                    </div>
                    <div className="footer-actions">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-6">
                                    <button className="btn client-white" onClick={this.goBack}>Back</button>

                                </div>
                                <div className="col-xs-6 text-right">
                                    <button className="btn primary" onClick={this.forwardToTile}>ADD</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchSelf, fetchTile, fetchSinglePlan, clearTile, clearPlans, clearPlan}, dispatch);
}

function mapStateToProps({user, tile, plans}){
    return {
        user,
        tile:tile.tile,
        plans:plans.plans,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleTileIntroDisplay));
