import { organizationApi } from "../../api/organizationApi";
import {userApi} from "../../api/userApi";
import {FETCH_SELF} from "../../actions/action_user";

//**************** Actions that involve the store *****************//
// get a users info from daxko
export async function fetchDaxkoUser(code) {
    return async dispatch => {
        const {data: {result}} = await organizationApi().getDaxkoUser(code);
        const userRes = await userApi().getUser();
        dispatch({ type: FETCH_SELF, payload: userRes });
        return {result, user: userRes.data.user};
    }
}

//**************** Actions that don't involve the store *****************//

// function to get a list of orgs in total or those that belong to a coach
export async function fetchOrganizations(userId) {
    const { data } = await organizationApi().getOrganizations(userId);
    return data.result;
}

// function to get or search list of content that may be assigned to an org
export async function searchOrgContent(orgId, params={}) {
    const {search, organization, sort} = params;
    const {data: {result}} = await organizationApi().getAssignable(orgId, search, organization, sort);
    return result;
}

// function to assign categories to an organization
export async function assignCategoryToOrg(orgId, categoryId) {
    const {data: {result}} = await organizationApi().setAssigned(orgId, categoryId);
    return result;
}

// function to get categories already assigned to an org
export async function fetchOrgAssignedCategories(orgId) {
    const {data: {result}} = await organizationApi().getAssigned(orgId);
    return result;
}

// function to remove categories from an assigned organization
export async function removeCategoryFromOrg(orgId, categoryId) {
    const {data: {result}} = await organizationApi().deleteAssigned(orgId, categoryId);
    return result;
}

// reorder categories attached to an organization
export async function reorderOrgCategories(orgId, categories) {
    const {data: {result}} = await organizationApi().orderOrganizationCategories(orgId, categories);
    return result;
}

// update the email preferences of an organization
export async function setOrgEmailPreference(orgId, optIn) {
    const {data: {result}} = await organizationApi().updateOrgEmailPreference(orgId, optIn);
    return result;
}

// connect an organization to daxko
export async function connectToDaxko(orgId, scope) {
    const {data: {result}} = await organizationApi().connectDaxko(orgId, scope);
    return result;
}

// disconnect an organization to daxko
export async function disconnectToDaxko(orgId, scope) {
    const {data: {result}} = await organizationApi().disconnectDaxko(orgId);
    return result;
}

