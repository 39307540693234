export const LOAD_POLICY = 'LOAD_POLICY';
export const LOAD_POLICY_VERSION = 'LOAD_POLICY_VERSION';
export const LOAD_VERSION_LIST = 'LOAD_VERSION_LIST';
export const SET_POLICY_EDIT = 'SET_POLICY_EDIT';
export const COMPLIANCE_CHECK = 'COMPLIANCE_CHECK';
export const CLIENT_POLICY_LIST = 'CLIENT_POLICY_LIST';

// pass a policy object to reducers
export const loadPolicyToState = (policy) => {
    return { type: LOAD_POLICY, payload: policy };
};

// pass a policy version to reducers
export const loadVersionToState = (version) => {
    return { type: LOAD_POLICY_VERSION, payload: version };
};

// pass a list of versions to reducers
export const loadVersionList = (list) => {
    return { type: LOAD_VERSION_LIST, payload: list };
};

// pass a list of versions to reducers
export const setEditablePolicy = (bool) => {
    return { type: SET_POLICY_EDIT, payload: bool };
};

// pass bool to compliance reducer
export const setCompliance = (bool) => { return { type: COMPLIANCE_CHECK, payload: bool } };

// pass a list of policies meant for clients to a reducer
export const loadPolicyList = (list) => {
    return { type: CLIENT_POLICY_LIST, payload: list }
};