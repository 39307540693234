import React, {Component} from 'react';
import profileImage from '../../../../images/avatar-1577909_960_720.png';

export class ImageUpload extends Component{
    constructor(props){
        super(props);
        this.state={
            image:'',
            maxWidth : 200,
            maxHeight : 200,
        };
        // this.resizeImage = this.resizeImage.bind(this);
        this.uploadProfileImage=this.uploadProfileImage.bind(this);
    }

    triggerFileInput = (e) => {
        document.getElementById('profileImage').click()
    }

    uploadProfileImage(e){
        this.props.clickImg(false);
        let file = e.target.files[0];
        if(!file){
            //false disables save btn / true enables
            this.props.clickImg(true);
            //
            this.props.errorHandler("no file selected");
            return;
        }
        this.props.setProfileImage(file);
    }

    render(){
        return(
            <div onClick={this.triggerFileInput}>
                <div className='clearfix'>
                    <div className='profile-image' >
                        <img className='responsive-image tour-team-img' src={this.props.image ? this.props.image : profileImage} alt="Profile"/>
                    </div>
                </div>
                <input id='profileImage' type="file" accept="image/png, image/jpeg, image/jpg" className='profileInput' onChange={this.uploadProfileImage}/>
            </div>
        )
    }
}