import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

let cls;
 class NoteList extends Component{
    constructor(props){
        super(props);
           this.state = {

               id: null,
               noteListActive: false,
       };
    }

    componentDidMount() {
      this.setState({id: this.props.id})
      if(this.props.tile_id){
        axios.get(`/tiles/single/${this.props.tile_id}`)
        .then(res => {
          let plan = res.data.plan;
          plan.length > 1 ? this.setState({description:"Activity"}) : this.setState({description:plan[0].description})
      })
      }
    }

  // componentDidUpdate(prevProps) {
  //   if(prevProps !== this.props.tile_id) {
  //     this.setState({noteListActive: true})
  //     this.setState({id: this.props.id}, () => {
  //       this.props.idHandler(this.state.id, this.state.listStyle, this.state.noteListActive);
  //     });
  //   }
  // }
// TWO NAMES SAME CLASS 
    // changeListClass() {
    //     this.setState({listStyle: !this.state.listStyle})
    // }

   idChange = () => {
      this.setState({noteListActive: true})
      this.setState({id: this.props.id}, () => {
        this.props.idHandler(this.state.id, this.state.listStyle, this.state.noteListActive);
      });
    };

      changeListClass() {
        if((this.props.style === undefined && this.props.newNoteActive === false) || (this.props.style === undefined && this.props.newNoteActive === undefined)){
          cls = "active-li-first note-li col-xs-12";
        }
        else if(this.props.newNoteActive === true && this.props.style === true ){
          cls = "active-li-last note-li col-xs-12";
        }
        else{
          cls = "note-li col-xs-12";
        }
      }

    render(){

      let createdAt = moment.parseZone(this.props.created_at).local().format("MM/DD/YY h:mm A");
      this.changeListClass()

      return(
            <li tabindex="1" className={cls} onClick={()=>this.idChange()}>
                   <h4>{this.props.title}</h4>
                   {this.props.tile_id ? <p className="note-description capitalize">Related To: {this.state.description}</p> : <p>Not related to a tile.</p>}
                   <p>{createdAt}</p>
            </li>
      )
    }
}

export default withRouter(NoteList);
