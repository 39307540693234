import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//eslint-disable-next-line
import FullCalendar from "@fullcalendar/react"; // leave this here. While it's not used in this file, the child component ScheduleCalendar requires it be loaded prior to the child rendering.
import { Draggable } from "@fullcalendar/interaction";
import { Modal } from "react-bootstrap";
import parseISO from "date-fns/parseISO";

// styles
import styles from "./contentSchedule.module.css";

// actions
import {
  deleteScheduledContent,
  fetchHierarchicalCategories,
  fetchScheduledContent,
  searchAllContent,
  setScheduledContent,
  updateScheduledContent,
} from "../../store/actions/contentActions";

// components
import Display from "./display/display";
import { CONTENT_TYPES } from "../../serverVariables/contentTypes";
import ScheduleCalendar from "./calendar/scheduleCalendar";
import ScheduleEdit from "./scheduleEdit/scheduleEdit";
import { ErrorAlert } from "../alerts/alerts";

const ContentScheduler = (props) => {
  // local
  const [content, setContent] = useState([]);
  const [search, setSearch] = useState({ page: 1 });
  const [pages, setPages] = useState(1);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [edit, setEdit] = useState({ show: false });
  const [calendarViewStart, setCalendarViewStart] = useState(null);
  const [calendarViewEnd, setCalendarViewEnd] = useState(null);
  const [modalError, setModalError] = useState("");
  const [generalError, setGeneralError] = useState("");

  useEffect(() => {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".event",
      eventData: function(eventEl) {
        const contentId = eventEl.getAttribute("data-id");
        const title = eventEl.getAttribute("data-title");
        const thumbnail = eventEl.getAttribute("data-thumbnail");
        const category = eventEl.getAttribute("data-category");
        const subcategory = eventEl.getAttribute("data-subcategory");
        const length = eventEl.getAttribute("data-length");
        const format = eventEl.getAttribute("data-format");
        return {
          allDay: false,
          contentId,
          title,
          thumbnail,
          category,
          subcategory,
          length,
          format,
        };
      },
    });
  }, []);

  useEffect(() => {
    submit();
  }, [search.page]);

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    if (!calendarViewStart || !calendarViewEnd) return;
    loadEvents(parseISO(calendarViewStart), parseISO(calendarViewEnd));
  }, [calendarViewStart, calendarViewEnd]);

  async function loadEventsTrigger(info) {
    const { startStr, endStr } = info;
    setCalendarViewStart(startStr);
    setCalendarViewEnd(endStr);
  }

  async function loadEvents(start, end) {
    try {
      setGeneralError("");
      const events = await fetchScheduledContent(start, end);
      setCalendarEvents(
        events.map((event) => {
          let category = event.category_name;
          let subcategory = "";
          if (event.parent_category_name) {
            category = event.parent_category_name;
            subcategory = event.category_name;
          }
          return {
            id: event.content_id,
            start: event.start_time,
            end: event.end_time,
            allDay: true,
            title: event.content_title,
            extendedProps: {
              eventId: event.class_event_id,
              contentId: event.content_id,
              title: event.content_title,
              thumbnail: event.content_thumbnail,
              category,
              subcategory,
              length: event.content_length,
            },
          };
        })
      );
    } catch (e) {
      setGeneralError("Unable to load events. Please try refreshing the page");
      console.log(e);
    }
  }

  async function loadCategories() {
    try {
      const res = await fetchHierarchicalCategories(CONTENT_TYPES.FITNESS);
      setCategories([
        { id: "", name: "All Categories", subcategories: [] },
        ...res,
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  async function submit() {
    const params = { status: "draft" };
    params.status = "published";
    params.type = "fitness";
    if (search.title) params.title = search.title;
    if (search.category) params.category = search.category.value;
    try {
      const res = await searchAllContent(search.page, params);
      setContent(res.content);
      setPages(res.pages);
    } catch (e) {
      console.log(e);
    }
  }

  function changePage({ selected }) {
    setSearch({ ...search, page: selected + 1 });
  }

  function changeCategory(val) {
    setSearch({ ...search, category: val });
  }

  function handleTitleChange(val) {
    setSearch({ ...search, title: val });
  }

  function openEdit(val) {
    setEdit({ ...edit, ...val, show: true });
  }

  function closeEdit(keepEvent) {
    if (!keepEvent && !edit.eventId && edit.event) edit.event.remove();
    setEdit({ show: false });
    setModalError("");
  }

  async function submitNewSchedule(id, start, end) {
    try {
      const res = await setScheduledContent(id, start, end);
      edit.event.setStart(start);
      edit.event.setEnd(end);
      edit.event.setExtendedProp("eventId", res.id);
      closeEdit(true);
    } catch (e) {
      setModalError(e.response.data.error);
      console.log(e);
    }
  }

  async function updateSchedule(eventId, contentId, start, end) {
    try {
      setGeneralError("");
      setModalError("");
      await updateScheduledContent(eventId, contentId, start, end);
      if (edit.event) {
        edit.event.setStart(start);
        edit.event.setEnd(end);
      }
      if (edit.show) closeEdit(true);
    } catch (e) {
      console.table(e);
      setGeneralError(e.response.data.error);
      setModalError(e.response.data.error);
      console.log(e);
    }
  }

  async function removeScheduledEvent(eventId) {
    try {
      setGeneralError("");
      await deleteScheduledContent(eventId);
      edit.event.remove();
      closeEdit();
    } catch (e) {
      setGeneralError(e.response.data.error);
      console.log(e);
    }
  }

  function submitForm() {
    if (search.page === 1) {
      submit();
    } else {
      setSearch({ ...search, page: 1 });
    }
  }

  return (
    <div>
      <div className="page-header-container searchForm-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item underline ">
                    <Link to="/dashboard" className="text-light">
                      Dashboard
                    </Link>
                  </li>
                  <span className="arrow" />
                  <li className="breadcrumb-item underline ">
                    <Link to="/dashboard/Content" className="text-light">
                      Content
                    </Link>
                  </li>
                  <span className="arrow" />
                  <li
                    className="breadcrumb-item text-light active"
                    aria-current="page"
                  >
                    Schedule
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-12 col-md-6">
              <h1>Schedule</h1>
            </div>
          </div>
        </div>
      </div>

      <div className={`container ${styles.container}`}>
        <div id="external-events" className={styles.display}>
          <Display
            submit={submitForm}
            title={search.title}
            setTitle={handleTitleChange}
            categories={categories}
            setCategory={changeCategory}
            category={search.category}
            content={content}
            pages={pages}
            changePage={changePage}
          />
        </div>
        <div className={styles.schedule}>
          {generalError ? (
            <div className="margin-bottom-15">
              <ErrorAlert>{generalError}</ErrorAlert>
            </div>
          ) : null}
          <ScheduleCalendar
            save={updateSchedule}
            onChange={loadEventsTrigger}
            openModal={openEdit}
            calendarEvents={calendarEvents}
          />
        </div>
      </div>

      <Modal show={edit.show} onHide={closeEdit}>
        {edit.show ? (
          <ScheduleEdit
            content={edit}
            update={updateSchedule}
            remove={removeScheduledEvent}
            save={submitNewSchedule}
            error={modalError}
          />
        ) : null}
      </Modal>
    </div>
  );
};
export default ContentScheduler;
