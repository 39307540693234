import React, {useEffect, useState, useRef} from 'react';

// styles
import styles from './rankAnimation.module.css';

// components
import StrapIcon from "./strapIcon";
import {ReactComponent as GreenStar} from '../../images/icons/greenStar.svg';
import {RANKS_IDS} from "../../serverVariables/ranks";

const bronzeBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/ranks/Ranking_Bronze200x200.png';
const silverBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/ranks/Ranking_Silver200x200.png';
const goldBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/ranks/Ranking_Gold20x200.png';
const platinumBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/ranks/Ranking_Platinum200x200.png';
const RankAnimation = props => {
    // props
    const {rank, close} = props;
    // local
    const [localRank] = useState(rank); // capture the rank variable on first pass
    const timeouts = useRef([]);
    const [showBackground, setShowBackground] = useState(false);
    const [showMedalStrap, setShowMedalStrap] = useState(false);
    const [animateCongratulations, setAnimateCongratulations] = useState(false);
    const [animateGlow, setAnimateGlow] = useState(false);
    const [animateStars, setAnimateStars] = useState(false);
    const [animateStarsTwo, setAnimateStarsTwo] = useState(false);
    const [showContinue, setShowContinue] = useState(false);

    // medal refs
    const bronze = useRef();
    const silver = useRef();
    const gold = useRef();
    const platinum = useRef();

    // medal classes
    const bronzeClasses = useRef([]);
    const silverClasses = useRef([]);
    const goldClasses = useRef([]);
    const platinumClasses = useRef([]);

    // kick off the animation and clear any timeouts that still exist before dismounting
    useEffect(() => {
        startAnimation();
        return () => {
            timeouts.current.map(val => clearTimeout(val));
        }
    }, []);

    // transition the container in
    useEffect(() => {
        setShowBackground(true);
    }, []);

    function startAnimation() {
        let time = 1;
        const t1 = setTimeout(() => {
            setShowMedalStrap(true);
        }, time * 1000);
        timeouts.current.push(t1);

        let newTime;
        if (localRank.id === RANKS_IDS.SILVER) newTime = silverAnimation(time);
        if (localRank.id === RANKS_IDS.GOLD) newTime = goldAnimation(time);
        if (localRank.id === RANKS_IDS.PLATINUM) newTime = platinumAnimation(time);

        generalAnimation(newTime);

    }

    function closeAnimation() {
        setShowBackground(false);
        setTimeout(() => close(), 1000);
    }

    function generalAnimation(time) {
        time += .7;
        const t5 = setTimeout(() => {
            setAnimateCongratulations(true);
        }, time*1000);
        timeouts.current.push(t5);

        time += .2;
        const t6 = setTimeout(() => {
            setAnimateGlow(true);
        }, time*1000);
        timeouts.current.push(t6);

        const t7 = setTimeout(() => {
            setAnimateStars(true);
        }, time*1000);
        timeouts.current.push(t7);

        const t8 = setTimeout(() => {
            setAnimateStarsTwo(true);
        }, time*1000);
        timeouts.current.push(t8);

        const t9 = setTimeout(() => {
            setShowContinue(true);
        }, time*1000);
        timeouts.current.push(t9);

        return time;
    }

    function silverAnimation(time) {
        const {current: b} = bronze;
        const {current: s} = silver;

        time += 1;
        const t1 = setTimeout(() => {
            b.classList.add(styles.scale);
        }, time*1000);
        timeouts.current.push(t1);

        time += 1;
        const t2 = setTimeout(() => {
            b.classList.add(styles.bronzeMove);
        }, time*1000);
        timeouts.current.push(t2);

        time += .7;
        const t3 = setTimeout(() => {
            s.classList.add(styles.scale);
        }, time*1000);
        timeouts.current.push(t3);

        time += 1;
        const t4 = setTimeout(() => {
            s.classList.add(styles.silverMove);
        }, time*1000);
        timeouts.current.push(t4);

        return time;
    }

    function goldAnimation(time) {
        const {current: b} = bronze;
        const {current: s} = silver;
        const {current: g} = gold;

        time += 1;
        const t1 = setTimeout(() => {
            b.classList.add(styles.scale);
        }, time*1000);
        timeouts.current.push(t1);

        time += 1;
        const t2 = setTimeout(() => {
            b.classList.add(styles.bronzeMove_2);
        }, time*1000);
        timeouts.current.push(t2);

        time += .7;
        const t3 = setTimeout(() => {
            s.classList.add(styles.scale);
        }, time*1000);
        timeouts.current.push(t3);

        time += 1;
        const t4 = setTimeout(() => {
            s.classList.add(styles.silverMove_2);
        }, time*1000);
        timeouts.current.push(t4);

        time += .7;
        const t5 = setTimeout(() => {
            g.classList.add(styles.scale);
        }, time*1000);
        timeouts.current.push(t5);

        time += 1;
        const t6 = setTimeout(() => {
            g.classList.add(styles.goldMove);
        }, time*1000);
        timeouts.current.push(t6);

        return time;
    }

    function platinumAnimation(time) {
        const {current: b} = bronze;
        const {current: s} = silver;
        const {current: g} = gold;
        const {current: p} = platinum;

        time += 1;
        const t1 = setTimeout(() => {
            b.classList.add(styles.scale);
        }, time*1000);
        timeouts.current.push(t1);

        time += 1;
        const t2 = setTimeout(() => {
            b.classList.add(styles.bronzeMove_3);
        }, time*1000);
        timeouts.current.push(t2);

        time += .7;
        const t3 = setTimeout(() => {
            s.classList.add(styles.scale);
        }, time*1000);
        timeouts.current.push(t3);

        time += 1;
        const t4 = setTimeout(() => {
            s.classList.add(styles.silverMove_3);
        }, time*1000);
        timeouts.current.push(t4);

        time += .7;
        const t5 = setTimeout(() => {
            g.classList.add(styles.scale);
        }, time*1000);
        timeouts.current.push(t5);

        time += 1;
        const t6 = setTimeout(() => {
            g.classList.add(styles.goldMove_2);
        }, time*1000);
        timeouts.current.push(t6);

        time += .7;
        const t7 = setTimeout(() => {
            p.classList.add(styles.scale);
        }, time*1000);
        timeouts.current.push(t7);

        time += 1;
        const t8 = setTimeout(() => {
            p.classList.add(styles.platinumMove);
        }, time*1000);
        timeouts.current.push(t8);

        return time;
    }

    return (
        <div className={`${styles.container} ${showBackground ? styles.show : ''}`}>
            <div className={`${styles.modal} ${showBackground ? styles.show : ''}`}>
                <GreenStar className={`${styles.greenStar} ${styles.left} ${animateStars ? styles.around : ''}`}/>
                <GreenStar className={`${styles.greenStar} ${styles.right} ${animateStarsTwo ? styles.around : ''}`}/>

                {showMedalStrap ? <div className={styles.strap}><StrapIcon/></div> : null}

                <h2 className={`${styles.messageSecondary} ${styles.animateText} ${animateCongratulations ? styles.activeAnimation : ''}`}>Congratulations</h2>
                <h2 className={`${styles.message} ${styles.animateText} ${animateCongratulations ? styles.activeAnimation : ''}`}>You've achieved a new rank!</h2>
                <h1 className={`${styles.rank} ${animateGlow ? styles.glowText : ''}`}>{localRank.name}</h1>

                <img ref={bronze} className={`${styles.medal} ${bronzeClasses.current.join(' ')}`} src={bronzeBadge} alt="bronze"/>
                <img ref={silver} className={`${styles.medal} ${silverClasses.current.join(' ')}`} src={silverBadge} alt="silver"/>
                <img ref={gold} className={`${styles.medal} ${goldClasses.current.join(' ')}`} src={goldBadge} alt="gold"/>
                <img ref={platinum} className={`${styles.medal} ${platinumClasses.current.join(' ')}`} src={platinumBadge} alt="platinum"/>

                <div className={styles.bottomArc}>
                    <div className={styles.arc}/>
                    <button className={`${styles.continue} ${showContinue ? styles.show : ''}`} type='button' onClick={closeAnimation}>Continue</button>
                </div>
            </div>
        </div>
    )
}
export default RankAnimation;