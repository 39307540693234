import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import validate from "validate.js";
import { isEmpty } from "lodash";
import axios from "axios";
import { connect } from "react-redux";

// styles
import styles from "./signUp.module.css";

// actions
import { acceptPolicyVersion } from "../../../store/actions/policyActions";
import { updateStatus } from "../../../store/actions/userActions";

// components
import SignUpLoading from "./signupLoading";
import { PrimaryButton } from "../../buttons/buttons";
import { ErrorAlert } from "../../alerts/alerts";
import TreoLogo from "../../../images/logo.png";
import NumberFormat from "react-number-format";
import TermsOfService from "../termsOfService/termsOfService";
import { STATUSES } from "../../../serverVariables/statuses";

const constraints = {
  firstName: {
    type: "string",
    presence: "^First name is required",
    length: {
      minimum: 1,
      maximum: 255,
      tooShort: "^First name is required",
      tooLong: "^First name must be less than 255 character",
    },
    format: {
      pattern: /^[A-Za-z ]+$/, // This pattern allows only alphabetic characters
      message: '^First name must contain only alphabetic characters',
    },
  },
  lastName: {
    type: "string",
    presence: "^Last name is required",
    length: {
      minimum: 1,
      maximum: 255,
      tooShort: "^Last name is required",
      tooLong: "^Last name must be less than 255 character",
    },
       format: {
      pattern: /^[A-Za-z ]+$/, // This pattern allows only alphabetic characters
      message: '^Last name must contain only alphabetic characters',
    },
  },
  phone: {
    numericality: true,
    length: { is: 10, message: "Phone number is invalid" },
  },
  email: {
    type: "string",
    presence: "^Email is required",
    email: "^Enter a valid email",
  },
  password: {
    type: "string",
    presence: "^Password is required",
    length: {
      minimum: 8,
      maximum: 15,
      tooShort: "^Password must be at least 8 characters long",
      tooLong: "^Password must be less than 15 characters",
    },
  },
  confirm: {
    type: "string",
    presence: "^Password is required",
    length: {
      minimum: 8,
      maximum: 15,
      tooShort: "^Password must be at least 8 characters long",
      tooLong: "^Password must be less than 15 characters",
    },
  },
};

const SignUp = (props) => {
  // props
  const { orgId, code, setPage, logo, updateStatus} = props;

  // state
  const [validation, setValidation] = useState({});
  const [formState, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirm: "",
    phone: "",
  });
  const [creating, setCreating] = useState(false);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState("");
  const [policies, setPolicies] = useState([]);

  async function submit(e) {
    e.preventDefault();
    const errors = {};
    const { firstName, lastName, phone, email, password, confirm } = formState;
    const validation = validate(
      {
        firstName,
        lastName,
        phone,
        email,
        password,
        confirm,
      },
      constraints
    );
    if (validation) {
      errors.firstName = validation.firstName;
      errors.lastName = validation.lastName;
      errors.phone = validation.phone;
      errors.email = validation.email;
      errors.password = validation.password;
      errors.confirm = validation.confirm;
    }
    if (password && confirm && password !== confirm) {
      errors.confirm = ["Passwords do not match"];
    }
    Object.keys(errors).map((key) => {
      if (!errors[key]) delete errors[key];
    });
    setValidation(errors);
    if (!isEmpty(errors)) return;

    try {
      // b/e validation
   const res = await axios.post(`/organizations/validate-new-client/${orgId}/${code}`, {
        first_name: firstName,
        last_name: lastName,
        phone,
        email,
        password,
      });
      // create user
      setCreating(true);
    const response =  await axios.post(`/organizations/new-client/${orgId}/${code}`, {
        first_name: firstName,
        last_name: lastName,
        phone,
        email,
        password,
      });

      if(response.status === 400){
        setError("Request has timed out please try again" + response.data.message );
      }

      if(response.status != 200){
      setError(response.data.message);
      }
      // accept policies
      await Promise.all(
        policies.map((policy) =>
          acceptPolicyVersion(policy.id, policy.active_version_id, email)
        )
      );

      // update status
     const responses = await updateStatus(email, STATUSES.onboarding_assessment);
      setFinished(true);
     } catch (error) {
      console.error(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(`Error: ${error.response.status} - ${error.response.data.message}`);
      } else if (error.request) {
        // The request was made but no response was received
        setError("No response received from the server.");
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("Error setting up the request.");
      }
    }
  }
  if (creating) {
    return (
      <SignUpLoading finished={finished} url={`/member/onboard-assessment`} error={error} setError={setError}/>
    );

  }
  return (
    <div>
      {orgId == 1040 || orgId == 1034 ?
          null
          :
          <div
              className="margin-bottom-2x"
              style={{ maxWidth: logo ? "50px" : "150px" }}
          >
            <img
                className={styles.logo}
                src={logo || TreoLogo}
                alt="Treo Wellness"
            />
          </div>
      }
      <div>{error ? <ErrorAlert>{error}</ErrorAlert> : null}</div>
      <form onSubmit={submit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            value={formState.firstName}
            onChange={(e) =>
              setForm({ ...formState, firstName: e.target.value })
            }
            onBlur={(e) => {
              const problems = validate(
                { firstName: e.target.value },
                { firstName: constraints.firstName }
              );
              if (problems) {
                setValidation({ ...validation, firstName: problems.firstName });
              } else {
                const copy = Object.assign({}, validation);
                delete copy.firstName;
                setValidation(copy);
              }
            }}
            type="text"
            className="form-control"
            id="firstName"
          />
          {validation.firstName ? (
            <div>
              {validation.firstName.map((err) => (
                <span key={err} className="d:b text-danger">
                  {err}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            value={formState.lastName}
            onChange={(e) =>
              setForm({ ...formState, lastName: e.target.value })
            }
            onBlur={(e) => {
              const problems = validate(
                { lastName: e.target.value },
                { lastName: constraints.lastName }
              );
              if (problems) {
                setValidation({ ...validation, ...problems });
              } else {
                const copy = Object.assign({}, validation);
                delete copy.lastName;
                setValidation(copy);
              }
            }}
            type="text"
            className="form-control"
            id="lastName"
          />
          {validation.lastName ? (
            <div>
              {validation.lastName.map((err) => (
                <span key={err} className="d:b text-danger">
                  {err}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <NumberFormat
            id="phone"
            className="form-control"
            format="(###) ###-####"
            mask=""
            type="tel"
            onChange={(e) =>
              setForm({
                ...formState,
                phone: e.target.value.replace(/\D/g, ""),
              })
            }
            onBlur={(e) => {
              const problems = validate(
                { phone: e.target.value.replace(/\D/g, "") },
                { phone: constraints.phone }
              );
              if (problems) {
                setValidation({ ...validation, ...problems });
              } else {
                const copy = Object.assign({}, validation);
                delete copy.phone;
                setValidation(copy);
              }
            }}
            value={formState.phone}
            required
          />
          {validation.phone ? (
            <div>
              {validation.phone.map((err) => (
                <span key={err} className="d:b text-danger">
                  {err}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            value={formState.email}
            onChange={(e) => setForm({ ...formState, email: e.target.value })}
            onBlur={(e) => {
              const problems = validate(
                { email: e.target.value },
                { email: constraints.email }
              );
              if (problems) {
                setValidation({ ...validation, ...problems });
              } else {
                const copy = Object.assign({}, validation);
                delete copy.email;
                setValidation(copy);
              }
            }}
            type="email"
            className="form-control"
            id="email"
          />
          {validation.email ? (
            <div>
              {validation.email.map((err) => (
                <span key={err} className="d:b text-danger">
                  {err}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password (minimum 8 characters)</label>
          <input
            value={formState.password}
            onChange={(e) =>
              setForm({ ...formState, password: e.target.value })
            }
            onBlur={(e) => {
              const problems = validate(
                { password: e.target.value },
                { password: constraints.password }
              );
              if (problems) {
                setValidation({ ...validation, ...problems });
              } else {
                const copy = Object.assign({}, validation);
                delete copy.password;
                setValidation(copy);
              }
            }}
            type="password"
            className="form-control"
            id="password"
          />
          {validation.password ? (
            <div>
              {validation.password.map((err) => (
                <span key={err} className="d:b text-danger">
                  {err}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="confirm">Confirm Password</label>
          <input
            value={formState.confirm}
            onChange={(e) => setForm({ ...formState, confirm: e.target.value })}
            onBlur={(e) => {
              const problems = validate(
                { confirm: e.target.value },
                { confirm: constraints.confirm }
              );
              if (problems) {
                return setValidation({ ...validation, ...problems });
              } else {
                const copy = Object.assign({}, validation);
                delete copy.confirm;
                setValidation(copy);
              }
              if (
                formState.password &&
                formState.confirm &&
                formState.password !== formState.confirm
              ) {
                setValidation({
                  ...validation,
                  confirm: ["Passwords do not match"],
                });
              } else if (
                formState.password &&
                formState.password === formState.confirm &&
                validation.confirm
              ) {
                const copy = Object.assign({}, validation);
                delete copy.confirm;
                setValidation(copy);
              }
            }}
            type="password"
            className="form-control"
            id="confirm"
          />
          {validation.confirm ? (
            <div>
              {validation.confirm.map((err) => (
                <span key={err} className="d:b text-danger">
                  {err}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <h4 className="t-t:u">Terms and Conditions</h4>
          <p>
            By continuing you agree to Treo's terms and conditions as outlined
            below.
          </p>
          <TermsOfService loadPolicies={setPolicies} />
        </div>
        <div className="t-a:c">
          <button
            type="button"
            tabIndex={0}
            className={styles.back}
            onClick={() => setPage(1)}
          >
            Back
          </button>
          <PrimaryButton type="submit">Sign Up</PrimaryButton>
        </div>
      </form>
    </div>
  );
};

const actions = {
  updateStatus,
};

export default withRouter(connect(null, actions)(SignUp));
