import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";
import { Alert} from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// styles
import './step4.css';

// actions
import { getUserWellnessScore } from "../../../actions/action_wellnessScore";
import { fetchSingleUser } from "../../../actions/action_user";

// components
import {GoalBar} from './GoalBar';
import {Notes} from './Notes';

let newPlan = {};
class Step4 extends Component{
   constructor(props){
       super(props);
           this.state = {
               firstName : '',
               filteredDescription: [],
               description: this.props.location.state.description,
               step4Child: true,
               show: false,
               alertStyle: true,
               alert: '',
               newPlan: {},
               tileId: null,
               noteTitle:props.location.state.noteTitle,
               notes:props.location.state.notes,
               scaleStart:[null, 0],
               scaleStart1:[null, 0],
               scaleStart2:[null, 0],
               scaleStart3:[null, 0],
               scaleStart4:[null, 0],
               scaleStart5:[null, 0],
               scaleEnd:[null, 20],
               scaleEnd1:[null, 20],
               scaleEnd2:[null, 20],
               scaleEnd3:[null, 20],
               scaleEnd4:[null, 20],
               scaleEnd5:[null, 20],
               planStatus: ["a", 1],
               planStatus1: ["a", 1],
               planStatus2: ["a", 1],
               planStatus3: ["a", 1],
               planStatus4: ["a", 1],
               planStatus5: ["a", 1],
       };
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
   }

   componentWillUnmount(){
    newPlan = {};
   }

     planStatusHandler = (planStatusValue) => {
      if(planStatusValue[0] === 0){
        this.setState({
          planStatus: planStatusValue
        })
      }
      else if(planStatusValue[0] === 1){
         this.setState({
          planStatus1: planStatusValue
        })       
      }
      else if(planStatusValue[0] === 2){
         this.setState({
          planStatus2: planStatusValue
        })       
      }
      else if(planStatusValue[0] === 3){
         this.setState({
          planStatus3: planStatusValue
        })       
      }
      else if(planStatusValue[0] === 4){
         this.setState({
          planStatus4: planStatusValue
        })       
      }
      else if(planStatusValue[0] === 5){
         this.setState({
          planStatus5: planStatusValue
        })       
      }
        
      };

     scaleStartHandler = (scaleStartValue) => {
      if(scaleStartValue[0] === 0){
        this.setState({
          scaleStart: scaleStartValue
        })
      }
      else if(scaleStartValue[0] === 1){
         this.setState({
          scaleStart1: scaleStartValue
        })       
      }
      else if(scaleStartValue[0] === 2){
         this.setState({
          scaleStart2: scaleStartValue
        })       
      }
      else if(scaleStartValue[0] === 3){
         this.setState({
          scaleStart3: scaleStartValue
        })       
      } 
      else if(scaleStartValue[0] === 4){
         this.setState({
          scaleStart4: scaleStartValue
        })       
      }
      else if(scaleStartValue[0] === 5){
         this.setState({
          scaleStart5: scaleStartValue
        })       
      }

      };

     scaleEndHandler = (scaleEndValue) => {
      if(scaleEndValue[0] === 0){
        this.setState({
          scaleEnd: scaleEndValue
        })
      }
      else if(scaleEndValue[0] === 1){
         this.setState({
          scaleEnd1: scaleEndValue
        })       
      }
      else if(scaleEndValue[0] === 2){
         this.setState({
          scaleEnd2: scaleEndValue
        })       
      }
      else if(scaleEndValue[0] === 3){
         this.setState({
          scaleEnd3: scaleEndValue
        })       
      } 
      else if(scaleEndValue[0] === 4){
         this.setState({
          scaleEnd4: scaleEndValue
        })       
      }
      else if(scaleEndValue[0] === 5){
         this.setState({
          scaleEnd5: scaleEndValue
        })       
      }

      };

  handleDismiss() {
    newPlan = {};
    this.setState({ show: false });
    this.props.history.push({
    pathname: `/Client/${this.props.username}/tiles/`});
  }

  handleShow() {
    this.setState({ show: true });
  }

     noteTitleHandler = (noteTitleValue) => {
        this.setState({
          noteTitle: noteTitleValue
        })
      };

     notesHandler = (notesValue) => {
        this.setState({
          notes: notesValue
        })
      };

   publishNote(tileId) {
    if(this.props.location.state.notes)
      axios.post('/notes/create', { 
        username : this.props.username,
        title : this.state.noteTitle,
        content: this.state.notes,
        tileId : tileId,
        parent : null,
        }
         )
   }

    publishTile() {
      if(newPlan[5]){
      axios.post('/tiles/entry', { 
        clientUsername : this.props.username,
        tileCategory : this.props.location.state.categoryId,
        tileDescription : newPlan[0].category,
        plan:[
            {
            
               planStatus : newPlan[0].planStatus,
                planDescription : newPlan[0].description,
                scaleStart : newPlan[0].scaleStart,
                scaleEnd : newPlan[0].scaleEnd,
                planUnits: newPlan[0].planUnits,
                minPoint : newPlan[0].minPoint,
                maxPoint : newPlan[0].maxPoint,
                inverted : newPlan[0].inverted,
                increment: newPlan[0].increment
            },
            {
            
               planStatus : newPlan[1].planStatus,
                planDescription : newPlan[1].description,
                scaleStart : newPlan[1].scaleStart,
                scaleEnd : newPlan[1].scaleEnd,
                planUnits: newPlan[1].planUnits,
                minPoint : newPlan[1].minPoint,
                maxPoint : newPlan[1].maxPoint,
                inverted : newPlan[1].inverted,
                increment : newPlan[1].increment
            },
            {
            
               planStatus : newPlan[2].planStatus,
                planDescription : newPlan[2].description,
                scaleStart : newPlan[2].scaleStart,
                scaleEnd : newPlan[2].scaleEnd,
                planUnits: newPlan[2].planUnits,
                minPoint : newPlan[2].minPoint,
                maxPoint : newPlan[2].maxPoint,
                inverted : newPlan[2].inverted,
                increment : newPlan[2].increment
            },
            {
            
               planStatus : newPlan[3].planStatus,
                planDescription : newPlan[3].description,
                scaleStart : newPlan[3].scaleStart,
                scaleEnd : newPlan[3].scaleEnd,
                planUnits: newPlan[3].planUnits,
                minPoint : newPlan[3].minPoint,
                maxPoint : newPlan[3].maxPoint,
                inverted : newPlan[3].inverted,
                increment : newPlan[3].increment
            },
            {
            
               planStatus : newPlan[4].planStatus,
                planDescription : newPlan[4].description,
                scaleStart : newPlan[4].scaleStart,
                scaleEnd : newPlan[4].scaleEnd,
                planUnits: newPlan[4].planUnits,
                minPoint : newPlan[4].minPoint,
                maxPoint : newPlan[4].maxPoint,
                inverted : newPlan[4].inverted,
                increment : newPlan[4].increment
            },
            {
            
               planStatus : newPlan[5].planStatus,
                planDescription : newPlan[5].description,
                scaleStart : newPlan[5].scaleStart,
                scaleEnd : newPlan[5].scaleEnd,
                planUnits: newPlan[5].planUnits,
                minPoint : newPlan[5].minPoint,
                maxPoint : newPlan[5].maxPoint,
                inverted : newPlan[5].inverted,
                increment : newPlan[5].increment
            },
         ]}
         )
          .then(res => {
        if(res.status === 200){
            this.setState({ tileId: res.data.result.tile.id }, function(){
                this.publishNote(this.state.tileId);
            });
            this.setState({ show: true });
            this.setState({ alert: 'success' });
            this.setState({ alertStyle: true });
            this.props.getUserWellnessScore(this.props.singleUser.user.username);
            this.props.fetchSingleUser(this.props.singleUser.user.username);
            this.props.history.push({
            pathname: `/Client/${this.props.username}/tiles/`});
        }
      })
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
      }


      else if(newPlan[4]){
      axios.post('/tiles/entry', { 
        clientUsername : this.props.username,
        tileCategory : this.props.location.state.categoryId,
        tileDescription : newPlan[0].category,
        plan:[
            {
            
               planStatus : newPlan[0].planStatus,
                planDescription : newPlan[0].description,
                scaleStart : newPlan[0].scaleStart,
                scaleEnd : newPlan[0].scaleEnd,
                planUnits: newPlan[0].planUnits,
                minPoint : newPlan[0].minPoint,
                maxPoint : newPlan[0].maxPoint,
                inverted : newPlan[0].inverted,
                increment : newPlan[0].increment
            },
            {
            
               planStatus : newPlan[1].planStatus,
                planDescription : newPlan[1].description,
                scaleStart : newPlan[1].scaleStart,
                scaleEnd : newPlan[1].scaleEnd,
                planUnits: newPlan[1].planUnits,
                minPoint : newPlan[1].minPoint,
                maxPoint : newPlan[1].maxPoint,
                inverted : newPlan[1].inverted,
                increment : newPlan[1].increment
            },
            {
            
               planStatus : newPlan[2].planStatus,
                planDescription : newPlan[2].description,
                scaleStart : newPlan[2].scaleStart,
                scaleEnd : newPlan[2].scaleEnd,
                planUnits: newPlan[2].planUnits,
                minPoint : newPlan[2].minPoint,
                maxPoint : newPlan[2].maxPoint,
                inverted : newPlan[2].inverted,
                increment : newPlan[2].increment
            },
            {
            
               planStatus : newPlan[3].planStatus,
                planDescription : newPlan[3].description,
                scaleStart : newPlan[3].scaleStart,
                scaleEnd : newPlan[3].scaleEnd,
                planUnits: newPlan[3].planUnits,
                minPoint : newPlan[3].minPoint,
                maxPoint : newPlan[3].maxPoint,
                inverted : newPlan[3].inverted,
                increment : newPlan[3].increment
            },
            {
            
               planStatus : newPlan[4].planStatus,
                planDescription : newPlan[4].description,
                scaleStart : newPlan[4].scaleStart,
                scaleEnd : newPlan[4].scaleEnd,
                planUnits: newPlan[4].planUnits,
                minPoint : newPlan[4].minPoint,
                maxPoint : newPlan[4].maxPoint,
                inverted : newPlan[4].inverted,
                increment : newPlan[4].increment
            },
         ]}
         )
      .then(res => {
        if(res.status === 200){
            this.setState({ tileId: res.data.result.tile.id }, function(){
                this.publishNote(this.state.tileId);
            });
            this.setState({ show: true });
            this.setState({ alert: 'success' });
            this.setState({ alertStyle: true });
            this.props.getUserWellnessScore(this.props.singleUser.user.username);
            this.props.fetchSingleUser(this.props.singleUser.user.username);
            this.props.history.push({
            pathname: `/Client/${this.props.username}/tiles/`});
        }
      })
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
      }
      else if(newPlan[3]){
      axios.post('/tiles/entry', { 
        clientUsername : this.props.username,
        tileCategory : this.props.location.state.categoryId,
        tileDescription : newPlan[0].category,
        plan:[
            {
            
               planStatus : newPlan[0].planStatus,
                planDescription : newPlan[0].description,
                scaleStart : newPlan[0].scaleStart,
                scaleEnd : newPlan[0].scaleEnd,
                planUnits: newPlan[0].planUnits,
                minPoint : newPlan[0].minPoint,
                maxPoint : newPlan[0].maxPoint,
                inverted : newPlan[0].inverted,
                increment : newPlan[0].increment
            },
            {
            
               planStatus : newPlan[1].planStatus,
                planDescription : newPlan[1].description,
                scaleStart : newPlan[1].scaleStart,
                scaleEnd : newPlan[1].scaleEnd,
                planUnits: newPlan[1].planUnits,
                minPoint : newPlan[1].minPoint,
                maxPoint : newPlan[1].maxPoint,
                inverted : newPlan[1].inverted,
                increment : newPlan[1].increment
            },
            {
            
               planStatus : newPlan[2].planStatus,
                planDescription : newPlan[2].description,
                scaleStart : newPlan[2].scaleStart,
                scaleEnd : newPlan[2].scaleEnd,
                planUnits: newPlan[2].planUnits,
                minPoint : newPlan[2].minPoint,
                maxPoint : newPlan[2].maxPoint,
                inverted : newPlan[2].inverted,
                increment : newPlan[2].increment
            },
            {
            
               planStatus : newPlan[3].planStatus,
                planDescription : newPlan[3].description,
                scaleStart : newPlan[3].scaleStart,
                scaleEnd : newPlan[3].scaleEnd,
                planUnits: newPlan[3].planUnits,
                minPoint : newPlan[3].minPoint,
                maxPoint : newPlan[3].maxPoint,
                inverted : newPlan[3].inverted,
                increment : newPlan[3].increment
            },
         ]}
         )
      .then(res => {
        if(res.status === 200){
            this.setState({ tileId: res.data.result.tile.id }, function(){
                this.publishNote(this.state.tileId);
            });
            this.setState({ show: true });
            this.setState({ alert: 'success' });
            this.setState({ alertStyle: true });
            this.props.getUserWellnessScore(this.props.singleUser.user.username);
            this.props.fetchSingleUser(this.props.singleUser.user.username);
            this.props.history.push({
            pathname: `/Client/${this.props.username}/tiles/`});
        }
      })
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
      }
      else if(newPlan[2]){
      axios.post('/tiles/entry', { 
        clientUsername : this.props.username,
        tileCategory : this.props.location.state.categoryId,
        tileDescription : newPlan[0].category,
        plan:[
            {
            
               planStatus : newPlan[0].planStatus,
                planDescription : newPlan[0].description,
                scaleStart : newPlan[0].scaleStart,
                scaleEnd : newPlan[0].scaleEnd,
                planUnits: newPlan[0].planUnits,
                minPoint : newPlan[0].minPoint,
                maxPoint : newPlan[0].maxPoint,
                inverted : newPlan[0].inverted,
                increment : newPlan[0].increment
            },
            {
            
               planStatus : newPlan[1].planStatus,
                planDescription : newPlan[1].description,
                scaleStart : newPlan[1].scaleStart,
                scaleEnd : newPlan[1].scaleEnd,
                planUnits: newPlan[1].planUnits,
                minPoint : newPlan[1].minPoint,
                maxPoint : newPlan[1].maxPoint,
                inverted : newPlan[1].inverted,
                increment : newPlan[1].increment
            },
            {
            
               planStatus : newPlan[2].planStatus,
                planDescription : newPlan[2].description,
                scaleStart : newPlan[2].scaleStart,
                scaleEnd : newPlan[2].scaleEnd,
                planUnits: newPlan[2].planUnits,
                minPoint : newPlan[2].minPoint,
                maxPoint : newPlan[2].maxPoint,
                inverted : newPlan[2].inverted,
                increment : newPlan[2].increment
            },
         ]}
         )
      .then(res => {
        console.log(res);
        console.log(res.data);
        if(res.status === 200){
            this.setState({ tileId: res.data.result.tile.id }, function(){
                this.publishNote(this.state.tileId);
            });
            this.setState({ show: true });
            this.setState({ alert: 'success' });
            this.setState({ alertStyle: true });
            this.props.getUserWellnessScore(this.props.singleUser.user.username);
            this.props.fetchSingleUser(this.props.singleUser.user.username);
            this.props.history.push({
            pathname: `/Client/${this.props.username}/tiles/`});
        }
      })
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
      }
      else if(newPlan[1]){
      axios.post('/tiles/entry', { 
        clientUsername : this.props.username,
        tileCategory : this.props.location.state.categoryId,
        tileDescription : newPlan[0].category,
        plan:[
            {
            
                planStatus : newPlan[0].planStatus,
                planDescription : newPlan[0].description,
                scaleStart : newPlan[0].scaleStart,
                scaleEnd : newPlan[0].scaleEnd,
                planUnits: newPlan[0].planUnits,
                minPoint : newPlan[0].minPoint,
                maxPoint : newPlan[0].maxPoint,
                inverted : newPlan[0].inverted,
                increment : newPlan[0].increment
            },
            {
            
               planStatus : newPlan[1].planStatus,
                planDescription : newPlan[1].description,
                scaleStart : newPlan[1].scaleStart,
                scaleEnd : newPlan[1].scaleEnd,
                planUnits: newPlan[1].planUnits,
                minPoint : newPlan[1].minPoint,
                maxPoint : newPlan[1].maxPoint,
                inverted : newPlan[1].inverted,
                increment : newPlan[1].increment
            }
         ]}
         )
      .then(res => {
        console.log(res);
        console.log(res.data);
        if(res.status === 200){
            this.setState({ tileId: res.data.result.tile.id }, function(){
                this.publishNote(this.state.tileId);
            });
            this.setState({ show: true });
            this.setState({ alert: 'success' });
            this.setState({ alertStyle: true });
            this.props.getUserWellnessScore(this.props.singleUser.user.username);
            this.props.fetchSingleUser(this.props.singleUser.user.username);
            this.props.history.push({
            pathname: `/Client/${this.props.username}/tiles/`});
        }
      })
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
      }
      else {
        axios.post('/tiles/entry', { 
        clientUsername : this.props.username,
        tileCategory : this.props.location.state.categoryId,
        tileDescription : this.props.location.state.categoryId,
        plan:[
            {
            
               planStatus : newPlan[0].planStatus,
                planDescription : newPlan[0].description,
                scaleStart : newPlan[0].scaleStart,
                scaleEnd : newPlan[0].scaleEnd,
                planUnits: newPlan[0].planUnits,
                minPoint : newPlan[0].minPoint,
                maxPoint : newPlan[0].maxPoint,
                inverted : newPlan[0].inverted,
                increment : newPlan[0].increment
            }
         ]}
         )
      .then(res => {
        if(res.data.message === 'SUCCESS'){
            this.setState({ tileId: res.data.result.tile.id }, function(){
                this.publishNote(this.state.tileId);
            });
            this.props.getUserWellnessScore(this.props.singleUser.user.username);
            this.props.fetchSingleUser(this.props.singleUser.user.username);
            this.props.history.push({
            pathname: `/Client/${this.props.username}/tiles/`});
        }
      
        if(res.data.message === "User can't have more than 6 tiles"){
            this.setState({ show: true });
            this.setState({alert:'User cannot have more than 6 tiles'});
            this.setState({ alertStyle: false });          
        }})
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
        }

  }

    goalBarLoop() {
        var bars = [];
        
        for (var i in this.state.newPlan) {
          for (var i2 in this.state.newPlan[i]) {
            console.log(this.state.newPlan[i][i2].planStatus)
            bars.push(<GoalBar 
             increment={this.state.newPlan[i][i2].increment}
             inverted={this.state.newPlan[i][i2].inverted}
             step4Child={this.state.step4Child}
             singleDescription ={this.state.newPlan[i][i2].description}
             planUnits={this.state.newPlan[i][i2].planUnits}
             planStatus={this.state.newPlan[i][i2].planStatus}
             scaleStart={this.state.newPlan[i][i2].scaleStart}
             scaleEnd={this.state.newPlan[i][i2].scaleEnd}
             minPoint={this.state.newPlan[i][i2].minPoint}
             maxPoint={this.state.newPlan[i][i2].maxPoint}
             planUnitsHandler={this.planUnitsHandler}
             valueHandler={this.valueHandler}
             descriptionHandler={this.descriptionHandler}
             category={this.props.location.state.category} 
             description={this.props.location.state.description}
             planStatusHandler={this.planStatusHandler} 
             username={this.props.username}
             newplan={this.newPlan}
             scaleStartHandler={this.scaleStartHandler}
             scaleEndHandler={this.scaleEndHandler}
             increments={[]}
             />);
          }
          // for (var key2 in newPlan[key]) {
          //   console.log(key2, newPlan[key][key2]);

          // }
        }
        return bars;
    }

    componentDidMount() {
        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({firstName:res.data.user.first_name});
            })
        this.setState(prevState => ({
            newPlan: {
                ...prevState.newPlan,
                newPlan
            }
        }));

        let arrLength = this.props.location.state.description.length;
        // console.log(arrLength);
        for(var i = 0; i < arrLength; i++) {
            if(this.props.location.state.description[i] === "Custom") {
                console.log(i);
               this.props.location.state.description.splice(i, arrLength -i -1 );
            }
            // console.log(this.props.location.state.description);
        }

        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({user:res.data.results});
            })
    }

  handleChange = (value) => {
    this.setState({
      value,
    });
  }

      newObjectLoop() {
      var i;
      for (i = 0; i < this.props.location.state.description.length; i++) { 
        newPlan[i] =  { 
                category:this.props.location.state.category,
                description:this.props.location.state.description[i],
                planStatus:'',
                scaleStart:null,
                scaleEnd:null,
                planUnits:'',
                minPoint:0,
                maxPoint:0,
                inverted:null,
                increment:null,
          }
        }

        newPlan[0].planUnits = this.props.location.state.planUnits.substring(1);
        if(newPlan[1])newPlan[1].planUnits = this.props.location.state.planUnits1.substring(1);
        if(newPlan[2])newPlan[2].planUnits = this.props.location.state.planUnits2.substring(1);
        if(newPlan[3])newPlan[3].planUnits = this.props.location.state.planUnits3.substring(1);
        if(newPlan[4])newPlan[4].planUnits = this.props.location.state.planUnits4.substring(1);
        if(newPlan[5])newPlan[5].planUnits = this.props.location.state.planUnits5.substring(1);
        if(newPlan[6])newPlan[6].planUnits = this.props.location.state.planUnits6.substring(1);

        newPlan[0].planStatus = this.props.location.state.planStatus[1];
        if(newPlan[1])newPlan[1].planStatus = this.props.location.state.planStatus1[1];
        if(newPlan[2])newPlan[2].planStatus = this.props.location.state.planStatus2[1];
        if(newPlan[3])newPlan[3].planStatus = this.props.location.state.planStatus3[1];
        if(newPlan[4])newPlan[4].planStatus = this.props.location.state.planStatus4[1];
        if(newPlan[5])newPlan[5].planStatus = this.props.location.state.planStatus5[1];

        if(newPlan[0])newPlan[0].scaleStart = this.props.location.state.scaleStart[1];
        if(newPlan[1])newPlan[1].scaleStart = this.props.location.state.scaleStart1[1];
        if(newPlan[2])newPlan[2].scaleStart = this.props.location.state.scaleStart2[1];
        if(newPlan[3])newPlan[3].scaleStart = this.props.location.state.scaleStart3[1];
        if(newPlan[4])newPlan[4].scaleStart = this.props.location.state.scaleStart4[1];
        if(newPlan[5])newPlan[5].scaleStart = this.props.location.state.scaleStart5[1];

        newPlan[0].scaleEnd = this.props.location.state.scaleEnd[1];
        if(newPlan[1])newPlan[1].scaleEnd = this.props.location.state.scaleEnd1[1];
        if(newPlan[2])newPlan[2].scaleEnd = this.props.location.state.scaleEnd2[1];
        if(newPlan[3])newPlan[3].scaleEnd = this.props.location.state.scaleEnd3[1];
        if(newPlan[4])newPlan[4].scaleEnd = this.props.location.state.scaleEnd4[1];
        if(newPlan[5])newPlan[5].scaleEnd = this.props.location.state.scaleEnd5[1];

        newPlan[0].minPoint = this.props.location.state.value[1];
        if(newPlan[1])newPlan[1].minPoint = this.props.location.state.value1[1];
        if(newPlan[2])newPlan[2].minPoint = this.props.location.state.value2[1];
        if(newPlan[3])newPlan[3].minPoint = this.props.location.state.value3[1];
        if(newPlan[4])newPlan[4].minPoint = this.props.location.state.value4[1];
        if(newPlan[5])newPlan[5].minPoint = this.props.location.state.value5[1];


        newPlan[0].maxPoint = this.props.location.state.value[2];
        if(newPlan[1])newPlan[1].maxPoint = this.props.location.state.value1[2];
        if(newPlan[2])newPlan[2].maxPoint = this.props.location.state.value2[2];
        if(newPlan[3])newPlan[3].maxPoint = this.props.location.state.value3[2];
        if(newPlan[4])newPlan[4].maxPoint = this.props.location.state.value4[2];
        if(newPlan[5])newPlan[5].maxPoint = this.props.location.state.value5[2];

        newPlan[0].inverted = this.props.location.state.inverted[1];
        if(newPlan[1])newPlan[1].inverted = this.props.location.state.inverted1[1];
        if(newPlan[2])newPlan[2].inverted = this.props.location.state.inverted2[1];
        if(newPlan[3])newPlan[3].inverted = this.props.location.state.inverted3[1];
        if(newPlan[4])newPlan[4].inverted = this.props.location.state.inverted4[1];
        if(newPlan[5])newPlan[5].inverted = this.props.location.state.inverted5[1];

        newPlan[0].increment = this.props.location.state.increment[1];
        if(newPlan[1])newPlan[1].increment = this.props.location.state.increment1[1];
        if(newPlan[2])newPlan[2].increment = this.props.location.state.increment2[1];
        if(newPlan[3])newPlan[3].increment = this.props.location.state.increment3[1];
        if(newPlan[4])newPlan[4].increment = this.props.location.state.increment4[1];
        if(newPlan[5])newPlan[5].increment = this.props.location.state.increment5[1];

      }

     goBack() {
        window.history.back();
    }

    render(){
      console.log(newPlan)
        let changeAlertStyle = this.state.alertStyle ? "success" : "danger";

        return(
            <div>
                <div className="row" >
                    <div className="col-md-3 col-xs-12">
                        <h2>Add New Tile</h2>
                        <p>Select new tile for {this.state.firstName}</p>
                    </div>
                    <div className="col-md-9 col-xs-12">
                        <ul className="progressbar">
                            <li className="active">Step 1</li>
                            <li className="active">Step 2</li>
                            <li className="active">Step 3</li>
                            <li className="active">Step 4</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <h2>Verify Tile Settings</h2>
                        <h3>Goal Bar Settings</h3>
                        <p>Set initial goal</p>
                    </div>
                </div>
                <div className="row">
                <div className="goal-container col-md-6">
                {this.newObjectLoop()}
                {this.goalBarLoop()}
                </div>

                        <Notes
                        noteTitle={this.props.location.state.notes ? this.props.location.state.notes : null}
                        noteContent={this.props.location.state.notes ? this.props.location.state.notes : null}
                        username={this.props.username}
                        step4Child={this.state.step4Child}
                        />

                    {/*<a>Save &amp; Finish Later</a>*/}
                        <button className="publish-btn btn pub-back-container-btn" onClick={()=>this.publishTile()}>Publish</button>
                        {this.state.show
                          ?  <Alert className="col-md-4" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                                <p>{this.state.alert}</p>
                              </Alert>
                          : null
                        }
                        <button className='back-btn btn pub-back-container-btn'  onClick={()=>this.goBack()}>BACK</button>
                </div>
            </div>
        )

    }
}

function mapStateToProps({notes, singleUser}){
    return {notes, singleUser}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getUserWellnessScore, fetchSingleUser,}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step4));