import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';

// styles
import styles from './onDemand.module.css';

// actions
import {
    fetchCategory,
    fetchClassOfTheWeek, fetchOnDemandContent,
    fetchOnDemandFilters
} from "../../../store/actions/contentActions";

// components
import ClientHeader from "../../clientHeader/clientHeader";
import ClassOfTheWeek from "../classOfTheWeek/classOfTheWeek";
import {CONTENT_META_TYPES} from "../../../serverVariables/contentMetaTypes";
import {ReactComponent as FilterIcon} from "../../../images/filter-icon.svg";
import VideoList from "../videoList/videoList";
import {Modal} from "react-bootstrap";
import OnDemandFilter from "../../onDemandFilter/onDemandFilter";
// import _ from "lodash";

const DEFAULT_FORM = {
    page: 0,
};

const OnDemandList = props => {
    // props
    const {match, user} = props;
    // local
    const [category, setCategory] = useState(null);
    const [COTW, setCOTW] = useState(null);
    const [filters, setFilters] = useState(null);
    const pages = useRef(0);
    const [openFilter, setOpenFilter] = useState(false);
    const [lastReached, setLastReached] = useState(false);
    const [filtersInUse, setFiltersInUse] = useState([]);

    /* content and contentState must change together to represent the same object */
    const content = useRef([]);
    const [contentState, setContentState] = useState(null);

    /* form and formState must change together to represent the same object*/
    const form = useRef(DEFAULT_FORM);
    const [formState, setFormState] = useState(form.current);

    // grab list of content and category on mount
    useEffect(() => {
        fetchCategory(match.params.category)
            .then(result => setCategory(result))
            .catch(e => console.log(e));

        fetchClassOfTheWeek(match.params.category)
            .then(result => {
                let instructor, classType, intensity;
                instructor = result.meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.TRAINER);
                classType = result.meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.CLASS_TYPES);
                intensity = result.meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.INTENSITY);
                setCOTW({
                    id: result.id,
                    thumbnail: result.thumbnail,
                    length: result.format_length,
                    classType: classType.selected_option.content_meta_option_label,
                    instructor: instructor.selected_option.content_meta_option_label,
                    intensity: intensity.selected_option.content_meta_option_id,
                    theme: result.theme,
                    episode: result.episode,
                })
            })
            .catch(e => console.log(e));
    }, []);

    useEffect(() => {
        loadContent(true);
        fetchOnDemandFilters(match.params.category)
            .then(res => {
                const classTypes = res.find(item => item.content_meta_type_id === CONTENT_META_TYPES.CLASS_TYPES);
                const instructors = res.find(item => item.content_meta_type_id === CONTENT_META_TYPES.TRAINER);
                const intensities = res.find(item => item.content_meta_type_id === CONTENT_META_TYPES.INTENSITY);
                const equipment = res.find(item => item.content_meta_type_id === CONTENT_META_TYPES.EQUIPMENT);
                const lengths = res.find(item => item.content_meta_type_id === CONTENT_META_TYPES.CLASS_LENGTH);
                if (lengths && lengths.options.length) {
                    lengths.options = lengths.options.sort((a,b) => a.content_meta_value - b.content_meta_value);
                }
                setFilters({
                    classTypes, instructors, intensities, equipment, lengths
                });
            }).catch(e => console.log(e));
    }, []);

    function changeForm(val) {
        form.current = {
            page: form.current.page,
            ...val,
        };
        setFormState({page: formState.page, ...val});
    }

    function resetForm(){
        form.current = DEFAULT_FORM;
        setFormState(DEFAULT_FORM);
        loadContent(true);
        setOpenFilter(false);
    }

    async function loadContent(reset) {
        try {
            // check if you are at the last page
            if (form.current.page >= pages.current && pages.current !== 0 && !reset) return;

            // increment the page if you are not resetting to 1
            let newPage;
            if (reset) {
                newPage = 1
            } else {
                newPage = form.current.page +1;
            }
            // fetch content
            const obj = await fetchOnDemandContent(match.params.category, {...form.current, page: newPage});
            const result = obj.content;
            pages.current = obj.pages;
            form.current = {...form.current, page: newPage};
            setFormState({...form.current, page: newPage});
            if (newPage >= pages.current) setLastReached(true);

            // add new content to growing list
            const transformed = result.map(val => {
                let instructor, classType, intensity;
                instructor = val.meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.TRAINER);
                classType = val.meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.CLASS_TYPES);
                intensity = val.meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.INTENSITY);
                return {
                    ...val,
                    classType: classType.selected_option.content_meta_option_label,
                    instructor: instructor.selected_option.content_meta_option_label,
                    intensity: intensity.selected_option.content_meta_option_id,
                }
            });
            let temp;
            if (reset) {
                temp = transformed;
            } else {
                temp = [...content.current, ...transformed];
            }
            setContentState(temp);
            content.current = temp;
            setOpenFilter(false);
            setFiltersInUse(Object.keys(form.current).filter(key => form.current[key] && key !== 'page'));
        } catch (e) {
            console.log(e);
            setOpenFilter(false);
        }
    }

    function showTM(val) {
        val = val.toLowerCase();
        if (val === 'climbmill' || val === 'ascent trainer' || val.indexOf('virtual active') > -1){
            return <span className={styles.trademark}>&#8482;</span>
        }
    }

    if (!category || !contentState) return <div/>;

    return (
        <>
            <ClientHeader backUrl={`/member/clientDash/${user.username}`}/>
            <div className='container'>
                <h1 className={styles.category}>Category | {category.category_name}{showTM(category.category_name)}</h1>

                {/* Class of the Week */}
                {COTW ?
                    <div className='margin-bottom-25'>
                        <ClassOfTheWeek content={COTW}/>
                    </div>
                    : null
                }
                <div className="d:f j-c:s-b a-i:c margin-bottom-15">
                    {COTW ? <h2 className={styles.subHeading}>{filtersInUse.length ? contentState.length : 'All'} {category.category_name}{showTM(category.category_name)} Classes</h2> : null}
                    <button onClick={() => setOpenFilter(true)} className={`${styles.btn}`}>
                        <FilterIcon className={styles.filterIcon}/>
                        <span>Filter</span>
                    </button>
                </div>

                {/* videos*/}
                <VideoList videos={contentState} callback={loadContent}/>

                {lastReached ? <p style={{color: '#ccc', textAlign: 'center'}}>All Content Loaded</p> :null}

                {/* filters */}
                <Modal show={openFilter} onHide={() => setOpenFilter(false)}>
                    {openFilter ?
                        <OnDemandFilter
                            close={() => setOpenFilter(false)}
                            callback={changeForm}
                            submit={loadContent}
                            reset={resetForm}
                            values={formState}
                            filters={filters}
                            instructorLabel={category.instructor_label || 'Instructors'}
                        />
                        : null
                    }
                </Modal>
            </div>
        </>
    )
}

function mapStateToProps({user}) {
    return {user};
}

export default connect(mapStateToProps)(OnDemandList);