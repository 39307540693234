import React from 'react';

// styles
import styles from './howToScore.module.css';

// images
import {ReactComponent as LoggingIcon} from '../../../images/RulesIcons_Rules_Logging.svg';
import {ReactComponent as LearningIcon} from '../../../images/RulesIcons_Rules_Learning.svg';
import {ReactComponent as LivingIcon} from '../../../images/RulesIcons_Living.svg';
import {ReactComponent as ScoringIcon} from '../../../images/RulesIcons_Rules_Scoring.svg';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";

const HowToScore = props => {
    // props
    const {logging, learning, living, scoring, close} = props;

    return (
        <BoxContainer className={styles.container}>
            <div className={`${styles.exit}`} onClick={close}>&times;</div>
            <p className={styles.heading}>How To Score</p>
            <p className={styles.blurb}>Treo Wellbeing Points are awarded for taking action to achieve your healthy lifestyle. Points reset for a new challenge every month.</p>

            <div className={styles.logging}>
            <p className={styles.title}>Logging <LoggingIcon/></p>
                {logging.map(item => (
                    <div className={styles.row}>
                        <div className={styles.points}>{item.value}pts</div>
                        <div>{item.description}</div>
                    </div>
                ))}
            </div>

            <div className={styles.separator}/>

            <div className={styles.learning}>
            <p className={styles.title}>Learning <LearningIcon/></p>
                {learning.map(item => (
                    <div className={styles.row}>
                        <div className={styles.points}>{item.value}pts</div>
                        <div>{item.description}</div>
                    </div>
                ))}
            </div>

            <div className={styles.separator}/>

            <div className={styles.living}>
            <p className={styles.title}>Living <LivingIcon/></p>
                {living.map(item => (
                    <div className={styles.row}>
                        <div className={styles.points}>{item.value}pts</div>
                        <div>{item.description}</div>
                    </div>
                ))}
            </div>

            <div className={styles.separator}/>

            <div className={styles.scoring}>
            <p className={styles.title}>Bonus Points <ScoringIcon/></p>
                {scoring.map(item => (
                    <div className={styles.row}>
                        <div className={styles.points}>{item.value}pts</div>
                        <div>{item.description}</div>
                    </div>
                ))}
            </div>

        </BoxContainer>
    )
}

export default HowToScore;