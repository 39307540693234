import React from'react';
import {Route} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import CoachSummary from "./CoachSummary";
import NotesMain from "../Notes/NotesMain";
import ClientSearchMain from "./clientSearchMain";
import CalendlyEventView from "../../calendlyEventView/calendlyEventView";
import SupervisorInbox from "../../inbox/supervisorInbox/supervisorInbox";
import ModerationContainer from "../../moderationAdmin/moderationContainer/moderationContainer";

const CoachDisplay = ({match, username}) =>{
    return(
        <div>
            <Route
                exact
                path={match.url+"/overview"}
                render={()=><CoachSummary match={match} username={username}/>}
            />
            <Route
                path={match.url+"/calendar"}
                component={CalendlyEventView}
            />
            <Route
                path={match.url+"/inbox"}
                component={SupervisorInbox}
            />
            <Route
                path={match.url+"/notes"}
                render={()=><NotesMain {...withRouter} match={match} username ={username}/>}
            />
            <Route
                path={match.url+"/CoachClientSearch"}
                render={()=><ClientSearchMain/>}
            />
            <Route
                path={match.url+"/moderation"}
                render={()=><ModerationContainer/>}
            />
        </div>
    )

};


export default withRouter(CoachDisplay);
