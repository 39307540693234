import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// actions
import { getOrganizationClients, setOrganizationClientPage } from "../../../actions/action_organizations";

// styles
import styles from './organizationClients.module.css';

// components
import { BasicLink } from "../../links/links";
import {GeneralPagination} from "../../pagination/generalPagination/generalPagination";
import ClientSortAndFilter from "../../clientSortAndFilter/clientSortAndFilter";
import ClientResult from "../../clientSearch/clientResult";

const OrganizationClients = ({ organization, clients, getClients, setPage, }) => {

    const [ query, setQuery ] = useState('');

    function changePage({ selected }) {
        const page = selected + 1;
        setPage(page);
        const orgQuery = query + `&organization=${organization.id}&`
        getClients(orgQuery, page);
        window.scrollTo(0, 0);
    }

    function sortAndFilter(queryString) {
        setQuery(queryString);
    }

    useEffect(() => {
        changePage({ selected: 0 });
    }, [ query ]);

    return (
        <div>
            <div className="row margin-top-2x">
                <div className="col-xs-12 col-sm-7">
                    <h2 className='m:0'>Members</h2>
                </div>
                <div className={`col-xs-12 col-sm-5 ${styles.addButton}`}>
                    <BasicLink
                        to={`/dashboard/Organization/${organization.id}/clients/new`}
                        className="btn primary btn btn-default margin-bottom">
                        + Add New Member
                    </BasicLink>
                </div>
            </div>
            <div className="row">
                <div className='col-xs-12 col-lg-3'>
                    <ClientSortAndFilter
                        organization={false}
                        callback={sortAndFilter}/>
                </div>
                <div className='col-xs-12 col-lg-9'>
                    <p className={styles.found}>{clients.total} members found</p>
                    <ul className='tiles-ul'>
                        { clients.clients.map(client => (
                            <ClientResult client={client}/>
                        ))}
                    </ul>
                    <div>
                        <GeneralPagination forcePage={clients.page-1} onPageChange={changePage} pages={clients.pages}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps({ organizationClients }){
    return {
        clients: organizationClients,
    }
}

const actions = {
    getClients: getOrganizationClients,
    setPage: setOrganizationClientPage,
}
export default connect(mapStateToProps, actions)(OrganizationClients);