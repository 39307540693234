import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

// styles
import styles from './leaderboard.module.css';

// actions
import {setNickname} from "../../../store/actions/userActions";
import {fetchSelf} from "../../../actions/action_user";

// image
import ProfileImage from '../../../images/avatar-1577909_960_720.png';
import {ReactComponent as LeaderBoardIcon} from "../../../images/LeaderboardModal_Trophy.svg";
import {ReactComponent as LeaderBoardSteps} from '../../../images/Leaderboard_StepsModal.svg';

import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import Badge from "../../badge/badge";
import {RANKS} from "../../../serverVariables/ranks";
import {PrimaryButton, ToggleButtons} from "../../buttons/buttons";
import {NICKNAME_STATUSES} from "../../../serverVariables/leaderboardNicknameStatuses";
import HowToScore from "../howtoScore/howToScore";
import {Modal} from "react-bootstrap";
import {getHowToScore} from "../../../store/actions/badgesActions";

const Leaderboard = props => {
    // props
    const {leaderboard, nickname, nicknameStatus, community, setCommunity} = props;
    // state
    const {user} = props;
    // actions
    const {fetchSelf} = props;
    // local
    const [nicknameForm, setNicknameForm] = useState(nickname);
    const [error, setError] = useState('');
    const [showHowToScore, setShowHowToScore] = useState(false);
    const [logging, setLogging] = useState([]);
    const [living, setLiving] = useState([]);
    const [learning, setLearning] = useState([]);
    const [scoring, setScoring] = useState([]);

    useEffect(() => {
        async function prepare() {
            try{
                const howTo = await getHowToScore();
                const log = howTo.find(item => item.name.toLowerCase() === 'logging');
                const learn = howTo.find(item => item.name.toLowerCase() === 'learning');
                const living = howTo.find(item => item.name.toLowerCase() === 'living');
                const score = howTo.find(item => item.name.toLowerCase() === 'bonus');
                setLogging(log.scoring_types);
                setLearning(learn.scoring_types);
                setScoring(score.scoring_types);
                setLiving(living.scoring_types);
            } catch (e) {
                console.log(e);
            }
        }

        prepare();
    }, []);

    function closeModal() {
        setShowHowToScore(false);
    }

    function selectBorder(rank) {
        if (rank === RANKS.BRONZE) return '#d6764c';
        if (rank === RANKS.SILVER) return '#9d9d9d';
        if (rank === RANKS.GOLD) return '#f7b637';
        if (rank === RANKS.PLATINUM) return '#a2cad8';
    }

    let border = 'transparent';
    border = selectBorder(user.game.rank_name.toLowerCase());

    async function submit(e) {
        e.preventDefault();

        // validation
        if (nicknameForm.length > 25) return setError('Nickname cannot be greater than 25 characters');
        if (!nicknameForm) return setError('Nickname cannot be blank');
        setError('');

        await setNickname(nicknameForm);
        fetchSelf();
    }
    
    if (nicknameStatus.toLowerCase() === NICKNAME_STATUSES.DEFAULT) {
        return (
            <div className={styles.container}>
                <BoxContainer className={styles.prompt}>
                    <div className={styles.iconContainer}>
                        <div className={styles.underlay}>
                            <LeaderBoardIcon className={styles.cup}/>
                        </div>
                        <LeaderBoardSteps className={styles.steps}/>
                    </div>
                    <form onSubmit={submit} className={styles.form}>
                        <div className={styles.bulgeContainer}>
                            <div className={styles.bulge}/>
                        </div>
                        <p>Choose the name you would like to <br/>appear on the leaderboard.</p>
                        <div className="form-group">
                            <input value={nicknameForm} onChange={e=>setNicknameForm(e.target.value)} type="text" className="form-control"/>
                        </div>
                        {error ? <span className="text-danger">{error}</span> : null}
                        <PrimaryButton onClick={submit} className={styles.button}>Submit</PrimaryButton>
                    </form>
                </BoxContainer>
            </div>
        )
    }
    let placeholder = true;
    let youIndex = 0;
    for (let member of leaderboard) {
        const YOU = member.nickname.toLowerCase() === 'you';
        if (YOU) {
            break;
        }
        youIndex++;
    }
    if (youIndex <= 11) placeholder = false;

    const toggleOptions = [
        { label: user.organization.community_name, value:'org', onClick: () => setCommunity({org: true, all: false}) },
        { label: 'All Members', value: 'all', onClick: () => setCommunity({org: false, all: true}) },
    ];

    const {community_all, community_org} = user.organization;
    return (
        <div className={styles.container} data-cy="leaderBoardToggle">
            {community_all && community_org ?
                <div className={styles.toggleContainer}>
                    <ToggleButtons
                        startOption={community.org ? 1 : 2}
                        className={styles.toggle}
                        activeColor='#272727'
                        options={toggleOptions}
                        />
                </div>
                : null
            }

            <div className={styles.howToRow} data-cy="howToRow">
                <span>Get ahead of the competition</span>
                <button onClick={() => setShowHowToScore(true)}><i className={`fas fa-question ${styles.question}`}/>How to Score</button>
            </div>
            <BoxContainer className={styles.rowContainer} data-cy="leaderBoardContainer">
                {leaderboard.map((member, i) => {
                    let ring = selectBorder(member.rank.toLowerCase());
                    const YOU = member.nickname.toLowerCase() === 'you';
                    return (
                        <>
                            {i === 0 && placeholder ?
                                <div key={`${member.nickname}_first`} className={`${styles.row} ${styles.blur}`}>
                                    <div
                                        style={{border: `2px solid ${ring}`, backgroundImage: `url(${ProfileImage})`}}
                                        className={styles.profileImg}/>

                                    <div className={styles.infoContainer}>
                                        <div  className={styles.nickname}>John Smith</div>
                                        <div className={styles.info}>
                                            <div className={styles.progressOuter}>
                                                <div className={styles.progressInner} style={{width: `${Math.round(((member.points+100)/1500)*100)}%`}}/>
                                            </div>

                                            <Badge className={styles.badge} shadow size={50} badge={member.level.toLowerCase()}/>
                                            <div className={styles.points}>{member.points+100}</div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            <div id={YOU ? 'you_leaderboard' : ''} key={member.nickname} className={styles.row} data-cy="leaderBoardTiles">
                                <div
                                    style={YOU ?
                                        {border: `5px solid ${border}`, backgroundImage: `url(${member.profile_image || ProfileImage})`}
                                        :
                                        {border: `2px solid ${ring}`, backgroundImage: `url(${member.profile_image || ProfileImage})`}}
                                    className={styles.profileImg}/>

                                <div className={styles.infoContainer}>
                                    <div  className={styles.nickname}>{member.nickname.toLowerCase() === 'you' ? <><strong>(You)</strong>&nbsp;<span>{user.nickname}</span></> : `${member.nickname}`}</div>
                                    <div className={styles.info}>
                                        <div className={styles.progressOuter}>
                                            <div className={styles.progressInner} style={{backgroundColor: YOU ? ring : '', width: `${Math.round((member.points/1500)*100)}%`}}/>
                                        </div>

                                        <Badge className={styles.badge} shadow size={50} badge={member.level.toLowerCase()}/>
                                        <div className={styles.points}>{member.points}</div>
                                    </div>
                                </div>
                            </div>
                            {i === leaderboard.length-1 ?
                                <div key={`${member.nickname}_last`} className={`${styles.row} ${styles.blur}`}>
                                    <div
                                        style={{border: `2px solid ${ring}`, backgroundImage: `url(${ProfileImage})`}}
                                        className={styles.profileImg}/>

                                    <div className={styles.infoContainer}>
                                        <div  className={styles.nickname}>John Smith</div>
                                        <div className={styles.info}>
                                            <div className={styles.progressOuter}>
                                                <div className={styles.progressInner} style={{width: `${Math.round(((member.points+100)/1500)*100)}%`}}/>
                                            </div>

                                            <Badge className={styles.badge} shadow size={50} badge={member.level.toLowerCase()}/>
                                            <div className={styles.points}>{member.points+100}</div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </>
                    )
                })}
            </BoxContainer>

            <Modal show={showHowToScore} onHide={closeModal}>
                <HowToScore
                    logging={logging}
                    learning={learning}
                    scoring={scoring}
                    living={living}
                    close={closeModal}/>
            </Modal>
        </div>
    )
}

function mapStateToProps({user}) {
    return {user};
}

const actions = {
    fetchSelf
};
export default connect(mapStateToProps, actions)(Leaderboard);