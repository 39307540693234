import axios from 'axios';

export const GET_BOOKINGS = 'GET_BOOKINGS';
export function getBookings(username, client){
    let url;
    if(!client){
        url = `/calendar/bookings/${username}`;
    }else{
        url = `/calendar/bookings/${username}?client=${client}`;
    }

    let request = axios.get(url);

      axios.get(url)
      .then((res)=> {
        console.log(res)
        })
        .catch((error)=>{
           console.log(error);
        });
    
    return{
        type: GET_BOOKINGS,
        payload:request
    };
}

export const RESET_BOOKINGS = 'RESET_BOOKINGS';
export function resetBookings(){
    return{
        type:RESET_BOOKINGS,
        payload:null
    }
}

