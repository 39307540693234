import React from 'react';

// styles
import styles from './memberStreak.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const MemberStreak = props => {
    // props
    const {data} = props;

    if (!data) {
        return (
            <div className={styles.container2}>
                <GraphPlaceholder/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.secondaryLabel}>{data.lastComm} Days</div>
                <div className={styles.subLabel}>Last Communication</div>
            </div>
            <div className={styles.inner}>
                <div className={styles.secondaryLabel}>{data.currentStreak} Days</div>
                <div className={styles.subLabel}>Current Streak</div>
            </div>
            <div className={styles.inner}>
                <div className={styles.secondaryLabel}>{data.longestStreak} Days</div>
                <div className={styles.subLabel}>Longest Streak</div>
            </div>
        </div>
    )
}

export default MemberStreak;