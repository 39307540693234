// npm modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import validate from 'validate.js';

// actions
import {
    loadPolicyToState,
    loadVersionToState,
    loadVersionList,
    setEditablePolicy,
} from "../../actions/action_policy";

// components
import PolicyVersion from "./policyVersion";
import { ButtonList } from "../lists/lists";
import Loading from "../Loading/Loading";
import {BasicButton} from "../buttons/buttons";

const constraints = {
    version: {
        type: 'string',
        presence: true,
        length: { minimum: 1, maximum: 255 }
    },
    text: {
        type: 'string',
        presence: true,
        length: { minimum: 1 }
    }
};
const ACTIVE = 4;
const DEACTIVATE = 3;

const PolicyVersionNewContainer = ({
    singlePolicy,
    policyVersion,
    editablePolicy,
    versionList,
    loadPolicyToState,
    loadVersionToState,
    loadVersionList,
    setEditablePolicy,
    ...props
}) => {
    const buttons = [
        { id: 0, text: 'Create New Version', onClick: () => createNew() },
        { id: 1, text: 'View Previous Versions', active: true, onClick: () => loadPreviousVersions() },
        { id: 2, text: 'Save/Publish', onClick: () => createNewVersion() }, // keep save/publish button at end of list
    ];

    const [ fetchedVersion, setFetchedVersion ] = useState(null);
    const [ buttonState, setButtonState ] = useState(buttons);
    const [ errors, setErrors ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ hiddenLoad, setHiddenLoad ] = useState(false);
    const [ mostCurrentVersion, setMostCurrentVersion ] = useState(null);

    // setup view for new versions
    const createNew = () => {
        if (singlePolicy.title){
            props.history.push(`/dashboard/Policy/${singlePolicy.title.split(' ').join('-')}/${singlePolicy.id}/new`);
            loadVersionToState({
                id: Date.now(),
                version_number: '',
                text: '' });
            setEditablePolicy(true);
        }
        // change button menu state
        setButtonState(buttons.map((btn, i) => {
            delete btn.active;
            if (i === 0) btn.active = true;
            if (i === 2) delete btn.disabled;
            return btn;
        }));
    };

    // get policy versions from server and load into state
    const fetchVersions = (policyId) => {
        if (policyId) {
            axios.get(`/policy/${policyId}/versions`)
                .then(res => {
                    if (res && res.data) {
                        const result = res.data.result;
                        if (result.length) result[0].active = true;
                        loadVersionList(result);
                        setFetchedVersion(result);
                    }
                }).catch(e => console.log(e));
        }
    };

    // fetch the most recent version of a policy
    const fetchMostRecentVersion = () => {
        axios.get(`/policy/${singlePolicy.id}/versions`)
            .then(res => {
                loadVersionToState(res.data.result[0]);
                setMostCurrentVersion(res.data.result[0]);
            })
            .catch(e => console.log(e));
    }

    // setup view for older versions
    const loadPreviousVersions = () => {
        const { versionNumber } = props.match.params;
        if (singlePolicy.title) {
            props.history.push(`/dashboard/Policy/${singlePolicy.title.split(' ').join('-')}/${singlePolicy.id}/${versionNumber}`);
            fetchVersions(singlePolicy.id);
            fetchMostRecentVersion();
            setEditablePolicy(false);
        }
        // change button menu state
        setButtonState(buttons.map((btn, i) => {
            delete btn.active;
            if (i === 1) btn.active = true;
            if (i ===2) btn.disabled = true;
            return btn;
        }));
    };

    const createNewVersion = (e) => {
        if (e && e.preventDefault) e.preventDefault();
        // prevent double submission
        if (loading) return;

        // validate form
        const errors = {};
        const validation = validate({ version: policyVersion.version_number, text: policyVersion.text }, constraints);
        if (validation) {
            if (validation.version) errors.version = validation.version;
            if (validation.text) errors.text = validation.text;
            setErrors(errors);
            return;
        } else {
            setErrors(errors);
        }
        setLoading(true);
        // create new version
        axios.post(`/policy/new-version`, {
            policy_id: singlePolicy.id,
            version_number: policyVersion.version_number,
            content: policyVersion.text,
        }).then(res => {
            loadPreviousVersions();
            fetchMostRecentVersion();
            setEditablePolicy(false);
            setLoading(false);
        }).catch(e => console.log(e));
    };

    const changePolicyStatus = (status) => {
        if (hiddenLoad) return;
        setHiddenLoad(true);
        axios.put(`/policy/${singlePolicy.id}/status/${status}`)
            .then(() => {
                loadPolicyToState({
                    ...singlePolicy,
                    statusId: status ===  ACTIVE ? ACTIVE : DEACTIVATE,
                });
                setHiddenLoad(false);
            })
            .catch(e => console.log(e));
    };

    // on mount scheduling
    useEffect( () => {
        const { policyId, versionNumber } = props.match.params;
        // load policy into state right away
        axios.get(`/policy/single/${policyId}`)
            .then(res => {
                const policy = res.data.result;
                loadPolicyToState({ title: policy.title, id: parseInt(policyId), statusId: policy.status_id });
                setLoading(false);
            })
            .catch(e => console.log(e));

        // set correct buttons
        if (versionNumber !== 'new') {
            setButtonState(buttons.map((btn, i) => {
                if (i === 2) btn.disabled = true;
                return btn;
            }));
        } else {
            setButtonState(buttons.map((btn, i) => {
                delete btn.active;
                if (i === 0) btn.active = true;
                return btn;
            }));
        }
    }, []);

    // updates for when singlePolicy changes
    useEffect(() => {
        // update closures in buttons
        setButtonState(buttonState.map((btn, i) => {
            if (i===0) btn.onClick = createNew;
            if (i ===1) btn.onClick = loadPreviousVersions;
            if (i === 2) btn.onClick = createNewVersion;
            return btn;
        }))

        // update versions
        const { versionNumber } = props.match.params;
        // check if versions have already been fetched or if user is creating a new version
        if (singlePolicy.title) {
            if (!fetchedVersion && versionNumber !== 'new') {
                // version id is present, load previous version into state
                setEditablePolicy(false);
                loadPreviousVersions();
                // load recent version into state
                fetchMostRecentVersion()
            } else if (versionNumber === 'new') {
                // version id is not present. load empty version into state
                setEditablePolicy(true);
                loadVersionToState({ id: Date.now(), text:'', version_number:'' });
                axios.get(`/policy/${singlePolicy.id}/versions`)
                    .then(res => {
                        setMostCurrentVersion(res.data.result[0]);
                    })
                    .catch(e => console.log(e));

            }
            fetchVersions(singlePolicy.id);
        }

    }, [ singlePolicy ]);

    // updates for when a policyVersion changes
    useEffect(() => {
        // change button menu state
        if (policyVersion.created_at) {
            setButtonState(buttons.map((btn, i) => {
                delete btn.active;
                if (i === 1) btn.active = true;
                if (i === 2) btn.disabled = true;
                return btn;
            }));
            props.history.push(`/dashboard/Policy/${singlePolicy.title.split(' ').join('-')}/${singlePolicy.id}/${policyVersion.version_number}`);
        } else {
            setButtonState(buttonState.map((btn, i) => {
                if (i===0) btn.onClick = createNew;
                if (i ===1) btn.onClick = loadPreviousVersions;
                if (i === 2) btn.onClick = createNewVersion;
                return btn;
            }))
        }
    }, [ policyVersion ]);

    // clear state when component unmounts
    useEffect(() => {
        return () => {
            loadVersionToState({});
            loadPolicyToState({});
            loadVersionList([]);
        }
    }, []);

    if (loading) {
        const offset = (window.innerHeight - 76)/2;
        return (
            <div style={{ position: 'relative', top: offset }}>
                <Loading />
            </div>
        )
    }

    return (
		<div>
			<div className="page-header-container searchForm-container">
				<div className='container'>
					<div className="row">
						<div className="col-sm-12">
							<nav>
								<ol className="breadcrumb ">
									<li className="breadcrumb-item underline ">
										<a href="/dashboard" className="text-light">Dashboard</a></li>
									<span className="arrow"/>
									<li className="breadcrumb-item underline ">
										<a href="/dashboard/Policy" className="text-light">Policy</a></li>
									<span className="arrow"/>
									<li className="breadcrumb-item text-light active">{props.match.params.versionNumber}</li>
								</ol>
							</nav>
						</div>
						<div className="col-xs-12">
							<h1>Policy Versions</h1>
						</div>
					</div>
				</div>
			</div>

			<div className="container margin-top-3x">
				<div className="row">
					<div className="col-xs-12 col-sm-3">
						<div className="widget-container margin-bottom-2x">
							<ButtonList list={buttonState}/>
						</div>	
						<div className=" text-center margin-bottom-2x">
							<BasicButton 								
								className="btn"
								disabled={!versionList || versionList.length < 1}
								onClick={() => changePolicyStatus(singlePolicy.statusId === ACTIVE ? DEACTIVATE : ACTIVE)}>
								{singlePolicy.statusId === ACTIVE ? 'Deactivate Policy' : 'Activate Policy'}								
							</BasicButton>
						</div>
					</div>
					<div className="col-xs-12 col-sm-9">
						<div className="widget-container margin-bottom-2x">
					    	<PolicyVersion
                                mostCurrentVersion={mostCurrentVersion}
                                editable={editablePolicy}
                                errors={errors}/>
						</div>
					</div>
				</div>				
			</div>
		</div>
    )
};

const mapStateToProps = ({ singlePolicy, policyVersion, editablePolicy, versionList }) => {
    return {
        singlePolicy,
        policyVersion,
        editablePolicy,
        versionList
    }
};

const actions = {
    loadVersionToState,
    loadPolicyToState,
    loadVersionList,
    setEditablePolicy,
};

export default connect(mapStateToProps, actions)(PolicyVersionNewContainer);

PolicyVersionNewContainer.propTypes = {
    singlePolicy: PropTypes.shape({
        policy_id: PropTypes.string,
    }),
    policyVersion: PropTypes.object,
    editablePolicy: PropTypes.bool,
    versionList: PropTypes.array,
    loadPolicyToState: PropTypes.func,
    loadVersionToState: PropTypes.func,
    loadVersionList: PropTypes.func,
    setEditablePolicy: PropTypes.func,
    match: PropTypes.shape({
        params: PropTypes.shape({
            versionNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            policyId: PropTypes.number
        })
    })
};

PolicyVersion.defaultProps = {
    editablePolicy: false,
};