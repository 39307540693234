import React from 'react';

// styles
import styles from './intro.module.css';

// components
import {PrimaryButton} from "../../buttons/buttons";
import HabitsBlock from "../../../images/habits_blocks.jpg";

const Intro = props => {
    // props
    const {user, next} = props;

    return (
        <div className={styles.fadeIn}>
            <h2 className={styles.name} data-cy="introName">Hi {user.first_name},</h2>
            <p className={styles.introHeading}>Are you ready to start your journey to <strong>healthier habits?</strong></p>
            <div>
                <img className={styles.clipboard} src={HabitsBlock} alt="" data-cy="habitImage"/>
            </div>
            <p className={styles.intro}>We researched which lifestyle habits are most effective for achieving the health goal you select. It’s daily habits that drive long-term results. Ready to identify your top goal?</p>
            <div className={styles.start}>
                <PrimaryButton onClick={() => next(2)} data-cy="startAssesmentButton">Choose Your Health Goal</PrimaryButton>
            </div>
        </div>
    )
};

export default Intro;