import React from 'react';

// components
import {VICTORY_TYPES} from "../../serverVariables/victoryTypes";
import Badge from "../badge/badge";
import {ReactComponent as StreakIcon} from "../../images/icons/Victory_Streak.svg";
import {CheckIn15_200_shadow, CheckIn8_200_shadow, Watched_8_shadow, Watched_15_shadow} from "../../serverVariables/badges";
import PathwaysVictoryTiles from "../pathwaysVictoryTiles/pathwaysVictoryTiles";
import RankMedal from "../rankMedal/rankMedal";

const SelectVictoryImage = (props) => {
    const {typeId, victory} = props;

    if (typeId === VICTORY_TYPES.BADGES) {
        let id = victory.victory.level_achievement.level.id;
        if (!id) id = victory.victory.level_achievement.level_id;
        return <Badge className={props.className} shadow badge={id}/>
    }
    if (typeId === VICTORY_TYPES.STREAK) {
        return <StreakIcon className={props.className}/>;
    }
    if (typeId === VICTORY_TYPES.CHECK_IN_8) {
        return <img className={props.className} src={CheckIn8_200_shadow} alt=''/>;
    }
    if (typeId === VICTORY_TYPES.CHECK_IN_15) {
        return <img className={props.className} src={CheckIn15_200_shadow} alt=''/>;
    }
    if (
        typeId === VICTORY_TYPES.MEALTIME_SPACING ||
        typeId === VICTORY_TYPES.EATING_OCCASIONS ||
        typeId === VICTORY_TYPES.VEGETABLES_AND_FRUIT ||
        typeId === VICTORY_TYPES.PROTEIN_SERVINGS ||
        typeId === VICTORY_TYPES.SWEETENED_FOODS ||
        typeId === VICTORY_TYPES.SWEETENED_DRINKS ||
        typeId === VICTORY_TYPES.STRESS_RELEASING ||
        typeId === VICTORY_TYPES.SLEEP ||
        typeId === VICTORY_TYPES.PHYSICAL_ACTIVITY ||
        typeId === VICTORY_TYPES.SPRINT_INTENSITY ||
        typeId === VICTORY_TYPES.STRENGTH_BUILDING ||
        typeId === VICTORY_TYPES.RELATED_SKILLS ||
        typeId === VICTORY_TYPES.FOUNDATIONS ||
        typeId === VICTORY_TYPES.COACH ||
        typeId === VICTORY_TYPES.JUST_FOR_YOU
    ) {
        return <PathwaysVictoryTiles pathway={typeId} size={200} className={props.className}/>
    }

    if (typeId === VICTORY_TYPES.RANK) {
        return <RankMedal className={props.className} rank={victory.victory.level_achievement.rank.id}/>;
    }
    if (typeId === VICTORY_TYPES.VIDEOS_WATCHED_8) {
        return <img className={props.className} src={Watched_8_shadow} alt=''/>;
    }
    if (typeId === VICTORY_TYPES.VIDEOS_WATCHED_15) {
        return <img className={props.className} src={Watched_15_shadow} alt=''/>;
    }
    else return <div/>
}

export default SelectVictoryImage;