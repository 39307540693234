import mainApi from "./mainApi";

export function analyticsApi() {
    return {
        // get the number of new members
        getNewMembers: function(query) {
            return mainApi.get(`/facts/user/new/members${formatQuery(query)}`);
        },
        // get the number of actively engaged members
        getActiveMembers: function(query) {
            return mainApi.get(`/facts/user/engagement/count${formatQuery(query)}`);
        },
        // get the daily log of new members
        getNewMemberLog: function(query) {
            return mainApi.get(`/facts/user/new/members/graph${formatQuery(query)}`);
        },
        // get the top organizations
        getTopOrganization: function(query) {
            return mainApi.get(`/facts/user/top/clients${formatQuery(query)}`);
        },
        // get the conversion metrics of a signup group
        getConversionMetrics: function(query) {
            return mainApi.get(`/facts/user/conversion${formatQuery(query)}`);
        },
        // get abandonment metrics
        getAbandonmentMetrics: function(query) {
            return mainApi.get(`/facts/user/abandonment${formatQuery(query)}`)
        },
        // get a graph of member engagement
        getMemberEngagement: function(query) {
            return mainApi.get(`/facts/user/engagement/members/graph${formatQuery(query)}`);
        },
        // get a graph of member session length
        getMemberSessionData: function(query) {
            return mainApi.get(`/facts/user/engagement/sessions/graph${formatQuery(query)}`);
        },
        // get a graph of demo data - age
        getAgeDemographics: function(query) {
            return mainApi.get(`/facts/user/age/demo${formatQuery(query)}`);
        },
        // get a graph of demo data - weight
        getWeightDemographics: function(query) {
            return mainApi.get(`/facts/user/weight/demo${formatQuery(query)}`);
        },
        // get a graph of demo data - height
        getHeightDemographics: function(query) {
            return mainApi.get(`/facts/user/height/demo${formatQuery(query)}`);
        },
        // get a graph of demo data - gender
        getGenderDemographics: function(query) {
            return mainApi.get(`/facts/user/gender/demo${formatQuery(query)}`);
        },
        // get total content views
        getContentViews: function(query) {
            return mainApi.get(`/facts/content/total/views${formatQuery(query)}`);
        },
        // get total content watch time
        getContentWatchTime: function(query) {
            return mainApi.get(`/facts/content/total/watchtime${formatQuery(query)}`);
        },
        // get the top category watched
        getTopCategoryWatched: function(query) {
            return mainApi.get(`/facts/content/top/category${formatQuery(query)}`);
        },
        // get the top content piece watched
        getTopContentWatched: function(query) {
            return mainApi.get(`/facts/content/top/content${formatQuery(query)}`);
        },
        // get the metrics for pathways content
        getPathwaysViews: function(query) {
            return mainApi.get(`/facts/content/pathway/stats${formatQuery(query)}`);
        },
        // get the least watched category
        getLeastWatchedCategory: function(query) {
            return mainApi.get(`/facts/category/least-viewed${formatQuery(query)}`);
        },
        // get list of content in order of popularity or duration
        searchContentPopularity: function(query, searchParams) {
            let formattedQuery = formatQuery(query);
            formattedQuery += `page=${searchParams.page}&`;
            if (searchParams.title) formattedQuery += `title=${searchParams.title}&`;
            if (searchParams.category) formattedQuery += `category_id=${searchParams.category}&`;
            if (searchParams.contentType) formattedQuery += `content_type_id=${searchParams.contentType}&`;
            if (searchParams.sort) formattedQuery += `sort=${searchParams.sort}&`;
            return mainApi.post(`/facts/content/list${formattedQuery}`);
        },
        // get the content view log data
        getContentViewsLog: function(query) {
            return mainApi.get(`/facts/content/graph/views${formatQuery(query)}`);
        },
        // get the content watch time log data
        getContentWatchTimeLog: function(query) {
            return mainApi.get(`/facts/content/graph/watchtime${formatQuery(query)}`);
        },
        // get the content average duration watched log data
        getContentDurationLog: function(query) {
            return mainApi.get(`/facts/content/graph/average/duration${formatQuery(query)}`);
        },
        // get the category view log data
        getCategoryViewsLog: function(query) {
            return mainApi.get(`/facts/category/graph/views${formatQuery(query)}`);
        },
        // get the category watch time log data
        getCategoryWatchTimeLog: function(query) {
            return mainApi.get(`/facts/category/graph/watchtime${formatQuery(query)}`);
        },
        // get the category average duration watched log data
        getCategoryDurationLog: function(query) {
            return mainApi.get(`/facts/category/graph/average/duration${formatQuery(query)}`);
        },
        // get the members that a coach had assigned to them during a period
        getCoachAssignedMembers: function(query) {
            return mainApi.get(`/facts/coach/assigned/members${formatQuery(query)}`);
        },
        // get the organizations that a coach had assigned to them during a period
        getCoachAssignedOrganizations: function(query) {
            return mainApi.get(`/facts/coach/assigned/organizations${formatQuery(query)}`);
        },
        // get the daily log of a coach's member growth
        getCoachMemberGrowthLog: function(query) {
            return mainApi.get(`/facts/coach/growth/graph${formatQuery(query)}`);
        },
        // get the daily log of a coach's messaging history
        getCoachMessageLog: function(query) {
            return mainApi.get(`/facts/coach/messaging/graph${formatQuery(query)}`);
        },
        // get the totaled messaging stats of a coach
        getCoachMessageStats: function(query) {
            return mainApi.get(`/facts/coach/messaging/stats${formatQuery(query)}`);
        },
        // get the number of threads a coach started
        getCoachStartedThreadCount: function(query) {
            return mainApi.get(`/facts/coach/new/threads${formatQuery(query)}`);
        },
        // get the number of threads a coach reopened
        getCoachReopenedThreadCount: function(query) {
            return mainApi.get(`/facts/coach/reopened/threads${formatQuery(query)}`);
        },
        // get the response time stats for a coach
        getCoachResponseTimeStats: function(query) {
            return mainApi.get(`/facts/coach/average/response/stats${formatQuery(query)}`);
        },
        // get the daily log of a coaches schedule
        getCoachSchedulingLog: function(query) {
            return mainApi.get(`/facts/coach/scheduling/graph${formatQuery(query)}`);
        },
        // get the calendar stats of a coach
        getCoachCalendarStats: function(query) {
            return mainApi.get(`/facts/coach/appointment/list${formatQuery(query)}`);
        },
        // get the drop off ratio of a coach
        getCoachDropoff: function(query) {
            return mainApi.get(`/facts/coach/member/dropoff${formatQuery(query)}`);
        },
        // get the health goal distribution of a period
        getHealthGoals: function(query) {
            return mainApi.get(`/facts/habit/goal/distribution${formatQuery(query)}`);
        },
        // get analytics victory feed
        getAnalyticsVictoryFeed: function(query, victoryId) {
            let url = `/facts/victories/list${formatQuery(query)}`;
            if (victoryId) url += `&victory_id=${victoryId}`;
            return mainApi.get(url);
        },
        // get the number of profile views members are making on average
        getProfileViews: function(query) {
            return mainApi.get(`/facts/profile/views${formatQuery(query)}`);
        },
        // get the average time between victories being made
        getVictoryTimespan: function(query) {
            return mainApi.get(`/facts/time/between/victories${formatQuery(query)}`);
        },
        // get the victory distribution over a given time
        getVictoryDistribution: function(query) {
            return mainApi.get(`/facts/victory/distribution${formatQuery(query)}`);
        },
        // get the victory distribution over a given time
        getReactionAverages: function(query) {
            return mainApi.get(`/facts/reaction/averages${formatQuery(query)}`);
        },
        // get the members who met their goals for a given time
        getMembersThatMetGoals: function(query) {
            return mainApi.get(`/facts/monthly/goal/achievement${formatQuery(query)}`);
        },
        // get the members who met their goals for a given time via a csv file
        getMembersThatMetGoalsCsv: function(query) {
            return mainApi.get(`/facts/export/monthly/goal/achievement${formatQuery(query)}`);
        },
        getNonAssessmentListMembersExport: function(query) {
            return mainApi.get(`/facts/export/noassessment${formatQuery(query)}`);
        },
        getOptionalAssessmentListMembers:function(query) {
            return mainApi.get(`/facts/noassessment/list${formatQuery(query)}`);
        },

        // amount of memmbers that have taken the assessment and not taken the assessment
        getOptionalAssessmentAmountTaken:function(query) {
            return mainApi.get(`/facts/assessment/stats${formatQuery(query)}`);
        },

        // get wotd watch time 
        getWotdWatchTime: function(query){
            return mainApi.get(`/facts/wotd/watchtime${formatQuery(query)}`)
        },
        getWotdViews: function(query){
            return mainApi.get(`/facts/wotd/views${formatQuery(query)}`)
        },
        getWotdContentViews: function(query){
            return mainApi.get(`/facts/wotd/content/graph/views${formatQuery(query)}`)
        },
        getWotdContentWatchTime: function(query){
            return mainApi.get(`/facts/wotd/content/graph/watchtime${formatQuery(query)}`)
        },
        getWotdContentAvgDuration: function(query){
            return mainApi.get(`/facts/wotd/content/graph/average${formatQuery(query)}`)
        },
        getWotdCategoryViews: function(query){
            return mainApi.get(`/facts/wotd/category/graph/views${formatQuery(query)}`)
        },
        getWotdCategoryWatchTime: function(query){
            return mainApi.get(`/facts/wotd/category/graph/watchtime${formatQuery(query)}`)
        },
        getWotdCategoryAvgDuration: function(query){
            return mainApi.get(`/facts/wotd/category/graph/average${formatQuery(query)}`)
        },
        getWotdVideoList: function(query, searchParams){
            let formattedQuery = formatQuery(query);
            formattedQuery += `page=${searchParams.page}&`;
            if (searchParams.title) formattedQuery += `title=${searchParams.title}&`;
            if (searchParams.category) formattedQuery += `category_id=${searchParams.category}&`;
            if (searchParams.contentType) formattedQuery += `content_type_id=${searchParams.contentType}&`;
            if (searchParams.sort) formattedQuery += `sort=${searchParams.sort}&`;
            return mainApi.post(`/facts/wotd/content/list${formattedQuery}`);
        },

        // new daily wotd video routes for analytics
        getDailyWotdViews: function(query){
            return mainApi.get(`/facts/wotd/v2/views${formatQuery(query)}`)
        },
        getDailyWotdWatchTime: function(query){
            return mainApi.get(`/facts/wotd/v2/watchtime${formatQuery(query)}`)
        },
        getDailyWotdWatchTime: function(query){
            return mainApi.get(`/facts/wotd/v2/watchtime${formatQuery(query)}`)
        },
        getDailyWotdCategoryWatchTime: function(query){
            return mainApi.get(`/facts/wotd/v2/category/graph/watchtime${formatQuery(query)}`)
        },
        getDailyWotdCategoryViews: function(query){
            return mainApi.get(`/facts/wotd/v2/category/graph/views${formatQuery(query)}`)
        },
        getDailyWotdCategoryAverage: function(query){
            return mainApi.get(`/facts/wotd/v2/category/graph/average${formatQuery(query)}`)
        },
        getDailyWotdContentWatchTime: function(query){
            return mainApi.get(`/facts/wotd/v2/content/graph/watchtime${formatQuery(query)}`)
        },
        getDailyWotdContentViews: function(query){
            return mainApi.get(`/facts/wotd/v2/content/graph/views${formatQuery(query)}`)
        },
        getDailyContentWotdAverage: function(query){
            return mainApi.get(`/facts/wotd/v2/content/graph/average${formatQuery(query)}`)
        },
        getDailyContentList: function(query, searchParams){
            let formattedQuery = formatQuery(query);
            formattedQuery += `page=${searchParams.page}&`;
            if (searchParams.title) formattedQuery += `title=${searchParams.title}&`;
            if (searchParams.category) formattedQuery += `category_id=${searchParams.category}&`;
            if (searchParams.contentType) formattedQuery += `content_type_id=${searchParams.contentType}&`;
            if (searchParams.sort) formattedQuery += `sort=${searchParams.sort}&`;
            return mainApi.post(`/facts/wotd/v2/content/list${formattedQuery}`)
        },
        getHealthConnectSyncedCount: function(query){
            return mainApi.get(`/facts/health-connect/synced/count${formatQuery(query)}`)
        },
    }
}

function formatQuery(query) {
    let queryString = '?';
    queryString += `start_date=${query.start.toISOString()}&`;
    queryString += `end_date=${query.end.toISOString()}&`;
    if (query.organizationId) queryString += `organization_id=${query.organizationId}&`;
    if (query.statusId) queryString += `member_status=${query.statusId}&`;
    if (query.coach) queryString += `coach=${query.coach}&`;
    if (query.gender) queryString += `gender=${query.gender}&`;
    if (query.ageGroup) queryString += `age=${query.ageGroup}&`;
    if (query.rank) queryString += `rank=${query.rank}&`;
    return queryString;
}