import {FETCH_TILE} from "../actions/action_tile";
import {CLEAR_TILE} from "../actions/action_tile";

export default function(state={tile:null}, action){
    switch (action.type){
        case FETCH_TILE:
            return {tile:action.payload.data.tile};

        case CLEAR_TILE:
            return Object.assign({}, action.payload);
        // no default
    }
    return state;
}
