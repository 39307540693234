import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import{getIntroTile} from "../../../../actions/action_tile";
import ContentDisplayContainer from '../../../contentDisplay/MainContainer';
import _ from 'lodash';


class TileContainer extends Component{
    constructor(props){
        super(props);
        this.state={
            tile_id:this.props.match.params.tile,
            content_categories:[],
        };
    }

    componentDidMount(){
        if(!this.props.tile){
            this.props.getIntroTile(this.state.tile_id);
        }else if(this.props.tile){
            this.setCategories();
        }
    }

    componentDidUpdate(prevPros){
        if(!_.isEqual(prevPros.tile, this.props.tile)){
            this.setCategories();
        }
    }

    setCategories=()=>{
        if(this.props.tile){
            this.setState({content_categories:[{name:this.props.tile.tile.tile_category}]});
        }
    };

    render(){
        return (
            <div className="container margin-top">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <h2>{this.props.tile ? this.props.tile.tile.tile_category : null}</h2>
                        {this.props.tile ? this.props.tile.plan.map(plan=>{
                            return plan.description;
                        }) : null}
                    </div>
                </div>

                <div className="row">
                    <div class="col-sm-12">
                        <h3>Recommended Resources</h3>
                        <div class="widget-container">
                                <ContentDisplayContainer
                                categories={this.state.content_categories}
                                defaultValue={true}
                                tile={this.props.tile ? this.props.tile.tile : null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps=({intro_tile})=>{
    return{
        tile:intro_tile
    }
};

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({getIntroTile}, dispatch)
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TileContainer));