import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import styles from './AddEquipBtn.module.css';

class ModifyEquipment extends React.Component {
    constructor(props){
        super(props);
          this.state={
            equipment:this.props.name,
            sprint8:this.props.sprint8
        }
    }

  equipmentChange = (e) => {
      this.setState({equipment:e.target.value}, () => {
        this.props.equipmentModifyChangeHandler(this.state.equipment);
      })
  };  

  sprint8Change = (e) => {
      this.setState({sprint8:!this.state.sprint8}, () => {
        this.props.sprint8ModifyChangeHandler(this.state.sprint8);
      })
  };    

  modifyGym(){
      axios.post(`/user/client-equipment/${this.props.username}/update/${this.props.id}`, {
        name: this.state.equipment,
        sprint8:this.state.sprint8,
      })
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.setState({ show: true });
                this.setState({ alert: 'success' });
                this.setState({ alertStyle: true });
                this.setState({ update: true })
                this.props.updateChangeHandler(this.state.update);
            }})
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
    }

    deleteGym(){
    axios.delete(`/user/client-equipment/${this.props.username}/delete/${this.props.id}`)
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.setState({ show: true });
                this.setState({ alert: 'success' });
                this.setState({ alertStyle: true });
                this.setState({ update: true })
                this.props.updateChangeHandler(this.state.update);
            }})
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
    }

	render() {
    console.log(this.state.sprint8)
    return (
      <div className="col-md-6 margin-bottom-med">
          <BoxContainer className='padding-standard'>
              <div>
                  <div>
                      <label>EQUIPMENT #{this.props.index + 1}</label>
                      <input
                          type="text"
                          // placeholder={this.props.name}
                          value={this.state.equipment}
                          onChange={(e) => this.equipmentChange(e)}
                      />
                      <input className="sprint8-check" id="checkBox" defaultChecked={this.props.sprint8}
                             value={this.state.sprint8} type="checkbox" onChange={(e) => this.sprint8Change(e)}/>
                      <label className="sprint8-check-label">Sprint8 Equipped</label>
                  </div>
              </div>

              <div className="row">

                  {this.props.id
                      ?  <div className={`margin-top ${styles.equipBtnContainer}`}>
                          <div className="col-xs-6">
                              <button className="btn add-equip-btn" onClick={(e) => this.modifyGym(e)}>SAVE
                                  CHANGES</button>
                          </div>
                          <div className="col-xs-6">
                              <button className="btn delete-equip-btn" onClick={(e) => this.deleteGym(e)}>DELETE</button>
                          </div>
                      </div>
                      : null
                  }


              </div>
          </BoxContainer>

      </div>
    );
  }
}

export default withRouter(ModifyEquipment);


