import React, {useEffect, useState} from 'react';
import validate from 'validate.js';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

// actions
import {getOrganizationCoaches, resetOrganizationCoaches} from "../../../actions/action_organizations";

// components
import { PrimaryButton } from "../../buttons/buttons";
import { ErrorAlert, InfoAlert } from "../../alerts/alerts";

const constraints = {
    firstName: {
        presence: '^First name is required',
        type: 'string',
        length: {
            minimum: 1,
            maximum: 50,
            tooShort: '^First name is required',
            tooLong: '^First name must be less than 50 characters'
        },
    },
    lastName: {
        presence: '^Last name is required',
        type: 'string',
        length: {
            minimum: 1,
            maximum: 50,
            tooShort: '^Last name is required',
            tooLong: '^Last name must be less than 50 characters'
        },
    },
    email: {
        presence: '^Email is required',
        type: 'string',
        email: { message: '^Enter a valid email address'},
        length: {
            maximum: 255,
            tooLong: '^Email must be less than 255 characters'
        }
    },
    password: {
        presence: '^Password is required',
        type: 'string',
        length: {
            minimum: 8,
            maximum: 50,
            tooShort: '^Password must be at least 8 characters long',
            tooLong: '^Password must be less than 50 characters'
        },
    },
    confirmPassword: {
        presence: '^Password is required',
        type: 'string',
        length: {
            minimum: 8,
            maximum: 50,
            tooShort: '^Password must be at least 8 characters long',
            tooLong: '^Password must be less than 50 characters'
        },
    }
};

const OrganizationNewClient = ({ organization, getCoaches, resetCoaches, history }) => {
    const [ state, updateState ] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',

    });
    const [ loading, setLoading ] = useState(false);
    const [ submitLoading, setSubmitLoading ] = useState(false);
    const [ loadingError, setLoadingError ] = useState('');
    const [ validation, setValidation ] = useState({});
    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    function submit(e) {
        e.preventDefault();
        if (loading || submitLoading) return; // prevent double submission
        setLoading(true);

        // validation
        const errors = {};
        const { firstName, lastName, email, password, confirmPassword } = state;
        const validation = validate({
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
        }, constraints);
        if (validation) {
            Object.keys(validation).map(key => errors[key] = validation[key]);
        }
        if (password !== confirmPassword) errors.confirmPassword = ['Passwords do not match'];
        setValidation(errors);
        if (!isEmpty(errors)) {
            setLoading(false);
            return;
        }
        setLoading(false);
        setSubmitLoading(true)
        // create user
        axios.post(`/organizations/admin/new-client/${organization.id}`, {
            first_name: firstName,
            last_name: lastName,
            email,
            password
        })
            .then(() => {
                // forward to user page
                history.push(`/client/${email}/overview`);
            })
            .catch(e => {
                setLoadingError(e.response.data.error);
                setSubmitLoading(false);
            })
    }

    // grab coaches on mount and add to state
    // remove coach on dismount
    useEffect(() => {
        getCoaches(organization.id)
            .catch(() => setLoadingError('Unable to load coach list for organization'));
        return () => resetCoaches();
    }, []);

    return (
        <form onSubmit={submit}>
            <div>
                <h2>New Member</h2>
                <p>SAP Account # {organization.sap}</p>
            </div>
            <div>
                { loadingError ? <ErrorAlert>{loadingError}</ErrorAlert> : null }
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <h3>Member Info</h3>
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                            value={state.firstName}
                            onChange={e => setState({ firstName: e.target.value })}
                            type="text"
                            id="firstName"
                            className="form-control"/>
                        { validation.firstName ?
                            <div>{validation.firstName.map(err => <span className="text-danger">{err}</span>)}</div>
                            : null
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            value={state.lastName}
                            onChange={e => setState({ lastName: e.target.value })}
                            type="text"
                            id="lastName"
                            className="form-control"/>
                        { validation.lastName ?
                            <div>{validation.lastName.map(err => <span className="text-danger">{err}</span>)}</div>
                            : null
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            value={state.email}
                            onChange={e => setState({ email: e.target.value })}
                            type="email"
                            id="email"
                            className="form-control"/>
                        { validation.email ?
                            <div>{validation.email.map(err => <span className="text-danger">{err}</span>)}</div>
                            : null
                        }
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <h3>Password</h3>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            value={state.password}
                            onChange={e => setState({ password: e.target.value })}
                            type="password"
                            id="password"
                            className="form-control"/>
                        { validation.password ?
                            <div>{validation.password.map(err => <span className="text-danger">{err}</span>)}</div>
                            : null
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            value={state.confirmPassword}
                            onChange={e => setState({ confirmPassword: e.target.value })}
                            type="password"
                            id="confirmPassword"
                            className="form-control"/>
                        { validation.confirmPassword ?
                            <div>{validation.confirmPassword.map(err => <span className="text-danger">{err}</span>)}</div>
                            : null
                        }
                    </div>
                </div>
            </div>
            <div>
                {
                    submitLoading ?
                        <InfoAlert>
                            Creating member. This may take a minute.
                        </InfoAlert>
                        :
                        <PrimaryButton type='submit' onClick={submit}>Create Member</PrimaryButton>
                }
            </div>
        </form>
    )
}

const actions = {
    getCoaches: getOrganizationCoaches,
    resetCoaches: resetOrganizationCoaches,
};

export default withRouter(connect(null, actions)(OrganizationNewClient));