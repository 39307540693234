import React from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import NewEquipment from './NewEquipment';
import ModifyEquipment from './ModifyEquipment';



class Equipment extends React.Component {
    constructor(){
        super();
          this.state={
          equipArr: [],
          equipment:'',
          show: false,
          alertStyle: true,
          alert: '',
          showAdd:true,
          equipArrNew: [],
        }
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    }

    getEquipList(){
      this.setState({showAdd:true})
      this.setState({equipArr:[]})
      this.setState({equipArrNew:[]})
      axios.get(`/user/client-equipment/${this.props.username}`)
      .then((res)=> {
        console.log(res)
        this.setState({equipArr:res.data.result})
      })
        .catch((error)=>{
           console.log(error);
        });
    }

    componentDidMount() {
      this.getEquipList();
    }

  equipListModify=()=>{
        if(this.state.equipArr){
            return this.state.equipArr.map((item, i)=>{
              let equip = this.state.equipArr[i];
                   return(
                    <ModifyEquipment
                    name={equip.name}
                    sprint8={equip.sprint8}
                    index={i}
                    equipmentModifyChangeHandler={this.equipmentModifyChangeHandler}
                    sprint8ModifyChangeHandler={this.sprint8ModifyChangeHandler}
                    id={equip.id}
                    username={this.props.username}
                    updateChangeHandler={this.updateChangeHandler}
                    />
                   )
            });
        }
    };

  equipListNew=()=>{
        if(this.state.equipArrNew){
            return this.state.equipArrNew.map((item, i)=>{
              let equip = this.state.equipArrNew[i];
                   return(
                    <div>
                      <NewEquipment 
                      name={equip.name}
                      sprint8={equip.sprint8}
                      index={i}
                      equipmentNewChangeHandler={this.equipmentNewChangeHandler}
                      sprint8NewChangeHandler={this.sprint8NewChangeHandler}
                      id={equip.id}
                      username={this.props.username}
                      updateChangeHandler={this.updateChangeHandler}
                      showAddChangeHandler={this.showAddChangeHandler}
                      />
                    </div>
                   )
            });
        }
    };

  goBack() {
    window.history.back();
  }

  updateChangeHandler = (value) => {
      if(value === true){
        this.getEquipList();
      }
  }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  showAddChangeHandler = (show) => {
    this.setState({showAdd:show}, () => {
        if(this.state.showAdd){
            this.setState({equipArrNew:[]})
        }
    });
  }

  equipmentModifyChangeHandler = (equipment) => {
    this.setState({equipmentModify:equipment})
  }

  sprint8ModifyChangeHandler = (sprint8) => {
    this.setState({sprint8Modify:sprint8})
  }

  equipmentNewChangeHandler = (equipment) => {
    this.setState({equipmentNew:equipment})
  }

  sprint8NewChangeHandler = (sprint8) => {
    this.setState({sprint8New:sprint8})
  }

    addItem(e){          
        const obj = {'name':'', };
        this.setState({
            equipArrNew: [...this.state.equipArrNew, obj]
        }, () => {this.setState({showAdd:false})});    
    }

	render() {
    return (
      <div>
        <div>
          <div className="row">
            {this.equipListModify()}
            {this.equipListNew()}
          </div>            
            {this.state.equipArr.length >= 10 || this.state.showAdd === false
              ? null
              : <div>
                    <button className="btn btn-inline add--icon col-md-4" onClick={(e)=>this.addItem(e)}>ADD MORE</button>
                </div>
            }
        </div>
      </div>
    );
  }
}

export default withRouter(Equipment);
