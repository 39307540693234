import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import Select from 'react-select';
import { format } from 'date-fns';
import ReactTooltip from "react-tooltip";

// styles
import styles from "./pathwaysReporting.module.css";

// actions
import {
    setClientPathwayList,
    setClientPathway,
    resetClientPathway,
    fetchMemberPathwayStats,
} from "../../../store/actions/pathwaysActions";
import {fetchContentCategories, fetchContent} from "../../../store/actions/contentActions";

// components
import {STATUSES} from "../../../serverVariables/statuses";
import {SecondaryInfoAlert} from "../../alerts/alerts";
import Loading from "../../Loading/Loading";
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {Modal} from "react-bootstrap";
import {ContentViewContainer} from "../../Admin/Content/contentViewContainer";
import InfoIcon from "../../../images/info_icon.png";

const PathwaysReporting = props => {
    // state
    const {singleUser: {user: member}, clientPathwayList, clientPathway} = props;
    // actions
    const {setClientPathwayList, setClientPathway, resetClientPathway} = props;
    // local
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [stats, setStats] = useState({});
    const [show, setShow] = useState(false);
    const [content, setContent] = useState({});

    function renderMostWatched() {
        let title = 'No Videos Watched';
        let views = '';
        if (stats.most_watched) {
            title = stats.most_watched.title;
            views = stats.most_watched.times_watched;
        }
        return (
            <BoxContainer className={styles.stats}>
                <h2 className="dashBox-text">{title}</h2>
                <p className="dashBox-text">Most Watched Video</p>
                {views ? <p className="dashBox-text">(<span>{views}</span> views)</p> : null}
            </BoxContainer>
        )

    }

    function renderWatched() {
        let percent = 0;
        let watched = 0;
        let total = 0;
        if (!isEmpty(stats) && stats.total_videos > 0) {
            percent = Math.floor((parseInt(stats.total_watched)/parseInt(stats.total_videos))*100);
            watched = stats.total_watched;
            total = stats.total_videos;
        }else if( !isEmpty(stats)) {
            watched = stats.total_watched;
        }
        return (
            <BoxContainer className={styles.stats}>
                <h2 className="dashBox-text">{percent}%</h2>
                <p className="dashBox-text">Total Videos Watched</p>
                <p className="dashBox-text">({watched} watched / {total} total)</p>
            </BoxContainer>
        )
    }

    function renderAssigned() {
        let percent = 0;
        let watched = 0;
        let assigned = 0;
        if (!isEmpty(stats) && stats.total_assigned > 0) {
            percent = Math.floor((parseInt(stats.total_watched)/parseInt(stats.total_assigned))*100);
            assigned = stats.total_assigned;
            watched = stats.total_watched;
        } else if( !isEmpty(stats)) {
            watched = stats.total_watched;
        }
        return (
            <BoxContainer className={styles.stats}>
                <h2 className="dashBox-text pos:r">
                    {percent}%
                    <p className={`hover ${styles.tooltip}`} data-tip='Members may go over 100% if their assigned categories change with Just For You or they are assigned a new health goal'>
                        <img src={InfoIcon} alt='monthly seat info'/>
                    </p>
                </h2>
                <p className="dashBox-text">Assigned Videos Watched</p>
                <p className="dashBox-text">({watched} watched / {assigned} assigned)</p>
            </BoxContainer>
        )
    }

    function renderStatsTable() {
        if (stats && stats.content && stats.content.length) {
            return (
                <tbody>
                {stats.content.map(item => (
                    <tr onClick={() => openModal(item.id)}>
                        <td>{item.status}</td>
                        <td>{item.category.name}</td>
                        <td>{item.title}</td>
                        <td>{item.times_watched}</td>
                        <td>{item.last_watched ? format(new Date(item.last_watched), 'MM-dd-yyyy') : null}</td>
                    </tr>
                ))}
                </tbody>
            )
        }
    }

    function closeModal() {
        setShow(false);
        setContent({});
    }

    async function openModal(contentId) {
        try {
            const result = await fetchContent(contentId);
            setContent(result);
            setShow(true);
        } catch(e) {
            console.log(e);
        }
    }


    // grab members list of pathways on mount
    useEffect(() => {
        setClientPathwayList(member.user_id);
    }, [setClientPathwayList]);

    // grab a single pathway once pathways list has updated
    // reset on dismount
    useEffect(() => {
        if (clientPathwayList.length) {
            setClientPathway(clientPathwayList[0].id, member.user_id);
        }
        return () => resetClientPathway();
    }, [clientPathwayList, setClientPathway]);

    // grab all content categories on mount
    useEffect(() => {
        fetchContentCategories()
            .then(cats => {
                const updated = [{
                    "id": null,
                    "name": "All Categories",
                    "content_type_id": null,
                    "content": [],
                    "subcategories": [],
                }, ...cats];
                setCategories(updated);
            });
    }, [fetchContentCategories]);

    // when the category list changes select the category that corresponds with the users health goal
    useEffect(() => {
        if (categories.length && !isEmpty(clientPathway)) {
            setSelectedCategory({
                value: clientPathway.health_goal_pathways_content.category_id,
                label: clientPathway.health_goal_pathways_content.category_name});
        }
    }, [categories, clientPathway]);

    // when selected category updates, fetch new stats
    useEffect(() => {
        if (selectedCategory) {
            fetchMemberPathwayStats(member.user_id, selectedCategory.value)
                .then(results => {
                    setStats(results.formatted_content);
                })
        }
    }, [selectedCategory]);

    // show message indicating member is still onboarding
    if (member.user_status_id < STATUSES.onboarding_coach_consult_pending) {
        return (
            <div className="container margin-top-lrg">
                <SecondaryInfoAlert>
                    <p>Report data is not available until member completes their assessment and is assigned content.</p>
                </SecondaryInfoAlert>
            </div>
        )
    }
    if (isEmpty(clientPathway) && isEmpty(stats)) return (
        <div className="container margin-top-lrg">
            <Loading/>
        </div>
    )
    return (
        <div>
            <div className={styles.dropdownContainer}>
                <ReactSelectCategories
                    onBlur={() => {
                    }}
                    className='capitalize margin-bottom-2x'
                    name='categories'
                    list={categories}
                    multi={true}
                    value={selectedCategory}
                    onChange={val => setSelectedCategory(val)}
                />
            </div>

            <div className={styles.statsContainer}>
                {renderMostWatched()}
                {renderWatched()}
                {renderAssigned()}
            </div>

            <div>
                <table className="table content-table">
                    <thead>
                    <tr>
                        <th scope="col">Status</th>
                        <th scope="col">Category</th>
                        <th scope="col">Title</th>
                        <th scope="col"># of times watched</th>
                        <th scope='col'>Last Watch Date</th>
                    </tr>
                    </thead>
                    { renderStatsTable()}
                </table>
                { stats && stats.content && stats.content.length < 1 ?
                    <div>
                        <SecondaryInfoAlert>
                            <p>There are no videos in this category.</p>
                        </SecondaryInfoAlert>
                    </div>
                    : null
                }
            </div>
            <Modal dialogClassName='content-modal' show={show} onHide={closeModal}>
                <Modal.Body>
                    <ContentViewContainer
                        title={content.title}
                        content_type={content.content_type}
                        content={content.content}
                        hero_image={content.hero_image}
                        handleClose={closeModal}
                    />
                </Modal.Body>
            </Modal>
            <ReactTooltip  place='top' type='light' event='click' effect='solid' globalEventOff='click' clickable />
        </div>
    )
}

function mapStateToProps({singleUser, clientPathwayList, clientPathway}) {
    return {
        singleUser,
        clientPathwayList,
        clientPathway
    }
}

const actions = {
    setClientPathwayList,
    setClientPathway,
    resetClientPathway,
}
export default connect(mapStateToProps, actions)(PathwaysReporting);

function ReactSelectCategories({ list, label, value, name, onChange, ...props}) {
    const groups = list.map(category => {
        if (category.subcategories.length === 0) return { value: category.id, label: category.name };

        const options = [];
        options.push({ value: category.id, label: category.name });
        category.subcategories.forEach(sub => {
            options.push({value: sub.id, label: sub.name});
        });
        return { label: category.name, options };
    });

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const categoryStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, data) => {
            return {
                ...styles,
                marginLeft: data.options.filter(item => item.label === data.label).length ? 0 : 20
            };
        },
        input: styles => ({ ...styles }),
        placeholder: styles => ({ ...styles }),
        singleValue: (styles, { data }) => ({ ...styles }),
    };

    return(
        <div className={props.className}>
            <Select
                className='select-form-control'
                onBlur={props.onBlur}
                value={value}
                name={name}
                options={groups}
                onChange = {e => {
                    onChange(e)
                }}
                formatGroupLabel={formatGroupLabel}
                styles={categoryStyles}
            />
        </div>
    )
}