//client detail subheader component
import React from 'react';
import './subHeader.css';
import {VictoryProgress} from './victoryProgress';
import axios from 'axios';
import {withRouter, Link} from 'react-router-dom';
import moment from 'moment';

class SubHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			user : {},
        };
        // this.handleShow = this.handleShow.bind(this);

    }


    componentDidMount() {
    	console.log(this.props.username);
      axios.get(`/user/client/single/${this.props.username}`)
        .then(res => {
          this.setState({user:res.data.results});
        })
    }


	render() {
		console.log(this.props.username);
		let lastLogin = moment(this.state.user.last_login).format('MMMM Do YYYY, h:mm a');;
		return(
			<div className="subHeader-container">
				<div className="subHeader">
					<div className="subHeader-content-container">
						<div className="subHeader-img-container">
							<VictoryProgress />
						</div>
						<div className="subHeader-text-container">
							<h4 className="subHeader-text">{this.state.user.firstName} {this.state.user.lastName}</h4>
							<p className="subHeader-text">TREO Score: 12,480</p>
							<p className="subHeader-text">Last Active:{lastLogin}</p>
						</div>
						<div className="subHeader-button-container">
							<button className="subHeader-button">+ Message Member</button>
						</div>
					</div>
                    <div className="subheaderNav">
                        <ul>
                            <li><Link to={this.props.match.url+"/"}>Member Summary</Link></li>
                            <li><Link to={this.props.match.url+"/tiles/"}>Tiles</Link></li>
                            <li><Link to='/'>Calendar</Link></li>
                            <li><Link to='/'>Inbox</Link></li>
                        </ul>
                    </div>
				</div>
			</div>
			);
	}
};

export default withRouter(SubHeader);