import React, {Component} from 'react';
import ClientHeader from "../../clientHeader/clientHeader";
import CoachingTeamMain from './CoachingTeamMain';
import SingleCoach from './SingleCoach';
import {Route} from 'react-router-dom';
import {fetchSelf} from "../../../actions/action_user";
import {connect} from 'react-redux';

class AdminDashMain extends Component{
    constructor(){
        super();
        this.state = {
          wellnessScore:'', 
        }
    }

    render(){
        // eslint-disable-next-line
      var scoreClass = 'low';
      if (this.state.wellnessScore >= 51 && this.state.wellnessScore < 75 ) {
          scoreClass = 'average';
      } else if (this.state.wellnessScore >= 75 && this.state.wellnessScore < 90 ) {
          scoreClass = 'high';
      } else if (this.state.wellnessScore >= 90) {
          scoreClass = 'highest';
      } 

      if(this.props.user){
          return(
              <div className="client-container">
                  <ClientHeader backUrl={`/member/clientDash/${this.props.user.username}`}/>
                  <Route path={`/member/clientDash/${this.props.user.username}/coaching-team/coaches`} render={()=><CoachingTeamMain {...this.props}/>}/>
                  <Route path={`/member/clientDash/${this.props.user.username}/coaching-team/coach/:username`} render={()=><SingleCoach {...this.props}/>}/>
              </div>
          )
      }else{
          return <div> </div>
      }
    }
}


function mapStateToProps({user}){
    return{user}
}

export default connect(mapStateToProps, {fetchSelf})(AdminDashMain);
