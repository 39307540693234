import React from 'react';
import {Link} from 'react-router-dom';
import profileImage from '../../../images/avatar-1577909_960_720.png';

const ProfileImage = ({image, url_path}) =>{
    return(
            <div className="client-summary-profile">
                <Link to={`/${url_path}`}>
                    <img className="responsive-image" src={image ? image : profileImage} alt="Profile"/>
                </Link>
            </div>
    )
};

export default ProfileImage;