import React, { useState, useEffect } from "react";
import randomstring from "randomstring";

// styles
import styles from "./standardDropdown.module.css";

/*
initialize component with array of options with form:
[ { value: x, label: 'y' }, { value: x, label: 'y' }]
 */
export const StandardDropdown = (props) => {
  // props
  const {
    options,
    callback,
    Icon,
    providedValue,
    className,
    maxHeight,
  } = props;
  if (!options || !options.length)
    return "Component must be initialized with options";

  // local
  const [id] = useState(
    randomstring.generate({
      length: 7,
      charset: "alphabetic",
    })
  );
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(providedValue || options[0]);

  useEffect(() => {
    window.addEventListener("click", detectClick);
    return () => window.removeEventListener("click", detectClick);
  }, []);

  function detectClick(e) {
    if (document.getElementById(id).contains(e.target)) {
      // Clicked inside the dropdown
    } else {
      // Clicked outside the dropdown
      const dropdown = document.getElementById(id);
      if (
        dropdown
          .querySelector(`.${styles.customSelect}`)
          .classList.contains(styles.open)
      ) {
        open();
      }
    }
  }

  function open() {
    const dropdown = document.getElementById(id);
    setActive((prevState) => !prevState);
    dropdown
      .querySelector(`.${styles.customSelect}`)
      .classList.toggle(styles.open);
  }

  function selectOption(option) {
    setSelected(option);
    if (callback) callback(option);
  }

  // Apply the `maxHeight` prop to make the options list scrollable
  const optionsStyle = {
    maxHeight: maxHeight || "500px", // Fallback to 200px if not provided
    overflowY: "auto",
  };

  return (
    <div
      id={id}
      className={`${styles.customSelectWrapper} ${
        active ? styles.active : ""
      } ${className || ""} standard-dropdown`}
      onClick={open}
    >
      <div className={styles.customSelect}>
        <div className={styles.customSelectTrigger}>
          {Icon ? (
            <span className={styles.icon}>
              <Icon />
              <span>{selected.label}</span>
            </span>
          ) : (
            <span>{selected.label}</span>
          )}
          <i className={`fas fa-chevron-down ${styles.arrow}`} />
        </div>
        <div className={styles.customOptions} style={optionsStyle}>
          {options.map((option) => (
            <span
              className={`${styles.customOption} ${
                option.value === selected.value ? styles.selected : ""
              }`}
              key={option.value}
              data-value={option.value}
              onClick={() => selectOption(option)}
            >
              {option.label}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
