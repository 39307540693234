import React from 'react';
import {Route} from 'react-router-dom';

// components
import OnDemandList from "./onDemandList/onDemandList";
import SingleOnDemand from "./singleOnDemand/singleOnDemand";

const OnDemandContainer = props => {
    return (
        <div className="client-container pathways-container">
            <Route path={`${props.match.url}/:category`} exact component={OnDemandList}/>
            <Route path={`${props.match.url}/video/:contentId`} exact component={SingleOnDemand}/>
        </div>
    )
}

export default OnDemandContainer