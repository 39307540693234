import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import validate from 'validate.js';

// components
import AssessmentList from "./assessmentList";
import { BasicLink } from "../links/links";
import {BasicButton} from "../buttons/buttons";

const AssessmentListContainer = (props) => {
	const [ list, setList ] = useState([]);
	// state that determines if modal to duplicate assessment is showing
	const [duplicateModal, setDuplicateModal] = useState(false);
	// state the holds the temporary form data of an assessment that may be duplicated while duplicate form modal is open
	const [duplicate, setDuplicate] = useState(null);
	// state that holds the title of a new duplicate assessment when modal is open
	const [duplicateTitle, setDuplicateTitle] = useState('');
	// state that holds errors for duplicate creation form
	const [duplicateErrors, setDuplicateErrors] = useState([]);
	// holds loading state of duplicate creation
	const [duplicateLoading, setDuplicateLoading] = useState(false);

    const getForms = () => {
        axios.get('/forms/list')
            .then(data => {
                if (Array.isArray(data.data.result)){
                    setList(data.data.result)
                }else{ setList([data.data.result])}
            })
            .catch(e => console.log(e));
    };

    const setDefault = async (listItem) => {
        await axios.post('/forms/group', { formId: listItem.id, groupId: 1 });
        getForms();
    };

    const duplicateAssessment = async(listItem) => {
    	setDuplicateLoading(true); // prevent double form submission

		// validation
		const constraints = {
			title: {
				presence: true,
				type: 'string',
				length: { minimum: 1, maximum: 255 }
			}
		};
		const errors = {};
		const validation = validate({ title: duplicateTitle }, constraints);
		if (validation) {
			if (validation.title) errors.title = validation.title;
			setDuplicateErrors(errors);
			return;
		}
		setDuplicateErrors(errors);

		// create new copy
    	const res = await axios.post('/forms/copy', { formId: listItem.id, title: duplicateTitle })
			.catch(e => console.log(e));

    	// forward to copy's local edit screen
		props.history.push({
			pathname: `/dashboard/Assessments/modify/${res.data.result.id}`,
			state: { duplicate: true }
		});

	};

    const openDuplicateModal = (listItem) => {
    	setDuplicate(listItem);
    	setDuplicateModal(true);
	};

    const closeDuplicateModal = () => {
    	setDuplicate(null);
    	setDuplicateTitle('');
    	setDuplicateErrors({});
    	setDuplicateModal(false);
    	setDuplicateLoading(false);
	};

    // fetch list of assessments on mount
    useEffect(() => {
        getForms();
    }, []);

    return (
		<div>
			<div class="page-header-container searchForm-container">							
				<div className="container">		
					<div className="row">
						<div class="col-sm-12">
							<nav>
								<ol class="breadcrumb ">
									<li class="breadcrumb-item underline "><a href="/dashboard" class="text-light">Dashboard</a></li>
									<span class="arrow"></span>
									<li class="breadcrumb-item text-light active">Assessments</li>
								</ol>
							</nav>
						</div>
						<div className="col-sm-12 col-md-8 margin-top">
							<h1>Assessments</h1>
						</div>
						<div className="col-sm-12 col-md-4 text-right margin-top">
							<BasicLink className='btn primary' to='/dashboard/Assessments/new'>+ New Assessment</BasicLink>
						</div>
					</div>
				</div>					
			</div>

			<div className="container">
				<div className="row">
					<div className="col-12">
						<AssessmentList list={list} setDefault={setDefault} showDuplicate={openDuplicateModal}/>
					</div>
				</div>
			</div>

			<Modal show={duplicateModal} onHide={closeDuplicateModal}>
				<Modal.Header closeButton><h2>Duplicate Assessment</h2></Modal.Header>
				<Modal.Body>
					<form onSubmit={e=>e.preventDefault()}>
						<label htmlFor="duplicate">
							Assessment Name
							<input
								onChange={e=>setDuplicateTitle(e.target.value)}
								type="text"
								placeholder='Assessment Name'
								required
							/>
						</label>
						{duplicateErrors.title ?
							duplicateErrors.title.map(err=><p key={err.trim()} className="text-danger">{err}</p>)
							: null
						}
					</form>
				</Modal.Body>
				<Modal.Footer>
					<BasicButton onClick={closeDuplicateModal}>Cancel</BasicButton>
					<BasicButton disabled={duplicateLoading} className="btn primary" onClick={()=>duplicateAssessment(duplicate)}>+ Create</BasicButton>
				</Modal.Footer>
			</Modal>
		</div>
    )
};

export default AssessmentListContainer;