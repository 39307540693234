import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


// components
import ClientHeader from "../clientHeader/clientHeader";
import ClientPathwayListContainer from './clientPathwaysListContainer';


const ClientPathwaysRoute = ({ user, ...props }) => {
    return (
        <div className="client-container">
            <ClientHeader backUrl={`/member/clientDash/${user.username}`}/>
            <div className="container client-account-dash">
                { user ? <ClientPathwayListContainer/> : null }
            </div>
            <div className="footer-actions">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6">
                            <button className="btn client-white" onClick={props.history.goBack}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps({ user }){
    return {
        user
    }
}

ClientPathwaysRoute.propTypes = {
    user: PropTypes.object,
};

export default connect(mapStateToProps)(ClientPathwaysRoute);
