//search subheader component
import React from "react";
import axios from "axios";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import PhotoContainer from "../../profilePhotos/container";

class CoachSearch extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
      userCount: 0,
      users: [],
      username: "",
      totalPages: 1,
      add: false,
      coaches: [],
      show: false,
      alertStyle: true,
      alert: "",
    };
    this.handleShowModal = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getTiles = this.getTiles.bind(this);
    this.addCoach = this.addCoach.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  onChange = (event) => {
    this.setState({ username: event.target.value });
    console.log(this.state);
  };

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  getUsers() {
    return this.state.users.map((user, i) => {
      return (
        <li className="tile">
          <div className="row">
            <div className="col-md-9 tile-border-right">
              <div className="profile-image">
                <Link to={"/coach/" + user.username}>
                  <PhotoContainer
                    image={user.profile_image}
                    username={user.username}
                    url_path={`coach`}
                  />
                </Link>
              </div>
              <div className="profile-container">
                <div className="profile-name">
                  <Link to={"/coach/" + user.username}>
                    {`${user.firstName} ${user.lastName}`}
                  </Link>
                </div>
                <p>Last Communication : ##</p>
                <p>Last Login : {user.lastLogin}</p>
              </div>
            </div>
            <div className="col-md-3">
              <input
                key={user}
                name="list"
                className="addCoach-check"
                type="radio"
                id="coach-radio"
                onChange={(e) => this.addCoach(e, user)}
              />
              <label className="addCoach-check-label">Add Coach</label>
            </div>
          </div>
        </li>
      );
    });
  }

  addCoach(e, value) {
    this.setState({
      coaches: value.username,
    });
  }

  submitCoach() {
    console.log(this.state.coaches);
    axios
      .post(`/user/client-assign/${this.props.username}`, {
        coach_username: this.state.coaches,
      })
      .then((res) => {
        if (res.data.message === "SUCCESS") {
          this.setState({ show: true });
          this.setState({ alert: "success" });
          this.setState({ alertStyle: true });
          this.setState({ showModal: false });
          this.props.handleClose();
        }
      })
      .catch((error) => {
        let message = error.response.data.error;
        this.setState({ show: true });
        this.setState({ alert: message });
        this.setState({ alertStyle: false });
      });
  }

  getTiles(e) {
    e.preventDefault();
    this.setState({ showBoxes: false });
    axios
      .get(`/user/coaches/${this.state.username}/1?calendly=true`)
      .then((res) => {
        console.log(res);
        const users = res.data;
        this.setState({ users: users.results });
        this.setState({ userCount: res.data.results.length });
      });
  }

  componentDidMount() {
    axios
      .get("/user/coaches/1?calendly=true")
      .then((res) => {
        console.log(res);
        this.setState({ totalPages: res.data.pages });
        this.setState({ users: res.data.results });
        this.setState({ userCount: res.data.count });
      })
      .catch((err) => console.log(err));
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShowModal() {
    this.setState({ showModal: true });
  }

  handlePageClick(e) {
    console.log(e.selected);
    axios
      .get(`/user/coaches/${e.selected + 1}?calendly=true`)
      .then((res) => {
        this.setState({ users: res.data.results });
        this.setState({ userCount: res.data.count });
      })
      .catch((err) => console.log(err));
  }

  render() {
    let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
    return (
      <div className="">
        <div className="page-header-container searchForm-container">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <h1 class="page-title">Coaches</h1>
              </div>
            </div>

            <Form inline onSubmit={this.getTiles}>
              <FormGroup
                value={this.state.username}
                onChange={this.onChange}
                controlId="formInlineName"
              >
                <ControlLabel>Name</ControlLabel>{" "}
                <FormControl type="text" placeholder="Jane Doe" />
              </FormGroup>{" "}
              <Button className="searchForm-btn" type="submit">
                Search
              </Button>
            </Form>
          </div>
        </div>

        <div className="row">
          <h2 className="col-md-6 col-sm-12">
            {this.state.userCount} Coaches Found
          </h2>
          <input
            className="btn primary col-md-6"
            type="submit"
            value="Save"
            onClick={() => this.submitCoach()}
          />
          {this.state.show ? (
            <Alert
              className="col-md-4 save-profile-alert"
              bsStyle={changeAlertStyle}
              onDismiss={this.handleDismiss}
            >
              <p>{this.state.alert}</p>
            </Alert>
          ) : null}
        </div>

        <div>
          <div class="tile-header">
            <div className="row">
              <div className="col-md-4 text-indent-left">Person</div>
              <div className="col-md-4 text-center">Members</div>
            </div>
          </div>

          <ul className="tiles-ul">{this.getUsers()}</ul>
        </div>
        <ReactPaginate
          pageCount={this.state.totalPages}
          pageRange={10}
          pageMargin={5}
          pageClassName="btn btn-default"
          previousClassName="btn btn-default"
          nextClassName="btn btn-default"
          onPageChange={this.handlePageClick}
        />
      </div>
    );
  }
}

export default withRouter(CoachSearch);
