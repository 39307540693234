import React, {useState, useEffect, useRef} from 'react';
import _ from "lodash";

// styles
import styles from './communityOthers.module.css';

// actions
import {recordProfileView, victoryReaction} from "../../store/actions/communityActions";

// components
import ClientHeader from "../clientHeader/clientHeader";
import {fetchOthersVictories} from "../../store/actions/communityActions";
import Loading from "../Loading/Loading";
import VictoryTile from "../victoryTile/victoryTile";

const CommunityOthers = props => {
    // props
    const {match} = props;
    // local
    const [userId] = useState(match.params.userId);
    const [profile, setProfile] = useState(null);
    const [victories, setVictories] = useState(null);
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const endReached = useRef(false);
    const last = useRef();
    const feed = useRef();


    useEffect(() => {
        feed.current = victories;
    }, [victories]);

    useEffect(() => {
        if (userId) loadVictories();
    }, [userId]);

    useEffect(() => {
        try{
            if (userId) recordProfileView(userId);
        } catch (e) {
            console.log(e);
        }
    })

    useEffect(() => {
        window.addEventListener('scroll', _.throttle(onScroll, 500));
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    async function onScroll() {
        if (last.current) {
            const inView = isElementInViewport(last.current);
            if (inView && !loading && feed.current && !endReached.current) {
                setLoading(true);
                await loadVictories(feed.current);
                setLoading(false);
            }
        }
    }

    function isElementInViewport (el) {
        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    // gather another members victories
    async function loadVictories(oldVictories) {
        try {
            let res;
            if (oldVictories) {
                res = await fetchOthersVictories(userId, oldVictories[oldVictories.length-1].victory.id)
                setVictories([...oldVictories, ...res.victories]);
            } else {
                res = await fetchOthersVictories(userId);
                setVictories(res.victories);
                const copy = {...res};
                delete copy.victories;
                setProfile(copy);
                setReady(true);
            }
            if (!res.victories.length) endReached.current = true;
        } catch(e) {
            console.log(e);
        }
    }

    let profileImg = 'https://treo.s3.us-east-2.amazonaws.com/assets/avatar.png';
    if (profile && profile.profile_image) profileImg = profile.profile_image;
    return (
        <>
            <div className="client-container">
                <ClientHeader backUrl={`/member/community`}/>
            </div>
            {ready ?
                <div className='client-container container'>
                    <div className={styles.profileContainer}>
                        <div className={styles.profile} style={{backgroundImage: `url(${profileImg})`}}/>
                        <h3>{profile.nickname}</h3>
                    </div>
                    <div className={styles.header}>
                        <span>Victories</span>
                        <span className={styles.labelRight}>Show extra support by reacting below!</span>
                    </div>
                    <div>
                        {victories.map((victory, i) => {
                            const copy = {...victory};
                            copy.victory.user = {profile_image: profileImg, nickname: victories.nickname};
                            if (i === victories.length-1) {
                                return (
                                    <div ref={last} key={victory.victory.id}>
                                        <VictoryTile noProfile victory={victory} changeReaction={victoryReaction}/>
                                        {loading && !endReached.current ? <Loading height={25} width={25} /> : null}
                                        {endReached.current ? <p className='t-a:c'>You're all caught up!</p> : null}
                                    </div>
                                )
                            }
                            return (
                                <div key={victory.victory.id}><VictoryTile noProfile changeReaction={victoryReaction} victory={victory}/></div>
                            )
                        })}
                    </div>
                </div>
                :
                <div className="padding-standard">
                    <Loading/>
                </div>
            }
        </>
    )
}

export default CommunityOthers;