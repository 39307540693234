import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PlanList from './planList';
import {bindActionCreators} from "redux";
import {fetchTile} from "../../../actions/action_tile";
import {fetchSinglePlan} from "../../../actions/action_tile";
import {connect} from 'react-redux';



class AddTileActivityStep1 extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedPlanId:null,
        };
        this.updateValue = this.updateValue.bind(this);
        this.goBackToTile=this.goBackToTile.bind(this);
    }

    forwardToSingleTile(){
        this.props.fetchSinglePlan(this.state.selectedPlanId);
            this.props.history.push({
                pathname: '/member/tile/'+this.props.match.params.tileId,
                state: { intro: this.props.location.state.intro, planId:this.state.selectedPlanId }

            });
    }

    updateValue(args){
        this.setState({selectedPlanId:args},this.forwardToSingleTile);

    }

    goBackToTile(){
        this.props.history.push(`/member/clientDash/${this.props.username}/habits`);
    }

    componentDidMount(){
        console.log(this.props)
        this.props.fetchTile(this.props.match.params.tileId);
    }

    render(){
        return(
            <PlanList
                category ={this.props.tile ? this.props.tile.tile_category:null}
                plans={this.props.plans}
                updateValue={this.updateValue}
                goBackToTile={this.goBackToTile}
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchTile, fetchSinglePlan}, dispatch);
}

function mapStateToProps({tile, plans}){
    return {
        tile:tile.tile,
        plans:plans.plans,
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AddTileActivityStep1));