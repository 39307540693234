import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

// not sure if we need this
class ClientSummary extends Component{
    render(){
      return(
          <h1>Hi there</h1>
      )
    }
}

export default withRouter(ClientSummary);