import {FETCH_TILE} from "../actions/action_tile";
import {CLEAR_PLANS} from "../actions/action_tile";
import _ from 'lodash';


export default function(state= {plans:null}, action){
    switch (action.type){

        case FETCH_TILE:
            let plans = action.payload.data.plan.map(plan=>{
               plan.description = _.startCase(_.toLower(plan.description));
               return plan;
            });
            return {plans};

        case CLEAR_PLANS:
            return {plans:action.payload}
        
        // no default

    }
    return state;
}
