import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

// styles
import styles from './contentList.module.css';

// components
import {SecondaryInfoAlert} from "../../alerts/alerts";
import {InlineDangerButton} from "../../buttons/buttons";
import {ReactComponent as Checkmark} from '../../../images/checkmark-circle.svg';
import {ReactComponent as Times} from '../../../images/times-circle.svg';

const ContentList = props => {
    // props
    const {tag, content, removeCallback, updateCallback} = props;

    // local
    const [editing, setEditing] = useState(false);
    const [update, setUpdate] = useState('');
    const [confirm, setConfirm] = useState(false);

    // update content list whenever tag changes
    useEffect(() => {
        if (!tag) return;
        // fetch content
        setUpdate(tag.name);
        setEditing(false);
    }, [tag]);

    function save(e) {
        e.preventDefault();
        setEditing(false);
        updateCallback({id: tag.id, name: update});
    }

    function cancel(e) {
        e.preventDefault();
        setEditing(false);
        setUpdate(tag.name);
    }

    function openEdit() {
        setConfirm(false);
        setEditing(true);
    }

    function remove() {
        setConfirm(false);
        removeCallback(tag);
    }

    if (!tag) {
        return (
            <div>
                <SecondaryInfoAlert>Select Tag from right column</SecondaryInfoAlert>
            </div>
        )
    }
    return (
        <div>
            <div className='margin-bottom-15'>
                {editing ?
                    <form onSubmit={save}>
                        <div className="form-group d:f a-i:c">
                            <input
                                value={update}
                                onChange={event => setUpdate(event.target.value)}
                                type="text"
                                className={`form-control ${styles.form}`}/>
                            <button className={styles.icons} onClick={save}>
                                <Checkmark/>
                            </button>
                            <button type='button' onClick={cancel} className={`${styles.icons} ${styles.cancel}`}>
                                <Times/>
                            </button>
                        </div>
                    </form>
                    :
                    <div className='d:f'>
                        <h4 className={styles.tagTitle}>{tag.name} - {tag.count}</h4>
                        <button className={styles.tagEdit} onClick={openEdit}>Edit</button>
                        <span className={styles.tagEdit}>-</span>
                        {confirm ?
                            <div>
                                <InlineDangerButton onClick={remove}><strong>Confirm Removal</strong></InlineDangerButton>
                                <button className={styles.confirm} onClick={() => setConfirm(false)}>Cancel</button>
                            </div>
                            :
                            <InlineDangerButton onClick={() => setConfirm(true)}>Remove</InlineDangerButton>
                        }
                    </div>
                }
            </div>
            {content.map(item => (
                <Link className={styles.row} to={`/dashboard/content/new?content=${item.id}`}>{item.title}</Link>
            ))}
        </div>
    )
}

export default ContentList;