export const COLORS = [
    "#ee4d41",
    "#00776c",
    "#58006b",
    "#752140",
    "#ad4241",
    "#5e0046",
    "#932a00",
    "#467900",
    "#2054b4",
    "#8fa387",
    "#c2283a",
    "#9b6e00",
    "#b0008f",
    "#3f8d37",
    "#4061d1",
    "#1c4432",
    "#115e27",
    "#a47724",
    "#892f36",
    "#34a627",
    "#b099a5",
    "#10769a",
    "#aa602a",
    "#791d21",
    "#6c7c1b",
    "#d49f51",
    "#8b864b",
    "#885339",
    "#1a1e0f",
    "#df5502",
    "#517226",
    "#9e80bb",
    "#59cb34",
    "#435454",
    "#ab5bec",
    "#114b4b",
    "#1b73d7",
    "#d95e55",
    "#3f69c4",
    "#aa391f",
    "#9241f1",
    "#531ace",
    "#8f5c3f",
    "#ed6ce1",
    "#3f5066",
    "#dc3be3",
    "#b8dd39",
    "#51c1f6",
    "#3cfd00",
    "#7f46d6",
    "#bcb691",
    "#361b87",
    "#6ee35b",
    "#b2d1cd",
    "#8b16fe",
    "#ec4864",
    "#29824c",
    "#e7ab59",
    "#dd8dd1",
    "#68f54c",
    "#e3fe1d",
    "#b52339",
    "#ca157a",
    "#848773",
    "#a21491",
    "#ccbe43",
    "#f54d7a",
    "#10412e",
    "#3206af",
    "#e8e432",
    "#de21ff",
    "#464dc8",
    "#19145f",
    "#8a180b",
    "#2772e7",
    "#e005af",
    "#81036e",
    "#67547d",
    "#a059c9",
    "#f3941b",
    "#d0ee50",
    "#330989",
    "#643714",
    "#f92c54",
    "#a07721",
    "#713259",
    "#137813",
    "#f87204",
    "#31e69f",
    "#5b0c3c",
    "#7062c5",
    "#49e403",
    "#e547cc",
    "#db42d8",
    "#1f8dde",
    "#ab0cb8",
    "#8274dd",
    "#45dbdc",
    "#c80349",
    "#e3b2f9",
    "#b528f2",
    "#7f36df",
    "#45e2a0",
    "#17c390",
    "#ecd764",
    "#9182b0",
    "#9ef731",
    "#e724e0",
    "#23633c",
    "#e6757b",
    "#ed934d",
    "#2e6346",
    "#a45926",
    "#6d0ed3",
    "#b70f1e",
    "#5ad27c",
    "#33f2c9",
    "#195ed0",
    "#80e858",
    "#821e57",
    "#866c8c",
    "#b1b29e",
    "#9408f6",
    "#ec3ce6",
    "#1c5f2e",
    "#b5d4cf",
    "#7a2f79",
    "#ae44e0",
    "#aeae0c",
    "#3f5466",
    "#d85",
    "#befc9c",
    "#76474f",
    "#9fee02",
    "#fc1975",
    "#419440",
    "#bab503",
    "#831f8d",
    "#8ce211",
    "#cf7d1b",
    "#33de4a",
    "#48720f",
    "#519e28",
    "#28d26e",
    "#420a84",
    "#32986e",
    "#380330",
    "#164b76",
    "#ad1992",
    "#6ef118",
    "#93336a",
    "#7750c1",
    "#de61e9",
    "#152b2f",
    "#ba4d16",
    "#f01c66",
    "#94b647",
    "#422431",
    "#bd678e",
    "#5c8184",
    "#6843ce",
    "#c98453",
    "#5362ff",
    "#d1718f",
    "#406b8d",
    "#41dd95",
    "#1e09f2",
    "#50477f",
    "#f4edc2",
    "#5d94b7",
    "#12d70d",
    "#d68d7e",
    "#302127",
    "#a5f1b9",
    "#58e06d",
    "#3a062f",
    "#ca6150",
    "#d19e0a",
    "#d1f8d5",
    "#a254be",
    "#268d2e",
    "#889487",
    "#ac5021",
    "#83bb60",
    "#d014d5",
    "#c1e4ca",
    "#42031c",
    "#1fbdb1",
    "#e46048",
    "#8e8806",
    "#132400",
    "#291cc3",
    "#1f23d4",
    "#be01f8",
    "#aa3083",
    "#2e4ae6",
    "#1a3101",
    "#e86730",
    "#40b127",
    "#4f076d",
    "#fff6b8",
    "#ec5919",
    "#6969c6",
    "#1cfb6f",
    "#d1f160",
    "#217735",
    "#741993",
    "#a292ee",
    "#b0169d",
    "#5d6712",
    "#dbf743",
    "#d32d09",
    "#b651ec",
    "#c0f500",
    "#b47a50",
    "#d1cdda",
    "#4c02fc",
    "#cb9eb7",
    "#b53077",
    "#404be8",
    "#e7a46d",
    "#20a7ac",
    "#6a48c0",
    "#510b45",
    "#5527aa",
    "#5f53de",
    "#94d0da",
    "#bb813a",
    "#bcf8f6",
    "#37269e",
    "#3d3c47",
    "#6e646c",
    "#451b4f",
    "#a515b2",
    "#a12fe9",
    "#e2eb2e",
    "#adbea9",
    "#900be9",
    "#c75f41",
    "#1c1648",
    "#393c09",
    "#5dee86",
    "#d8e1c6",
    "#e1a419",
    "#9636aa",
    "#52e171",
    "#1b321d",
    "#2523d0",
    "#7ab80c",
    "#bceb17",
    "#eddbf1",
    "#9cb610",
    "#2647d8",
    "#3f6a60",
    "#458cc0",
    "#775fef",
    "#b13cfa",
    "#513ec8",
    "#128567",
    "#958d23",
    "#fa6790",
    "#55f92a",
    "#c17fa4",
    "#5f01e1",
    "#954a01",
    "#9fea4b",
    "#56fc39",
    "#4502c7",
    "#951218",
    "#2abe4b",
    "#c63f1e",
    "#cffd7b",
    "#6f311c",
    "#38da0b",
    "#daa4f4",
    "#3cbbec",
    "#e32fb4",
    "#54426c",
    "#be7163",
    "#d7225f",
    "#386e3a",
    "#f8305f",
    "#3f3189",
    "#2b5247",
    "#670b82",
    "#fa7a02",
    "#1f2c28",
    "#b632a9",
    "#a4db15",
    "#2a2a5c",
    "#80fba7",
    "#5cacd1",
    "#4b99aa",
    "#7250da",
    "#45da66",
    "#7f8be5",
    "#26058c",
    "#212cbe",
    "#1d165a",
    "#aa06d4",
    "#ed9763",
    "#9c9a3b",
    "#a31051",
    "#cd675f",
    "#1e4e1c",
    "#5a0c42",
    "#31d088",
    "#d17c34",
    "#e22549",
    "#279eba",
    "#976220",
    "#69b19d",
    "#1f3685",
    "#708425",
    "#11e210",
    "#515094",
    "#3fc6d4",
    "#81e539",
    "#ba186f",
    "#3c567f",
    "#1f0e5e",
    "#e1ffe6",
    "#1003dd",
    "#1daa1d",
    "#85347a",
    "#dd951f",
    "#d9631a",
    "#a52ccc",
    "#7e8d39",
    "#6eda78",
    "#1133e1",
    "#5891f3",
    "#edaa56",
    "#95e66d",
    "#524c38",
    "#712bac",
    "#9c7fb0",
    "#de4195",
    "#a541f1",
    "#d134ee",
    "#17f25e",
    "#6d42ea",
    "#3cdd22",
    "#68ddd0",
    "#3a8040",
    "#cf0b3a",
    "#551abe",
    "#f08f0e",
    "#716105",
    "#c1835d"
];