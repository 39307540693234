//admin dash view
import React, {Component} from 'react';
import HeaderAdmin from '../headerAdmin';
import HeaderCoach from '../HeaderCoach';
import Account from '../Account/AccountMain';
import AdminSummary from '../Account/AdminSummary';
import CoachSearchMain from "../coachSearch/coachSearchMain";
import ContentRoutes from '../Content/contentRoutes';
import {Route} from 'react-router-dom';
import {fetchSelf} from "../../../actions/action_user";
import {connect} from 'react-redux';
import TileListContainer from '../tiles/AllTiles/TileListContainer';
import TileContainer from '../tiles/AllTiles/TileContainer';
import {bindActionCreators} from 'redux';
import AssessmentListContainer from "../../assessmentList/assessmentListContainer";
import AssessmentNewContainer from "../../assessmentsNew/assessmentsNewContainer";
import PolicyListContainer from '../../policyList/policyListContainer';
import PolicyVersionNewContainer from "../../policyVersionNew/policyVersionNewContainer";
import PolicyNewContainer from "../../policyNew/policyNewContainer";
import OrganizationList from "../../organizationList/organizationList";
import OrganizationsNew from "../../organizationsNew/organizationsNew";
import OrganizationSingle from "../../organizationSingle/organizationSingle";
import ClientSearch from "../../clientSearch/clientSearch";
import MainInbox from "../../inbox/mainInbox/mainInbox";
import MessageCenter from "../../inbox/messageCenter/messageCenter";
import AnalyticsContainer from "../../analytics/analyticsContainer/analyticsContainer";
import ModerationContainer from "../../moderationAdmin/moderationContainer/moderationContainer";
import ThrivePass from '../thrivePass/ThrivePass';

class AdminDashMain extends Component{

    componentDidMount(){
        this.props.fetchSelf();
    }

    userPermission(){
        if(this.props.user){
            if(this.props.user.permission_id === 3){
                return( 
                    <div>
                        <HeaderAdmin />
                        <Route path={this.props.match.url + '/analytics'} component={AnalyticsContainer}/>
                        <Route path={this.props.match.url + "/ClientSearch"} render={()=><ClientSearch/>}/>
                    </div>
                    )
            }
            else{
                return( 
                    <div>
                        <HeaderCoach />
                        <Route path={this.props.match.url + '/analytics'} component={AnalyticsContainer}/>
                        <Route path={this.props.match.url + "/ClientSearch"} render={()=><ClientSearch/>}/>
                        <Route path={this.props.match.url + "/moderation"} component={ModerationContainer}/>
                    </div>
                    )
            }
        }
    }

    renderTileList=()=>{
        if(this.props.user){
            return <TileListContainer/>
        }else{
            this.props.fetchSelf();
            return <div> </div>
        }
    };

    renderTile=()=>{
        if(this.props.user){
            return <TileContainer/>
        }else{
            this.props.fetchSelf();
            return <div> </div>
        }
    };

    render(){
        if (!this.props.user) return <div/>
        return(
            <div>
                {this.userPermission()}
                <Route path={this.props.match.url +"/CoachSearch"} render={()=><CoachSearchMain/>}/>
                <Route path={this.props.match.url + "/Content"} render={()=><ContentRoutes {...this.props}/>}/>
                <Route path={this.props.match.url + "/Assessments"} exact component={AssessmentListContainer}/>
                <Route path={this.props.match.url + "/Assessments/new"} exact component={AssessmentNewContainer}/>
                <Route path={this.props.match.url + "/Assessments/modify/:id"} exact component={AssessmentNewContainer}/>
                <Route path={this.props.match.url + "/Policy/"} exact component={PolicyListContainer}/>
                <Route path={this.props.match.url + "/Policy/new"} exact component={PolicyNewContainer}/>
                <Route path={this.props.match.url + "/Policy/:title/:policyId/:versionNumber"} exact component={PolicyVersionNewContainer}/>
                <Route path={this.props.match.url + "/Inbox"} component={MainInbox}/>
                <Route path={this.props.match.url + "/Account"} render={ () => <Account {...this.props} />}/>
                <Route path={this.props.match.url + "/AdminSummary"} render={ () => <AdminSummary {...this.props} />}/>
                <Route path={this.props.match.url + "/Tile-List"} exact render={()=>this.renderTileList()}/>
                <Route path={this.props.match.url + "/Tile-List/Tile/:tile"} exact render={()=>this.renderTile()}/>
                <Route path={this.props.match.url + "/Organizations"} exact component={OrganizationList}/>
                <Route path={this.props.match.url + "/Organizations/new"} exact component={OrganizationsNew}/>
                <Route path={this.props.match.url + "/Organization/:id"} component={OrganizationSingle}/>
                <Route path={this.props.match.url + "/message-center"} component={MessageCenter}/>
                <Route path={this.props.match.url + "/thrivepass"} component={ThrivePass}/>
            </div>
        )
    }
}


function mapStateToProps({user}){
    return{user}
}

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({fetchSelf}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashMain);
