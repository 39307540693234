import React, { useState } from 'react';
import { connect } from 'react-redux';

// components
import PlanSelection from "./planSelection";
import ActivityLogger from "./activityLogger";

const ClientTileLogging = props => {
    // state
    const { tile, dailyScore } = props;
    // props
    const { close } = props;
    // local
    const [ step, setStep ] = useState(tile.plan.length > 1 ? 1 : 2);
    const [ plan, setPlan ] = useState(tile.plan.length > 1 ? null : tile.plan[0]);
    const [ currentScore ] = useState(dailyScore);

    function goToStep2(obj) {
        setStep(2);
        setPlan(obj);
    }

    // screen to select a plan from
    if (step === 1) {
        return <PlanSelection tile={tile} close={close} next={goToStep2}/>;
    }
    // screen to log an activity
    if (step === 2 && plan) {
        return <ActivityLogger
            oldScore={currentScore}
            tile={tile}
            plan={plan}
            close={close}/>;

    }

    return <div/>
}

function mapStateToProps(state) {
    return {
        tile: state.clientTile,
        dailyScore: state.dailyScore
    }
}

export default connect(mapStateToProps)(ClientTileLogging);