// npm modules
import React, { useState, useEffect } from 'react';

// Styles
import styles from './giphyContainer.module.css';

// Components
import GiphyLogo from '../../images/GIPHY_LOGO.png';
import GiphySearch from "./giphySearch";

const Giphy = ({ callback }) => {
    const [show, setShow] = useState(false);
    const [gif, setGif] = useState(null);

    useEffect(() => {
        if (callback && gif) callback(gif);
    }, [ gif ]);

    if (show) {
        return (
            <GiphySearch callback={setGif} close={()=>setShow(false)}/>
        )
    }
    return (
        <div>
            <div onClick={()=>setShow(true)} className={styles.giphyWrapper}>
                <img className={styles.giphyLogo} src={GiphyLogo} alt="GIPHY"/>
            </div>
        </div>
    )
};

export default Giphy;