import React, { useState, useEffect } from 'react';
import axios from 'axios';

// components
import { BasicLink } from "../links/links";
import PolicyList from "./policyList";

const AssessmentListContainer = () => {
    const [ list, setList ] = useState([]);

    const getPolicies = async () => {
        const res = await axios.get('/policy/policies')
            .catch(e => console.log(e));
        if (res && res.data) {
            setList(res.data.result);
        }
    };

    // fetch list of assessments on mount
    useEffect(() => {
        getPolicies();
    }, []);

    return (	
		<div>
			<div className="page-header-container searchForm-container">
				<div className='container'>
					<div className="row">
						<div className="col-sm-12">
							<nav>
								<ol className="breadcrumb ">
									<li className="breadcrumb-item underline ">
										<a href="/dashboard" className="text-light">Dashboard</a></li>
									<span className="arrow"/>
									<li className="breadcrumb-item text-light active">Policy</li>
								</ol>
							</nav>
						</div>
						<div className="col-sm-12 col-md-6">
							<h1>Policies</h1>
						</div>
						<div className="col-sm-12 col-md-6 text-right">
							<BasicLink className="searchForm-addNew btn primary btn btn-default" to='/dashboard/Policy/new'>+ New Policy</BasicLink>
						</div>
					</div>				
				</div>
			</div>
			<div className="container margin-top-lrg">
				<div className="row">
					<div className="col-xs-12">
						<PolicyList list={list}/>
					</div>
				</div>
			</div>
		</div>
    )
};

export default AssessmentListContainer;