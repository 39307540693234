import mainApi from "./mainApi";

export function tileApi() {
    return {
        getClientTiles: async function() {
            return mainApi.get('/tiles/client');
        },
        getArchivedTiles: async function(page) {
            return mainApi.get(`/tiles/client/archived/${page}`);
        },
        getTile: async function (id) {
            return mainApi.get(`/tiles/single/${id}`);
        },
        getPlanActivity: async function(planId, date) {
            return mainApi.get(`/tiles/activity/calendar/?plan_id=${planId}&date=${date}`);
        },
        setTileStatus: function(tileId, username, status) {
            return mainApi.put(`/tiles/modify-status`, {tileId, username, updatedStatus: status})
        },
        // get a list of a clients tiles for admins
        getMembersTiles: function(username) {
            return mainApi.get(`/tiles/list/${username}`);
        },
        // get a list of plan metrics for admins
        getPlanMetrics: function(planId, startDate, endDate){
            return mainApi.get(`/plan/metrics/${planId}?date=${endDate}&end=${startDate}`)
        }
    }
}