import React, {useState} from 'react';

// styles
import styles from './averageScores.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {ToggleButtons} from "../../buttons/buttons";
import {GraphPlaceholder} from "../../graphPlaceholder/graphPlaceholder";

const AverageScores = props => {
    // props
    const {data} = props;
    // local
    const [averages, setAverages] = useState('last_week');
    const options = [
        { label: '7 Day', value:'7 Day', onClick: () => setAverages('last_week') },
        { label: '30 Day', value: '30 Day', onClick: () => setAverages('last_month') },
        { label: 'Lifetime', value: 'Lifetime', onClick: () => setAverages('all_time')}
    ];

    if (!data) {
        return (
            <BoxContainer className={styles.container2}>
                <GraphPlaceholder/>
            </BoxContainer>
        )
    }

    return (
        <BoxContainer className={styles.container}>
            <div className={styles.toggles}>
                <ToggleButtons options={options}/>
            </div>
            <div className={styles.averages}>
                <div className={styles.singleAverage}>
                    <h2>{data[averages].daily_average || '-'}</h2>
                    <p className={styles.subLabel}>Daily Score</p>
                </div>
                <div className={styles.singleAverage}>
                    <h2>{data[averages].log_average || '-'}</h2>
                    <p className={styles.subLabel}>Avg Daily Logging</p>
                </div>
                <div className={styles.singleAverage}>
                    <h2>{data[averages].learn_average || '-'}</h2>
                    <p className={styles.subLabel}>Avg Daily Learning</p>
                </div>
                <div className={styles.singleAverage}>
                    <h2>{data[averages].live_average || '-'}</h2>
                    <p className={styles.subLabel}>Avg Daily Living</p>
                </div>
                <div className={styles.singleAverage}>
                    <h2>{data[averages].bonus_average || '-'}</h2>
                    <p className={styles.subLabel}>Avg Daily Bonus</p>
                </div>
            </div>
        </BoxContainer>
    )
}

export default AverageScores;