import React from 'react';
import moment from 'moment';

// styles
import styles from './organizationInfo.module.css';

// variables
import {ORG_STATUSES} from "../../serverVariables/statuses";

// components
import PlaceholderLogo from '../../images/organization.png';

const OrganizationInfo = ({ organization }) => {

    return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <nav>
                        <ol className="breadcrumb ">
                            <li className="breadcrumb-item underline ">
                                <a href="/dashboard" className="text-light">Dashboard</a>
                            </li>
                            <span className="arrow"/>
                            <li className="breadcrumb-item text-light active">
                                <a href="/dashboard/Organizations">Organizations</a>
                            </li>
                            <span className="arrow"/>
                            <li className="breadcrumb-item text-light active">
                                { organization.name }
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className={`col-xs-12 ${styles.container}`}>
                    <div className={styles.logoContainer}>
                        <img className={styles.logo} src={organization.brand_logo || PlaceholderLogo} alt={organization.name}/>
                        {organization.status_id === ORG_STATUSES.INACTIVE ?
                            <div className='t-a:c text-danger'>
                                <h5 className='m-b:.5'>Archived On:</h5>
                                <p>{moment(new Date().toISOString()).format('MM/DD/YYYY')}</p>
                            </div>
                            : null
                        }
                    </div>
                    <div className={styles.titleContainer}>
                        <h1>{organization.name}</h1>
                        <div className={styles.infoRow}>
                            <div className={styles.infoContainer}>
                                <p className={styles.info}>
                                    <span className={styles.label}>ID: </span>{organization.id}
                                </p>
                                <p className={styles.info}>
                                    <span className={styles.label}>Start Date: </span>{moment(organization.start_date).format('MM-DD-YYYY')}
                                </p>
                                <p className={styles.info}>
                                    <span className={styles.label}>End Date: </span>{moment(organization.end_date).format('MM-DD-YYYY')}
                                </p>
                                <p className={styles.info}>
                                    <span className={styles.label}>SAP Account #: </span>{organization.sap}
                                </p>
                                <p className={styles.info}>
                                    <span className={styles.label}>Sales Channel: </span>{organization.sales_channel || '-'}
                                </p>

                             
                            </div>
                            <div className={styles.infoContainer}>
                                <p className={styles.info}>
                                    <span className={styles.label}>Company Contact </span>
                                </p>
                                <p className={styles.info}>
                                    <span className={styles.label}>Name: </span>{organization.contact_name || '-'}
                                </p>
                                <p className={styles.info}>
                                    <span className={styles.label}>Phone: </span>{organization.contact_phone || '-'}
                                </p>
                                <p className={styles.info}>
                                    <span className={styles.label}>Email: </span>{organization.contact_email || '-'}
                                </p>
                            </div>
                            <div className={styles.infoContainer}>
                                <p className={styles.info}>
                                    <span className={styles.label}>Billing Address </span>
                                </p>
                                <p className={styles.info}>
                                    {organization.address}
                                </p>
                                <p className={styles.info}>
                                    {organization.city} {organization.state} {organization.zip}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrganizationInfo;