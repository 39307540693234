import React, { useState, useEffect } from "react";

// components
import DailyWotdHeader from "./wotdSubComponents/DailyWotdHeader";
import DailyWotdFooter from "./wotdSubComponents/DailyWotdFooter";
import DailyWotdBody from "./wotdSubComponents/DailyWotdBody";
import Loading from "../Loading/Loading";

// styles
import styles from "./DailyWotdVideo.module.css";

// actions
import {
  fetchWotdGenericVideos,
} from "../../store/actions/wotdActions";

const DailyWotdContainer = (props) => {

  // data type: number
  const [categoryId, setCategoryId] = useState(null);
  const [urlMatchingCategoryName, setUrlMatchingCategoryName] = useState(null);
  async function fetchWotdGenericList() {
    try {
      // Fetch the category name from the URL
      const urlcategoryName = props.match.params.wotdName
  
      // Fetch the response data
      const response = await fetchWotdGenericVideos();
      const responseData = response;

      // Find a category that matches the url category name
      const matchingCategory = responseData.find(
        (category) =>
          category.name.replace(/\s/g, '').toLowerCase() === urlcategoryName.replace(/\s/g, '').toLowerCase()
      );
      if (matchingCategory) {
        // If a match is found, set the matching category and treadmill name
        setCategoryId(matchingCategory.category_id);
        setUrlMatchingCategoryName(matchingCategory.name);
      } else {
        // Handle the case where no match is found
        console.log("category not found");
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchWotdGenericList();
  }, []);

  if (categoryId === null || urlMatchingCategoryName === null ) {
    return (
      <div className={styles.loadingContainer}>
        {" "}
        <Loading />
      </div>
    );
  }
  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <DailyWotdHeader />
        <DailyWotdBody categoryId={categoryId}  matchingCategory={urlMatchingCategoryName}/>
        <DailyWotdFooter />
      </div>
    </div>
  );
};

export default DailyWotdContainer;
