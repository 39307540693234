// npm modules
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

// actions
import { setClientPathway } from "../../store/actions/pathwaysActions";

const AssessmentList = props => {
    // state
    const { singleUser } = props;
    // actions
    const { setClientPathway } = props;
    // props
    const { list=[] } = props;

    return (
        <div>
            { list.map(item => (
                <div onClick={() => setClientPathway(item.id, singleUser.user.id)}>
                    { `${moment(item.created_at).format('MMMM Do, YYYY')} Pathway` }
                    <hr/>
                </div>
            ))}
        </div>
    )
};

const actions = {
    setClientPathway,
};

function mapStateToProps({ singleUser }) {
    return {
        singleUser,
    }
}

AssessmentList.propTypes = {
    list: PropTypes.array,
    setClientPathway: PropTypes.func,
};

export default connect(mapStateToProps, actions)(AssessmentList);