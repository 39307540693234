import {
    ACKNOWLEDGE_ALERT,
    ADD_ALERT,
    CLEAR_ALERT,
    FIRE_ALERT,
    POP_ALERT_LIST,
    REORDER_ALERT_LIST
} from "../storeConstants";

// manipulates a list of alerts in state
export function alertReducer(state=[], action) {
    switch(action.type) {
        case ADD_ALERT:
            if (!Array.isArray(action.payload)) {
                return [...state, action.payload]
            } else {
                return [...state, ...action.payload]
            }
        case POP_ALERT_LIST:
            return [...action.payload];
        case REORDER_ALERT_LIST:
            return [...action.payload];
        default:
            return state;
    }
}

// manipulates a piece of state that components subscribe to in order to
// know when to show themselves
export function alertSubscriptionReducer(state={}, action) {
    switch(action.type) {
        case FIRE_ALERT:
            return {...action.payload, fired: false};
        case CLEAR_ALERT:
            return action.payload;
        case ACKNOWLEDGE_ALERT:
            return {...action.payload, fired: true};
        default:
            return state;
    }
}