// npm modules
import React, { useState, useEffect }from 'react';
import { connect } from 'react-redux';
import { parse as parseQuery } from 'query-string';
import { Link } from 'react-router-dom';

// styles
import styles from './organizationList.module.css';

// actions
import { getOrganizations, setOrganizationPage } from "../../actions/action_organizations";

// components
import { PrimaryLink} from "../links/links";
import { GeneralPagination } from "../pagination/generalPagination/generalPagination";
import OrganizationTile from "./organizationTile";
import Loading from "../Loading/Loading";
import {PrimaryButton} from "../buttons/buttons";

const OrganizationList = ({ organizationList, getOrganizations, setOrganizationPage, ...props }) => {
    const [ loading, setLoading ] = useState(false);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('active');
 
    function changePage ({ selected }) {
        const page = selected+1;
        setOrganizationPage(page);
        let url = `${props.match.url}?page=${page}`;
        if (search) url += `&search=${search}`;
        url += `&status=${status}`;
        props.history.push(url);
        getOrganizations(page, search, status);
        window.scrollTo(0, 0);
    }

    function submitSearch(e) {
        e.preventDefault();
        setOrganizationPage(1);
        let url = `${props.match.url}?page=${1}`;
        if (search) url += `&search=${search}`;
        url += `&status=${status}`;
        props.history.push(url);
        getOrganizations(1, search, status);
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        const page = parseInt(parseQuery(props.location.search).page);
        let org = parseQuery(props.location.search).search;
        let orgStatus = parseQuery(props.location.search).status
        if (!org) org = '';
        let url = `${props.match.url}?page=${page || 1}`;
        url += `&status=${orgStatus || status}`;
        if (org) {
            url += `&search=${org}`;
            setSearch(org);
        }
        if (orgStatus) setStatus(orgStatus);
        props.history.push(url);
        getOrganizations(page || organizationList.page, org, orgStatus)
            .then(() => setLoading(false))
            .catch(e => console.log(e));
        setOrganizationPage(page);
    },[]);


    function changeStatus(val) {
        setStatus(val);
        setOrganizationPage(1);
        let url = `${props.match.url}?page=${1}`;
        url += `&status=${val}`;
        props.history.push(url);
        setSearch('');
        getOrganizations(1, '', val);
        window.scrollTo(0, 0);
    }

    if (loading) return <Loading/>;
    return (
        <div>
            <div className='page-header-container searchForm-container'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav>
                                <ol className="breadcrumb ">
                                    <li className="breadcrumb-item underline ">
                                        <a href="/dashboard" className="text-light">Dashboard</a>
                                    </li>
                                    <span className="arrow"/>
                                    <li className="breadcrumb-item text-light active">Organizations</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <h1>Organizations</h1>
                        </div>
                        <div className="col-sm-12 col-md-6 text-right" data-cy="addOrgBtn"> 
                            <PrimaryLink
                                to={`/dashboard/Organizations/new`}>
                                + Add Organization
                            </PrimaryLink>
                        </div>
                        <div className="col-xs-12">
                            <form onSubmit={submitSearch} className={styles.search} data-cy="searchOrg">
                                <div className="form-group">
                                    <label htmlFor="search">Search Organizations</label>
                                    <input
                                        value={search}
                                        onChange={e => setSearch(e.target.value)}
                                        type="text"
                                        className={`${styles.text} form-control`}/>
                                </div>
                                <PrimaryButton onClick={submitSearch} className={styles.button}>Search</PrimaryButton>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={`row ${styles.header}`}>
                    <div className="col-xs-12 col-sm-6">
                        <h3>{organizationList.total} Organizations Found</h3>
                    </div>
                    <div className={`col-xs-12 col-sm-6 ${styles.headerBtns}`}>
                        <button className={status === 'active' ? 't-d:u' : ''} onClick={() => changeStatus('active')}>Active</button>
                        <button className={status === 'inactive' ? 't-d:u' : ''} onClick={() => changeStatus('inactive')}>Archived</button>
                    </div>
                </div>

                <div className="margin-top-2x" data-cy="orgList">
                    <div className="tile-header hidden-xs hidden-sm">
                        <div className="row">
                            <div className="col-md-4 text-indent-left">
                            </div>
                            <div className="col-md-2 text-center">
                                Seats Taken (Month)
                            </div>
                            <div className="col-md-2 text-center">
                                Active Members (Month)
                            </div>
                            <div className="col-md-2 text-center">
                                Seats Taken (Total)
                            </div>
                            <div className="col-md-2 text-center">
                                Active Members (Total)
                            </div>
                        </div>
                    </div>

                    <ul className="tiles-ul">
                        { organizationList.organizations.map(org => {
                            return (
                                <Link className='black' to={`/dashboard/Organization/${org.id}/overview`}>
                                    <OrganizationTile organization={org}/>
                                </Link>
                            )
                        })}
                    </ul>
                </div>
                <div className="pagination-container">
                    <GeneralPagination forcePage={organizationList.page-1} pages={organizationList.pages} onPageChange={changePage}/>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps({ organizationList }) {
    return { organizationList };
}

const actions = {
    getOrganizations,
    setOrganizationPage
}
export default connect(mapStateToProps, actions)(OrganizationList);