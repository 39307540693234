import React from 'react';
import {Route} from 'react-router-dom';

// component
import FeaturedContent from "./featuredContent";
import FeaturedVideo from "./featuredVideo/featuredVideo";
import FeaturedAudio from "./featuredAudio/featuredAudio";
import FeaturedPdf from "./featuredPdf/featuredPdf";

const FeaturedContentContainer = props => {
    return (
        <div className="client-container pathways-container">
            <Route path={`${props.match.url}/:category`} exact component={FeaturedContent}/>
            <Route path={`${props.match.url}/video/:contentId`} exact component={FeaturedVideo}/>
            <Route path={`${props.match.url}/audio/:contentId`} exact component={FeaturedAudio}/>
            <Route path={`${props.match.url}/pdf/:contentId`} exact component={FeaturedPdf}/>
        </div>
    )
};

export default FeaturedContentContainer;

