import React, {useState, useEffect, } from 'react';
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// styles
import styles from './victories.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import LikeReaction from "../../community/likeReaction/likeReaction";
import WowReaction from "../../community/wowReaction/wowReaction";
import EnvyReaction from "../../community/envyReaction/envyReaction";
import LoveReaction from "../../community/loveReaction/loveReaction";
import {VICTORY_TYPES} from "../../../serverVariables/victoryTypes";
import {REACTION_TYPES} from "../../../serverVariables/reactionTypes";
import {ReactComponent as ChevronIcon} from '../../../images/chevron-right-solid.svg';
import {fetchVictoryReactions} from "../../../store/actions/communityActions";
import CommunitySingleReaction from "../../communitySingleReaction/communitySingleReaction";
import Loading from "../../Loading/Loading";
import {ReactComponent as BackArrow} from "../../../images/icons/back_arrow_long_tail.svg";

const Victories = props => {
    // props
    const {victories, loadMore, ended} = props;
    // local
    const [active, setActive] = useState(null);
    const [reactions, setReactions] = useState(null);
    const [endedReactions, setEndedReactions] = useState(false);

    useEffect(() => {
        if (active) loadReactions();
    }, [active]);

    function abbrNum(number, decPlaces) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10,decPlaces);

        // Enumerate number abbreviations
        var abbrev = [ "k", "m", "b", "t" ];

        // Go through the array backwards, so we do the largest first
        for (var i=abbrev.length-1; i>=0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10,(i+1)*3);

            // If the number is bigger or equal do the abbreviation
            if(size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number*decPlaces/size)/decPlaces;

                // Handle special case where we round up to the next abbreviation
                if((number === 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                number += abbrev[i];

                // We are done... stop
                break;
            }
        }

        return number;
    }

    function selectVictoryTitle(victory) {
        if (victory.victory.victory_type.id === VICTORY_TYPES.BADGES) {
            return <p className={styles.label}>Earned {victory.victory.level_achievement.level.name} Badge</p>
        }
        if (victory.victory.victory_type.id === VICTORY_TYPES.STREAK) {
            return <p className={styles.label}>7 Day Streak</p>
        }
        if (
            victory.victory.victory_type.id === VICTORY_TYPES.MEALTIME_SPACING ||
            victory.victory.victory_type.id === VICTORY_TYPES.EATING_OCCASIONS ||
            victory.victory.victory_type.id === VICTORY_TYPES.VEGETABLES_AND_FRUIT ||
            victory.victory.victory_type.id === VICTORY_TYPES.PROTEIN_SERVINGS ||
            victory.victory.victory_type.id === VICTORY_TYPES.SWEETENED_FOODS ||
            victory.victory.victory_type.id === VICTORY_TYPES.SWEETENED_DRINKS ||
            victory.victory.victory_type.id === VICTORY_TYPES.STRESS_RELEASING ||
            victory.victory.victory_type.id === VICTORY_TYPES.SLEEP ||
            victory.victory.victory_type.id === VICTORY_TYPES.PHYSICAL_ACTIVITY ||
            victory.victory.victory_type.id === VICTORY_TYPES.SPRINT_INTENSITY ||
            victory.victory.victory_type.id === VICTORY_TYPES.STRENGTH_BUILDING ||
            victory.victory.victory_type.id === VICTORY_TYPES.RELATED_SKILLS ||
            victory.victory.victory_type.id === VICTORY_TYPES.FOUNDATIONS ||
            victory.victory.victory_type.id === VICTORY_TYPES.COACH ||
            victory.victory.victory_type.id === VICTORY_TYPES.JUST_FOR_YOU ||
            victory.victory.victory_type.id === VICTORY_TYPES.CHECK_IN_8 ||
            victory.victory.victory_type.id === VICTORY_TYPES.CHECK_IN_15 ||
            victory.victory.victory_type.id === VICTORY_TYPES.VIDEOS_WATCHED_8 ||
            victory.victory.victory_type.id === VICTORY_TYPES.VIDEOS_WATCHED_15
        ) {
            return <p className={styles.label}>Earned {victory.victory.victory_type.name}</p>;
        }
        if (victory.victory.victory_type.id === VICTORY_TYPES.RANK) {
            return <p className={styles.label}>Earned {victory.victory.level_achievement.rank.name} Rank</p>
        }
    }

    function select(victory){
        if (active && victory.victory.id === active.victory.id) setActive(null);
        else setActive(victory);
    }

    async function loadReactions(reactionId) {
        try {
            const res = await fetchVictoryReactions(active.victory.id, reactionId);
            if (reactions) {
                setReactions([...reactions, ...res.reactions]);
            } else {
                setReactions(res.reactions);
            }
            if (!res.more) {
                setEndedReactions(true);
            }

        } catch (e) {
            console.log(e);
        }
    }

    function getCounts(reactions) {
        let likes, loves, wows, envies;
        if (reactions) {
            likes = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.LIKE);
            if (likes) likes = likes.count;
            else likes = 0;

            wows = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.WOW);
            if (wows) wows = wows.count;
            else wows = 0;

            envies = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.ENVY);
            if (envies) envies = envies.count;
            else envies = 0;

            loves = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.LOVE);
            if (loves) loves = loves.count;
            else loves = 0;

        }
        return {likes, loves, wows, envies};
    }

    function close() {
        setActive(null);
        setReactions(null);
    }

    function renderActive() {
        const victory = active;
        return (
            <div>
                <div key={victory.victory.id} onClick={() => select(victory)} className={`${styles.row} ${styles.active}`}>
                    <button onClick={close} className={styles.back}><BackArrow/> Back</button>
                    <div className={styles.rowInfo}>
                        <p className={styles.label}>{selectVictoryTitle(victory)}</p>
                        <span className={styles.earned}>Earned {format(parseISO(victory.victory.achievement_date), 'MM/dd/yy')}</span>
                    </div>
                </div>
                {reactions ?
                    reactions.map(reaction => {
                        reaction.victory = victory.victory;
                        reaction.victory.victory_type.victory_type_id = reaction.victory.victory_type.id;
                        return (<CommunitySingleReaction reaction={reaction} link={`/client/${reaction.user.username}/overview`}/>)
                    })
                    :
                    <div className="padding-standard"><Loading/></div>
                }
                {!endedReactions && reactions && reactions.length?
                    <div>
                        <button onClick={() => loadReactions(reactions[reactions.length-1].reaction_id)} className={styles.more}>Load More</button>
                    </div>
                    : null
                }
            </div>
        )
    }

    function  renderAll() {
        return(
            <div className={styles.victories}>
                {victories.map(victory => {
                    const {likes, loves, wows, envies} = getCounts(victory.reactions);
                    return (
                        <div key={victory.victory.id} onClick={() => select(victory)} className={styles.row}>
                            <div className={styles.rowInfo}>
                                <p className={styles.label}>{selectVictoryTitle(victory)}</p>
                                <span className={styles.earned}>Earned {format(parseISO(victory.victory.achievement_date), 'MM/dd/yy')}</span>
                            </div>
                            <div className={styles.reactions}>
                                <div>
                                    <span>{abbrNum(likes, 1)}</span>
                                    <LikeReaction
                                        disabled
                                        className={`${styles.icon} ${styles.like}`}
                                        color='transparent'
                                        stroke='#354052'
                                        animatedColor='#6bd0f3'
                                        animatedStroke='#e8ecf0'/>
                                </div>
                                <div>
                                    <span>{abbrNum(wows, 1)}</span>
                                    <WowReaction
                                        disabled
                                        className={styles.icon}
                                        color='transparent'
                                        stroke='#354052'
                                        animatedColor='#f7cb5d'
                                        animatedStroke='#c78430'/>
                                </div>
                                <div>
                                    <span>{abbrNum(envies, 1)}</span>
                                    <EnvyReaction
                                        disabled
                                        className={styles.icon}
                                        color='transparent'
                                        stroke='#354052'
                                        animatedColor='#adcb54'
                                        animatedStroke='#3f4e3d'/>
                                </div>
                                <div>
                                    <span>{abbrNum(loves, 1)}</span>
                                    <LoveReaction
                                        disabled
                                        className={styles.icon}
                                        color='transparent'
                                        stroke='#354052'
                                        animatedColor='#e5466c'
                                        animatedStroke='transparent'/>
                                </div>
                                <ChevronIcon className={`${styles.chevron}`}/>
                            </div>
                        </div>
                    )
                })}
                {!ended && victories.length ?
                    <div>
                        <button onClick={() => loadMore(victories[victories.length-1].victory.id)} className={styles.more}>Load More</button>
                    </div>
                    : null
                }
            </div>

        )
    }

    return (
        <BoxContainer className={`padding-small ${styles.container}`}>
            <h4 className='m:0 margin-bottom-15'>Victories</h4>

            {active ?
                <>{renderActive()}</>
                :
                <>{renderAll()}</>
            }
        </BoxContainer>
    )
}
export default Victories;