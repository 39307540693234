//search subheader component
import React from 'react';
import axios from 'axios';
import { Button, Form, Modal } from 'react-bootstrap';
import {withRouter, Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import PhotoContainer from "../../profilePhotos/container";


// let unread = [];
// let totalClients = [];
class CoachSearch extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
            userCount:0,
            users: [],
            username: '',
            totalPages:1,
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getTiles = this.getTiles.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    onChange = (event) => {
      this.setState({ username: event.target.value });
      console.log(this.state);
    };

    componentDidMount() {
        this.handleTileData(`/user/coaches/1`);
    } 

    async handleTileData(url){
       let res = (await axios.get(url)).data;//list of clients
       let clients = res.results;
       let pages = res.pages;
       let formatted_tiles = [];
       // eslint-disable-next-line 
       let username;
       await asyncForEach(clients, async (client)=>{//wait for loop through each client
            username=client.username;
          await axios.get(`/coach-metrics/client-count/${client.username}`)
            .then((res)=> {
              client.clients = res.data.result;
            })
            .catch((error)=>{
               console.log(error);
            });
          await axios.get(`/inbox/${client.username}/1?read=false`)
            .then((res)=> {
              client.unread = res.data.result.totalMessages;
            })
            .catch((error)=>{
               console.log(error);
            });
            formatted_tiles.push(client);
            console.log(formatted_tiles)
      }); 

       this.setState({users: formatted_tiles});
       this.setState({userCount: clients.length});
       this.setState({totalPages: pages});
            
        async function asyncForEach(array, callback){
          for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array)
              }
          }
    }

    getUsers(){
      if(this.state.users)return this.state.users.map((user, i) =>{

            return <li className="tile tile--dashboard">
                        <div className="row">
                            <div className="col-md-4 col-sm-6 tile-border-right">
                                    <Link to={'/coach/'+user.username}>
                                      {user.unread > 0 &&                                            
                                          <Link to={'/coach/'+user.username+'/inbox'}>    
                                              <span className={'unread-amount'}>{user.unread}</span>
                                          </Link>
                                      }
                                        <PhotoContainer 
                                        image={user.profile_image} 
                                        username={user.username} 
                                        url_path={`coach/${user.username}/overview`}
                                        />
                                    </Link>
                                <div className="profile-container">
                                    <div className="profile-name">
                                        <Link to={'/coach/'+user.username+'/overview'}>
                                            {`${user.firstName} ${user.lastName}`}
                                        </Link>
                                    </div>
                                    <p>Last Communication : ##</p>
                                    <p>Last Login : {user.lastLogin}</p>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-6 tile-border-right text-center">
                                <h3 className="no-margin">{user.clients}</h3>
                                <p>Assigned Members</p>
                            </div>
                            <div className="col-md-2 col-sm-12 tile-border-right text-center">
                                <h3 className="no-margin">...</h3>
                                <p>...</p>
                            </div>
                            <div className="col-md-2 col-sm-12 tile-border-right text-center">
                                <h3 className="no-margin">...</h3>
                                <p>...</p>
                            </div>
                            <div className="col-md-2 col-sm-12 text-center">
                                <h3 className="no-margin">...</h3>
                                <p>...</p>
                            </div>
                        </div>
                    </li>
        });
      }

    getTiles(e) {
      e.preventDefault();
      this.setState({showBoxes:false})
      axios.get(`/user/coaches/${this.state.username}/1`)
        .then(res => {
          console.log(res)
          const users = res.data;
          this.setState({users: users.results});
          this.setState({userCount: res.data.results.length});

        })
    }

    handleClose() {
      this.setState({ show: false });
    }

    handleShow() {
      this.setState({ show: true });
    }

    handlePageClick(e){
      this.handleTileData(`/user/coaches/${e.selected+1}`);
    }

  render() {
    return(
      <div className="">
          <div className="page-header-container searchForm-container">
                  <div className="container">
                      <div className="row">
                          <div className="col-sm-12">
                            <nav>
                              <ol className="breadcrumb no-padding-left">
                                <li className="breadcrumb-item underline "><a href="/dashboard" class="text-light">Dashboard</a></li><span className="arrow"></span> 
                                <li className="breadcrumb-item text-light active">Coaches</li>  
                              </ol>
                            </nav>
                          </div>
                          <div className="col-sm-12 col-md-6">
                              <h1 class="page-title">Coaches</h1>
                          </div>
                      </div>
                      
                      <Form className='row' onSubmit={this.getTiles}>
                        
                            <div className="col-sm-12 col-md-4 form-group">
                                <label>Name</label>
                                <input className='form-control' type='text' placeholder='Search by name' value={this.state.username} onChange={this.onChange}/>
                            </div>                            
                            <div className="col-sm-12 col-md-2">
                                <label>&nbsp;</label>                                
                                <Button className="btn margin-top-2x" type="submit">Search</Button>
                            </div>          
                          
                        </Form>
              </div>
                </div>

                <div className="container">
                    <div className="row">
                        <h2 className="col-sm-12">{this.state.userCount} Coaches Found</h2>
                    </div>

                    <div>
                      <div class="tile-header hidden-xs hidden-sm">
                          <div className="row">
                              <div className="col-md-4 text-indent-left">
                                 Person
                              </div>
                              <div className="col-md-2 text-center">
                                 Members
                              </div>
                              <div className="col-md-2 text-center">
                                 Stat #1
                              </div>
                              <div className="col-md-2 text-center">
                                 Stat #2
                              </div>
                              <div className="col-md-2 text-center">
                                 Stat #3
                              </div>
                          </div>
                      </div>

                      <ul className="tiles-ul">
                        {this.getUsers()}
                      </ul>
                    </div>
                   
                    { this.state.totalPages > 1 &&
                        <div className="pagination-container">                        
                            <ReactPaginate
                                pageCount={this.state.totalPages}
                                pageRange={10}
                                pageMargin={5}
                                pageClassName='paginate paginate-default'
                                previousClassName='paginate paginate-previous'
                                nextClassName='paginate paginate-next'
                                onPageChange={this.handlePageClick}
                            />                        
                        </div>
                    }
                    
                </div>

                <Modal show={this.state.show} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                      dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                      ac consectetur ac, vestibulum at eros.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.handleClose}>Close</Button>
                  </Modal.Footer>
                </Modal>
      </div>
      );
  }
};

export default withRouter(CoachSearch);
