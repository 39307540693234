import React from "react";
import Error from "../ErrorMessage/Error";
import axios from "axios";
import BackButton from '../BackButton'
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      message_type: null,
      email: null,
    };
  }
  sendPasswordEmail(email) {
    axios
      .post("/login/password-reset", { email })
      .then(() => {
        this.setState({
          message: "Please check your email",
          message_type: "success",
        });
      })
      .catch((e) => {
        this.setState({ message: e.response.data.error });
      });
  }

  render() {
    return (
      <div className="apple-background">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.sendPasswordEmail(this.state.email);
          }}
        >
          <div className="password-form">
            <h1>Forgot Password</h1>
            <Error
              message={this.state.message}
              type={this.state.message_type}
            />
            <p>
              Fill out the form below to reset your password. An email will be
              sent to you with a link to reset
            </p>
            <input
              type="email"
              value={this.state.email}
              placeholder="Email"
              onChange={(event) => this.setState({ email: event.target.value })}
            />
            <div className="margin-top-2x d:f j-c:s-b">
              <BackButton to={"/"} color="white"/>
              <button
                type="submit"
                className={"btn primary"}
                onClick={() => this.sendPasswordEmail(this.state.email)}
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ForgotPassword;
