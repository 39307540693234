export const formatArray = (selectData) => {
    const selectAllOption = { id: -1, category_name: 'All Categories' };
    const formattedData = [selectAllOption, ...selectData];
    return formattedData.map(obj => {
      const {
        category_id,
        category_name,
      } = obj;
  
      return {
        id: category_id,
        name: category_name,
        content_type_id: 0,
        subcategories: 0,
      };
    });
  }
