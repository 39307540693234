import mainApi from "./mainApi";

export function thrivePassApi() {
    return {
        postThrivePassNewOrder: async function(newOrder) {
            return mainApi.post("/thrivepass/new/order", newOrder);
        },
        getThrivePassProducts: async function() {
            return mainApi.get("/thrivepass/products")
        },
        getThrivePassOrders: async function (page, limit) {
            const params = {
            };
            if (page) params.page = page;
            if (limit) params.limit = limit;
          
            return mainApi.get("/thrivepass/orders", {
              params: params
            });
   
        }
    }
}



