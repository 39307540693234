import React, { useState, useEffect } from "react";
import { parse as parseQuery } from "query-string";
import randomstring from "randomstring";

// styles
import styles from "./signupContainer.module.css";

// components
import OrganizationChoice from "./organizationChoice/organizationChoice";
import SignUp from "./signUp/signUp";
import Waitlist from "./waitList/waitlist";
import WaitlistConfirmed from "./waitList/waitlistConfirmed";
import TreoLogo from "../../images/logo.png";
import DaxkoSignup from "../daxkoSignup/daxkoSignup";
import Loading from "../Loading/Loading";
import { fetchDaxkoUser } from "../../store/actions/organizationActions";
import { organizationApi } from "../../api/organizationApi";

const SignUpContainer = (props) => {
  // local
  const [page, setPage] = useState(0);
  const [logo, setLogo] = useState(null);
  const [code, setCode] = useState("");
  const [orgId, setOrgId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [loading, setLoading] = useState(false);
  const [console, setConsole] = useState(false);

  useEffect(() => {
    mount();
  }, []);

  // useEffect(() => {
  //   if (returnedDaxkoState) {
  //     const stateCheck = parseQuery(props.location.search).state;
  //     if (returnedDaxkoState === stateCheck) {
  //       setPage(2);
  //       const code = parseQuery(props.location.search).code;
  //       fetchDaxkoUser(code)
  //         .then((res) => {
  //           setDaxkoFields(res);
  //         })
  //         .catch((e) => console.log(e));
  //     }
  //   }
  // }, [returnedDaxkoState]);

  async function mount() {
    // check if orgId and invite code are present
    const orgId = parseQuery(props.location.search).orgId;
    const inviteCode = parseQuery(props.location.search).inviteCode;
    const consoleQuery = parseQuery(props.location.search).console;

    // check if orgId and invite code from console were provided
    if (orgId && inviteCode) {
      setLoading(true);

      // check org id and invite code against server
      try {
        const {data: { result: validCode }} = await organizationApi().checkOrgCode(orgId, inviteCode);
        const {data: { result: seats }} = await organizationApi().checkOrgSeatAvailability(orgId);

        setLogo(validCode.logo);
        setOrgId(orgId);
        setCode(validCode.code_id);
        setConsole(consoleQuery);
        if (seats) setPage(2);
        else {
          setPage(3);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setOrgId("");
        setCode("");
      }
    } else {
      setPage(1);
    }
  }

  function choosePath(page) {
    setPage(page);
  }
  function changePage(page) {
    const inviteCode = parseQuery(props.location.search).inviteCode;
    const stateValue = parseQuery(props.location.search).state;
  
    if (page === 1) {
      if (console) {
        props.history.push("/console/signup");
        return;
      }
      
      if (inviteCode === "WOTD") {
        props.history.push(`/${orgId}/workoutoftheday`);
        return;
      }
  
      if (stateValue) {
        props.history.push(`/${orgId}/${stateValue}/workoutofthedayvideo`);
        return;
      }
    }
  
    setPage(page);
  }
  

  function Forms() {
    if (page === 0) {
      return <div />;
    }
    if (page === 1) {
      return (
        <div className={styles.choiceContainer}>
          <div style={{ maxWidth: logo ? "50px" : "150px" }}>
            <img className={styles.logo} src={TreoLogo} alt="Treo Wellness" />
          </div>
          <OrganizationChoice
            setOrgId={setOrgId}
            setCode={setCode}
            setLogo={setLogo}
            setPage={choosePath}
          />
        </div>
      );
    }

    if (page === 2) {
      return (
        <div>
          <SignUp
            orgId={orgId}
            code={code}
            logo={logo}
            setPage={changePage}
          />
        </div>
      );
    }
    if (page === 3) {
      return (
        <div>
          <div style={{ maxWidth: logo ? "50px" : "150px" }}>
            <img
              className={styles.logo}
              src={logo || TreoLogo}
              alt="Treo Wellness"
            />
          </div>
          <Waitlist
            orgId={orgId}
            code={code}
            setPage={changePage}
            setFirstName={setFirstName}
          />
        </div>
      );
    }

    if (page === 4) {
      return (
        <div>
          <div style={{ maxWidth: logo ? "50px" : "150px" }}>
            <img
              className={styles.logo}
              src={logo || TreoLogo}
              alt="Treo Wellness"
            />
          </div>
          <WaitlistConfirmed firstName={firstName} />
        </div>
      );
    }

    return <div />;
  }

  if (loading) {
    return (
      <div id="signupContainer" className={styles.container}>
        <Loading />
      </div>
    );
  }

  return (
      <>
        {(orgId == 1040 || orgId == 1034) && page === 2 ?
            <div className={styles.greenHeader}>
              <div className={styles.greenHeaderInner}>
                <div className={styles.johnsonContainer}>
                  <img src="https://treo.s3.us-east-2.amazonaws.com/jfw/jfw_logo.png" alt=""/>
                  <div className={styles.whiteBar}/>
                  <img src="https://treo.s3.us-east-2.amazonaws.com/jfw/treo_logo.png" alt=""/>
                </div>
                <p className='m-b:0'>Welcome to Treo Wellness.</p>
                <p className='m-b:0'>
                  Create your account to get started.
                  <a className={styles.learnMore} target='_blank' href="https://www.johnsonfitness.com/treo">Learn More</a>
                </p>
              </div>
            </div>
            : null
        }
        <div id="signupContainer" className={styles.container}>
          <Forms />
        </div>
      </>
  );
};

export default SignUpContainer;
