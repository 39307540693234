// npm modules
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// actions
import { removeQuestion, alterQuestion, addList } from "../../actions/action_forms";

// components
import SingleResponse from "./singleResponse";
import {BasicButton} from "../buttons/buttons";
import InputImage from "../imageThumbnail/imageThumbnail";

const SingleQuestion = ({ question, num, length, taxonomy, removeQuestion, alterQuestion, addList }) => {
    return (
        <Accordion allowZeroExpanded={true} >
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <h4>Question {num} <i className="fas fa-chevron-down"/></h4>
                        <p>{question.HEADLINE}</p>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    { length === 1 ? null : <BasicButton className="btn delete" onClick={()=>removeQuestion(question)}>Delete Question</BasicButton>}
                    <div className='form-group'>
                        <label>Question Type</label>
                        <select readOnly value={question.typeId} className='form-control'>
                            <option value={8}>Multiple Choice</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>Category</label>
                        <select onChange={e => alterQuestion({ ...question, categoryId: e.target.value })} value={question.categoryId} className='form-control'>
                            <option value="" selected disabled>Choose a Category</option>
                            { taxonomy.map(key => <option key={key.id} value={key.id}>{key.name}</option>)}
                            <option value="">No Category</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <input
                            id={`_${question.id}`}
                            style={{width: 'auto'}}
                            className='margin-right'
                            type="checkbox"
                            checked={question.optional === '1' || question.optional}
                            onChange={e => alterQuestion({ ...question, optional: e.target.checked })}
                        />
                        <label htmlFor={`_${question.id}`}>Optional</label>
                    </div>
                    <div className="form-group">
                        <label>Headline</label>
                        <input
                            type="text"
                            className='form-control'
                            value={question.HEADLINE}
                            onChange={e => alterQuestion({ ...question, HEADLINE: e.target.value })}
                            placeholder={'Question Headline'}/>
                        { question.validation && question.validation.HEADLINE ?
                            question.validation.HEADLINE.map(err => <span className="text-danger">{err}</span>):
                            null
                        }
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <input
                            type="text"
                            className='form-control'
                            value={question.DESCRIPTION}
                            onChange={e => alterQuestion({ ...question, DESCRIPTION: e.target.value })}
                            placeholder={'Question Description'}/>
                        { question.validation && question.validation.DESCRIPTION ?
                            question.validation.DESCRIPTION.map(err => <span className="text-danger">{err}</span>):
                            null
                        }
                    </div>
                    <div className="form-group">
                        <label>Image</label>
                        <InputImage setImage={file => alterQuestion({ ...question, IMAGE: file })} image={question.IMAGE }/>
                    </div>
                    <div className="answer-group">
                        <h4>Answers</h4>
                        { question.validation && question.validation.LIST ?
                            question.validation.LIST.map(err => <span className="text-danger">{err}</span>):
                            null
                        }
                        { question.LIST.map(item => <SingleResponse key={item.id} list={item} question={question}/> )}
                        <BasicButton className="btn primary outline" onClick={()=>addList(question)}>+ Add Option</BasicButton>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    )
};

const actions = {
    alterQuestion,
    removeQuestion,
    addList,
};

export default connect(null, actions)(SingleQuestion);

SingleQuestion.propTypes = {
    question: PropTypes.shape({
        label: PropTypes.string,
        text: PropTypes.string,
        list: PropTypes.array
    }).isRequired,
    length: PropTypes.number.isRequired,
    num: PropTypes.number.isRequired,
    taxonomy: PropTypes.object,
    alterQuestion: PropTypes.func,
    removeQuestion: PropTypes.func,
    addList: PropTypes.func,
};