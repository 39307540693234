import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import {connect} from 'react-redux';

// styles
import styles from './WellnessScore.module.css';

// actions
import { getLifetimeScore, getDailyScore, setChangedScoreFlag, fetchMonthlyScore } from "../../../store/actions/wellnessScoreActions";
import {getCurrentBadge} from "../../../store/actions/badgesActions";
import {checkAlerts, clearAlert} from "../../../store/actions/alertActions";

// images
import {ReactComponent as StreakIcon} from '../../../images/Notifcation_Streak.svg';
import {ReactComponent as LifetimeIcon} from '../../../images/Dashboard_Lifetime.svg';

// hooks
import useDidMountEffect from "../../../hooks/useDidMountEffect";

// components
import BadgeAlert from "../../badgeAlert/badgeAlert";
import WellnessScoreCircle from "../../wellnessScoreCircle/wellnessScoreCircle";
import Badge from "../../badge/badge";
import _ from "lodash";
import {ALERT_TYPES} from "../../../serverVariables/alertTypes";
import RankAnimation from "../../rankAnimation/rankAnimation";

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const WellnessScore = props => {
    // props
    const {history} = props;
    // state
    const {dailyScore, scoreChanged, user, lifetimeScore, monthlyScore, alertSubscription} = props;
    //actions
    const {setChangedScoreFlag, getLifetimeScore, getDailyScore, fetchMonthlyScore, clearAlert, checkAlerts} = props;
    // local
    const [state, updateState] = useState({
        wellnessScore: null,
        updatedScore: null,
        score:null,
        data:0,
        best:'',
        current:'',
        dailyGoal: null,
    });
    const [currentBadge, setCurrentBadge] = useState(null);
    const [monthlyAlert, setMonthlyAlert] = useState({show: false});
    const [rankAchievement, setRankAchievement] = useState();

    useEffect(() => {
        fetchMonthlyScore();
        checkScore();
        checkAlerts();
        axios.get('/score/streak')
            .then(
                res=>{
                    setState({current: res.data.results.currentStreak, best:res.data.results.longestStreak})
                }
            )
            .catch(e => console.log(e));
    }, []);

    useDidMountEffect(() => {
        if (scoreChanged > -1) {
            checkScore();
        }
    }, [scoreChanged]);

    useDidMountEffect(() => {
        const {updatedScore} = state;
        if (!updatedScore) return;
        let difference = 100/((updatedScore - (state.wellnessScore || 0))*Math.sqrt(.01));
        if (difference === Infinity) difference = 100;
        incrementAnimation(updatedScore, difference);
    }, [state.wellnessScore, state.updatedScore]);

    useEffect(() => {
        async function prepare() {
            try{
                const res = await getCurrentBadge();
                console.log(res)
                setCurrentBadge(res);
            } catch (e) {
                console.log(e);
            }
        }
        prepare();
    }, []);

    // subscribe to alerts
    useEffect(() => {
        if (!_.isEmpty(alertSubscription)) {
            const {alert_type: {name: alertName}} = alertSubscription;
            if (alertName === ALERT_TYPES.MONTHLY_GOAL_MAIN){
                showAlert({show: true, ...alertSubscription});
            }
            if (alertName === ALERT_TYPES.RANK) {
                const {name, id} = alertSubscription.level_achievement.level.rank;
                setRankAchievement({name, id});
            }
        }
    }, [alertSubscription]);


    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    function showAlert(val){
        setMonthlyAlert(val);
    }

    function removeMonthlyAlert() {
        setMonthlyAlert({...monthlyAlert, show: false,});
        setTimeout(() => clearAlert(), 2000);
    }

    function removeRankAlert() {
        setRankAchievement(null);
        setTimeout(() => clearAlert(), 2000);
    }

    function  incrementAnimation (end, interval){
        if (end === 0) end = 1;
        const denominator = state.dailyGoal ? state.dailyGoal : 1;
        const score = Math.ceil((state.wellnessScore / denominator)*100);
        if (state.wellnessScore < end) {
            setTimeout(() => {
                setState({
                    wellnessScore: state.wellnessScore < end ? state.wellnessScore + 1 : end,
                    // data: [{ x: 1, y: score }, { x: 2, y: 100 - score }]
                    data: score
                });
            }, interval);
        } else{
            setState({
                wellnessScore: end,
                data: score
                // data: [{ x: 1, y: score }, { x: 2, y: 100 - score }]
            });
            setChangedScoreFlag(-1);
        }

    }

    async function checkScore(){
        try {
            // get lifetime score
            getLifetimeScore();
            // capture current daily score
            const currentScore = parseInt(dailyScore);
            //get client wellness score
            const res = await getDailyScore();

            if (scoreChanged > -1) {
                const updatedScore = parseInt(res.payload);
                // score has been reset or has been increased
                if (scoreChanged > -1) {
                    // score has been increased
                    setState({wellnessScore: scoreChanged, updatedScore, dailyGoal: parseInt(res.daily_goal)});
                } else {
                    setState({wellnessScore: currentScore, dailyGoal: parseInt(res.daily_goal)});
                }
            } else {
                const score = Math.ceil((dailyScore / res.daily_goal)*100);
                setState({
                    wellnessScore: dailyScore,
                    dailyGoal: parseInt(res.daily_goal),
                    data: score
                    // data: [{ x: 1, y: score }, { x: 2, y: 100 - score }]
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    function openTrophyCase() {
        history.push(`/member/community`);
    }


    // sets the bg color based on wellness score
    let scoreClass = 'low';
    const goal = parseInt(user.daily_goal) || 200;
    const score = Math.ceil((dailyScore/goal)*100);
    if (score >= 0 && score < 50 ) {
        scoreClass = 'low';
    } else if (score >= 50 && score < 75 ) {
        scoreClass = 'average';
    } else if (score >= 75 && score < 90 ) {
        scoreClass = 'high';
    } else if (score >= 90) {
        scoreClass = 'highest';
    }


    const branded = user.organization.brand_switch && user.organization.bg_color ? 'branded' : '';

    return (
        <div className={`${scoreClass} wellness-bg dashboard ${branded}` }>

            {/* NEW BADGE ACHIEVEMENT */}
            <div className={`${styles.whiteContainer} ${!monthlyAlert.show ? styles.hide : ''}`}>
                {monthlyAlert.show ?
                    <BadgeAlert
                        dismiss={removeMonthlyAlert}
                        oldScore={parseInt(monthlyAlert.level_achievement.start_of_month_score)}
                        name={monthlyAlert.level_achievement.level.name}
                        newScore={parseInt(lifetimeScore.replace(',',''))}
                        oldBadge={monthlyAlert.level_achievement.old_badge.id}
                        newBadge={monthlyAlert.level_achievement.level.name}
                        description={monthlyAlert.level_achievement.level.description}
                    /> : null}
            </div>

            {/* NEW RANK ACHIEVEMENT*/}
            {rankAchievement ?
                <RankAnimation rank={rankAchievement} close={removeRankAlert}/>
                : null
            }

            <div className="container">
                <div className={styles.innerContainer}>
                    <div className={styles.progress} onClick={openTrophyCase}>
                        {currentBadge ?
                            <>
                                {monthlyScore >= 1500 ?
                                    <Badge shadow badge={currentBadge.next_badge.name}
                                           className={styles.topBadge}/>
                                    :
                                    <img className={styles.topBadge}
                                         src={'https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Locked200x200.png'}
                                         alt=""/>
                                }
                                <div className={styles.bar}>
                                    <div className={styles.inner} style={{height: `${Math.ceil((monthlyScore/1500)*100)}%`}}/>
                                </div>
                                <Badge shadow badge={currentBadge.current_badge.name} className={styles.bottomBadge}/>
                                <div className={styles.monthlyLabel}>
                                    <div style={{width: '100%'}}>{monthlyScore} / 1500</div>
                                    <span className={styles.subLabel}>{months[new Date().getMonth()]}'s Goal</span>
                                </div>
                            </>
                            : null
                        }
                    </div>

                    <div className={styles.goalContainer}>
                        <p className={styles.label}>{days[new Date().getDay()]}'s Goal</p>
                        <Link to='/member/score' className={`${styles.goal} tour-ws`}>
                            <WellnessScoreCircle label={`${days[new Date().getDay()]}'s Goal`} goal={state.dailyGoal} score={state.wellnessScore} data={state.data}/>
                        </Link>
                    </div>

                    <div className={styles.scoreInfo}>
                        <div className={styles.info}>
                            <StreakIcon/>
                            <div className={styles.main}>{state.current} of 7</div>
                            <span className={styles.subLabel}>Day Streak</span>
                        </div>
                        <div className={styles.info}>
                            <LifetimeIcon/>
                            <div className={styles.main}>{lifetimeScore}</div>
                            <span className={styles.subLabel}>Lifetime Score</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps=(state)=>{
    return {
        user: state.user,
        lifetimeScore: state.lifetimeScore,
        dailyScore: state.dailyScore,
        scoreChanged: state.scoreChanged,
        monthlyScore: state.monthlyScore,
        alertSubscription: state.alertSubscription,
    }
};

const actions = {
    getLifetimeScore,
    getDailyScore,
    setChangedScoreFlag,
    fetchMonthlyScore,
    clearAlert,
    checkAlerts,
}

export default withRouter(connect(mapStateToProps, actions)(WellnessScore));
