import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {parse as parseQuery} from "query-string";

// styles
import styles from './tagsContainer.module.css';

// actions
import {
    fetchContentTypes,
    fetchTags,
    newTag,
    changeTag,
    removeTag as removeAction,
    fetchTaggedContent,
} from "../../../store/actions/contentActions";

// components
import LinkList from "../../links/linkList/linkList";
import TagsList from "../tagsList/tagList";
import {ErrorAlert} from "../../alerts/alerts";
import ContentList from "../contentList/contentList";

const TagsContainer = props => {
    // props
    const {match, history, location} = props;
    // local
    const [generalError, setGeneralError] = useState(null);
    const [links, setLinks] = useState([]);
    const [contentType, setContentType] = useState(null);
    const [contentTypeList, setContentTypeList] = useState([]);
    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState(null);
    const [content, setContent] = useState([]);

    // grab content types on mount
    useEffect(() => {
        fetchContentTypes()
            .then(types => {
                setContentTypeList(types);
                const hrefs = types.map(type => ({
                    href: `/dashboard/Content/tags/${type.id}`,
                    label: type.type.charAt(0).toUpperCase() + type.type.slice(1),
                }));
                setLinks(hrefs);
                const {params: {type}} = match;
                setContentType(types.find(item => item.id === parseInt(type)));
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setGeneralError(e.response.data.error)
                } else {
                    setGeneralError('Unable to load content types. Please refresh to try again. If this error persists contact system admin');
                }
            })
    }, []);

    useEffect(() => {
        if (!contentType) return;
        const tagId = parseQuery(location.search).tag;
        getTags(tagId);
    }, [contentType]);

    useEffect(() => {
        const {params: {type}} = match;
        setContentType(contentTypeList.find(item => item.id === parseInt(type)));
        setSelected(null);
    }, [match.params.type]);

    useEffect(() => {
        if (selected) {
            getContent(selected.id);
        }
    }, [selected]);

    function chooseTag(tag) {
        setSelected(tag);
        history.push(`${match.url}?tag=${tag.id}`);
    }

    async function getTags(tagId=null) {
        try {
            const result = await fetchTags(contentType.id);
            setTags(result);
            if (tagId) {
                const found = result.find(item => item.id === parseInt(tagId));
                if (found) setSelected(found);
            }
        } catch (e) {
            if (e.response && e.response.data) {
                setGeneralError(e.response.data.error)
            } else {
                setGeneralError('Unable to load tags. Please refresh to try again. If this error persists contact system admin');
            }
        }
    }

    async function createTag(tag) {
        try {
            await newTag(tag, contentType.id);
            getTags();
        } catch (e) {
            if (e.response && e.response.data) {
                setGeneralError(e.response.data.error)
            } else {
                setGeneralError('Unable to create new tag. Please refresh to try again.');
            }
        }
    }

    async function removeTag(tag) {
        try {
            setSelected(null);
            await removeAction(tag.id);
            getTags();
        } catch (e) {
            if (e.response && e.response.data) {
                setGeneralError(e.response.data.error)
            } else {
                setGeneralError('Unable to remove tag. Please refresh to try again.');
            }
        }
    }

    async function updateTag(tag) {
        setSelected({...selected, name: tag.name});
        try {
            await changeTag(tag, contentType.id);
            getTags();
        } catch (e) {
            if (e.response && e.response.data) {
                setGeneralError(e.response.data.error)
            } else {
                setGeneralError('Unable to save new tag. Please refresh to try again.');
            }
        }
    }

    async function getContent(tagId) {
        try{
            const content = await fetchTaggedContent(tagId);
            setContent(content);
        } catch (e) {
            if (e.response && e.response.data) {
                setGeneralError(e.response.data.error)
            } else {
                setGeneralError('Unable to get content. Please refresh to try again.');
            }
        }
    }

    if (!contentType) return <div/>;
    return (
        <div>
            <div className="page-header-container searchForm-container">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav aria-label="breadcrumb ">
                                <ol className="breadcrumb ">
                                    <li className="breadcrumb-item underline ">
                                        <a href="/dashboard" className="text-light">
                                            Dashboard
                                        </a>
                                    </li>
                                    <span className="arrow"></span>
                                    <li className="breadcrumb-item underline ">
                                        <a href="/dashboard/Content" className="text-light">
                                            Content
                                        </a>
                                    </li>
                                    <span className="arrow"></span>
                                    <li
                                        className="breadcrumb-item text-light active"
                                        aria-current="page"
                                    >
                                        Tags
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <h1>Tags</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {generalError ?
                    <div className="row">
                        <div className="col-xs-12">
                            <ErrorAlert>{generalError}</ErrorAlert>
                        </div>
                    </div>
                    : null
                }
                <div className="row">
                    <div className="col-xs-12 col-md-3 col-lg-2 margin-top-lrg">
                        <LinkList links={links} exact={false}/>
                    </div>
                    <div className={`col-xs-12 col-md-9 col-lg-10 margin-top-lrg`}>
                        <div className={styles.mainContainer}>
                            <h3 className='margin-bottom-25 t-t:c m-t:0'>{contentType.type}</h3>
                            <div className="d:f">
                                <div className={styles.contentContainer}>
                                    <ContentList
                                        content={content}
                                        removeCallback={removeTag}
                                        updateCallback={updateTag}
                                        tag={selected}/>
                                </div>
                                <div className={styles.tagContainer}>
                                    <TagsList
                                        createCallback={createTag}
                                        callback={chooseTag}
                                        selected={selected}
                                        tags={tags}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(TagsContainer);