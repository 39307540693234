import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";

// styles
import styles from "./tiles.module.css";

// actions
import { fetchHealthGoal } from "../../../store/actions/userActions";

// components
import { SaveAlert } from "../../alerts/alerts";
import { PrimaryButton } from "../../buttons/buttons";
import { PrimaryLink } from "../../links/links";
import LastActiveBar from "./LastActiveBar";
import SparkLine from "./SparkLine";
import { HabitTileIcon } from "../../habitTileIcon/habitTileIcon";

let custom = true;

class Tiles extends React.Component {
  constructor() {
    super();
    this.state = {
      tileList: [],
      archivedTileList: [],
      listType: true,
      totalPages: 0,
      descriptions: [],
      allCategories: [],
      noData: false,
      noRecentData: false,
      tileCount: null,
      disableCreation: true,
      tileFlare: null,
      tileMoveDisabled: false,
      healthgoal: "",
    };
    this.handleTileData = this.handleTileData.bind(this);
    this.handleArchivedTileData = this.handleArchivedTileData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  findCustom() {
    
    axios
      .get(`/tiles/categories`)
      .then((res) => {
        this.setState({ allCategories: res.data.categories });
        this.state.allCategories.map((categories) => {
          axios
            .get(`/tiles/${categories.id}/plans`)
            .then((res) => {
              this.setState(
                {
                  descriptions: this.state.descriptions.concat(
                    res.data.descriptions
                  ),
                },
                () => {}
              );
            })
            .catch((error) => {
              console.log(error.message);
            });
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  componentDidMount() {
    this.findCustom();
    this.handleTileData(`/tiles/list/${this.props.username}`);

    // get healthgoal
    fetchHealthGoal(this.props.singleUser.user.id).then((goal) =>
      this.setState({ healthgoal: goal })
    );
  }

  async handleTileData(url) {
    let res = await axios.get(url);
    let tiles = res.data.result;
    this.setState({ tileCount: res.data.result.length });
    res.data.result.length > 5
      ? this.setState({ disableCreation: true })
      : this.setState({ disableCreation: false });
    const promises = tiles.map((tile) => {
      return new Promise((resolve) => {
        const clone = { ...tile };
        getPlanInfo(clone).then((planInfo) => {
          clone.plan = planInfo;
          resolve(clone);
        });
      });
    });
    const formatted_tiles = await Promise.all(promises);

    this.setState({ tileList: formatted_tiles });

    function getPlanInfo(tile) {
      const promises = tile.plan.map((plan) => {
        return new Promise((resolve) => {
          let date = moment().format();
          let end_date = moment()
            .subtract(7, "days")
            .format();
          axios
            .get(`/plan/metrics/${plan.id}?date=${date}&end=${end_date}`)
            .then((metrics) => {
              plan.metrics = metrics.data.results;
              axios.get(`/plan/average/${plan.id}`).then((averages) => {
                plan.average = averages.data.results.average;
                return resolve(plan);
              });
            });
        });
      });
      return Promise.all(promises);
    }
  }

  async handleArchivedTileData(url) {
    let res = await axios.get(url);
    let tiles = res.data.result;
    let formatted_Archivedtiles = [];
    let pages = res.data.pages;
    await asyncForEach(tiles, async (item) => {
      await asyncForEach(item.plan, async (plan) => {
        let date = moment().format();
        let end_date = moment()
          .subtract(7, "days")
          .format();
        res = await axios.get(
          `/plan/metrics/${plan.id}?date=${date}&end=${end_date}`
        );
        plan.metrics = res.data.results;
        res = await axios.get(`/plan/average/${plan.id}`);
        plan.average = res.data.results.average;
      });
      formatted_Archivedtiles.push(item);
    });

    this.setState({ archivedTileList: formatted_Archivedtiles });
    this.setState({ totalPages: pages });

    async function asyncForEach(array, callback) {
// eslint-disable-next-line
      let something = array.map((item) => item);
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }
  }

  onDragEnd = async ({ destination, source }) => {
    // do nothing if no movement was made
    if (!destination) return;
    if (destination.index === source.index) return;

    // reorder state
    const clone = [...this.state.tileList];
    const [removed] = clone.splice(source.index, 1);
    clone.splice(destination.index, 0, removed);
    this.setState({ tileList: clone });

    // save new order
    const ids = clone.map((tile) => tile.tile.id);
    await axios.post(`/tiles/sort/${this.props.singleUser.user.id}`, ids);

    // display flare
    this.setState(
      { tileFlare: removed.tile.id, tileMoveDisabled: true },
      () => {
        setTimeout(() => {
          this.setState({ tileFlare: null });
          setTimeout(() => this.setState({ tileMoveDisabled: false }), 4000);
        }, 2000);
      }
    );
  };

  createList = () => {
    if (this.state.tileList) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={"id_tiles"}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {this.state.tileList.map((tileList, i) => {
                  let description = tileList.plan[0].description;
                  custom = this.state.descriptions.includes(description);
                  let tileDescription = tileList.plan[0].description;
                  let tileIcon = tileDescription.replace(/\s/g, "");
                  if (
                    custom === false &&
                    tileList.tile.tile_category === "what you do" &&
                    tileList.plan.length === 1
                  )
                    tileIcon = `custom-physicalactivity`;
                  if (
                    custom === false &&
                    tileList.tile.tile_category === "what you eat" &&
                    tileList.plan.length === 1
                  )
                    tileIcon = `custom-veggieandfruitservings`;
                  if (
                    custom === false &&
                    tileList.tile.tile_category === "when you eat" &&
                    tileList.plan.length === 1
                  )
                    tileIcon = `custom-mealtimespacing`;
                  if (tileIcon === "vegetables&fruit")
                    tileIcon = "veggieandfruitservings";
                  if (
                    tileIcon === "didyoufindthiscontenthelpful?" ||
                    tileIcon === "Didyoufindthiscontenthelpful?"
                  )
                    tileIcon = "doc";
                  let plans =
                    tileList.plan.length > 1
                      ? "Activity"
                      : tileList.plan[0].description;

                  let category;
                  if (tileList.tile.tile_category === "what you do")
                    category = "wyd";
                  if (tileList.tile.tile_category === "what you eat")
                    category = "wye";
                  if (tileList.tile.tile_category === "when you eat")
                    category = "whenye";

                  return (
                    <Draggable
                      key={tileList.tile.id}
                      draggableId={`${tileList.tile.id}`}
                      index={i}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          key={tileList.tile.id}
                        >
                          <div id="admin-tileList" className="tileContainer">
                            <i
                              {...provided.dragHandleProps}
                              className={`fas fa-arrows-alt`}
                            />
                            <li className="tile">
                              <div
                                className={`tile-updated ${
                                  tileList.tile.id === this.state.tileFlare
                                    ? ""
                                    : "stash"
                                } ${
                                  !this.state.tileMoveDisabled ? "zIndex" : ""
                                }`}
                              >
                                <SaveAlert
                                  complete
                                  savedMessage="Sort Order Saved"
                                />
                              </div>
                              <div className="row">
                                <div className="col-md-3 col-sm-3 tile-border-right">
                                  <div className="col-md-4 no-padding-left">
                                    <Link
                                      to={
                                        this.props.match.url +
                                        "/TileDetail/" +
                                        tileList.tile.id
                                      }
                                    >
                                      <div className="tile-icon-admin-list-full">
                                        <HabitTileIcon
                                          tile={tileDescription}
                                          size={200}
                                        />
                                      </div>
                                    </Link>
                                  </div>

                                  <div className="col-md-8">
                                    <Link
                                      to={
                                        this.props.match.url +
                                        "/TileDetail/" +
                                        tileList.tile.id
                                      }
                                    >
                                      <h4
                                        className={`BG${category} capitalize`}
                                      >
                                        {tileList.tile.tile_category}
                                      </h4>
                                    </Link>

                                    <p className="capitalize">{plans}</p>
                                  </div>
                                </div>

                                <div className="col-sm-9">
                                  {tileList.plan.map((item) => {
                                    let average = item.average;
                                    let averageDifference;
                                    let averageDifferenceLabel;
                                    if (item.inverted === true) {
                                      averageDifference =
                                        item.average - item.minimum_point;
                                      if (averageDifference <= 0) {
                                        averageDifference = Math.abs(
                                          averageDifference
                                        );
                                        averageDifferenceLabel = `${item.units} above`;
                                      } else if (averageDifference > 0) {
                                        averageDifferenceLabel = `${item.units} below`;
                                      }
                                    } else {
                                      averageDifference =
                                        item.average - item.maximum_point;
                                      averageDifferenceLabel =
                                        averageDifference < 0
                                          ? `${item.units} below`
                                          : `${item.units} above`;
                                    }

                                    let lastActiveValue =
                                      item.last_activity_value;
                                    let scaleStart = item.scale_start;
                                    let scaleEnd = item.scale_end;
                                    let minPoint = item.minimum_point;
                                    let maxPoint = item.maximum_point;
                                    let inverted = item.inverted;
                                    let increment = item.incrementer;
                                    let status = item.status;
                                    let tile_category = item.tile_category;
                                    let lastActivity;
                                    if (item.last_activity) {
                                      lastActivity = moment(
                                        item.last_activity
                                      ).format("MM/DD/YYYY");
                                    }
                                    // eslint-disable-next-line
                                    let timerange = "week";
                                     // eslint-disable-next-line
                                    let data = item.metrics.map((metrics) => {
                                      return {
                                        x: metrics.date,
                                        y: metrics.plan_value,
                                      };
                                    });

                                    return (
                                      <div className="row">
                                        <div className="col-md-4 tile-border-right text-center">
                                          <div>
                                            <div className="plan-name">
                                              {Math.round(average)}
                                            </div>
                                            <div className="plan-category">
                                              {Math.round(averageDifference)}{" "}
                                              {averageDifferenceLabel}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <LastActiveBar
                                            scaleStart={scaleStart}
                                            scaleEnd={scaleEnd}
                                            lastActivity={lastActivity}
                                            lastActiveValue={lastActiveValue}
                                            minPoint={minPoint}
                                            maxPoint={maxPoint}
                                            increment={increment}
                                            inverted={inverted}
                                            status={status}
                                            tile_category={tile_category}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </li>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  };

  createArchivedList = () => {
    if (this.state.archivedTileList) {
      return this.state.archivedTileList.map((tileList, i) => {
        let description = tileList.plan[0].description;
        custom = this.state.descriptions.includes(description);
        let tileDescription = tileList.plan[0].description;
        let tileIcon = tileDescription.replace(/\s/g, "");
        if (
          custom === false &&
          tileList.tile.tile_category === "what you do" &&
          tileList.plan.length === 1
        )
          tileIcon = `custom-physicalactivity`;
        if (
          custom === false &&
          tileList.tile.tile_category === "what you eat" &&
          tileList.plan.length === 1
        )
          tileIcon = `custom-veggieandfruitservings`;
        if (
          custom === false &&
          tileList.tile.tile_category === "when you eat" &&
          tileList.plan.length === 1
        )
          tileIcon = `custom-mealtimespacing`;
        if (tileIcon === "vegetables&fruit")
          tileIcon = "veggieandfruitservings";
        if (
          tileIcon === "didyoufindthiscontenthelpful?" ||
          tileIcon === "Didyoufindthiscontenthelpful?"
        )
          tileIcon = "doc";
        let plans =
          tileList.plan.length > 1 ? "Activity" : tileList.plan[0].description;

        let category;
        if (tileList.tile.tile_category === "what you do") category = "wyd";
        if (tileList.tile.tile_category === "what you eat") category = "wye";
        if (tileList.tile.tile_category === "when you eat") category = "whenye";
        return (
          <li className="tile">
            <div className="row">
              <div className="col-md-3 col-sm-3 tile-border-right">
                <div className="col-md-4 no-padding-left">
                  <Link
                    to={
                      this.props.match.url + "/TileDetail/" + tileList.tile.id
                    }
                  >
                    <div className="tile-icon-admin-list-full">
                      <div className={"the--icon icon-" + tileIcon}>
                        <HabitTileIcon tile={description} size={200} />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-8">
                  <Link
                    to={
                      this.props.match.url + "/TileDetail/" + tileList.tile.id
                    }
                  >
                    <h4 className={`BG${category} capitalize`}>
                      {tileList.tile.tile_category}
                    </h4>
                  </Link>

                  <p className="capitalize">{plans}</p>
                </div>
              </div>
              <div className="col-sm-9">
                {tileList.plan.map((item) => {
                  let average = item.average;
                  let averageDifference;
                  let averageDifferenceLabel;
                  if (item.inverted === true) {
                    averageDifference = item.average - item.minimum_point;
                    if (averageDifference <= 0) {
                      averageDifference = Math.abs(averageDifference);
                      averageDifferenceLabel = `${item.units} above`;
                    } else if (averageDifference > 0) {
                      averageDifferenceLabel = `${item.units} below`;
                    }
                  } else {
                    averageDifference = item.average - item.maximum_point;
                    averageDifferenceLabel =
                      averageDifference < 0
                        ? `${item.units} below`
                        : `${item.units} above`;
                  }

                  let lastActiveValue = item.last_activity_value;
                  let scaleStart = item.scale_start;
                  let scaleEnd = item.scale_end;
                  let minPoint = item.minimum_point;
                  let maxPoint = item.maximum_point;
                  let inverted = item.inverted;
                  let increment = item.incrementer;
                  let status = item.status;
                  let tile_category = item.tile_category;
                  let lastActivity;
                  if (item.last_activity) {
                    lastActivity = moment(item.last_activity).format(
                      "MM/DD/YYYY"
                    );
                  }

                  let timerange = "week";
                  let data = item.metrics.map((metrics) => {
                    return { x: metrics.date, y: metrics.plan_value };
                  });

                  return (
                    <div className="row">
                      <div className="col-md-4 tile-border-right text-center">
                        <div>
                          <div className="plan-name">{Math.round(average)}</div>
                          <div className="plan-category">
                            {Math.round(averageDifference)}{" "}
                            {averageDifferenceLabel}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 tile-border-right">
                        <LastActiveBar
                          scaleStart={scaleStart}
                          scaleEnd={scaleEnd}
                          lastActivity={lastActivity}
                          lastActiveValue={lastActiveValue}
                          minPoint={minPoint}
                          maxPoint={maxPoint}
                          increment={increment}
                          inverted={inverted}
                          status={status}
                          tile_category={tile_category}
                        />
                      </div>
                      <div className="col-md-4">
                        {tileList.tile.tile_category_id === 4 ? null : (
                          <SparkLine
                            data={data}
                            timerange={timerange}
                            noData={
                              !item.last_activity_value && data.length === 0
                                ? true
                                : false
                            }
                            noRecentData={
                              item.last_activity_value && data.length === 0
                                ? true
                                : false
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </li>
        );
      });
    }
  };

  activeList() {
    this.setState({ listType: true });
    this.handleTileData(`/tiles/list/${this.props.username}`);
    this.setState({ totalPages: 0 });
  }

  archivedList() {
    this.setState({ listType: false });
    this.handleArchivedTileData(
      `/tiles/archived/${this.props.username}/page/1`
    );
  }

  handlePageClick(e) {
    this.handleArchivedTileData(
      `/tiles/archived/${this.props.username}/page/${e.selected + 1}`
    );
  }

  toStep1() {
    if (this.state.disableCreation) {
      return (
        <PrimaryButton disabled={this.state.disableCreation}>
          {this.state.tileCount > 5
            ? "member already has 6 tiles"
            : "+ Add New Tile"}
        </PrimaryButton>
      );
    } else {
      return (
        <PrimaryLink to={this.props.match.url + "/create/step1"}>
          + Add New Tile
        </PrimaryLink>
      );
    }
  }

  selectTileList = (e) => {
    if (e.target.value === "active") {
      this.activeList();
    } else {
      this.archivedList();
    }
  };

  render() {
    return (
      <div className="container margin-top-lrg">
        <div className="row">
          <div className="col-sm-8">
            <h2 className={styles.healthgoal}>
              Health Goal: {this.state.healthgoal}
            </h2>
            <select
              className={`form-control ${styles.tileSwitch}`}
              onChange={this.selectTileList}
            >
              <option value="active">Active</option>
              <option value="archived">Completed</option>
            </select>
          </div>
          <div className="col-sm-4 text-right margin-top">{this.toStep1()}</div>
        </div>

        <div>
          <div class="tile-header">
            <div className="row">
              <div className="col-md-3 text-indent-left">Tile Name & Focus</div>
              <div className="col-md-3 text-center">Average (Last 7 Days)</div>
              <div className="col-md-6 text-center">Last Recorded Activity</div>
            </div>
          </div>

          <ul className="tiles-ul">
            {this.state.listType
              ? this.createList()
              : this.createArchivedList()}
          </ul>
        </div>
        <div className="pagination-container">
          {this.state.totalPages > 0 ? (
            <ReactPaginate
              pageCount={this.state.totalPages}
              pageRange={10}
              pageMargin={5}
              pageClassName="paginate paginate-default"
              previousClassName="paginate paginate-previous"
              nextClassName="paginate paginate-next"
              onPageChange={this.handlePageClick}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ singleUser }) {
  return { singleUser };
}

export default connect(mapStateToProps)(Tiles);
