import React, {useState} from 'react';

// styles
import styles from './nicknames.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {SecondaryInfoAlert} from "../../alerts/alerts";

const Nicknames = props => {
    // props
    const {names, setStatus} = props;
    // local
    const [rejection, setRejection] = useState(null);

    function confirm(userId) {
        if (rejection === null) return setRejection(userId);
        setStatus(userId, 'rejected');
    }
    if (names.length < 1) {
        return (
            <div>
                <SecondaryInfoAlert>There are no nicknames awaiting review at this time</SecondaryInfoAlert>
            </div>
        )
    }

    return (
        <div>
            <ul className={styles.list}>
                {names.map(name => (
                    <li>
                        <BoxContainer className={styles.row}>
                            <div className={styles.username}>{name.username}</div>
                            <div className={styles.nickname}>{name.nickname}</div>
                            <button onClick={() => confirm(name.user_id)} className={styles.reject}>{rejection === name.user_id ? 'Confirm' : 'Reject'}</button>
                            <button onClick={() => setStatus(name.user_id, 'accepted')} className={styles.approve}>Approve</button>
                        </BoxContainer>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Nicknames;