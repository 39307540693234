import React, {Component} from 'react';
import {fetchSelf} from "../../../actions/action_user";
import {connect} from 'react-redux';
import PhotoContainer from '../CoachingTeam/Container';
import {  Modal, Button } from 'react-bootstrap';
import EditTeammate from './EditTeammate';

class TeammatesList extends Component{
    constructor(){
        super();
        this.state = {
          show: false,
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
      this.setState({ show: false });
    }

    handleShow() {
      this.setState({ show: true });
    }

    render(){
      return(


            <div className="support-member">                        
              <div className="support-img" onClick={() => this.setState({ show: true })}>
                  <PhotoContainer image={this.props.profileImage} username={null}/>
              </div>
              <div className="support-info text-center">

                  <div className="support-name">{this.props.nickname}</div>                            

                <div className="support-role">
                     {this.props.status === 1
                      ? <p>Teammate Since {this.props.date}</p>
                      : <p>Pending Since {this.props.date}</p>
                     }
                </div>
              </div>

              <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Teammate</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <EditTeammate
                      teammateId={this.props.teammateId}
                      email={this.props.email}
                      firstName={this.props.firstName}
                      lastName={this.props.lastName}
                      nickname={this.props.nickname}
                      profileImage={this.props.profileImage}
                      updateList={this.props.updateList}
                      handleClose={this.handleClose}
                     />

                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
              </Modal>

            </div>  

        )
    }
}


function mapStateToProps({user}){
    return{user}
}

export default connect(mapStateToProps, {fetchSelf})(TeammatesList);
