import React, {useState, useEffect, useRef} from 'react';

// styles
import styles from './graphPlaceholder.module.css';

// components
import {ReactComponent as Graph} from '../../images/graph_placeholder.svg';
import {ReactComponent as GraphLong} from '../../images/graph_placeholder_long.svg';

export const GraphPlaceholder = props => {
    // local
    const size = useRef(null);
    const [stateSize, setStateSize] = useState(0);
    // hooks
    useEffect(() => {
        onSize();
        window.addEventListener('resize', onSize);
        return () => window.removeEventListener('resize', onSize);
    }, []);

    // functions
    function onSize() {
        if (!size.current) return;
        setStateSize(size.current.clientWidth);
    }

    function choosePlaceholder() {
        if (!stateSize) return;
        if (stateSize < 200) return <Graph/>;
        if (stateSize < 500) return <Graph/>;
        return <GraphLong/>
    }
    return (
        <div ref={size} className={styles.placeholderContainer}>
            {choosePlaceholder()}
        </div>
    )
}