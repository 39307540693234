import React, {useState, useEffect, useRef} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

// styles
import styles from './organizationContent.module.css';

// actions
import {
    searchOrgContent,
    assignCategoryToOrg,
    fetchOrgAssignedCategories,
    removeCategoryFromOrg,
    reorderOrgCategories,
} from "../../../store/actions/organizationActions";

// components
import Loading from "../../Loading/Loading";
import ContentCategory from "./contentCategory/contentCategory";
import AddContent from "./addContent/addContent";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import {Modal} from "react-bootstrap";
import {DangerButton, PrimaryButton} from "../../buttons/buttons";

const OrganizationContent = props => {
    // props
    const {organization} = props;
    // local
    const [ready, setReady] = useState(false);
    const [content, setContent] = useState([]);
    const [categories, setCategories] = useState([]);
    const search = useRef(''); // used with stateSearch to keep track of input
    const [stateSearch, setStateSearch] = useState('') // used with search to keep track of input
    const [secondaryFilter, setSecondaryFilter] = useState({});
    const [show, setShow] = useState(false); // show removal warning
    const [categoryWarning, setCategoryWarning] = useState(null); // hold temp category for removal
    const [showAddWarning, setShowAddWarning] = useState(false); // show add modal
    const [addCategory, setAddCategory] = useState(null); // hold temp category for addition

    // check if content has finished loading
    useEffect(() => {
        getAssignedCategories();
        setReady(true);
    }, []);

    useDidMountEffect(() => {
        setTimeout(() => {
            if (stateSearch === search.current) {
                searchCategories({
                    search: search.current,
                    organization: secondaryFilter.organization,
                    sort: secondaryFilter.sort,
                });
            }
        }, 500);
    }, [stateSearch]);

    useDidMountEffect(() => {
        searchCategories({
            search: search.current,
            organization: secondaryFilter.organization,
            sort: secondaryFilter.sort,
        });
    }, [secondaryFilter]);

    async function getAssignedCategories() {
        try {
            const cats = await fetchOrgAssignedCategories(organization.id);
            setContent(cats);
            console.log(cats);
        } catch (e) {
            console.log(e);
        }
    }

    async function onDragEnd({destination, source, category}) {
        // do nothing if no movement was made
        if(!destination) return;
        if(destination.index === source.index) return;
        if (content.length <= 1) return; // can't reorder a list of 1

        let clone;
        if (!category) {
            clone = [...content];
            const [removed] = clone.splice(source.index, 1);
            clone.splice(destination.index, 0, removed);
            setContent(clone);
        } else {
            const found = content.find(item => item.category_id === category.category_id);
            clone = [...found.subcategories];
            const [removed] = clone.splice(source.index, 1);
            clone.splice(destination.index, 0, removed);
            const updated =content.map(cat => {
                if (cat.category_id === found.category_id) {
                    return {
                        ...found,
                        subcategories: clone,
                    }
                } else {
                    return cat;
                }
            })
            setContent(updated);
        }
        await reorderOrgCategories(organization.id, clone.map(item => item.assigned_id))
            .catch(e => console.log(e));
        getAssignedCategories();
    }

    function searchCategories(params) {
        try{
            if (!params.sort) return;
            searchOrgContent(organization.id, params)
                .then(cats => {
                    setCategories(cats);
                    console.log(cats);
                })
        } catch(e) {
            console.log(e);
        }
    }

    async function setAssignment(category) {
        const parent = categories.find(cat => {
            return cat.subcategories.find(sub => sub.id === category.id);
        });
        if (parent && !content.find(cat => cat.category_id === parent.id) && !addCategory && parent.content.length){
            setAddCategory({category, parent});
            setShowAddWarning(true);
            return;
        }
        try {
            await assignCategoryToOrg(organization.id, category.id);
            getAssignedCategories();
            searchCategories({
                search: search.current,
                organization: secondaryFilter.organization,
                sort: secondaryFilter.sort,
            });
        } catch (e) {
            console.log(e);
        }
    }

    async function removeAssignment(category) {
        try {
            await removeCategoryFromOrg(organization.id, category.assigned_id);
            getAssignedCategories();
            searchCategories({
                search: search.current,
                organization: secondaryFilter.organization,
                sort: secondaryFilter.sort,
            });
            closeWarning();
        } catch (e) {
            console.log(e);
        }
    }

    function showWaring(category) {
        setCategoryWarning(category);
        setShow(true);
    }

    function closeWarning() {
        setCategoryWarning(null);
        setShow(false);
    }

    function closeAddWarning() {
        setAddCategory(null);
        setShowAddWarning(false);
    }

    if (!ready) return (
        <div className='margin-top-2x'>
            <Loading/>
        </div>
    )
    return (
        <div className='margin-top-2x'>
            <h2 className='m:0 margin-bottom-2x'>Content</h2>

            <div className={styles.container}>
                {/* list of content assigned to org */}
                <div className={styles.list}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='mainCategoryDrop'>
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {content.map((item, i) => (
                                        <Draggable key={item.category_id} draggableId={`${item.category_id}`} index={i}>
                                            {(provided) => (
                                                <div
                                                    key={item.category_id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}>
                                                    <ContentCategory
                                                        provided={provided}
                                                        category={item}
                                                        remove={showWaring}
                                                        onDragEnd={onDragEnd}/>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                {/* sidebar */}
                <div className={styles.sidebar}>
                    <h5 className='m:0 margin-bottom-15'>Add Content Tiles</h5>
                    <AddContent
                        assignCategory={setAssignment}
                        categories={categories}
                        search={search}
                        setStateSearch={setStateSearch}
                        setSecondaryFilter={setSecondaryFilter}
                        assigned={content}
                    />
                </div>
            </div>

            <Modal show={show} onHide={closeWarning}>
                <Modal.Header closeButton>
                    <h4>WARNING</h4>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove this category?</p>
                </Modal.Body>
                <Modal.Footer>
                    <DangerButton onClick={closeWarning} className='margin-right-25'>Cancel</DangerButton>
                    <PrimaryButton onClick={()=>removeAssignment(categoryWarning)}>Confirm</PrimaryButton>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddWarning} onHide={closeAddWarning}>
                <Modal.Header closeButton>
                    <h4>WARNING</h4>
                </Modal.Header>
                <Modal.Body>
                    {addCategory ?
                        <div>
                            <p>The parent category <strong>{addCategory.parent.category_name}</strong> hasn't been added to this organization yet. Adding <strong>{addCategory.category.category_name}</strong> will assign <strong>{addCategory.parent.category_name}</strong> and all of the content listed below.</p>
                            <ul>
                                {addCategory.parent.content.map(item => <li>{item.title}</li>)}
                            </ul>
                        </div>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <DangerButton onClick={closeAddWarning} className='margin-right-25'>Cancel</DangerButton>
                    <PrimaryButton onClick={() => {
                        const addCopy = {...addCategory.category};
                        setShowAddWarning(false);
                        setAddCategory(null);
                        setAssignment(addCopy);
                    }}>Confirm</PrimaryButton>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OrganizationContent;