import {combineReducers} from 'redux';

import {
    appStateReducer,
    sessionReducer,
} from "./reducers/appStateReducer";

import {
    lifeTimeScoreReducer,
    scoreHistoryReducer,
    dailyScoreReducer,
    scoreChangedReducer,
    monthScoreReducer,
} from "./reducers/wellnessScoreReducer";

import {
    clientPathwayReducer,
    clientPathwayListReducer
} from './reducers/pathwaysReducer';

import {
    clientTileReducer,
    clientTileListReducer,
} from "./reducers/tileReducers";

import {
    coachReducer,
    alertOptionsReducer
} from './reducers/userReducer';

import {
    memberAssessmentsReducer,
    singleMemberAssessmentReducer,
} from "./reducers/assessmentReducers";

import {
    alertReducer,
    alertSubscriptionReducer
} from "./reducers/alertReducer";

import {
    badgesReducer
} from "./reducers/badgesReducer";
import {
    communityReducer
} from "./reducers/communityReducer";

// legacy reducers
import userSelfReducer from "../reducers/reducer_userSelf";
import TileReducer from "../reducers/reducer_singleTile";
import PlansReducer from "../reducers/reducer_planList";
import PlanReducer from "../reducers/reducer_singlePlan";
import noteReducer from "../reducers/reducer_setNote";
import userSingleReducer from "../reducers/reducer_singleUser";
import singleWellnessScore from "../reducers/reducer_wellnessScore";
import messageReducer from "../reducers/reducer_message";
import coachReducerLegacy from "../reducers/reducer_coach";
import singleCoachReducer from "../reducers/reducer_singleCoach";
import {reducer as formReducer} from "redux-form";
import assessmentsReducer from "../reducers/reducer_assessments";
import singleAssessmentReducer from "../reducers/reducer_singleAssessment";
import activeContentReducer from "../reducers/reducer_content";
import responseReducer from "../reducers/reducer_responses";
import coachAssignmentCountReducer from "../reducers/reducer_coachAssignemntCount";
import {bookingsReducer} from "../reducers/reducer_calendar";
import {introTileReducer, tileListPageReducer, tileListReducer} from "../reducers/reducer_tiles";
import {userSearchReducer} from "../reducers/reducer_users";
import { newFormReducer, singleFormReducer} from "../reducers/reducer_forms";
import {
    clientPolicyListReducer,
    complianceCheckReducer,
    policyReducer,
    policyVersionReducer,
    setPolicyEditReducer,
    versionListReducer
} from "../reducers/reducer_policy";
import {
    organizationAvailableCoachesReducer, organizationClientsReducer, organizationCodesReducer,
    organizationListReducer,
    organizationReducer, organizationWaitlistReducer, seatHistoryReducer
} from "../reducers/reducer_organizations";

export default combineReducers({
    // general readiness/state of the app
    appState: appStateReducer,

    // holds the session key
    sessionKey: sessionReducer,

    // holds a single pathway
    clientPathway: clientPathwayReducer,

    // holds a list of pathways (for client use)
    clientPathwayList: clientPathwayListReducer,

    // holds a single tile for a client
    clientTile: clientTileReducer,

    // holds a list of tiles for a logged in client
    clientTileList: clientTileListReducer,

    // daily score
    dailyScore: dailyScoreReducer,

    // lifetime score
    lifetimeScore: lifeTimeScoreReducer,

    // current score for month
    monthlyScore: monthScoreReducer,

    // a list of assessments for a member
    memberAssessments: memberAssessmentsReducer,

    // score history (either daily or lifetime)
    scoreHistory: scoreHistoryReducer,

    // whether the score has changed
    scoreChanged: scoreChangedReducer,

    // holds the details of a single coach
    singleCoach: coachReducer,

    // holds a single assessment
    singleMemberAssessment: singleMemberAssessmentReducer,

    // holds the list of alerts yet to run
    alertList: alertReducer,

    // holds the current alert being notified
    alertSubscription: alertSubscriptionReducer,

    // holds current badge hierarchy
    badges: badgesReducer,

    // holds the alert options a member has chosen
    alertOptions: alertOptionsReducer,

    // holds the number of community notifications waiting
    communityNotifications: communityReducer,

    /*********** LEGACY REDUCERS BELOW ***********/
    //state info about user who called
    user:userSelfReducer,

    //state info relating to single tile
    tile:TileReducer,

    //state info about list of plans for a single tile
    plans:PlansReducer,

    //state info about singular plan
    plan:PlanReducer,

    //
    notes:noteReducer,

    //state info regarding single user
    singleUser:userSingleReducer,

    //single wellness score info for single user
    wellnessScore: singleWellnessScore,

    message:messageReducer,

    //holds a custom object of coach data
    coach:coachReducerLegacy,

    //holds a coaches data called from the server
    single_coach:singleCoachReducer,

    //handles any state related to redux forms
    form:formReducer,

    //assessments for User
    assessments: assessmentsReducer,

    //holds single assessment
    single_assessment: singleAssessmentReducer,

    //holds a single piece of content that is currently being viewed
    active_content:activeContentReducer,

    //holds array of responses to an assessment
    responses: responseReducer,

    //holds list of coaches in desc order from most clients assigned to least
    coach_assignment_count: coachAssignmentCountReducer,

    //holds bookings for a coach
    bookings : bookingsReducer,

    //holds list of intro tiles, paginated
    intro_tiles : tileListReducer,

    //holds the total count of intro tiles
    intro_tiles_count : tileListPageReducer,

    //holds a single intro tile
    intro_tile : introTileReducer,

    //holds either a null value or an array of usernames for client searching
    username_search : userSearchReducer,

    // holds a form awaiting saving
    newForm: newFormReducer,

    // holds a single form
    singleForm: singleFormReducer,

    // holds a single policy version
    policyVersion: policyVersionReducer,

    // holds a single policy
    singlePolicy: policyReducer,

    // holds a list of versions of a particular policy
    versionList: versionListReducer,

    // set the editable state of a single policy
    editablePolicy: setPolicyEditReducer,

    // holds the state of a users current compliance with policies'
    complianceCheck: complianceCheckReducer,

    // holds a list of polices which a client may accept/decline
    clientPolicyList: clientPolicyListReducer,

    // holds a list of organizations
    organizationList: organizationListReducer,

    // holds a single organization
    organization: organizationReducer,

    // holds a list of coaches available to an organization
    organizationAvailableCoaches: organizationAvailableCoachesReducer,

    // holds a list of clients that belong to an organization
    organizationClients: organizationClientsReducer,

    // holds a list of codes for an organization
    organizationCodes: organizationCodesReducer,

    // holds organization seat history
    seatHistory: seatHistoryReducer,

    // holds organization waitlist
    organizationWaitlist: organizationWaitlistReducer,
});
