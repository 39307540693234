import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";
import './Step1.css'

let whatYouDo = [];
let whenYouEat = [];
let whatYouEat = [];

class Step1 extends Component{
   constructor(props){
       super(props);
           this.state = {
               firstName : '',
               tileStyle1: true,
               tileStyle2: true,
               tileStyle3: true,
               whenYouEat: null,
               whatYouEat: null,
               whatYouDo: null,
               category: null,
               categoryId: null,
               validate: false,
               categories: [],
               activeTilesArrAll: [],
               whatYouDoActive: [],
               whenYouEatActive: [],
               whatYouEatActive: []

       };
   }

    moveToStep2(categoryId, category){
        console.log(categoryId, category)
        if(this.state.category === null) {
            alert('select a tile please')
            return;
        }
        let currentPath = this.props.location.pathname,
            parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
        this.props.history.push({
            pathname: parentPath+'/step-2',
            state: {
                categoryId:categoryId,
                category:category,
                planStatus:'active',          
            }
        })
    }

    componentDidMount() {
         axios.get(`/notes/list/${this.props.username}/page/1`)
            .then(res => {
                console.log(res)
            })
        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({firstName:res.data.user.first_name});
            })
        axios.get(`/tiles/categories`)
            .then(res => {
                this.setState({categories: res.data.categories})
            })
        axios.get(`/tiles/list/${this.props.username}`)
            .then(res => {
                console.log(res)
                this.setState({activeTilesArrAll: res.data.result});
            })
    }

    findActiveTiles=()=>{
            whatYouEat = [];
            whenYouEat = [];
            whatYouDo = [];
            if(this.state.activeTilesArrAll){
                 console.log(this.state.activeTilesArrAll)
                 this.state.activeTilesArrAll.map((item)=>{  

                        if(item.tile.tile_category_id === 1){whatYouDo.push(item.tile.tile_category_id)}
                        if(item.tile.tile_category_id === 2){whenYouEat.push(item.tile.tile_category_id)}
                        if(item.tile.tile_category_id === 3){whatYouEat.push(item.tile.tile_category_id)}

                });
            }
        };

    changeTileClass1() {
        this.setState({tileStyle1: !this.state.tileStyle1}, () => {
            if(this.state.tileStyle1 === false){
                this.setState({category: 'when you eat'})
                this.setState({categoryId: 2})
                this.setState({tileStyle2: true})
                this.setState({tileStyle3: true})
            }
            else{this.setState({category: null})}
        }) 

    }
    changeTileClass2() {
        this.setState({tileStyle2: !this.state.tileStyle2}, () => {
            if(this.state.tileStyle2 === false){
                this.setState({category: 'what you eat'})
                this.setState({categoryId: 3})
                this.setState({tileStyle1: true})
                this.setState({tileStyle3: true})
            }
            else{this.setState({category: null})}
        })
    }
    changeTileClass3() {
        this.setState({tileStyle3: !this.state.tileStyle3}, () => {
            if(this.state.tileStyle3 === false){
                this.setState({category: 'what you do'})
                this.setState({categoryId: 1})
                this.setState({tileStyle1: true})
                this.setState({tileStyle2: true})
            }
            else{this.setState({category: null})}
        })
    }

    render(){
        // {this.findActiveTiles()} // dont know if we need this
        let changeTileClass1 = this.state.tileStyle1 ? "tileCategories1 tile-card" : "tile-card tile-selected";
        let changeTileClass2 = this.state.tileStyle2 ? "tileCategories2 tile-card" : "tile-card tile-selected";
        let changeTileClass3 = this.state.tileStyle3 ? "tileCategories3 tile-card" : "tile-card tile-selected";
        return(
            <div>
                <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <h2>Add New Tile</h2>
                        <p>Select new tile for {this.state.firstName}</p>
                    </div>
                </div>
                <div className="row margin-top">
                    <div className="col-md-9 col-xs-12">
                        <ul className="progressbar">
                            <li className="active">Step 1: Select Tile</li>
                            <li>Step 2: Select Plan</li>
                            <li>Step 3: Set Goals</li>
                            <li>Step 4: Confirm & Save</li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-xs-12">
                        <button className="btn primary" onClick={()=>this.moveToStep2(this.state.categoryId, this.state.category)}>Next Step</button>
                    </div>
                </div>
                <div className="row">
                    <div className="tileCategoriesContainer tile-creation--container">
                        <div className={changeTileClass1} onClick={()=>this.changeTileClass1()}>
                            <h4 className={'tileBar-center _whenye'}>When You Eat</h4>
                            <p>Description Text</p>
                            <p><i>{whenYouEat.length} Active Tiles</i></p>
                        </div>
                        <div className={changeTileClass2} onClick={()=>this.changeTileClass2()}>
                            <h4 className={'tileBar-center _wye'}>What You Eat</h4>
                            <p>Description Text</p>
                            <p><i>{whatYouEat.length} Active Tiles</i></p>
                        </div>
                        <div className={changeTileClass3} onClick={()=>this.changeTileClass3()}>
                            <h4 className={'tileBar-center _wyd'}>What You Do</h4>
                            <p>Description Text</p>
                            <p><i>{whatYouDo.length} Active Tiles</i></p>
                        </div>
                    </div>                                        
                </div>
            </div>
        )

    }
}

export default withRouter(Step1);

