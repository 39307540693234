import {policyApi} from "../../api/policyApi";

//**************** Actions that involve the store *****************//

//**************** Actions that don't involve the store *****************//

// get whether a member is in compliance with all treo policies
export async function checkCompliance(username){
    const {data: {result}} = await policyApi().memberCompliance(username);
    return result;
}

// fetch a list of active policies for the app
export async function fetchActivePolicies(){
    const {data: {result}} = await policyApi().getActivePolicies();
    return result;
}

// fetch a version of a policy
export async function fetchPolicyVersion(versionId){
    const {data: {result}} = await policyApi().getPolicyVersion(versionId);
    return result;
}

// accept a version of a policy
export async function acceptPolicyVersion(policyId, versionId, username){
    const {data: {result}} = await policyApi().acceptPolicy(policyId, versionId, username);
    return result;
}