import { calendarApi } from "../../api/calendarApi";

//**************** Actions that involve the store *****************//

//**************** Actions that don't involve the store *****************//

// fetch all of the calendly calendars for a logged in coach
// optional parameter of coachId
export async function fetchCalendars(coachId=null) {
    const {data: {result}} = await calendarApi().getCalendars(coachId);
    return result;
}

// add a calendar to a coach
export async function addCalendar(val) {
    const {data: {result}} = await calendarApi().postCalendar(val);
    return result;
}

// update a calendar to a coach
export async function updateCalendar(id, val) {
    const {data: {result}} = await calendarApi().updateCalendar(id, val);
    return result;
}

// delete a calendar to a coach
export async function removeCalendar(id) {
    const {data: {result}} = await calendarApi().deleteCalendar(id);
    return result;
}

// fetch the calendly api key for a user, optional id for admins
export async function fetchCalendlyApiKey(id=null) {
    const {data: {result}} = await calendarApi().getCalendlyApiKey(id);
    return result;
}

// update/set a calendly api key
export async function updateApiKey(key) {
    const {data: {result}} = await calendarApi().updateCalendlyApiKey(key);
    return result;
}

// get list of calendly events for a coach
export async function getEvents(coachId) {
    const {data: {result}} = await calendarApi().getCalendlyEvents(coachId);
    return result;
}

// make a record of a calendar appointment being made
export async function recordSchedule(coachId, eventId, calendarId) {
    calendarApi().recordAppointment(coachId, eventId, calendarId);
}