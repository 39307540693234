import React, {Component} from 'react';
import {VictoryPie} from 'victory';

//props:
//score = wellness score of a particular person
export class WellnessScoreScale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            score: this.props.score ? this.props.score : 0,
            data: this.getData(this.props.score ? this.props.score : 0)
        };
    }

    getData(score) {
        return [{ x: 1, y: score }, { x: 2, y: 100 - score }];
    }

    componentDidUpdate(prevProps, prevstate){
         if(prevProps.score !== this.props.score ){
            this.setState({data: this.getData(this.props.score)})
         }
    }

    renderScoreTrail =()=>{
        return(
            <div className='victory-circle'>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                    <VictoryPie
                        standalone={false}
                        animate={{ duration: 1000 }}
                        data={this.state.data}
                        innerRadius={175}
                        cornerRadius={35}
                        labels={() => null}
                        style={{
                            data: { fill: (d) => {
                                    let color;
                                    if(d.y < 50) color = "#ec463f";
                                    if(d.y > 49 && d.y < 76) color = "#e0da60";
                                    if(d.y > 75) color = "#41ce41";
                                    return d.x === 1 ? color : "transparent";
                                }
                            }
                        }}
                    />
                </svg>
            </div>
        )
    };

    render() {

        return (
            <div>
                {this.props.score ? this.renderScoreTrail() : null}
            </div>
        );
    }
}