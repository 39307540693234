import {analyticsApi} from "../../api/analyticsApi";

//**************** Actions that involve the store *****************//

//**************** Actions that don't involve the store *****************//

// fetch the number of new members between two given dates
export async function fetchNewMembers(query) {
    const {data: {result}} = await analyticsApi().getNewMembers(query);
    return result;
}

export async function fetchActiveMembers(query) {
    const {data: {result}} = await analyticsApi().getActiveMembers(query);
    return result;
}

// fetch the graph of new member signups
export async function fetchNewMemberGraph(query) {
    const {data: {result}} = await analyticsApi().getNewMemberLog(query);
    return result;
}

// fetch the list of top preforming organizations
export async function fetchTopClients(query) {
    const {data: {result}} = await analyticsApi().getTopOrganization(query);
    return result;
}

// fetch the conversion metrics of a member group
export async function fetchConversionFactors(query) {
    const {data: {result}}= await analyticsApi().getConversionMetrics(query);
    return result;
}

// fetch the abandonment metrics of a member group
export async function fetchAbandonmentFactors(query) {
    const {data: {result}}= await analyticsApi().getAbandonmentMetrics(query);
    return result;
}

// fetch the member engagement graph data
export async function fetchEngagementData(query) {
    const {data: {result}}= await analyticsApi().getMemberEngagement(query);
    return result;
}

// fetch the member session graph data
export async function fetchSessionData(query) {
    const {data: {result}}= await analyticsApi().getMemberSessionData(query);
    return result;
}

// fetch the member session graph data
export async function fetchAgeDemographics(query) {
    const {data: {result}}= await analyticsApi().getAgeDemographics(query);
    return result;
}

// fetch the member session graph data
export async function fetchWeightDemographics(query) {
    const {data: {result}}= await analyticsApi().getWeightDemographics(query);
    return result;
}

// fetch the member session graph data
export async function fetchHeightDemographics(query) {
    const {data: {result}}= await analyticsApi().getHeightDemographics(query);
    return result;
}

// fetch the member session graph data
export async function fetchGenderDemographics(query) {
    const {data: {result}}= await analyticsApi().getGenderDemographics(query);
    return result;
}

// fetch the number of views all content has gotten for a period
export async function fetchTotalContentViews(query) {
    const {data: {result}}= await analyticsApi().getContentViews(query);
    return result;
}

// fetch the number of views all content has gotten for a period
export async function fetchContentWatchTime(query) {
    const {data: {result}}= await analyticsApi().getContentWatchTime(query);
    return result;
}

// fetch the category that had the most watched videos
export async function fetchMostWatchedCategory(query) {
    const {data: {result}}= await analyticsApi().getTopCategoryWatched(query);
    return result;
}

// fetch the single content piece that was most watched
export async function fetchMostWatchedContent(query) {
    const {data: {result}}= await analyticsApi().getTopContentWatched(query);
    return result;
}

// fetch the states (1 and 5+) for pathways views
export async function fetchPathwaysViews(query) {
    const {data: {result}}= await analyticsApi().getPathwaysViews(query);
    return result;
}

// fetch the category that received the least number of views
export async function fetchLeastWatchedCategory(query) {
    const {data: {result}}= await analyticsApi().getLeastWatchedCategory(query);
    return result;
}

// fetch a list of content by its popularity
export async function fetchPopularContentList(query, search) {
    const {data: {result}}= await analyticsApi().searchContentPopularity(query, search);
    return result;
}

// fetch a log of content views
export async function fetchContentViewsData(query) {
    const {data: {result}}= await analyticsApi().getContentViewsLog(query);
    return result;
}

// fetch a log of content watch time
export async function fetchContentWatchTimeData(query) {
    const {data: {result}}= await analyticsApi().getContentWatchTimeLog(query);
    return result;
}

// fetch a log of content average view duration time
export async function fetchContentDurationData(query) {
    const {data: {result}}= await analyticsApi().getContentDurationLog(query);
    return result;
}

// fetch a log of category views
export async function fetchCategoryViewsData(query) {
    const {data: {result}}= await analyticsApi().getCategoryViewsLog(query);
    return result;
}

// fetch a log of category watch time
export async function fetchCategoryWatchTimeData(query) {
    const {data: {result}}= await analyticsApi().getCategoryWatchTimeLog(query);
    return result;
}

// fetch a log of category average view duration time
export async function fetchCategoryDurationData(query) {
    const {data: {result}}= await analyticsApi().getCategoryDurationLog(query);
    return result;
}

// fetch the number of members that were assigned to a coach
export async function fetchCoachAssignedMembers(query) {
    const {data: {result}}= await analyticsApi().getCoachAssignedMembers(query);
    return result;
}

// fetch the number of organizations that were assigned to a coach
export async function fetchCoachAssignedOrganizations(query) {
    const {data: {result}}= await analyticsApi().getCoachAssignedOrganizations(query);
    return result;
}

// fetch the log of coach member growth
export async function fetchCoachMemberGrowthData(query) {
    const {data: {result}}= await analyticsApi().getCoachMemberGrowthLog(query);
    return result;
}

// fetch the log of coach message history
export async function fetchCoachMessageData(query) {
    const {data: {result}}= await analyticsApi().getCoachMessageLog(query);
    return result;
}

// fetch the stats of a coaches overall messaging activity
export async function fetchCoachMessagingStats(query) {
    const {data: {result}}= await analyticsApi().getCoachMessageStats(query);
    return result;
}

// fetch the number of threads a coach stated
export async function fetchCoachOpenedThreads(query) {
    const {data: {result}}= await analyticsApi().getCoachStartedThreadCount(query);
    return result;
}

// fetch the number of threads a coach reopened
export async function fetchCoachReopenedThreads(query) {
    const {data: {result}}= await analyticsApi().getCoachReopenedThreadCount(query);
    return result;
}

// fetch the average response time for coach messaging
export async function fetchCoachMessageResponseTime(query) {
    const {data: {result}}= await analyticsApi().getCoachResponseTimeStats(query);
    return result;
}

// fetch the log history of a coaches schedule
export async function fetchCoachSchedulingData(query) {
    const {data: {result}}= await analyticsApi().getCoachSchedulingLog(query);
    return result;
}

// fetch the dropoff ratio of a coach
export async function fetchCoachDropoff(query) {
    const {data: {result}}= await analyticsApi().getCoachDropoff(query);
    return result;
}

// fetch the stats of a coaches calendars
export async function fetchCoachCalendarStats(query) {
    const {data: {result}}= await analyticsApi().getCoachCalendarStats(query);
    return result;
}

// fetch health goal distribution rates
export async function fetchHealthGoalDistribution(query) {
    const {data: {result}}= await analyticsApi().getHealthGoals(query);
    return result;
}

// fetch victory feed for analytics
export async function fetchAnalyticsVictories(query, victoryId) {
    const {data: {result}}= await analyticsApi().getAnalyticsVictoryFeed(query, victoryId);
    return result;
}

// fetch profile views from victories
export async function fetchVictoryAverages(query) {
    const [profile, timespan, distribution] = await Promise.all([
        analyticsApi().getProfileViews(query),
        analyticsApi().getVictoryTimespan(query),
        analyticsApi().getVictoryDistribution(query),
    ]);
    return {
        profile: profile.data.result,
        timespan: timespan.data.result,
        distribution: distribution.data.result,
    };
}

// fetch reaction averages
export async function fetchReactionAverages(query) {
    const {data: {result}}= await analyticsApi().getReactionAverages(query);
    return result;
}

// fetch members who met their monthly goal
export async function fetchMembersWhoMetGoals(query) {
    const {data: {result}}= await analyticsApi().getMembersThatMetGoals(query);
    return result;
}

// fetch members who met their monthly goal in a csv file
export async function fetchMembersWhoMetGoalsCsv(query) {
    const {data}= await analyticsApi().getMembersThatMetGoalsCsv(query);
    return data;
}


export async function fetchOptionalAssessmentListMembers(query) {
    const {data: {result}}= await analyticsApi().getOptionalAssessmentListMembers(query);
    return result;
}

export async function fetchOptionalAssessmentAmountTaken(query) {
    const {data: {result}}= await analyticsApi().getOptionalAssessmentAmountTaken(query);
    return result;
}

export async function fetchNonAssessmentListMembersExport(query) {
    const response = await analyticsApi().getNonAssessmentListMembersExport(query);
    return response.data;
}

// first wotd
export async function fetchWotdWatchTime(query) {
    const {data}= await analyticsApi().getWotdWatchTime(query);
    return data;
}

export async function fetchWotdViews(query) {
    const {data}= await analyticsApi().getWotdViews(query);
    return data;
}

export async function fetchWotdContentViews(query){
    const {data: {result}}= await analyticsApi().getWotdContentViews(query);
    return result
}

export async function fetchWotdContentWatchTimes(query){
    const {data: {result}}= await analyticsApi().getWotdContentWatchTime(query);
    return result
}

export async function fetchWotdContentAvgDurations(query){
    const {data: {result}}= await analyticsApi().getWotdContentAvgDuration(query);
    return result
}
export async function fetchWotdCategoryViews(query){
    const {data: {result}}= await analyticsApi().getWotdCategoryViews(query);
    return result
}
export async function fetchWotdCategoryAvgWatchTime(query){
    const {data: {result}}= await analyticsApi().getWotdCategoryWatchTime(query);
    return result
}
export async function fetchWotdCategoryAvgDurations(query){
    const {data: {result}}= await analyticsApi().getWotdCategoryAvgDuration(query);
    return result
}

export async function fetchWotdVideoList(query, search){
    const {data: {result}}= await analyticsApi().getWotdVideoList(query, search);
    return result
}

// New Daily Wotd 
export async function fetchDailyWotViews(query){
    const {data: {result}}= await analyticsApi().getDailyWotdViews(query);
    return result
}
export async function fetchDailyWotdWatchTime(query){
    const {data: {result}}= await analyticsApi().getDailyWotdWatchTime(query);
    return result
}

export async function fetchDailyWotdCategoryWatchTime(query){
    const {data: {result}}= await analyticsApi().getDailyWotdCategoryWatchTime(query);
    return result
}
export async function fetchDailyWotdCategoryViews(query){
    const {data: {result}}= await analyticsApi().getDailyWotdCategoryViews(query);
    return result
}

export async function fetchDailyWotdCategoryAverage(query){
    const {data: {result}}= await analyticsApi().getDailyWotdCategoryAverage(query);
    return result
}

export async function fetchDailyWotdContentWatchTime(query){
    const {data: {result}}= await analyticsApi().getDailyWotdContentWatchTime(query);
    return result
}

export async function fetchDailyWotdContentViews(query){
    const {data: {result}}= await analyticsApi().getDailyWotdContentViews(query);
    return result
}
export async function fetchDailyContentWotdAverage(query){
    const {data: {result}}= await analyticsApi().getDailyContentWotdAverage(query);
    return result
}

export async function fetchDailyContentList(query, search){
    const {data: {result}} = await analyticsApi().getDailyContentList(query, search);
    return result
}

export async function fetchHealthConnectSyncedCount(query) {
    const {data: {result}} = await analyticsApi().getHealthConnectSyncedCount(query);
    return result
}


