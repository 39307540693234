import React, {useState} from 'react';

// styles
import styles from './section_2.module.css';

// components
import Plate from '../../../images/plate_time.jpg';
import {PrimaryButton} from "../../buttons/buttons";

const Section_2_Intro = props => {
    // props
    const {next} = props;
    // local
    const [ready, setReady] = useState(false);
    return (
        <div className={`${ready ? styles.fadeIn : ''} ${styles.container}`}>
            <h2 className={styles.heading}><strong>EATING PATTERNS</strong> IMPACT YOUR HEALTH</h2>
            <img onLoad={() => setReady(true)} className={styles.image} src={Plate} alt=""/>
            <p className={styles.text}>When it comes to your health, daily eating patterns can be as important as what you eat. Let’s learn about your current eating patterns.</p>
            <div className="t-a:c">
                <PrimaryButton onClick={next} data-cy="eatPatBtn">Next</PrimaryButton>
            </div>
        </div>
    )
}
export default Section_2_Intro;