import React from 'react';
import {withRouter} from 'react-router-dom';
import CoachingTeam from './CoachingTeam';
import axios from 'axios';
import {fetchSelf} from "../../../actions/action_user";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


class CoachingTeamMain extends React.Component{
    constructor(){
        super();
        this.state = {
          wellnessScore:'', 
          coachList: [],
          showList:false,
        }
    }

    componentDidMount(){
        this.props.fetchSelf();
        axios.get(`/user/coaching-team/`)
            .then(res => {
              this.setState({coachList: res.data.result, showList:true});
              console.log(this.state.coachList);
            })
          axios.get('/score/wellness-score')
              .then(
                  res=>{                   
                      this.setState({
                          wellnessScore:res.data.results.score
                      });
                  }
              )
              .catch(err=>console.log('ERROR: ', err));
    }

    createList=()=>{
      if(this.state.showList){
            if(this.state.coachList && this.props.user && this.state.coachList.length > 0){
                return this.state.coachList.map((item, i)=>{
                    if (i === 0) {
                        return(
                            <CoachingTeam
                                className='tour-coach-coachBtn'
                                firstName={item.first_name}
                                lastName={item.last_name}
                                title={item.title}
                                username={this.props.user.username}
                                coachList={item}
                                profileImage={item.profile_image}
                                coachUsername={item.username}
                            />
                        )
                    }
                       return(                       
                          <CoachingTeam
                            firstName={item.first_name}
                            lastName={item.last_name}
                            title={item.title}
                            username={this.props.user.username}
                            coachList={item}
                            profileImage={item.profile_image}
                            coachUsername={item.username}
                          />
                       )
                });
            }
            else{
              return(
                <div className="container no-margin">
                  <h4>You are not assigned to a coach. Contact Treo for help.</h4>
                </div>
                )
            }
        };
      }

    goBack() {
      window.history.back();
    } 


    render(){
    // eslint-disable-next-line
      var scoreClass = 'low';
      if (this.state.wellnessScore >= 51 && this.state.wellnessScore < 75 ) {
          scoreClass = 'average';
      } else if (this.state.wellnessScore >= 75 && this.state.wellnessScore < 90 ) {
          scoreClass = 'high';
      } else if (this.state.wellnessScore >= 90) {
          scoreClass = 'highest';
      } 
      return(
        <div className="client-container">
              
          <div className="container client-account-dash">
              <h3>COACHING TEAM</h3>              
              <div className="row margin-top">
                  <ul  className="tiles-ul coaching-team">           
                      {this.createList()}
                  </ul>
              </div>
      	  </div>  
          <div className="footer-actions">
              <div className="container">
                  <div className="row">
                      <div className="col-xs-6">
                          <button className="btn client-white" onClick={this.goBack}>Back</button>
                          
                      </div>

                  </div>
              </div>
          </div>
        </div>        
                	
      )
    }
}

function mapStateToProps({user}){
    return {user}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchSelf}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoachingTeamMain));