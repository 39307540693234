import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// styles
import styles from "./WorkOutofDayLanding.module.css";

// components
import Loading from "../Loading/Loading";
import {
  fetchWotdOrgAccess,
  fetchtWotdAllCategories,
} from "../../store/actions/wotdActions";

const WorkOutofDayLanding = (props) => {
  const [accessData, setAccessData] = useState({
    access: null,
    code: "",
    orgId: null,
    branding: false,
    thumbnail: "",
    signupCode: "",
    wotdSignup: false,
  });
  const [videoData, setVideoData] = useState([]);
  const organizationId = Number(props.match.params.organizationId);

  async function fetchAccess() {
    try {
      const response = await fetchWotdOrgAccess(organizationId);
      setAccessData({
        access: response.data.result.wotd_access,
        code: response.data.result.wotd_access,
        orgId: response.data.result.id,
        branding: response.data.result.whitelabeled,
        thumbnail: response.data.result.whitelabeled_logo,
        signupCode: response.data.result.wotd_signup_code,
        wotdSignup: response.data.result.wotd_signup,
      });
    } catch (err) {
      console.log(err);
      setAccessData({ access: false });
    }
  }

  async function fetchWotdVideos() {
    try {
      const response = await fetchtWotdAllCategories();
      setVideoData(response);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchAccess();
    fetchWotdVideos();
    document.body.style.margin = "0";
    document.body.style.padding = "0";

    return () => {
      document.body.style.margin = "";
      document.body.style.padding = "";
    };
  }, []);

  const VideoCard = ({ video }) => {
    function capitalizeFirstLetter(text) {
      return text.replace(/\b\w/g, (l) => l.toUpperCase());
    }

    const lines = video.category_name.trim().split("\n");
    const capitalizedLines = lines.map((line) =>
      capitalizeFirstLetter(line.trim())
    );

    const outputText = capitalizedLines.join("\n");

    if (!video) return <h1>Loading...</h1>;

    return (
      <div className={styles.cardStyle}>
        <Link
          to={`/${organizationId}/${video.category_id}/workoutofthedayvideo`}
          className={styles.videoTitle}
        >
          <img
            className={styles.thumbnailStyle}
            src={video.category_tile_image}
            alt={video.category_name}
          />
          <div className={styles.overlayStyle}></div>
          <div className={styles.titleStyle}>
            <span className={styles.videoTitleLanding}>{outputText}</span>
            {video.category_name &&
            ["climbmill", "ascent trainer", "virtual active"].includes(
              video.category_name.toLowerCase()
            ) ? (
              <span
                className={(() => {
                  switch (video.category_name.toLowerCase()) {
                    case "ascent trainer":
                      return styles.ATtrademark;
                    case "virtual active":
                      return styles.VAtrademark;
                    default:
                      return styles.trademark;
                  }
                })()}
              >
                &#8482;
              </span>
            ) : null}
          </div>
        </Link>
      </div>
    );
  };

  if (accessData.access === null) {
    return (
      <div className={styles.redirectContainer}>
        <Loading />
      </div>
    );
  }

  if (accessData.access === false) {
    return (
      <div className={styles.redirectContainer}>
        <h1>Organization Does not have access </h1>
        <Loading />
      </div>
    );
  }

  if (accessData.access === true) {
    return (
      <section className={styles.landingContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.titleFontColor}>Workout of the Day</h1>
        </div>

        <div
          className={
            videoData && videoData.length > 8
              ? styles.videoContainerGreaterEight
              : styles.videoContainer
          }
        >
          {videoData.map((video) => (
            <VideoCard key={video.category_id} video={video} />
          ))}
        </div>

        {accessData.wotdSignup ? (
          <div className={styles.signUpBtnContainer}>
            <h3 className={styles.subTitleFontColor}>Like what you see?</h3>

            <Link
              to={`/signup?orgId=${organizationId}&inviteCode=${accessData.signupCode}`}
            >
              <button className={`btn ${styles.signUpBtn}`}>Sign Up</button>
            </Link>
          </div>
        ) : null}

        {accessData.branding === true ? (
          <div className={styles.brandingContainer}>
            <img
              className={styles.brandingLogo}
              src={
                accessData.branding
                  ? accessData.thumbnail
                  : "https://treo.s3.us-east-2.amazonaws.com/assets/logo-color.png"
              }
              alt="Treo Wellness"
            />
          </div>
        ) : (
          <img
            className={
                styles.logoContainer
            }
            src={
              accessData.branding
                ? accessData.thumbnail
                : "https://treo.s3.us-east-2.amazonaws.com/assets/logo-color.png"
            }
            alt="Treo Wellness"
          />
        )}
      </section>
    );
  }

  return (
    <div className={styles.redirectContainer}>
      <h1>Organization Does not have access </h1>
      <Loading />
    </div>
  );
};
export default WorkOutofDayLanding;
