import React, {useState, useEffect} from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// styles
import styles from './wellnessScoreCircle.module.css';

const WellnessScoreCircle = props => {
    // props
    const {data, score, goal, label, forceSmall} = props;
    // local
    const [, setSize] = useState(window.innerWidth);

    useEffect(() => {
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    },[]);

    function resize() {
        setSize(window.innerWidth);
    }
    return (
        <div className={`${styles.container} ${forceSmall ? styles.small : ''}`} data-cy="wellnessScoreGoal">
            <div className={styles.innerCircle} >
                {label ? <h2 className={styles.label}>{label}</h2> : ''}
                <h2><span className={styles.actualScore} data-cy="wellnessScoreNumber"> {score} </span> <span>/ {goal}</span></h2>
            </div>
            <div className={styles.outerCircle}>
                {!score || !goal ?
                    null
                    :
                    <div className='VictoryContainer'>
                        <CircularProgressbar value={data} data-cy="cirProgBar"/>
                    </div>
                }
            </div>
        </div>
    )
}

export default WellnessScoreCircle;