import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setCoach, getCoach} from "../../../actions/action_coach";
import PhotoContainer from "../Inbox/container";


class CoachingTeam extends Component{
    constructor(){
        super();
        this.state = {
          coachId: '',
          coachFirstName: '',
          coachLastName: '',
          coachList: [],
        }
    }

    captureCoach=()=>{
        this.props.setCoach(this.props.coachList);
    };

    render(){
      return(
          <li className={`tiles-li col-xs-12 col-sm-6 ${this.props.className}`} >
            <Link to={`/member/clientDash/${this.props.username}/coaching-team/coach/${this.props.coachUsername}`} onClick={this.captureCoach}>
                    <div className={"tile"}>
                      <div className="tile-titles">
                         <h4>{this.props.firstName}</h4>
                          <p>{this.props.title}</p>
                      </div>
                      <div className="tile-icon coaching-image">
                        <div className="profile-image">
                          <PhotoContainer 
                          image={this.props.profileImage} 
                          username={this.props.coachUsername}
                          />
                        </div>
                     
                        </div>
                  </div>                    
              </Link>
          </li>         
                  
      )
    }
}

function mapStateToProps({coach}){
    return {coach}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({setCoach, getCoach}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoachingTeam));


