import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import LogIn from './components/logIn';
import { unregister } from './registerServiceWorker';
import './components/styles.css';
import "shed-css/dist/index.css";
import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

//admin imports
import ClientDetailMainAdmin from './components/Admin/clientDetail/clientDetailMain';
import CoachDetailMain from './components/Admin/coachDetail/coachDetailMain.jsx';
import AdminDetailMain from './components/Admin/Account/AdminDetailMain';
import AdminDashMain from './components/Admin/adminDash/adminDashMain';

import { Provider } from 'react-redux';
import configureStore from "./store/configureStore";

//coach imports
import CoachDashMain from './components/Coach/coachDash/coachDashMain';
import ClientSearch from './components/Coach/clientSearch/clientSearchMain';
import ClientDetailMainCoach from './components/Coach/clientDetail/clientDetailMain';

//client imports
import ClientArea from "./components/clientArea/clientArea";
import SignUpContainer from "./components/signUp/signUpContainer";

//password recovery
import PasswordRecoveryRoutes from './components/PasswordRecovery/routes';

//teammates
import EncourageMain from './components/EncourageClient/EncourageMain';
import SignUpMain from './components/TeammateSignUp/SignUpMain';
import ConsoleLandingRoutes from "./components/consoleLanding/consoleLandingRoutes";
import WorkOutofDayLanding from './components/workoutofday/WorkOutofDayLanding';
import WorkOutofDayVideo from './components/workoutofday/WorkOutofDayVideo';
import DailyWotdVideo from './components/WOTDDailySubContainer/DailyWotdVideo';
import DailyWotdContainer from './components/WOTDDailySubContainer/DailyWotdContainer';


const store = configureStore();
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <div className='app-container'>
                <div id="disableScreen"/>
                <Switch>
                    <Route path="/" exact component={LogIn}/>
                    <Route path='/signup' component={SignUpContainer}/>
                    <Route path='/member' component={ClientArea}/>
                    <Route path="/:organizationId/workoutoftheday" component={WorkOutofDayLanding} />
                    <Route path="/:organizationId/:videoId/workoutofthedayvideo" component={WorkOutofDayVideo} />
                    <Route path="/workoutoftheday/:wotdName/:year?" component={DailyWotdContainer}  exact={true}/>
                    <Route path="/wotd/:category?/:categoryName/:category_id" component={DailyWotdVideo} />

                    {/*Coach Routes*/}
                    <Route path='/coachDash' exact component={CoachDashMain}/>
                    <Route path='/coach/ClientSearch' exact component={ClientSearch}/>
                    <Route path="/coach/Client/:username" component={ClientDetailMainCoach}/>


                    {/* Admin Routes*/}
                    <Route path="/dashboard" component={AdminDashMain}/>
                    <Route path="/client/:username" component={ClientDetailMainAdmin}/>
                    <Route path="/coach/:username" component={CoachDetailMain}/>
                    <Route path="/admin/:username" component={AdminDetailMain}/>

                    {/*Password Recovery*/}
                    <Route path='/password-reset' component={PasswordRecoveryRoutes}/>

                    {/*Teammates*/}
                    <Route path='/encourage' component={EncourageMain}/>
                    <Route path='/teammate-confirmation' component={SignUpMain}/>

                    {/*Console*/}
                    <Route path='/console' component={ConsoleLandingRoutes}/>
                </Switch>
            </div>
        </BrowserRouter>
    </Provider>
    , document.getElementById('root'));

unregister();
