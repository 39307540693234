import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

// styles
import styles from './communityAdmin.module.css';

// components
import {BoxContainer} from "../containers/boxContainer/boxContainer";
import Victories from "./victories/victories";
import {
    fetchCommunityStats,
    fetchMemberVictories,
    fetchOwnReactions,
    fetchReactionFeed
} from "../../store/actions/communityActions";
import Reactions from "./reactions/reactions";
import UserReactions from "./userReactions/userReactions";

const CommunityAdmin = props => {
    // state
    const {singleUser} = props;
    // local
    const [victories, setVictories] = useState([]);
    const [victoriesEnd, setVictoriesEnd] = useState(false);
    const [reactions, setReactions] = useState([]);
    const [reactionsEnd, setReactionsEnd] = useState(false);
    const [ownReactions, setOwnReactions] = useState([]);
    const [ownReactionsEnd, setOwnReactionsEnd] = useState(false);
    const [communityStats, setCommunityStats] = useState(null);


    useEffect(() => {
        loadVictories();
        loadReactions();
        loadOwnReactions();
        loadCommunityStats();
    }, []);

    async function loadVictories(victoryId) {
        try {
            const res = await fetchMemberVictories(victoryId, singleUser.user.id);
            setVictories([...victories, ...res.victories]);
            if(!res.more) setVictoriesEnd(true);
        } catch (e) {
            console.log(e);
        }
    }

    async function loadReactions(reactionId) {
        try {
            const res = await fetchReactionFeed(reactionId, null, singleUser.user.id);
            setReactions([...reactions, ...res.reactions]);
            if (!res.more) setReactionsEnd(true);
        } catch (e) {
            console.log(e);
        }
    }

    async function loadOwnReactions(reactionId) {
        try {
            const res = await fetchOwnReactions(reactionId, singleUser.user.id);
            setOwnReactions([...ownReactions, ...res.reactions]);
            if (!res.more) setOwnReactionsEnd(true);
        } catch (e) {
            console.log(e);
        }
    }

    async function loadCommunityStats() {
        try {
            const res = await fetchCommunityStats(singleUser.user.id);
            setCommunityStats(res);
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className='margin-top-lrg container'>
            <h2 className='m:0 margin-bottom-25'>Community</h2>
            <BoxContainer className={styles.statsContainer}>
                {communityStats ?
                    <>
                        { singleUser.user.game ?
                            <div>
                                <div className={styles.stat}>{singleUser.user.game.rank_name}</div>
                                <div className={styles.label}>Rank</div>
                            </div>
                            : null
                        }
                        <div>
                            <div className={styles.stat}>{communityStats.profile_impressions}</div>
                            <div className={styles.label}>Profile Impressions</div>
                        </div>
                        <div>
                            <div className={styles.stat}>{communityStats.reactions_given + communityStats.reactions_recieved}</div>
                            <div className={styles.label}>Total Reactions</div>
                        </div>
                        <div>
                            <div className={styles.stat}>{communityStats.reactions_given}</div>
                            <div className={styles.label}>User Reactions</div>
                        </div>
                    </>
                    : null
                }
            </BoxContainer>

            <div className={styles.innerRow}>
                <div className={styles.box}>
                    <Victories loadMore={loadVictories} victories={victories} ended={victoriesEnd}/>
                </div>
                <div className={styles.box}>
                    <Reactions loadMore={loadReactions} reactions={reactions} ended={reactionsEnd}/>
                </div>
            </div>

            <div className={styles.innerRow}>
                <div className={styles.box}>
                    <UserReactions reactions={ownReactions} loadMore={loadOwnReactions} ended={ownReactionsEnd}/>
                </div>
                <div className={styles.box}/>
            </div>

        </div>
    )
}

function mapStateToProps({singleUser}) {
    return {singleUser};
}

export default connect(mapStateToProps)(CommunityAdmin);