import React, {useEffect, useState} from 'react';
import validate from 'validate.js';
import {format} from 'date-fns';
import {Link} from 'react-router-dom';

// styles
import styles from './calendlySettings.module.css';

// actions
import {
    fetchCalendars,
    addCalendar,
    updateCalendar,
    removeCalendar,
    fetchCalendlyApiKey,
    updateApiKey,
} from "../../store/actions/calendarActions";

// components
import {BasicButton, DangerButton, PrimaryButton} from "../buttons/buttons";
import {Modal} from "react-bootstrap";
import {BoxContainer} from "../containers/boxContainer/boxContainer";
import {DangerAlert, ErrorAlert, SaveAlert} from "../alerts/alerts";

const addCalendarConstraints = {
    title: {
        presence: true,
        type: 'string',
        length: { minimum: 1, maximum: 255 }
    },
    url: {
        presence: true,
        type: 'string',
        length: { minimum: 1, maximum: 500 }
    },
    duration: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
        }
    },
};
const apiConstraints = {
    apiKey: {
        presence: true,
        type: 'string',
        length: { maximum: 500 }
    }
};

const CalendlySettings = () => {
    // local
    const [mounted, setMounted] = useState(false);
    const [error, setError] = useState(null); // global page error
    const [validation, setValidation] = useState(null); // form errors
    const [removalError, setRemovalError] = useState(null); // error trying to delete calendar
    const [calendars, setCalendars] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [previewModal, setPreviewModal] = useState({show: false, url: ''});
    const [deleteCal, setDeleteCal] = useState(null);
    const [editCalendar, setEditCalendar] = useState({
        id: null,
        title: '',
        url: '',
        duration: 0,
        onboarding: false,
    });
    const [saving, setSaving] = useState(false);
    const [finishedSaving, setFinishedSaving] = useState(false);
    const [savingApi, setSavingApi] = useState(false);
    const [finishedSavingApi, setFinishedSavingApi] = useState(false);
    const [apiKey, setApiKey] = useState('');

    async function postCalendar(e) {
        e.preventDefault();

        // validation
        const mistakes = validate(editCalendar, addCalendarConstraints);
        setValidation(mistakes);
        if (mistakes) return;

        setSaving(true);
        const val = {
            title: editCalendar.title,
            url: editCalendar.url,
            duration: editCalendar.duration,
            primary: editCalendar.onboarding,
        }
        let result;
        try {
            if (editCalendar.id) {
                result = await updateCalendar(editCalendar.id, val);
            } else {
                result = await addCalendar(val);
            }
            setFinishedSaving(true);
            setCalendars(result);
            setError(null);
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error)
            } else {
                setError('Unable to update Calendly calendars. If this error persists, please contact your systems administrator.')
            }
            hideModal();
        }
        setValidation(null);
    }

    async function postApiKey(e) {
        e.preventDefault();

        // validation
        const mistakes = validate({apiKey}, apiConstraints);
        setValidation(mistakes);
        if (mistakes) return;

        setSavingApi(true);
        try {
            await updateApiKey(apiKey);
            setFinishedSavingApi(true);
            setError(null);
        } catch (e) {
            try {
                if (e.response.data.error[0] === '{'){
                    setRemovalError(JSON.parse(e.response.data.error));
                } else {
                    setError(e.response.data.error);
                }
                getApi();
            } catch (e) {
                setError('Unable to save api key. If error persists, please contact system administrator');
            }
        }
        setValidation(null);
    }

    function hideModal() {
        setAddModal(false);
        setEditCalendar({
            id: null,
            title: '',
            url: '',
            duration: 0,
            onboarding: false,
        });
        setSaving(false);
        setFinishedSaving(false);
    }

    function openModal(val) {
        if (val) {
            setEditCalendar({
                id: val.id || null,
                title: val.title || '',
                url: val.url || '',
                duration: val.duration || 0,
                onboarding: val.onboarding || false,
                onboardingDisabled: val.onboarding || false,
            });
        }
        setAddModal(true);
    }

    async function remove(id) {
        setRemovalError(null);
        try{
            const calendars = await removeCalendar(id);
            setCalendars(calendars);
        } catch(e) {
            try {
                setRemovalError(JSON.parse(e.response.data.error));
            } catch (e) {
                setError('Unable to remove calendar. If error persists, please contact system administrator');
            }
        }
        setDeleteCal(null);
        setDeleteModal(false);
    }

    function hideDeleteModal() {
        setDeleteCal(null);
        setDeleteModal(false);
    }

    function openDeleteModal(calendar) {
        setDeleteCal(calendar);
        setDeleteModal(true);
    }

    function hidePreviewModal() {
        setPreviewModal({show: false, url: ''});
    }

    function getApi() {
        fetchCalendlyApiKey()
            .then(result => setApiKey(result))
            .catch(e => {
                if (e.response && e.response.data) {
                    setError(e.response.data.error)
                } else {
                    setError('Unable to get Calendly api key. If this error persists, please contact your systems administrator.')
                }
            })
    }

    // grab coach calendars on mount
    useEffect(() => {
        fetchCalendars()
            .then(result => {
                setCalendars(result);
                setMounted(true);
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setError(e.response.data.error)
                } else {
                    setError('Unable to get list of Calendly calendars. If this error persists, please contact your systems administrator.')
                }
                setMounted(true);
            });
    }, []);

    // grab coach api key on mount
    useEffect(() => {
        getApi();
    }, []);

    if (!mounted) return <div/>

    return (

        <div className='container'>
            <h2 className='m-t:0'>Calendars</h2>
            {calendars.length < 2 || !calendars.find(cal => cal.primary) ?
                <div>
                    <div className='p:1 bg-danger d:i-b'>Coaching account must have 2 calendly accounts associated with it with 1 marked as primary.</div>
                </div>
                : null
            }
            { error ?
                <div>
                    <ErrorAlert>
                        {error}
                    </ErrorAlert>
                </div>
                : null
            }
            <hr/>
            {removalError ?
                <div className="row margin-bottom-2x">
                    <div className="col-xs-12">
                        <DangerAlert>
                            <p>{removalError.error}</p>
                            <div className="row">
                                <div className="col-xs-12 col-md-6 margin-bottom">
                                    <p>Members</p>
                                    <ul>
                                        {removalError.members.map(member => {
                                            const {username, first_name, last_name} = member.coach_assignment_client;
                                            return (
                                                <li>
                                                    <Link
                                                        to={`/client/${username}/overview`}>
                                                        {first_name} {last_name}
                                                    </Link>
                                                </li>
                                            )})}
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-md-6 margin-bottom">
                                    <p>Organization Ids</p>
                                    <ul>
                                        {removalError.organizations.map(org => {
                                            return (
                                                <li>
                                                    {org.organization_id}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </DangerAlert>
                    </div>
                </div>
                : null
            }
            <div className="row margin-bottom-2x">
                <div className="col-xs-12">
                    <h3 className='m-t:0'>Calendly Access Token</h3>
                    <form onSubmit={postApiKey}>
                        <div className="form-group">
                            <label htmlFor="apiKey">Token</label>
                            <input
                                className='m-b:2'
                                onChange={e=>{
                                    setApiKey(e.target.value);
                                    setSavingApi(false);
                                    setFinishedSavingApi(false);
                                }}
                                value={apiKey}
                                placeholder='Enter Personal Access Token'
                                type="text"/>
                            { validation && validation.apiKey ?
                                <div>
                                    {validation.apiKey.map(err => <p className='text-danger'>{err}</p>)}
                                </div>
                                : null
                            }
                            <BasicButton className='m-r:2' onClick={postApiKey}>Save</BasicButton>
                            { savingApi ? <SaveAlert complete={finishedSavingApi}/> : null }
                        </div>
                    </form>
                </div>
            </div>
            <div className="row margin-bottom-2x">
                <div className="col-xs-6">
                    <h3 className='m-t:0'>Calendly Calendars</h3>
                </div>
                <div className="col-xs-6 d:f j-c:f-e">
                    <PrimaryButton onClick={()=>openModal()}>+ Add Calendar</PrimaryButton>
                </div>
            </div>

            <div>
                <div className="col xs-12">
                    {calendars.map(cal => (
                        <BoxContainer className={`${styles.calendarContainer} margin-bottom`}>
                            <div
                                className={styles.calendar}
                                onClick={()=>openModal({
                                    id: cal.id,
                                    title: cal.title,
                                    url: cal.url,
                                    onboarding: cal.primary,
                                    duration: cal.duration,
                                })}>
                                <h4 className={styles.title}>{cal.title} {cal.primary ? '(onboarding)' : null}</h4>
                                <p className={styles.url}>{cal.url}</p>
                                <p className={styles.editedBy}>Last Edited: {format(new Date(cal.updated_at), 'hh:mma - MM/dd/yy')} by {cal.creator.first_name} {cal.creator.last_name}</p>
                            </div>
                            <button onClick={() => setPreviewModal({show: true, url: cal.url})} className={`${styles.button} ${styles.preview}`}>Preview</button>
                            <button onClick={() => openDeleteModal(cal)} className={`${styles.button} ${styles.remove}`}>Remove</button>
                        </BoxContainer>
                    ))}
                </div>
            </div>
            <Modal show={addModal} onHide={hideModal}>
                <Modal.Header>
                    <Modal.Title>{editCalendar.id ? 'Edit Calendar' : 'Add Calendar'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={postCalendar}>
                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                                value={editCalendar.title}
                                onChange={e => setEditCalendar({...editCalendar, title: e.target.value})}
                                type="text"
                                id="title"
                                className="form-control"/>
                            { validation && validation.title ?
                                <div>
                                    {validation.title.map(err => <p className='text-danger'>{err}</p>)}
                                </div>
                                : null
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="calendarUrl">Url</label>
                            <input
                                value={editCalendar.url}
                                onChange={e => setEditCalendar({...editCalendar, url: e.target.value})}
                                type="text"
                                id="calendarUrl"
                                className="form-control"/>
                            { validation && validation.url ?
                                <div>
                                    {validation.url.map(err => <p className='text-danger'>{err}</p>)}
                                </div>
                                : null
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="duration">Duration (minutes)</label>
                            <input
                                value={editCalendar.duration}
                                onChange={e => setEditCalendar({...editCalendar, duration: parseInt(e.target.value)})}
                                type="number"
                                id="duration"
                                className="form-control"/>
                            { validation && validation.duration ?
                                <div>
                                    {validation.duration.map(err => <p className='text-danger'>{err}</p>)}
                                </div>
                                : null
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="onboarding">Onboarding</label>
                            <input
                                disabled={editCalendar.onboardingDisabled}
                                className={styles.onboardingCheckbox}
                                checked={editCalendar.onboarding}
                                onChange={e => setEditCalendar({...editCalendar, onboarding: e.target.checked})}
                                type="checkbox"
                                id="onboarding"/>
                            {editCalendar.onboardingDisabled ?
                                <p className="bg-warning p:1 b-r:1">
                                    You must set another schedule to onboarding to disable this calendar as onboarding.
                                </p>
                                : null
                            }
                        </div>
                    </form>
                    { saving ? <SaveAlert complete={finishedSaving}/> : null }
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton onClick={postCalendar}>Save</PrimaryButton>
                    <DangerButton onClick={hideModal} className='m-l:2'>Cancel</DangerButton>
                </Modal.Footer>
            </Modal>
            <Modal show={deleteModal} onHide={hideDeleteModal}>
                <Modal.Header>
                    <Modal.Title><p className="text-danger">Delete Calendar</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="text-danger">Confirm calendar delete.</h3>
                    <p>{deleteCal ? deleteCal.title : null}</p>
                </Modal.Body>
                <Modal.Footer>
                    <DangerButton onClick={()=>remove(deleteCal.id)}>Delete</DangerButton>
                    <BasicButton onClick={hideDeleteModal} className='m-l:2'>Cancel</BasicButton>
                </Modal.Footer>
            </Modal>
            <Modal show={previewModal.show} onHide={hidePreviewModal}>
                <Modal.Body>
                    <div style={{height: '500px'}}>
                        <Calendly url={previewModal.url}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BasicButton onClick={hidePreviewModal}>Close</BasicButton>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default CalendlySettings;

const Calendly = ({ url }) => {
    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute(
            'src',
            'https://assets.calendly.com/assets/external/widget.js'
        );
        head.appendChild(script);
    }, []);

    return (
        <div
            className="calendly-inline-widget"
            data-url={url}
            style={{ minWidth: '320px', height: '100%' }}
        />
    );
};