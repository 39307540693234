import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Draggable } from "@fullcalendar/interaction";
import { Modal } from "react-bootstrap";
import parseISO from "date-fns/parseISO";
import { format } from "date-fns";

// exported functions
import { formatArray } from "./contentScheduleFunctions";

// styles
import styles from "./classofDayContentSchedular.module.css";

// actions
import {
  fetchClassCategoriesDropdown,
  searchAllContent,
  fetchClassofTheDayClasses,
  fetchEditClassoftheDay,
  fetchDeleteClassoftheDay,
  fetchPostClassofTheDayToCalander,
} from "../../store/actions/contentActions";

// components
import Display from "./display/display";
import ScheduleEdit from "./scheduleEdit/scheduleEdit";
import ScheduleCalendar from "./calendar/scheduleCalendar";
import { ErrorAlert } from "../alerts/alerts";

const ClassofDayContentSchedular = () => {
  const [contentData, setContentData] = useState([]);
  const [search, setSearch] = useState({ page: 1 });
  const [pages, setPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [edit, setEdit] = useState({ show: false });
  const [modalError, setModalError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [calendarViewStart, setCalendarViewStart] = useState(null);
  const [calendarViewEnd, setCalendarViewEnd] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState([]);

  const [startHour, setStartHour] = useState(12);
  const [startPeriod, setStartPeriod] = useState("AM");

  const [endHour, setEndHour] = useState(12);
  const [endPeriod, setEndPeriod] = useState("AM");

  useEffect(() => {
    fetchCategoryFilterData();
    submit();
  }, [search.page]);

  useEffect(() => {
    if (!calendarViewStart || !calendarViewEnd) return;
    loadEvents(parseISO(calendarViewStart), parseISO(calendarViewEnd));
  }, [calendarViewStart, calendarViewEnd]);

  async function loadEventsTrigger(info) {
    const { startStr, endStr } = info;
    setCalendarViewStart(startStr);
    setCalendarViewEnd(endStr);
  }

  useEffect(() => {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".event",
      eventData: function(eventEl) {
        const contentId = eventEl.getAttribute("data-id");
        const title = eventEl.getAttribute("data-title");
        const thumbnail = eventEl.getAttribute("data-thumbnail");
        const category = eventEl.getAttribute("data-category");
        const subcategory = eventEl.getAttribute("data-subcategory");
        const length = eventEl.getAttribute("data-length");
        const format = eventEl.getAttribute("data-format");
        return {
          allDay: false,
          contentId,
          title,
          thumbnail,
          category,
          subcategory,
          length,
          format,
        };
      },
    });
  }, []);

  async function loadEvents(start, end) {
    try {
      const startDate = new Date(start);

      const startFormattedDate = startDate.toISOString();

      const endDate = new Date(end);

      const endFormattedDate = endDate.toISOString();

      setGeneralError("");
      const events = await fetchClassofTheDayClasses(
        startFormattedDate,
        endFormattedDate
      );

      setCalendarEvents(
        events.map((event) => {
          let category = event.category_name;
          let subcategory = "";
          if (event.parent_category_name) {
            category = event.parent_category_name;
            subcategory = event.category_name;
          }
          let wotdId = event.wotd_id;

          return {
            id: event.content_id,
            start: event.start_time,
            end: event.end_time,
            allDay: true,
            title: event.content_title,
            extendedProps: {
              eventId: event.class_event_id || wotdId,
              contentId: event.content_id,
              title: event.content_title,
              thumbnail: event.content_thumbnail,
              category,
              subcategory,
              length: event.content_length,
            },
          };
        })
      );
    } catch (e) {
      setGeneralError("Unable to load events. Please try refreshing the page");
      console.log(e);
    }
  }

  async function fetchCategoryFilterData() {
    try {
      fetchClassCategoriesDropdown().then((data) => setCategories(data));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const formattedData = useMemo(() => formatArray(categories), [categories]);

  async function submit() {
    const params = { status: "draft" };
    params.status = "published";
    params.type = "fitness";
    if (search.title) params.title = search.title;
    if (search.category) params.category = search.category.value;
    try {
      const res = await searchAllContent(search.page, params);
      setContentData(res.content);
      setPages(res.pages);
    } catch (e) {
      console.log(e);
    }
  }

  function changePage({ selected }) {
    setSearch({ ...search, page: selected + 1 });
  }

  function changeCategory(val) {
    setSearch({ ...search, category: val });
  }

  function handleTitleChange(val) {
    setSearch({ ...search, title: val });
  }
  function submitForm() {
    if (search.page === 1) {
      submit();
    } else {
      setSearch({ ...search, page: 1 });
    }
  }

  function closeEdit(keepEvent) {
    if (!keepEvent && !edit.eventId && edit.event) edit.event.remove();
    setEdit({ show: false });
    setModalError("");
  }

  function convertToISOWithSelectedTimeAndPeriod(
    dateString,
    selectedTime,
    selectedPeriod
  ) {
    const date = new Date(dateString);

    const selectedHours = parseInt(selectedTime);

    let adjustedHours = selectedHours;
    if (selectedPeriod === "PM" && selectedHours !== 12) {
      adjustedHours += 12;
    } else if (selectedPeriod === "AM" && selectedHours === 12) {
      adjustedHours = 0;
    }

    date.setHours(date.getHours() + adjustedHours);

    const isoString = date.toISOString();

    return isoString;
  }

  async function submitNewSchedule(id, start, end) {
    try {
      const isoStartTimeandDate = convertToISOWithSelectedTimeAndPeriod(
        start,
        startHour,
        startPeriod
      );

      const isoEndTimeandDate = convertToISOWithSelectedTimeAndPeriod(
        end,
        endHour,
        endPeriod
      );

      const res = await fetchPostClassofTheDayToCalander(
        id,
        isoStartTimeandDate,
        isoEndTimeandDate
      );

      edit.event.setStart(res.start_date);
      edit.event.setEnd(res.end_date);
      edit.event.setExtendedProp("eventId", res.id);
      closeEdit(true);
    } catch (e) {
      setModalError(e.response.data.error);
      console.log(e.response);
    }
  }
  async function updateSchedule(eventId, contentId, start, end) {
    try {
      const isoStartTimeandDate = convertToISOWithSelectedTimeAndPeriod(
        start,
        startHour,
        startPeriod
      );

      const isoEndTimeandDate = convertToISOWithSelectedTimeAndPeriod(
        end,
        endHour,
        endPeriod
      );

      setGeneralError("");
      setModalError("");
      await fetchEditClassoftheDay(
        eventId,
        contentId,
        isoStartTimeandDate,
        isoEndTimeandDate
      );
      if (edit.event) {
        edit.event.setStart(start);
        edit.event.setEnd(end);
      }
      if (edit.show) {
        closeEdit(true);
      }
    } catch (e) {
      setModalError(e.response.data.error);
      console.log(e);
    }
  }

  async function removeScheduledEvent(eventId) {
    try {
      setGeneralError("");
      await fetchDeleteClassoftheDay(eventId);
      edit.event.remove();
      closeEdit();
    } catch (e) {
      setGeneralError(e.response.data.error);
      console.log(e);
    }
  }

  function openEdit(val) {
    setEdit({ ...edit, ...val, show: true });
  }

  // prop to render which view in calander
  const showDailyView = true;

  return (
    <div>
      <div className="page-header-container searchForm-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item underline ">
                    <Link to="/dashboard" className="text-light">
                      Dashboard
                    </Link>
                  </li>
                  <span className="arrow" />
                  <li className="breadcrumb-item underline ">
                    <Link to="/dashboard/Content" className="text-light">
                      Content
                    </Link>
                  </li>
                  <span className="arrow" />
                  <li
                    className="breadcrumb-item text-light active"
                    aria-current="page"
                  >
                    Class of the Day
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-12 col-md-6">
              <h1>Class of the Day</h1>
            </div>
          </div>
        </div>
      </div>

      <div className={`container ${styles.container}`}>
        <div id="external-events" className={styles.display}>
          <Display
            submit={submitForm}
            title={search.title}
            setTitle={handleTitleChange}
            categories={formattedData}
            setCategory={changeCategory}
            category={search.category}
            content={contentData}
            pages={pages}
            changePage={changePage}
          />
        </div>
        <div className={styles.schedule}>
          {generalError ? (
            <div className="margin-bottom-15">
              <ErrorAlert>{generalError}</ErrorAlert>
            </div>
          ) : null}
          <ScheduleCalendar
            showDailyView={showDailyView}
            save={updateSchedule}
            openModal={openEdit}
            onChange={loadEventsTrigger}
            calendarEvents={calendarEvents}
          />
        </div>
      </div>

      <Modal show={edit.show} onHide={closeEdit}>
        {edit.show ? (
          <ScheduleEdit
            calendarEvents={calendarEvents}
            classOftheDay={true}
            content={edit}
            update={updateSchedule}
            remove={removeScheduledEvent}
            save={submitNewSchedule}
            error={modalError}
            setStartHour={setStartHour}
            setEndHour={setEndHour}
            startHour={startHour}
            setStartPeriod={setStartPeriod}
            startPeriod={startPeriod}
            endHour={endHour}
            setEndPeriod={setEndPeriod}
            endPeriod={endPeriod}
          />
        ) : null}
      </Modal>
    </div>
  );
};

export default ClassofDayContentSchedular;
