import React, { useState } from "react";

// hooks
import useDidMountEffect from "../../../hooks/useDidMountEffect";

// styles
import styles from "./questions.module.css";

// components
import { PrimaryButton } from "../../buttons/buttons";

import {
  makePathway,
  postAssessmentProfile,
} from "../../../store/actions/pathwaysActions";
import { withRouter } from "react-router-dom";
import Loading from "../../Loading/Loading";

const Questions = (props) => {
  // props
  const { questions, answerQuestion, next, assessment, submit, user } = props;
  // local
  const [answered, setAnswered] = useState(
    questions.filter((q) => q.answer).map((q) => q.question_id)
  );

  const [isLoading, setIsLoading] = useState(false);

  // check if all questions have been answered
  useDidMountEffect(() => {

    if (submit == true && isLoading && questions[2].question_id === 19) {
      let pathwayId = null;
      if (assessment !== undefined) {
        pathwayId = assessment.pathway_id;
      }

      props.history.push(
        `/member/clientDash/${user.username}/assessments/${pathwayId}`
      );
    }
    if (answered.length === questions.length && isLoading === false && questions[2].question_id != 19) next();
  }, [answered, submit, questions]);

  function answer(index, questionId, answer) {
    const found = answered.find((item) => item === questionId);
    if (!found) setAnswered([...answered, questionId]);
    answerQuestion(questionId, answer);
    scrollTo(index + 1);
  }

  function scrollTo(id) {
    const el = document.getElementById(`q${id}`);
    if (el) el.scrollIntoView({ behavior: "smooth" });
  }

  async function setProfile() {
    try {
      setIsLoading(true);
      let pathwayId = null;
      if (assessment !== undefined) {
        pathwayId = assessment.pathway_id;
      }

      const lifestyleResponse = await postAssessmentProfile(pathwayId);
      //const response = await makePathway(pathwayId);
      setIsLoading(false);
      props.history.push(
        `/member/clientDash/${user.username}/assessments/${pathwayId}`
      );
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      next();
    }
  }
  return (
    <div>
      <div className={styles.nav}>
        {questions.map((q, i) => {
          const complete = answered.find((item) => item === q.question_id);
          return (
            <span
              key={q.question_id}
              className={`${styles.navBtn} ${complete ? styles.complete : ""}`}
              onClick={() => scrollTo(i)}
            >
              {i + 1}
            </span>
          );
        })}
      </div>
      {questions.map((q, i) => {
        const long = q.answers.find((a) => a.label.length > 10);
        const last = i === questions.length - 1;
        
        return (
          <div
            id={"q" + i}
            key={q.question_id}
            className={`${styles.question} ${i % 2 !== 0 ? styles.grey : ""} ${
              i === 0 ? styles.first : ""
            } ${last ? styles.last : ""}`}
          >
            <p className={"m:0 margin-bottom-25"}>{q.label}</p>
            {q.image ? (
              <div className={styles.imageContainer}>
                <img className={styles.image} src={q.image} alt="" />
              </div>
            ) : null}
            <div className={styles.answerContainer}>
              {q.answers.map((a, j) => {
                const complete = q.answer && q.answer.answer_id === a.answer_id;
                return (
                  <div
                    className={`${styles.answer} ${long ? styles.full : ""}`}
                    data-cy={j === 0 ? "answer-options-1" : ""}
                  >
                    <div
                      className={`${styles.answerBtn} ${
                        complete ? styles.complete : ""
                      }`}
                      onClick={() => answer(i, q.question_id, a)}
                    >
                      <span>{a.label}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}

      <div className={styles.nav}>
        {questions.map((q, i) => {
          const complete = answered.find((item) => item === q.question_id);
          return (
            <span
              key={q.question_id}
              className={`${styles.navBtn} ${complete ? styles.complete : ""}`}
              onClick={() => scrollTo(i)}
            >
              {i + 1}
            </span>
          );
        })}
      </div>
      {answered.length === questions.length && (
        <div className="t-a:c margin-top-25">
          {isLoading ? (
            <Loading /> 
          ) : (
            <PrimaryButton onClick={submit ? setProfile : next}>
              {submit ? "Submit" : "Next"}
            </PrimaryButton>
          )}
        </div>
)}
    </div>
  );
};

export default withRouter(Questions);
