//single tile detail view
import React from 'react';
import axios from 'axios';
import './singleTile.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import {withRouter} from 'react-router-dom';
import '../../../../node_modules/font-awesome/css/font-awesome.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// is this component used anywhere?
class SingleTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            points:[
            ],
            user:'',
            tileId:props.match.params.id,
            tile:'',
            plans:''

        };
        this.changeGoal = this.changeGoal.bind(this);
    }

    componentDidMount(){

        axios.get('/tiles/single/'+this.state.tileId)
            .then(res=>{
                this.setState({tile:res.data.tile});
                this.setState({plans:res.data.plan});
            })
            .catch(err=>console.log(err));

        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({user:res.data.results});
            })
    }

    getPlanDescriptions(){
        if(this.state.plans){
            return(
                this.state.plans.map((plan=>{
                    return plan.description;
                }))
            )
        }
    }

    changeTileStatus(status){
        axios.put('/tiles/modify-status', {
            tileId:this.state.tile.id,
            updatedStatus:status
        })
    }

    changeGoal() {
      console.log('change goal');
      axios.put(`/tiles/modify/goal`,{
      }
        )
        .then(res => {
        })      
    }

  // planId : (the id of the plan you wish to affect),
  // scaleStart : (the new value the scale should start at),
  // scaleEnd : (the new value the scale should end at),
  // minPoint : (the new value the scale should turn from red to yellow at),
  // maxPoint : (the new value the scale should turn from yellow to green at)


    render() {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1
        };
        return (
            <div>
                <div className="row">
                    <i className='fa fa-check statusIcons' onClick={()=>{this.changeTileStatus('completed')}}></i>
                    <i className='fa fa-times statusIcons' onClick={()=>{this.changeTileStatus('inactive')}}></i>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <h2>{this.state.tile.category}</h2>
                        <p>Plan Descriptions</p>
                    </div>
                    <div className="col-md-3">
                        <h4>XX Consecutive Days</h4>
                    </div>
                    <div className="col-md-3">
                        <h4>{this.state.user.firstName} is going great</h4>
                        <p><span>Last 7 Days </span>XX% above avg</p>
                    </div>
                </div>
                <div className="row">
                    <img style={{maxWidth:'100%', height: "auto"}} src="http://via.placeholder.com/900x500" alt=""/>
                </div>
                <div className="row">

                    <h3>Recommended Resources</h3>
                    <Slider {...settings}>
                        <div>
                            <div style={{background: '#FD0100', height:'60px', width:"100%"}}></div>
                            <h5>Content Title</h5>
                            <h6>Some heading</h6>
                        </div>
                        <div>
                            <div style={{background: '#1f1f1f', height:'60px', width:"100%"}}></div>
                            <h5>Content Title</h5>
                            <h6>Some heading</h6>
                        </div>
                        <div>
                            <div style={{background: '#7901E7', height:'60px', width:"100%"}}></div>
                            <h5>Content Title</h5>
                            <h6>Some heading</h6>
                        </div>
                        <div>
                            <div style={{background: '#333333', height:'60px', width:"100%"}}></div>
                            <h5>Content Title</h5>
                            <h6>Some heading</h6>
                        </div>
                        <div>
                            <div style={{background: '#66CBFF', height:'60px', width:"100%"}}></div>
                            <h5>Content Title</h5>
                            <h6>Some heading</h6>
                        </div>
                        <div>
                            <div style={{background: '#333333', height:'60px', width:"100%"}}></div>
                            <h5>Content Title</h5>
                            <h6>Some heading</h6>
                        </div>
                    </Slider>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <h4>Goal Bar</h4>
                        <p>Review or change Tile goal bar</p>
                        <button>Save Changes</button>
                        <div>
                            <h5>Current Goal</h5>
                            <div>
                                draggable goal bar
                            </div>
                            <form>
                                <input type="checkbox"/>
                                    <label>Disable goal bar for this tile</label>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h4>Tile Coaching Notes</h4>
                        <p>{this.getPlanDescriptions()}</p>
                        {/* this button doesn't have onlick */}
                        {/* <a href="#">View All notes for this Member</a> */}
                        <p>View All notes for this Member</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SingleTile);


