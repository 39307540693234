import React from 'react';
import { Alert } from 'react-bootstrap';
import {ImageUpload} from "./imageUpload/imageUploadContainer";
import axios from 'axios';

let data = new FormData();

class SignUp extends React.Component{
    constructor(props){
        super(props);
        this.state ={
          show: false,
          alertStyle: true,
          alert: '',
          imgFile:null,
          firstName:'',
          lastName:'',
          email:'',
          profile_image:'',
          confirmed:false,
          disabledBtn:false,
        }
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.setProfileImage = this.setProfileImage.bind(this);
    }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  componentDidMount(){
    this.setState({
      firstName:this.props.firstName,
      lastName:this.props.lastName,
      email:this.props.email,
      imageURI:this.props.profile_picture
    })
  }

    setProfileImage(file){
      this.setState({imgFile:file})
      data = new FormData();
      data.append('image', file);
    };

    errorHandler = (error)=>{
        this.setState({error:error});
        this.setState({ show: true });
        this.setState({alert:error});
        this.setState({ alertStyle: false });
    };

    clickImg = (confirmation) => {
        if(!confirmation){
          this.setState({imgUploaded:false})
        }
        if(confirmation){
          this.setState({imgUploaded:true})
        }
    }
  
  readURI(e){
    if(e.target.files && e.target.files[0]){
      let reader = new FileReader();
      reader.onload = function(ev){
        this.setState({imageURI:ev.target.result});
      }.bind(this);
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  
  handleChange(e){
    this.readURI(e); 
    if (this.props.onChange !== undefined)
      this.props.onChange(e); 
  }

  removeImage(e){
    this.setState({imageURI:null})
    this.setProfileImage(this.state.imageURI); 
  }

    signUp(e){
      e.preventDefault()
      data = new FormData();
      this.setState({show:false, disabledBtn:true})
      data.append('token', this.props.token);
      data.append('email', this.state.email);
      data.append('image', this.state.imgFile);
      data.append('first_name', this.state.firstName);
      data.append('last_name', this.state.lastName);
      axios.post(`/teammates/confirm`, data)
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.setState({show:true, alertStyle:true, alert:'You are subscribed.', confirmed:true})
            }})
        .catch((error)=>{
          this.setState({show:true, alertStyle:false, alert:error.response.data.err})
        });
      data = new FormData();
    }

    render(){
        let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
        return (
        
        !this.state.confirmed
          ? <div className="container text-center">

                <div className="row">
                  <div className="teammate-logo-container margin-top">
                    <img src={'https://treo.s3.us-east-2.amazonaws.com/assets/logo.png'} alt='Treo Logo' />
                  </div>
                </div>

                <div className="row">
                    {this.props.invitee ? <h2>You've been invited to {this.props.invitee.first_name}'s team!</h2> : null }
                  <p>You will receive an email once a week to see how they are doing and easily show your support with the click of a button. To join their team, please click the "join team" button below. Thanks for your support!</p>
                </div>
                <form onSubmit={(e)=>this.signUp(e)}>
                    <div className="row margin-top-lrg">
                        <input disabled={this.state.disabledBtn} className="btn teammate-sign-up-btn" type="submit" value="Join the Team"/>
                    </div>
                  <div className="row margin-top-lrg confirm-teammate-form-container">
                    <div className="col-sm-6 col-xs-12">
                        <div className="profile-img-container" onChange={this.handleChange.bind(this)}>
                            <ImageUpload
                             image={this.state.imageURI} 
                             setProfileImage={this.setProfileImage}
                             errorHandler={this.errorHandler}
                             clickImg={this.clickImg}
                             />
                        </div>
                          <div>
                            <p onClick={this.removeImage.bind(this)}>x Remove Image</p>
                          </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 text-left">
                      <label htmlFor="firstname">First Name</label>
                      <input 
                      id="firstname"
                      type="text" 
                      value={this.state.firstName}
                      onChange={(event)=>this.setState({firstName:event.target.value})} 
                      required
                      />
                      <label htmlFor="name">Last Name</label>
                      <input 
                      id="last name"
                      type="text" 
                      value={this.state.lastName}
                      onChange={(event)=>this.setState({lastName:event.target.value})} 
                      required
                      />
                      <label htmlFor="email">Email</label>
                      <input 
                      id="email"
                      type="email" 
                      value={this.state.email}
                      onChange={(event)=>this.setState({email:event.target.value})} 
                      required
                      />
                    </div>
                  </div>
                </form>
                {this.state.show
                    ?  <Alert className="save-profile-alert confirm-teammate-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                          <p>{this.state.alert}</p>
                        </Alert>
                    : null
                }

            </div>
          : <div className="container text-center">

                <div className="row">
                  <div className="success-icon">
                      <svg class="checkmark teammates-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                  </div>
                  <div className="teammate-logo-container margin-top">
                    <img src={'https://treo.s3.us-east-2.amazonaws.com/assets/logo.png'} alt='Treo Logo' />
                  </div>
                </div>

                <div className="row">
                  <h2>You have been subscribed.</h2>
                </div>

                {this.state.show
                    ?  <Alert className="save-profile-alert confirm-teammate-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                          <p>{this.state.alert}</p>
                        </Alert>
                    : null
                }
            </div>
        
        )
    }
}

export default SignUp;