import {
    LIFETIME_SCORE,
    DAILY_SCORE,
    SCORE_CHANGED,
    WELLNESS_SCORE_HISTORY,
    LIFETIME_SCORE_HISTORY, MONTHLY_SCORE,
} from "../storeConstants";

// alters lifetimeScore
export const lifeTimeScoreReducer = (state=null, action) => {
    switch(action.type) {
        case LIFETIME_SCORE:
            return action.payload;
        default:
            return state;
    }
}

// alters dailyScore
export const dailyScoreReducer = (state=0, action) => {
    switch(action.type) {
        case DAILY_SCORE:
            return action.payload;
        default:
            return state;
    }
}

// alters scoreChanged state
export const scoreChangedReducer = (state=0, action) => {
    switch(action.type) {
        case SCORE_CHANGED:
            return action.payload;
        default:
            return state;
    }
}

export const scoreHistoryReducer = (state=[], action) => {
    switch(action.type) {
        case WELLNESS_SCORE_HISTORY:
            return action.payload;
        case LIFETIME_SCORE_HISTORY:
            return action.payload;
        default:
            return state;
    }
}

export const monthScoreReducer = (state=0, action) => {
    switch(action.type) {
        case MONTHLY_SCORE:
            return action.payload;
        default:
            return state;
    }
}