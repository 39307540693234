import {COACH, SET_ALERT_OPTIONS} from '../storeConstants';

// singleCoach
export const coachReducer = (state=null, action) => {
    switch(action.type) {
        case COACH:
            return action.payload;
        default:
            return state;
    }
}
// alertOptions
const initialAlertOptions = {
    daily_goal: 0,
    points_alert: false,
    points_sound: false,
};
export function alertOptionsReducer(state=initialAlertOptions, action){
    switch (action.type) {
        case SET_ALERT_OPTIONS:
            return action.payload;
        default:
            return state;
    }
}
