import {FETCH_SELF} from "../actions/action_user";

export default function(state=null, action){
    switch (action.type){
        case FETCH_SELF:
            if (action.payload && action.payload.data) {
                return action.payload.data.user;
            }
            return state;
        // no default
    }
    return state;
}