import React from 'react';
import './dashBoxes.css';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {setUsernameSearch} from "../../../actions/action_user";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

class DashBoxes extends React.Component {
    constructor(props){
        super(props);
        this.state={

        };
        this.forwardToAtRiskClients=this.forwardToAtRiskClients.bind(this);
    }

    forwardToAtRiskClients(){
            this.props.setUsernameSearch(this.props.atRiskUsernames);
            this.props.history.push(`/dashboard/clientSearch`);
    }

	render() {
		return(
            <div className=" margin-top-lrg">
                <div className="row">
                    <div className="col-xs-12 col-sm-4"> 
                        <div className="widget-container text-center">
                            <h2 className="dashBox-text">{this.props.totalAtRisk}</h2>
                            <h4 className="dashBox-text">At Risk Members</h4>
                            <p className="dashBox-text">{this.props.expectingContact} Expect Contact Today</p>
                            <p className="dashBox-text">{this.props.missedContact} Missed Expected Contact</p>
                            <div>
                                 <button onClick={this.forwardToAtRiskClients} class="btn primary">View At Risk Members</button>     
                            </div>   
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="widget-container text-center">
                            <h2 className="dashBox-text">{this.props.unread}</h2>
                            <h4 className="dashBox-text">Unread Messages</h4>
                            <p className="dashBox-text">Average Response Time: ###</p>
                            <p className="dashBox-text">Median Response Time: ###</p>
                            <Link to={`/dashboard/Inbox`}>
                                 <button class="btn primary">View Messages</button>     
                            </Link>   
                        </div>
                  </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="widget-container text-center">
                            <h2 className="dashBox-text">{this.props.totalMembers > 0 && this.props.totalMembers < 1 ? 1 : Math.round(this.props.totalMembers) }</h2>
                            <h4 className="dashBox-text">Total Monthly Members</h4>
                            <p className="dashBox-text">Average Days Logged In Streak: {Math.round(this.props.averageStreak)}</p>
                            <p className="dashBox-text">Median Days Logged In Streak: {Math.round(this.props.medianStreak)}</p>
                            <div className="button-container">
                                <Link to={`/dashboard/ClientSearch`}>
                                     <button class="btn primary">View All Members</button>     
                                </Link>            
                            </div>                       
                        </div>
                    </div>
                </div>
            </div>
			);
	}
};
const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({setUsernameSearch}, dispatch);
};

export default withRouter(connect(null, mapDispatchToProps)(DashBoxes));


