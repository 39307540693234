import React from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

const YearSelect = ({time, timerange, changeTime, disabled}) =>{
    return(
        <div className='graph-controls graph-controls-admin'>
            <span onClick={()=>disabled ? null : changeTime(moment(time).subtract(1, timerange), timerange)}>Previous {timerange}</span>
            {moment(time).isBefore(moment(), timerange) ? <span onClick={()=>disabled ? null : changeTime(moment(time).add(1, timerange), timerange)}>Next {timerange}</span> : null}
        </div>
    )
};


export default withRouter(YearSelect);
