import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import SelectSearch, {fuzzySearch} from 'react-select-search';
import 'react-select-search/style.css';

// styles
import styles from './mainInbox.module.css';

// actions
import {fetchMessages, fetchThread, markThreadRead, setArchivalStatus} from "../../../store/actions/postsActions";
import {fetchOrganizations} from "../../../store/actions/organizationActions";
import {fetchAllMembers} from "../../../store/actions/userActions";

// components
import Loading from "../../Loading/Loading";
import InboxList from "../inboxList/inboxList";
import ThreadBox from "../threadBox/threadBox";
import NewThreadBox from '../newThreadBox/newThreadBox';
import {BasicButton} from "../../buttons/buttons";
import {ErrorAlert} from "../../alerts/alerts";
import {PERMISSIONS} from "../../../serverVariables/permissions";

const FILTERS = {
    ALL: { label: 'All Messages', value: 1 },
    MEMBERS: {label: 'Individual Members', value: 2},
    ORGANIZATIONS: {label: 'Organizations', value: 3},
    RESPONSE: {label: 'No Response', value: 4},
};
const MainInbox = props => {
    // state
    const {user} = props;
    // local
    const [ready, setReady] = useState(false);
    const [error, setError] = useState('');
    const [page, setPage] = useState(0);
    const pages = useRef(0);
    const [threads, setThreads] = useState([]);
    const [singleThread, setSingleThread] = useState(null);
    const [newMessage, setNewMessage] = useState(false);
    const isLoading = useRef(false);
    const [archived, setArchived] = useState(false);
    const [filter1, setFilter1] = useState(FILTERS.ALL.value);
    const [filter2, setFilter2] = useState(null);
    const [members, setMembers] = useState([]);
    const [orgs, setOrgs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [endReached, setEndReached] = useState(false);

    // set view to ready
    useEffect(() => {
        mount();
        return () => cleanup();
        }, []);

    useEffect(() => {
        if (isLoading.current || page < 1) return;
        isLoading.current = true;
        getMessages();
    }, [page]);

    useEffect(() => {
        setPage(1);
        if (page === 1) getMessages();
    }, [archived])

    useEffect(() => {
        handleFilter1(filter1)
    }, [filter1]);

    useEffect(() => {
        if (filter2) {
            setPage(1);
            if (page === 1) getMessages();
        }
    }, [filter2]);

    async function mount() {
        setPage(prevState => prevState +1);
        window.addEventListener('scroll', detectScroll, {passive: true})
        setReady(true);
    }

    async function cleanup() {
        window.removeEventListener('scroll', detectScroll);
    }

    async function getMessages() {
        setLoading(true);
        try{
            const options = {archived};
            switch(filter1) {
                case FILTERS.MEMBERS.value:
                    options.username = filter2;
                    break;
                case FILTERS.ORGANIZATIONS.value:
                    options.organizationId = filter2;
                    break;
                case FILTERS.RESPONSE.value:
                    options.needResponse = true;
                    break;
                    
                default:
                    console.log('no filter selected')
            }
            const messages = await fetchMessages(page, options);
            if (page === 1) {
                setThreads(messages.threads);
            } else { setThreads([...threads, ...messages.threads]); }
            pages.current = parseInt(messages.pages);
            setLoading(false);
            if (page === pages.current){
                setEndReached(true);
            } else { setEndReached(false)}
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error)
            } else {
                setError('Unable to get messages. If this error persists, please contact your systems administrator.')
            }
        }
        finally {
            isLoading.current = false;
        }
    }

    async function selectThread(threadId, isNew=false) {
        setNewMessage(false);
        try {
            fetchThread(threadId)
                .then(result => {
                    markThreadRead(threadId, user.username);
                    let updates = threads.map(thread => {
                        if (thread.id === threadId) {
                            return {...thread, unread: "0"}
                        } else {
                            return thread;
                        }
                    });
                    if (isNew) updates = [{...result, unread: "0"}, ...threads];
                    setThreads(updates);
                    setSingleThread(result);
                })
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error)
            } else {
                setError('Unable to get thread. If this error persists, please contact your systems administrator.')
            }
            setSingleThread(null);
        }
    }

    function closeThreadPane() {
        setSingleThread(null);
    }

    function detectScroll() {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = document.documentElement;

        if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading.current) {
            setPage(prevState => {
                if (prevState !== pages.current){
                    return prevState + 1
                }
                return prevState;
            });
        }
    }

    function toggleArchived() {
        setArchived(!archived);
    }

    function setArchivedThread(threadId, archive) {
        setArchivalStatus(archive, threadId, user.username)
            .then(() => {
                setSingleThread(null);
                setThreads(threads.filter(thread => {
                    if (thread.id === threadId) {
                        return false
                    } else {
                        return thread;
                    }
                }))
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setError(e.response.data.error)
                } else {
                    setError('Unable to archive message. If this error persists, please contact your systems administrator.')
                }
            })
    }

    async function handleFilter1(val) {
        setFilter2(null);
        switch(val) {
            case FILTERS.ALL.value:
                resetFilter2();
                getThreads();
                break;
            case FILTERS.MEMBERS.value:
                setOrgs([]);
                const memberList = await fetchAllMembers();
                setMembers(memberList.map(member => ({
                    name: `${member.first_name} ${member.last_name}`,
                    value: member.username
                })));
                break;
            case FILTERS.ORGANIZATIONS.value:
                setMembers([]);
                let userId;
                if (user.permission_id === PERMISSIONS.COACH) userId = user.id;
                const organizations = await fetchOrganizations(userId);
                setOrgs(organizations.map(org => ({
                    name: org.name,
                    value: org.id,
                })));
                break;
            case FILTERS.RESPONSE.value:
                resetFilter2();
                getThreads();
                break;
            // no default
        }

        function getThreads() {
            if (page === 1) getMessages();
            else { setPage(1) }
        }

        function resetFilter2() {
            setOrgs([]);
            setMembers([]);
            setFilter2(null);
        }
    }

    if (!ready) return (
        <div className="m-t:2 d:f j-c:c">
            <Loading/>
        </div>
    );
    return (
        <div className="container margin-top">
            <div className="row">
                <div className="col-sm-12">
                    <nav>
                        <ol className="breadcrumb ">
                            <li className="breadcrumb-item underline "><a href="/dashboard"
                                                                          className="text-light">Dashboard</a></li>
                            <span className="arrow"></span>
                            <li className="breadcrumb-item text-light active">Messaging</li>
                        </ol>
                    </nav>
                </div>
                <div className="col-xs-6">
                    <h2 className='m:0'>Messaging</h2>
                </div>
                <div className="col-xs-6 text-right">
                    <button className="btn primary" onClick={(e) => setNewMessage(true)}>NEW
                        MESSAGE
                    </button>
                </div>
            </div>
            <div className="row">
                <div className={`col-xs-12 ${styles.filterContainer}`}>
                    <form className='d:f'>
                        <div className="form-group m-r:1">
                            <label htmlFor="filter1">Filter Messages</label>
                            <div className='searchable'>
                                <SelectSearch
                                    options={Object.values(FILTERS).map(item =>({name: item.label, value: item.value}))}
                                    id='filter1'
                                    onChange={val => setFilter1(parseInt(val))}
                                    value={filter1}/>
                            </div>
                        </div>
                        {/* org dropdown*/}
                        { orgs.length ?
                            <div className="form-group">
                                <label htmlFor="filter2">Organizations</label>
                                <div className="searchable">
                                    <SelectSearch
                                        filterOptions={fuzzySearch}
                                        search
                                        onChange={val => setFilter2(val)}
                                        options={orgs}
                                        value={filter2}
                                        name="filter2"
                                        placeholder="Choose an Organization" />
                                </div>
                            </div>
                            : null
                        }

                        {/* members dropdown*/}
                        { members.length ?
                            <div className="form-group">
                                <label htmlFor="filter2">Members</label>
                                <div className="searchable">
                                    <SelectSearch
                                        filterOptions={fuzzySearch}
                                        search
                                        onChange={val => setFilter2(val)}
                                        options={members}
                                        value={filter2}
                                        name="filter2"
                                        placeholder="Choose a Member" />
                                </div>
                            </div>
                            : null
                        }
                    </form>
                    { archived ?
                        <BasicButton onClick={toggleArchived}>View Messages</BasicButton>
                        :
                        <BasicButton onClick={toggleArchived}>View Archived</BasicButton>}
                </div>
            </div>
            <div className="row">
                {/*Error Message*/}
                <div className="col-xs-12">
                    {error ? <ErrorAlert>{error}</ErrorAlert> : null}
                </div>
            </div>
            <div className="row">
                {/*List of Threads*/}
                <div className="col-xs-12">
                    <InboxList
                        list={threads}
                        selectThread={selectThread}
                        active={singleThread}
                        archiveCallback={setArchivedThread}/>
                    {loading ?
                        <Loading/>
                        : null
                    }
                    {endReached ?
                        <p className='t-a:c text-info'>You're all caught up</p>
                        : null
                    }
                    <div className={`${styles.threadContainer} ${singleThread ? '' : styles.hide}`}>
                        {singleThread ?
                            <ThreadBox
                                thread={singleThread}
                                user={user}
                                archiveCallback={setArchivedThread}
                                close={closeThreadPane}
                                refreshThread={selectThread}/>
                            : null
                        }
                    </div>
                    <div className={`${styles.threadContainer} ${newMessage ? '' : styles.hide}`}>
                        {newMessage ?
                            <NewThreadBox
                                thread={singleThread}
                                user={user}
                                close={() => setNewMessage(false)}
                                newThreadCallback={selectThread}/>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
        )
    }

function mapStateToProps({user}) {
        return {user};
    }

export default connect(mapStateToProps)(MainInbox);