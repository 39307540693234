import React from 'react';

export const HabitTileIcon = props => {
    // props
    const {tile, size} = props;
    let icon = '';
    if (tile === 'eating occasions' || tile === 1) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_EatingOccasions_50.15x50.15.png';
        if(size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_EatingOccasions_200x200.png';
    } else if (tile === 'sweetened foods' || tile === 2) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_SweetenedFoods_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_SweetenedFoods_200x200.png';
    } else if (tile === 'sweetened drinks' || tile === 3) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_SweetenedDrinks_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_SweetenedDrinks_200x200.png';
    } else if (tile === 'protein servings' || tile === 4) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_Protein_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_Protein_200x200.png';
    }else if (tile === 'vegetables & fruit' || tile === 5) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_VeggiesFruit_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_FruitsVeggies_200x200.png';
    }else if (tile === 'mealtime spacing' || tile === 6) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_MealtimeSpacing_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_MealtimeSpacing_200x200.png';
    }else if (tile === 'sprint intensity' || tile === 7) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_SprintIntensity_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_SprintIntensity_200x200.png';
    }else if (tile === 'physical activity' || tile === 8) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_PhysicalActivity_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_PhysicalActivity_200x200.png';
    }else if (tile === 'stress releasing activity' || tile === 9) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_StressReleasing_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_StressRelease_200x200.png';
    }else if (tile === 'sleep' || tile === 10) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_Sleep_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_WhenYouSleep_200x200.png';
    }else if (tile === 'strength building activity' || tile === 11) {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_StrengthBuilding_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_StrengthBuilding_200x200.png';
    } else {
        icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/Icons_HabitTiles_SprintIntensity_50.15x50.15.png';
        if (size === 200) icon = 'https://treo.s3.us-east-2.amazonaws.com/assets/habit-tile-icons/two-hundreds/HabitTileIcons_SprintIntensity_200x200.png';
    }
    return (
        <img src={icon} alt="" style={{width: '100%', height: 'auto'}}/>
    )
};