import React from 'react';
import {withRouter,} from 'react-router-dom';
import Select, { Option,} from 'rc-select';
import 'rc-select/assets/index.css';
import {stateOptions} from './StatesData';

class StateSelect extends React.Component {
  state = {
    selectedState: '',
  };

  onChange = (selectedState, option) => {
    console.log('onChange', selectedState, option);
    this.setState({selectedState,}, () => {
      this.props.stateChangeHandler(this.state.selectedState);
    });
  }

stateList=()=>{
        if(stateOptions){
            return stateOptions.map((item, i)=>{
              let stateLabel = stateOptions[i].label;
              let stateValue = stateOptions[i].value;
// should value be statValue?              
                   return(
                      <Option value={stateLabel}>{stateLabel}</Option>

                   )
            });
        }
    };


  render() {
    return (<div>
      <div >
        <Select
          onChange={this.onChange}
          notFoundContent=""
          allowClear
          placeholder={this.props.gymState}
          value={this.state.selectedState}
          combobox
          backfill
        >
        {this.stateList()}

        </Select>
      </div>
    </div>);
  }
}

export default withRouter(StateSelect);
