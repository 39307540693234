import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {Button, Glyphicon, Modal} from "react-bootstrap";
import axios from "axios";
import { Link } from 'react-router-dom';

// actions
import { getOrganizationCoaches, resetOrganizationCoaches } from "../../../actions/action_organizations";

// styles
import styles from './organizationsCoaches.module.css';

// components
import { BasicButton } from "../../buttons/buttons";
import { ErrorAlert } from "../../alerts/alerts";
import Loading from "../../Loading/Loading";
import Placeholder from "../../../images/avatar-1577909_960_720.png";
import CoachSearch from "../../coachSearch/coachSearch";

const OrganizationCoaches = ({ organization, getCoaches, resetCoaches }) => {
    const [ coachModal, setCoachModal ] = useState(false);
    const [ loadError, setLoadError ] = useState('');

    function addCoach(coachId) {
        return axios.post(`/organizations/assign/coach/${organization.id}`, { coach_id: coachId})
            .then(() => getCoaches(organization.id))
            .then(() => setCoachModal(false))
            .then(() => setLoadError(''))
            .catch( e => setLoadError('Unable to add coach'));
    }

    function removeCoach(coachId) {
        return axios.put(`/organizations/remove/coach/${organization.id}`, { coach_id: coachId})
            .then(() => getCoaches(organization.id))
            .then(() => setLoadError(''))
            .catch( e => setLoadError(e.response.data.error));
    }

    // grab coaches on mount
    // reset coaches array on dismount
    useEffect(() => {
        getCoaches(organization.id)
            .catch(() => setLoadError('Unable to retrieve coaches'));
        return () => resetCoaches();
    },[]);

    if (!organization.coaches) {
        return (
            <Loading/>
        )
    }

    return (
        <div>
            { loadError ?
                <div>
                    <ErrorAlert>{loadError}</ErrorAlert>
                </div>
                : null
            }
            <div className="row margin-top-2x">
                <div className="col-xs-12 col-sm-8">
                    <h2 className='m:0'>Coaches</h2>
                </div>
                <div className={`col-xs-12 col-sm-4 ${styles.addButton}`}>
                    <BasicButton
                        onClick={() => setCoachModal(true)}
                        className="btn primary btn btn-default">
                        + Add New Coach
                    </BasicButton>
                </div>
            </div>
            <div className={'client-search-organizer'}>
                <div>
                    <h3>{organization.coaches.length} Coaches Found</h3>
                </div>
            </div>
            <div>
                <div className="margin-top-2x">
                    <div className="tile-header hidden-xs hidden-sm">
                        <div className="row">
                            <div className="col-md-4 text-indent-left">
                            </div>
                        </div>
                    </div>
                </div>
                <ul className='tiles-ul'>
                    { organization.coaches.map(coach => (
                        <li className='tile tile--dashboard'>
                            <div className="row client-search-list">
                                <div className="col-xs-12">
                                    <div className={styles.infoContainer}>
                                        <Link to={`/coach/${coach.username}`}>
                                            <img
                                                className={styles.profileImage}
                                                src={coach.profile_image || Placeholder}
                                                alt="coach"/>
                                        </Link>
                                        <Link to={`/coach/${coach.username}`} className={styles.info}>
                                            <h4 className={styles.name}>{coach.first_name} {coach.last_name}</h4>
                                            <p className={styles.title}>{coach.title}</p>
                                        </Link>
                                        <div>
                                        <span className={`${styles.remove} hover`} onClick={() => removeCoach(coach.coach_id)}>
                                            <Glyphicon glyph='glyphicon glyphicon-remove-circle'/> Remove
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <Modal show={coachModal} onHide={() => setCoachModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Coach Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CoachSearch onSelection={addCoach}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setCoachModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

function mapStateToProps({ organizationCoaches }) {
    return {
        coaches: organizationCoaches,
    }
}

const actions = {
    getCoaches: getOrganizationCoaches,
    resetCoaches: resetOrganizationCoaches,
}

export default connect(mapStateToProps, actions)(OrganizationCoaches);