import React from'react';
import {Route} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {Tiles} from "../tiles/tiles";
import CreateTile from "../CreateTile/CreateSteps"
import ClientSummary from "../ClientSummary/ClientSummary"
import SingleTile from "../tiles/singleTile"

const ClientDisplay = ({match, username}) =>{
    return(
        <div>
            <Route
                exact
                path={match.url+"/"}
                render={()=><ClientSummary match={match} username={username}/>}
            />
            <Route
                path={match.url+"/tiles"}
                render={()=><Tiles {...withRouter} match={match} username ={username}/>}
            />
            <Route
                path={match.url+'/TileDetail/:id'}
                    render={()=><SingleTile match={match} username={username}/>}
            />
            <Route
                path={match.url+"/create"}
                render={()=><CreateTile {...withRouter} match={match} username ={username}/>}
            />
        </div>
    )
};

export default withRouter(ClientDisplay);