import React, {useState, useRef, useEffect} from 'react'
// styles
import styles from './buttonRouteDropDown.module.css'


const ButtonRouteDropDown = props => {
    // props
    const {options, match, history, title} = props
    // state
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const handleOptionSelect = (optionValue) => {
        setIsOpen(false);
        history.push(`${match.url}${optionValue}`);
      };
      const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };

      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      // if user clicks outside of dropdown
      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
    <div className={styles.selectedOption} onClick={toggleDropdown}>
    {title ? title : 'Schedule'}
    </div>
    {isOpen && (
        <ul className={styles.optionsList}>
        {options.map((option) => (
            <li
            key={option.value}
            value={option.value}
            className={styles.option}
            onClick={() => handleOptionSelect(option.value)}
            >
            {option.label}
            </li>
        ))}
        </ul>
    )}
    </div>
  )
}

export default ButtonRouteDropDown