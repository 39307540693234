import { SINGLE_WELLNESS_SCORE} from "../actions/action_wellnessScore";

export default function(state=null, action){
    switch (action.type){
        case SINGLE_WELLNESS_SCORE:
            return action.payload.data.results ? action.payload.data.results : {score:0}
        // no default
    }
    return state;
}
