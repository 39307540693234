import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import TileListHeader from './TileListHeader';
import TileList from './TileList';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getIntroTiles} from "../../../../actions/action_tile";

class TileListContainer extends Component{
    constructor(props){
        super(props);
        this.state={
            page:1,
        }
    }

    componentDidMount(){
        this.props.getIntroTiles(this.state.page);
    }

    renderTileList=()=>{
        if(this.props.intro_tiles){
            return(
                <TileList tiles={this.props.intro_tiles} count={this.props.count}/>
            )
        }
    };

    render(){
        return(
            <div>
                <TileListHeader/>
                {this.renderTileList()}
            </div>
        )
    }
}


const  mapStateToProps=({intro_tiles, intro_tiles_count})=>{
    return{
        intro_tiles,
        count:intro_tiles_count
    }
};

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({getIntroTiles},dispatch)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TileListContainer));