import React, {useState} from 'react';
import {connect} from 'react-redux';

// styles
import styles from "./contentMemberReporting.module.css";

// components
import {STATUSES} from "../../serverVariables/statuses";
import {SecondaryInfoAlert} from "../alerts/alerts";
import SearchableDropdown from "../dropdowns/searchableDropdown/searchableDropdown";
import PathwaysReporting from "./pathwaysReporting/pathwaysReporting";
import OrganizationReporting from "./organization/organizationReporting";

const OPTIONS = {
    PATHWAYS: {name: 'Pathways', value: 'PATHWAYS'},
    ORGANIZATIONS: {name: 'Organization Content', value: 'ORGANIZATIONS'},
};

const ContentMemberReporting = props => {
    // state
    const {singleUser: {user: member}} = props;
    // local
    const [dropdownValue, setDropdownValue] = useState(OPTIONS.ORGANIZATIONS.value);
    const [sort, setSort] = useState('asc');

    // show message indicating member is still onboarding
    if (member.user_status_id < STATUSES.onboarding_coach_consult_pending) {
        return (
            <div className="container margin-top-lrg">
                <SecondaryInfoAlert>
                    <p>Report data is not available until member completes their assessment and is assigned content.</p>
                </SecondaryInfoAlert>
            </div>
        )
    }

    return (
        <div className="container margin-top-lrg">
            <div className='row'>
                <div className="col-xs-12">
                    <h2 className={styles.heading}>Reporting</h2>
                    <div className={styles.header}>
                        <SearchableDropdown
                            options={Object.values(OPTIONS).map(val => ({name: val.name, value: val.value}))}
                            onChange={val => setDropdownValue(val)}
                            value={dropdownValue}
                        />
                        { dropdownValue === OPTIONS.ORGANIZATIONS.value ?
                            <SearchableDropdown
                                options={[{name: 'Least Recent', value: 'desc'}, {name: 'Most Recent', value: 'asc'}]}
                                onChange={val => setSort(val)}
                                value={sort}
                            />
                            : null
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 margin-top-lrg">
                    {dropdownValue === OPTIONS.PATHWAYS.value ? <PathwaysReporting/> : null}
                    {dropdownValue === OPTIONS.ORGANIZATIONS.value ? <OrganizationReporting sort={sort}/> : null}
                </div>
            </div>
        </div>
    )
}

function mapStateToProps({singleUser}) {
    return {
        singleUser,
    }
}

export default connect(mapStateToProps)(ContentMemberReporting);