import React from 'react';
import {withRouter, Route} from 'react-router-dom';

// components
import Account from "./Account"
import LinkList from "../../links/linkList/linkList";
import CalendlySettings from "../../calendlySettings/calendlySettings";
import {PERMISSIONS} from "../../../serverVariables/permissions";

class AccountMain extends React.Component {
    // eslint-disable-next-line
	constructor(props) {
		super(props);
}

     accountHandler = (accountHandlerValue) => {
        this.setState({
          accountData: accountHandlerValue
        })
      };

	render() {
        let links = [{ href: `${this.props.match.url}/info`, label: 'Account' },];
        if (this.props.user.permission_id === PERMISSIONS.COACH) {
            links = [
                { href: `${this.props.match.url}/info`, label: 'Account' },
                { href: `${this.props.match.url}/calendars`, label: 'Calendars' },
            ];
        }
        return (
            <div className="container">
                <div className='row margin-top-lrg'>
                    <div className="col-xs-12 col-md-3 margin-bottom-2x">
                        <LinkList links={links}/>
                    </div>
                    <div className="col-xs-12 col-md-9">
                        <Route path={this.props.match.url + "/info"} render={ () => <Account {...this.props} />}/>
                        <Route path={this.props.match.url + "/calendars"} render={ () => <CalendlySettings {...this.props} />}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AccountMain);