import React, {useState, useEffect, useRef} from 'react';

// styles
import styles from './classOfTheWeek.module.css';

// components
import {ReactComponent as PlayIcon} from '../../../images/play.svg';
import {INTENSITIES} from "../../../serverVariables/intensities";
import {Link} from "react-router-dom";

const ClassOfTheWeek = props => {
    // props
    const {content} = props;
    // local
    const [height, setHeight] = useState(0);
    const thumbRef = useRef();

    useEffect(() => {
        getWidth();
        window.addEventListener('resize', getWidth);
        return () => window.removeEventListener('resize', getWidth);
    }, []);

    function getWidth() {
        if (thumbRef.current) {
            const width = thumbRef.current.clientWidth;
            if (width > 1200) {
                setHeight(width * 25/124);
            } else
                if (width > 600) {
                setHeight(width * 35/124);
            }
            else {
                setHeight(width * 44/65);
            }
        }
    }

    function setLevel(){
        if (content.intensity === INTENSITIES.LOW) {
            return (
                <div className={styles.intensity}>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot}`}/>
                    <span className={`${styles.dot}`}/>
                </div>
            )
        }
        if (content.intensity === INTENSITIES.MODERATE) {
            return (
                <div className={styles.intensity}>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot}`}/>
                </div>
            )
        }
        if (content.intensity === INTENSITIES.HIGH) {
            return (
                <div className={styles.intensity}>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot} ${styles.active}`}/>
                </div>
            )
        }
    }
    const thumbStyle = {
        height: `${height}px`,
    };
    const {current} = thumbRef;
    return (
        <Link to={`/member/on-demand/video/${content.id}`} className={styles.container}>
            <div
                ref={thumbRef}
                className={`${styles.thumbnail} ${current && current.clientWidth <= 600 ? styles.mobileThumbnail : ''}`}
                style={thumbStyle}>
                <div className={styles.gradient}/>
                <div className={styles.thumbnailImg} style={{...thumbStyle, backgroundImage: `url(${content.thumbnail})`}}/>
                <div className={styles.playBtn}>
                    <PlayIcon/>
                </div>
                <div className={styles.info}>
                    <p className={styles.heading}>Workout of the Week</p>
                    <p className={styles.titleCore}>{content.length} Min. {content.classType}</p>
                    <p className={styles.titleSub}>
                        <span className={styles.instructor}>{content.instructor}</span>
                        <span>Level &nbsp; {setLevel()}</span></p>
                    <p className={styles.titleSub}>
                        {content.theme ? <span className={styles.theme}>{content.theme}</span> : null}
                        Ep. {content.episode}</p>
                </div>
            </div>
        </Link>

    )
}

export default ClassOfTheWeek;