import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const WYSIWYG = ({ ...props }) => {
    const [, setEditor] = useState(null);

    function handleEditorChange(content) {
        props.onChangeHandler(content);
    }

    return (
        <Editor
            disabled={props.disabled}
            value={props.value}
            inline={false}
            init={{
                plugins : props.plugins,
                toolbar : props.toolbar
                ,
                setup: editor => setEditor(editor)
            }}
            onEditorChange={handleEditorChange}
        />
    )
};

export default WYSIWYG;