import React from 'react';
import { Route } from 'react-router-dom';

// components
import JustForYouContent from "./justForYouContent";
import JustForYouAdd from "./justForYouAdd";
import JustForYouSingle from "./justForYouSingle";

const JustForYouContentContainer = props => {
    // props
    const { match } = props;

    return (
        <div className="client-container pathways-container">
            <Route path={`${match.url}`} exact component={JustForYouContent}/>
            <Route path={`${match.url}/add`} component={JustForYouAdd}/>
            <Route path={`${match.url}/category/:category`} component={JustForYouSingle}/>
        </div>
    )
};

export default JustForYouContentContainer;