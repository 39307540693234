import React from 'react';

//styles
import styles from './progressBar.module.css';

const ProgressBar = props => {
    // props
    const {stage, createLifestyle, submit} = props;

    // if assessment is skipped only show 4 progress bars
    if(!createLifestyle && stage === 13){
    return (
        <div className={styles.containerSkipped} >
         <div className={styles.segment}></div>
         <div className={styles.segment}></div>
         <div className={styles.segment}></div>
        <div className={styles.segment}>
            <div className={`${styles.innerSegment} ${styles.filled}`}/>
        </div>
        </div>
        )
    }
    return (
        <div className={styles.container}>
            <div className={styles.segment}>
                <div className={`${styles.innerSegment} ${stage > 2 ? styles.filled : ''}`}/>
            </div>
            <div className={styles.segment}>
                <div className={`${styles.innerSegment} ${stage > 3 ? styles.halfFilled : ''}`}/>
                <div className={`${styles.innerSegment} ${stage > 4 ? styles.filled : ''}`}/>
            </div>
            <div className={styles.segment}>
                <div className={`${styles.innerSegment} ${stage > 5 ? styles.halfFilled : ''}`}/>
                <div className={`${styles.innerSegment} ${stage > 6 ? styles.filled : ''}`}/>
            </div>
            <div className={styles.segment}>
                <div className={`${styles.innerSegment} ${stage > 7 ? styles.halfFilled : ''}`}/>
                <div className={`${styles.innerSegment} ${stage > 8 ? styles.filled : ''}`}/>
            </div>
            <div className={styles.segment}>
                <div className={`${styles.innerSegment} ${stage > 9 ? styles.halfFilled : ''}`}/>
                <div className={`${styles.innerSegment} ${stage > 10 ? styles.filled : ''}`}/>
            </div>
            <div className={styles.segment}>
                <div className={`${styles.innerSegment} ${stage > 11 ? styles.halfFilled : ''}`}/>
                <div className={`${styles.innerSegment} ${stage > 12 ? styles.filled : ''}`}/>
            </div>
        {/* if assessment is taken later from more menu then remove demo progress bar */}
            {submit ? null :
            <div className={styles.segment}>
                    <div className={`${styles.innerSegment} ${stage > 13 ? styles.filled : ''}`}/>
            </div> 
        }
        </div>
    )
}

export default ProgressBar;