import React from 'react';

// styles
import styles from './reactions.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import CommunitySingleReaction from "../../communitySingleReaction/communitySingleReaction";

const Reactions = props => {
    // props
    const {reactions, loadMore, ended} = props;
    return (
        <BoxContainer className={styles.container}>
            <h4 className={styles.header}>Reactions</h4>

            <div className={styles.reactions}>
                {reactions.map(reaction => <CommunitySingleReaction reaction={reaction} link={`/client/${reaction.user.username}/overview`}/>)}
                {!ended && reactions.length ?
                    <div>
                        <button onClick={() => loadMore(reactions[reactions.length-1].reaction_id)} className={styles.more}>Load More</button>
                    </div>
                    : null
                }
            </div>
        </BoxContainer>
    )
}

export default Reactions;