import {
    LOAD_POLICY_VERSION,
    LOAD_POLICY,
    LOAD_VERSION_LIST,
    SET_POLICY_EDIT,
    COMPLIANCE_CHECK,
    CLIENT_POLICY_LIST,
} from "../actions/action_policy";

export const policyVersionReducer = (state={}, action) => {
    switch(action.type) {
        // load a single version into state
        case LOAD_POLICY_VERSION:
            return action.payload;
        default:
            return state;
        // no default
    }
};

export const policyReducer = (state={}, action) => {
    switch(action.type) {
        // load a single policy into state
        case LOAD_POLICY:
            return action.payload;
        default:
            return state;
        // no default
    }
};

export const versionListReducer = (state=[], action) => {
    switch(action.type) {
        // load a version list into state
        case LOAD_VERSION_LIST:
            return action.payload;
        default:
            return state;
        // no default
    }
};

export const setPolicyEditReducer = (state=false, action) => {
    if (action.type === SET_POLICY_EDIT) {
        return action.payload;
    }
    return state;
};

export const complianceCheckReducer = (state= true, action) => {
    if (action.type === COMPLIANCE_CHECK) {
        return action.payload;
    }
    return state;
};

export const clientPolicyListReducer = (state=null, action) => {
    switch(action.type) {
        // load a list of client policies into state
        case CLIENT_POLICY_LIST:
            return action.payload;
        default:
            return state;
        // no default
    }
}