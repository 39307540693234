import React from 'react';


import styles from "./iframes.module.css";

export const ResponsiveVimeoIframe = ({ src }) => {
    return (
        <div className={styles.videoContainer}>
            <iframe
                className={`vimeo-iframe ${styles.videoIframe}`}
                src={`https://player.vimeo.com/video/${src}`}
                width="100%"
                height="450"
                frameBorder="0"
                title='Responsive Iframe'
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen/>
        </div>
    )
};