import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";
import {Link} from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import CoachSearch from './SearchCoach';
import PhotoContainer from "./Container";
import Error from '../../ErrorMessage/Error';
import Loading from '../../Loading/Loading';


class CoachingTeam extends Component{
    constructor(){
        super();
        this.state = {
          coachId: '',
          coachFirstName: '',
          coachLastName: '',
          coachList: [],
          show: false,

            //remove coach modal
            remove_coach_modal : false,
            coach_username : null,

            //error message
            message : null,
            message_type : null,

            //loading component
            loading : false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.fetchCoaches = this.fetchCoaches.bind(this);
    }

    componentDidMount() {
        this.fetchCoaches();
    }

    fetchCoaches(){
        axios.get(`/user/coaching-team/?username=${this.props.username}`)
            .then(res => {
                this.setState({coachList: res.data.result});
                console.log(res.data);
            })
            .catch((error)=>{
                console.log(error);
            });
    }

    createList=()=>{
            if(this.state.coachList){
                return this.state.coachList.map((item, i)=>{
                  let firstName = this.state.coachList[i].first_name.charAt(0).toUpperCase()+this.state.coachList[i].first_name.substr(1).toLowerCase();
                  let lastName = this.state.coachList[i].last_name.charAt(0).toUpperCase()+this.state.coachList[i].last_name.substr(1).toLowerCase();

                       return(
                        
                          <div className="support-member">                        
                            <div className="support-img">
                              <Link to={'/coach/' + this.state.coachList[i].username}>
                                  <PhotoContainer image={item.profile_image} username={item.username}/>
                              </Link>
                            </div>
                            <div className="support-info text-center">
                              <Link to={'/coach/' + this.state.coachList[i].username}>
                                <div className="support-name">{firstName} {lastName}</div>                            
                              </Link>
                              <div className="support-role">{this.state.coachList[i].title}</div>
                                <button className='btn delete-equip-btn margin-top-2x' onClick={()=>this.removeCoachShow(this.state.coachList[i].username)}>Remove</button>
                            </div>
                          </div>                        
                  
                       )
                });
            }
        };

    handleClose() {
      this.setState({ show: false });
      axios.get(`/user/coaching-team/?username=${this.props.username}`)
        .then(res => {
          this.setState({coachList: res.data.result});
          console.log(res.data);
        })
        .catch((error)=>{
           console.log(error);
        });
    }

    showChangeHandler = (show) => {
      this.setState({show:show})
    };

    handleShow() {
      this.setState({ show: true });
    }

    removeCoachShow = (coach_username) =>{
        this.setState({remove_coach_modal : true, coach_username});
    };

    removeCoachHide=()=>{
        this.setState({remove_coach_modal : false, message : null, message_type : null});
    };

    removeCoach = () =>{
        this.setState({loading:true});
        let that = this;
        axios.put(`/user/coach-unassign/${this.props.username}`, {coach_username : this.state.coach_username})
            .then(()=>{
                that.fetchCoaches();
                that.removeCoachHide();
                this.setState({loading:false});
            })
            .catch((e)=>{
                console.log(e);
                that.setState({message : 'Unable to remove coach', type : 'danger'})
            });
    };

    render(){
      return(
       <div className="container margin-top">
            <div className="row">
                <div className="col-sm-12">
                    <h2>Coaching Team</h2>  
                    <p>Coaches have the ability to add notes, reply to messages, and change goals</p>
                </div>                                               
            </div> 
          			
            <div className="row">
                <div className="support-team-container">
                    {this.createList()}

                    <div className="support-member add-new-support" onClick={() => this.setState({ show: true })}>
                        <div className="support-img"></div>
                        <div className="support-info text-center">                         
                            Add New Coach                                                
                          <div className="support-role">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Assign Coach</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                  <CoachSearch
                    username={this.props.username}
                    handleClose={this.handleClose}
                    
                   />

              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Close</Button>
              </Modal.Footer>
            </Modal>

           <Modal show={this.state.remove_coach_modal} onHide={this.removeCoachHide}>

               <Modal.Body>
                   <h2>Remove Coach</h2>
                   <Error message={this.state.message} type={this.state.message_type}/>
                   <h3>Are you sure you want to remove this coach?</h3>
                   <button className='btn delete-equip-btn' onClick={this.removeCoach}>{this.state.loading ? <Loading width={'25'} height={'25'} type={'Puff'} color={'#ff0000'}/> : 'Confirm'}</button>
                   <button className='btn' onClick={this.removeCoachHide}>Cancel</button>
               </Modal.Body>
           </Modal>
      	</div>          
                	
      )
    }
}

export default withRouter(CoachingTeam);