import React, {useState} from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// styles
import styles from './retentionGraph.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const RetentionGraph = props => {
    // props
    const {data} = props;
    // local
    const [opacity, setOpacity] = useState({"7 Day": 1, "14 Day": 1, "30 Day": 1, active: []});

    function handleMouseClick(o) {
        const { dataKey } = o;
        const newOpacity = {"7 Day": 1, "14 Day": 1, "30 Day": 1, active: opacity.active};
        if (opacity.active && dataKey === opacity.active) {
            newOpacity.active = null;
        } else {
            Object.keys(opacity).forEach(key => {
                if (key === dataKey) {
                    newOpacity[key] = 1
                } else {
                    newOpacity[key] = .2;
                }
            });
            newOpacity.active = dataKey;
        }
        setOpacity(newOpacity);
    }

    if (!data) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <ResponsiveContainer width="100%" height="100%" debounce={1}>
                <LineChart
                    data={data}
                    margin={{
                        top: 15,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <XAxis dataKey="name" />
                    <YAxis label={{value: 'Percent', angle: -90,}}/>
                    <Tooltip />
                    <Legend onClick={handleMouseClick}/>
                    <Line type="monotone" dataKey="7 Day" stroke="#4BBC4E" strokeOpacity={opacity["7 Day"]} />
                    <Line type="monotone" dataKey="14 Day" stroke="#dbc000" strokeOpacity={opacity["14 Day"]} />
                    <Line type="monotone" dataKey="30 Day" stroke="#db2800" strokeOpacity={opacity["30 Day"]} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default RetentionGraph;