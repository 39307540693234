import { userApi } from "../../api/userApi";

import {COACH, SET_ALERT_OPTIONS} from '../storeConstants';
import {FETCH_SELF} from "../../actions/action_user";






// export async function  postAssessmentProfile(username, query) {
//     const response  = await userApi.getAssessmentProfile();
//     return response;
// }




//**************** Actions that involve the store *****************//

export function fetchCoach(username) {
    return async dispatch => {
        try {
            const { data } = await userApi().getCoach(username);
            dispatch({ type: COACH, payload: data.results });
        } catch (e) {
            return Promise.reject(e);
        }
    }
}

// update a user's profile and recall the b/e for the user
export function updateProfile(username, query) {
    return async dispatch => {
        try {
            await userApi().updateProfile(username, query);
            const userRes = await userApi().getUser();
            dispatch({ type: FETCH_SELF, payload: userRes });
        } catch (e) {
            return Promise.reject(e);
        }
    }
}

// update a user's status
export function updateStatus(username, status) {
    return async dispatch => {
        try {
            await userApi().updateStatus(username, status);
            const userRes = await userApi().getUser();
            dispatch({ type: FETCH_SELF, payload: userRes });
        } catch (e) {
            return Promise.reject(e);
        }
    }
}

// get the alert options a member has chosen
export function fetchAlertOptions(userId) {
    return async dispatch => {
        const options = await userApi().getAlertOptions(userId);
        dispatch({type: SET_ALERT_OPTIONS, payload: options.data});
        return options.data;
    }
}

//**************** Actions that don't involve the store *****************//

// get list of coaches assigned to a user
export async function fetchUserCoaches() {
    try {
        const { data } = await userApi().getCoaches();
        return data.result;
    } catch (e) {
        console.log(e);
        return [];
    }
}

// get list of clients for coach or admin
export async function fetchClients(page=1, query) {
    try {
        const { data } = await userApi().getClients(page, query);
        return data.results;
    } catch (e) {
        console.log(e);
        return {};
    }
}

// fetch a users healthgoal provided a user_id
export async function fetchHealthGoal(userId) {
    try {
        const { data } = await userApi().getHealthGoal(userId);
        return data.result;
    } catch (e) {
        console.log(e);
        return '';
    }
}

// save whether a member is a demo account to the server
export async function updateDemoMember(userId, val) {
    const {data:{result}} = await userApi().setDemoUser(userId, val);
    return result;
}

// fetch a list of members. either all members assigned to a coach or all members for admins
export async function fetchAllMembers() {
    const {data} = await userApi().getFullMemberList();
    return data;
}

// save a coach nudge to the server
export async function updateCoachNudge() {
    const {data} = await userApi().coachNudge(1);
    return data;
}

// a collection of methods for updating a tour
export async function tourUpdate(id){
    const {data} = await userApi().updateTour(id);
    return data;
}

// fetch the email preferences of a member based on org
export async function fetchEmailList(userId) {
    const {data}= await userApi().getEmailList(userId);
    return data;
}

// update the email preferences of a member based on org
export async function setEmailList(listId, optIn) {
    const {data: {result}} = await userApi().updateEmailList(listId, optIn);
    return result;
}

// get a complete listing of active coaches
export async function fetchFullCoachList() {
    const {data: {result}} = await userApi().getCoachList();
    return result;
}

// update a members daily goal
export async function setDailyGoal(goal, userId) {
    const {data} = await userApi().updateDailyGoal(goal, userId);
    return data;
}

// update a members alert notification settings
export async function setAlertNotification(alert, userId) {
    const {data} = await userApi().updateAlertNotification(alert, userId);
    return data;
}

// update a members nickname
export async function setNickname(nickname, userId) {
    const {data} = await userApi().updateNickname(nickname, userId);
    return data.result;
}

// get a member's activity for admins
export async function fetchMemberActivity(userId, start, end, page) {
    const {data} = await userApi().getActivityFeed(userId, start, end, page);
    return data.results;
}

// get a member's level activity for admins
export async function fetchMemberLevelActivity(userId, start, end) {
    const {data} = await userApi().getLevelActivity(userId, start, end);
    return data.results;
}

// get a member's score activity for admins
export async function fetchScoreMetricsBreakouts(userId, start, end) {
    const {data} = await userApi().getScoreBreakoutMetrics(userId, start, end);
    return data.results;
}

// get a member's score averages for 3 periods
export async function fetchScoreAverages(userId) {
    const {data} = await userApi().getScoreAverages(userId);
    return data.results;
}

// get a member's streak data for admins
export async function fetchMemberStreak(username) {
    const {data} = await userApi().getAdminStreak(username);
    return data.results;
}