import React from 'react';

class Encourage extends React.Component{
    constructor(props){
        super(props);
        this.state ={
          show: false,
          alertStyle: true,
          alert: '',
        }
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

    render(){
        return (
            <div className="container">

                <div className="row">
                  <div className="teammate-logo-container margin-top">
                    <img src={'https://s3.us-east-2.amazonaws.com/treo/Treo_Wellness_Coaching.png'} alt='Treo Logo' />
                  </div>
                </div>

                <div className="row">
                  <div className="encourageContainer">
                    <h3 className="reply-label">Send a Custom Message!</h3>
                    <textarea className='m-t:1 encourageMessage' rows="5" value={this.props.message} onChange={event => this.props.changeMessage(event.target.value)} ></textarea>
                    <span className={this.props.charactersRemaining < 0 ? 'text-danger d:b m-b:1' : 'd:b m-b:1'}>{this.props.charactersRemaining} characters remaining</span>
                    {this.props.messageError ? <span className="text-danger d:b m-b:1">{this.props.messageError}</span> : null}
                    <button onClick={this.props.send} className='btn'>Send</button>
                  </div>
                </div>

            </div>
        )
    }
}

export default Encourage;