import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";
import './step4.css'
import {GoalBar} from './GoalBar';
import {Notes} from './Notes';

class Step4 extends Component{
   constructor(props){
       super(props);
           this.state = {
               user : {},
               value: [0, 40, 60, 100],
       };
   }

    publishTile() {
      axios.post('/tiles/entry', { 
        clientUsername : this.props.username,
        tileCategory : this.props.location.state.category,
        tileDescription : this.props.location.state.description,
        plan:[
            {
                planStatus : this.props.location.state.planStatus,
                planDescription : this.props.location.state.description,
                scaleStart : this.props.location.state.scaleStart,
                scaleEnd : this.props.location.state.scaleEnd,
                planUnits: this.props.location.state.planUnits,
                minPoint : this.props.location.state.minPoint,
                maxPoint : this.props.location.state.maxPoint
            }
         ]})
      .then(res => {
        console.log(res);
        console.log(res.data);
        if(res.status === 200){
            this.props.history.push({
            pathname: `/admin/Client/${this.props.username}/tiles/`});
        }
        else{
            console.log("nope");
        }
      })
  }

    componentDidMount() {
        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({user:res.data.results});
            })
    }

  handleChange = (value) => {
    this.setState({
      value,
    });
  }

    render(){
        console.log(this.props.location.state.category);
        console.log(this.props.location.state.description);
        console.log(this.props.location.state.scaleStart);
        console.log(this.props.location.state.scaleEnd);
        console.log(this.props.location.state.planUnits);
        console.log(this.props.location.state.minPoint);
        console.log(this.props.location.state.maxPoint);
        return(
            <div>
                <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <h2>Add New Tile</h2>
                        <p>Select new tile for {this.state.user.firstName}</p>
                    </div>
                    <div className="col-md-9 col-xs-12">
                        <ul className="progressbar">
                            <li className="active">Step 1</li>
                            <li className="active">Step 2</li>
                            <li className="active">Step 3</li>
                            <li className="active">Step 4</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <h2>Verify Tile Settings</h2>
                        <h3>Goal Bar Settings</h3>
                        <p>Set initial goal</p>
                    </div>
                </div>
                <div className="row">
                    <GoalBar
                     category={this.props.location.state.category} 
                     description={this.props.location.state.description}
                     />
                    <Notes
                    />
                    <p>Save &amp; Finish Later</p>
                        <button onClick={()=>this.publishTile()}>Publish to Member's Dashboard</button>
                </div>
            </div>
        )

    }
}

export default withRouter(Step4);