import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import styles from './DailyWotd.module.css';
import { useRef } from 'react';

//images
let treadmill = 'https://treo.s3.us-east-2.amazonaws.com/assets/Treadmill+Gideon.png'
let springEight = 'https://treo.s3.us-east-2.amazonaws.com/assets/SP8GX23_TREADMILL.png'
let virtualActive = 'https://treo.s3.us-east-2.amazonaws.com/assets/VirtualActive_2000x1125.png'
let studioCycle = 'https://treo.s3.us-east-2.amazonaws.com/assets/Studio+Cycle+Glenna.png'
let uprightBike = 'https://treo.s3.us-east-2.amazonaws.com/assets/Upright+Bike+Kat.png'
let recumbent = 'https://treo.s3.us-east-2.amazonaws.com/assets/Recumbent+James.png'
let elliptical = 'https://treo.s3.us-east-2.amazonaws.com/assets/Elliptical+Kourtney.png'
let ascenttrainer = 'https://treo.s3.us-east-2.amazonaws.com/assets/Ascent+Jenny.jpg'
let climbMill = 'https://treo.s3.us-east-2.amazonaws.com/assets/Climbmill+Elizabeth.jpg'


const DailyWotdBody = (props) => {
  useEffect(() => {
    // Add the class to the body element to override padding-bottom
    document.body.classList.add('no-padding-bottom');
    document.body.style.background = '#ecf2f6';

    // Remove the class from the body element when the component unmounts
    return () => {
      document.body.classList.remove('no-padding-bottom');
      document.body.style.background = '';
    };
  }, []);

  const { categoryId,matchingCategory } = props; 

  let trainerLedImage = ""

  if (matchingCategory.toString() === 'Treadmill') {
    trainerLedImage = treadmill;
  } else if (matchingCategory.toString() === 'Studio Cycle'){
    trainerLedImage = studioCycle;
  }else if (matchingCategory.toString() === 'Upright Bike'){
    trainerLedImage = uprightBike;
  }else if (matchingCategory.toString() === 'Recumbent Bike'){
    trainerLedImage = recumbent;
  } else if (matchingCategory.toString() === 'Elliptical'){
    trainerLedImage = elliptical
  } else if (matchingCategory.toString() === 'Ascent Trainer'){
    trainerLedImage = ascenttrainer
  }else if (matchingCategory.toString() === 'ClimbMill'){
    trainerLedImage = climbMill
  }
 
  // Example data with categoryId included in the link
  const exampleWorkouts = [
    {
      id: 1,
      title: 'TRAINER-LED CLASS',
      imageUrl: trainerLedImage,
      link: `/wotd/${matchingCategory}/trainer-led/${categoryId}`, // Include categoryId in the link
    },
    {
      id: 2,
      title: 'VIRTUAL ACTIVE',
      imageUrl: virtualActive,
      link: `/wotd/${matchingCategory}/virtual-active/${categoryId}`, // Include categoryId in the link
    },
    {
      id: 3,
      title: 'SPRINT 8\u00AE',
      imageUrl: springEight,
      link: `/wotd/${matchingCategory}/sprint8/${categoryId}`, // Include categoryId in the link
    },
  ];
  function ResponsiveThumbnail({url}){
    // local
    const [height, setHeight] = useState(0);
    const imageRef = useRef(null);

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    function onResize() {
        if (!imageRef.current) return;
        const width = imageRef.current.clientWidth;
        setHeight(width*8/21);
        
    }
    return (
        <div
            ref={imageRef}
            className={styles.square}
            style={{backgroundImage: `url(${url})`, height: `${height}px`}}/>
    )
}

    const [workouts, setWorkouts] = useState(exampleWorkouts);
  
    return (
      <div className={styles.container}>
      {workouts.map((workout) => (
        <Link key={workout.id} to={workout.link} className={styles.square}>
          <ResponsiveThumbnail url={workout.imageUrl} />
          <div className={styles.title}>{workout.title}</div>
        </Link>
      ))}
    </div>
    );
  };
  


export default DailyWotdBody;
