// npm modules
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment';

// actions
import { loadVersionList, loadVersionToState, setEditablePolicy } from "../../actions/action_policy";

const PolicyVersionList = ({ versionList=[], loadVersionList, loadVersionToState, setEditablePolicy }) => {

    function versionClick(version){
        setEditablePolicy(false);
        const list = versionList.map(item => {
            // remove active class from version list
            if (item.active) delete item.active;
            if (item.id === version.id) item.active = true;
            return item;
        });
        // load new state
        loadVersionList(list);
        loadVersionToState(version);

    }
    return (
		<div>
			<label>Previous Versions of Policy</label>
			<div className="policy-versions">            
				<ul>                
					{ versionList.map(version => (
						<li key={version.id} className={version.active ? 'active' : ''} onClick={()=>versionClick(version)}>                        
							{version.version_number} ({moment(version.created_at).format('MM/DD/YYYY')})                        
						</li>
					))}
				</ul>
			</div>
		</div>
    )
};

const mapStateToProps = ({ versionList }) => {
    return {
        versionList,
    }
};

const actions = {
    loadVersionList,
    loadVersionToState,
    setEditablePolicy,
};

export default connect(mapStateToProps, actions)(PolicyVersionList);

PolicyVersionList.propTypes = {
    versionList: PropTypes.shape({}),
    loadVersionList: PropTypes.func,
    loadVersionToState: PropTypes.func,
    setEditablePolicy: PropTypes.func,
};