import {alertApi} from "../../api/alertApi";
import _ from 'lodash';

// store consts
import {
    ADD_ALERT,
    FIRE_ALERT,
    CLEAR_ALERT,
    POP_ALERT_LIST,
    ACKNOWLEDGE_ALERT,
    REORDER_ALERT_LIST,
} from "../storeConstants";

//**************** Actions that involve the store *****************//
// ask the server if there are any alerts that haven't been acknowledged yet
export const checkAlerts = () => {
    return async (dispatch, getState) => {
        const {alertList} = getState();

        // get current server list of alerts
        const {data: {result}} = await alertApi().getAlerts();

        // compare server list with known alerts to remove duplicates that
        // haven't been cleared yet
        const newAlerts = result.filter(alert => {
            return !alertList.find(item => {
                return alert.alert_id === item.alert_id
            })
        });
        dispatch({type: ADD_ALERT, payload: newAlerts});
    }
}

// place a new alert into state to notify any of the subscribers
export const fireAlert = () => {
    return (dispatch, getState) => {
        // grab the first alert in the alert list
        const {alertList, alertSubscription} = getState();

        // don't fire if alert is already in motion
        if (!_.isEmpty(alertSubscription)) return;

        // fire if an alert is waiting
        if (alertList.length) {
            const first = alertList[0];
            // notify the server that an alert was cleared (ignore the promise wait time)
            alertApi().deleteAlert(first.alert_id)
                .catch(e => {
                    console.group('Failed alert');
                    console.log(alertSubscription);
                    console.groupEnd();
                });
            dispatch({type: FIRE_ALERT, payload: first});
        }
    }
}

// callback for subscribers to alert state that they have finished showing an alert
export const clearAlert = () => {
    return async (dispatch, getState) => {
        const {alertList} = getState();

        // remove alert from subscription
        dispatch({type: CLEAR_ALERT, payload: {}});

        // remove the first item from the alert list
        dispatch({type: POP_ALERT_LIST, payload: alertList.slice(1)})
    }
}

// callback for subscribers to tell state an alert has been fired but
// may not have cleared yet
export const acknowledgeAlert = () => {
    return (dispatch, getState) => {
        const {alertSubscription} = getState();
        dispatch({type: ACKNOWLEDGE_ALERT, payload: alertSubscription})
    }
}

// tell state to move an alert to the next position
export const skipAlert = () => {
    return (dispatch, getState) => {
        const {alertList} = getState();

        if (alertList.length > 1){
            const alerts = alertList.slice(1);
            dispatch({type: REORDER_ALERT_LIST, payload: alerts});
        }
    }
}
//**************** Actions that don't involve the store *****************//