import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { isNil } from 'lodash';

// styles
import styles from './clientTrends.module.css';

// actions
import { fetchPlanActivity } from "../../store/actions/tileActions";

// components
import ClientHeader from "../clientHeader/clientHeader";
import MemberStatistics from "../memberStatistics/memberStatistics";
import { ToggleButtons } from "../buttons/buttons";
import { RANGES } from './ranges';
import HabitCalendar from "./habitCalendar/habitCalendar";
import HabitActivities from "./habitActivities/habitActivities";

const HABIT_CALENDAR = 'HABIT_CALENDAR';
const ACTIVITIES = 'ACTIVITIES';

const ClientTrends = props => {
    // states
    const { user, dailyScore } = props;
    // props
    const { plan, history } = props;
    // local
    const [ view, setView ] = useState(HABIT_CALENDAR);
    const [ date, setDate ] = useState(new Date());
    const [ calendarError, setCalendarError ] = useState('');
    const [ events, setEvents ] = useState([]);
    const [ range, setRange ] = useState(RANGES.WEEK);

    async function getPlanActivity() {
        try{
            setCalendarError('');
            const { activity_calendar } = await fetchPlanActivity(plan.id, date);
            const trendEvents = [];
            let span = {};

            activity_calendar.forEach((log, i) => {
                // check if a span has started
                if (log.activity && !span.start) {
                    span.start = log.date;
                }
                // if a span has started, check if it's stopped
                if (span.start) {
                    if (!log.activity) {
                        span.end = activity_calendar[i-1].date;
                        trendEvents.push(span);
                        span = {};
                    }
                }
                // cap a span that ends on the last day of the month
                if (i === activity_calendar.length-1 && span.start && !span.end){
                    span.end = activity_calendar[activity_calendar.length-1].date;
                    trendEvents.push(span);
                }
            })
            setEvents(trendEvents);
        } catch (e) {
            console.log(e);
            setCalendarError('Error retrieving habit activity');
        }
    }

    function renderView(){
        if (view === HABIT_CALENDAR) {
            return (
                <HabitCalendar
                    calendarError={calendarError}
                    events={events}
                    setDate={setDate}/>
            )
        }

        if (view === ACTIVITIES) {
            return (
                <HabitActivities plan={plan} range={range}/>
            )
        }
    }

    // fetch tile plan activity on mount
    useEffect(() => {
        getPlanActivity();
    }, [ date ]);

    // sets the bg color based on wellness score
    let scoreClass = 'noScore';
    if (dailyScore >= 0 && dailyScore < 50 ) {
        scoreClass = 'low';
    } else if (dailyScore >= 50 && dailyScore < 75 ) {
        scoreClass = 'average';
    } else if (dailyScore >= 75 && dailyScore < 90 ) {
        scoreClass = 'high';
    } else if (dailyScore >= 90) {
        scoreClass = 'highest';
    }
    const branded = user.organization.brand_switch && user.organization.bg_color ? 'branded' : '';
    const toggleOptions = [
        { label: 'Habit Calendar', value:'calendar', onClick: () => { setRange(RANGES.WEEK); setView(HABIT_CALENDAR) } },
        { label: 'Activity Graph', value: 'activity', onClick: () => setView(ACTIVITIES) },
    ];
    const dateToggleOptions = [
        { label: 'week', value: RANGES.WEEK, onClick: val => setRange(val) },
        { label: 'month', value: RANGES.MONTH, onClick: val => setRange(val) },
        { label: 'year', value: RANGES.YEAR, onClick: val => setRange(val) },
    ]
    return (
        <div className="client-container">
            <div>
                <ClientHeader type='transparent' backUrl={`/member/clientDash/${user.username}/habits`}/>
            </div>
            <div className={`${scoreClass} wellness-bg largeDesktop ${branded}`}>
                <MemberStatistics
                    user={user}
                    title={plan.description}
                    boxes={[
                        { statistic: isNil(plan.last_activity_value) ? '-' : plan.last_activity_value, description: `${plan.units} (Last Activity)` },
                        { statistic: isNil(plan.plan_average) ? '-' : plan.plan_average, description: `${plan.units}  (30 Day Avg)` },
                        { statistic: isNil(plan.best_value_scored) ? '-' : plan.best_value_scored, description: `${plan.units}  (Personal Best)` },
                    ]}/>
            </div>
            <div className={`general-container container ${styles.toggles}`}>
                <div className={styles.leftToggles}>
                    <ToggleButtons
                        className={styles.graphToggle}
                        activeColor='#272727'
                        options={toggleOptions}/>
                </div>
                <div>
                    { view === ACTIVITIES ?
                        <ToggleButtons
                            className={styles.dateToggle}
                            inactiveColor='#fff'
                            activeColor='#272727'
                            options={dateToggleOptions}/>
                            : null
                    }
                </div>
            </div>
            <div className='general-container container'>
                {renderView()}
            </div>
            <div className="footer-actions">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6">
                            <button className="btn client-white" onClick={()=>history.push(`/member/clientDash/${user.username}/habits`)}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        user: state.user,
        dailyScore: state.dailyScore,
    }
}

export default withRouter(connect(mapStateToProps)(ClientTrends));