//client search view
import React from 'react';
import {HeaderCoach} from '../headerCoach';
import ClientSearch from './clientSearch';
import {withRouter} from 'react-router-dom';


class CoachSearchMain extends React.Component {
    constructor(){
        super();
        this.state= {
            data : ""
        }
    }

    formChild1(params) {
        this.setState({
            data : params
        })
    }

    render() {
        return(
            <div>
                <HeaderCoach />
                <ClientSearch />
            </div>
        );
    }
};

export default withRouter(CoachSearchMain);

