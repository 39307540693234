//client detail view
import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import ClientHeader from "../../clientHeader/clientHeader";
import axios from 'axios';
import {connect} from 'react-redux';
import { Alert } from 'react-bootstrap';
import ArchivedMessages from './ArchivedMessages';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

class ArchivedMessagesMain extends React.Component {

    constructor(props){
        super(props);
        this.state ={
            wellnessScore:'', 
            tiles:[],
            emailArr: [],
            username: '',
            totalPages:1,
        }
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount(){
              axios.get(`/posts/threads/page/1?archived=true`)
              .then((res)=> {
                this.setState({emailArr:res.data.threads})
                this.setState({totalPages:res.data.pages})
              })
                .catch((error)=>{
                   console.log(error);
                })
        //get client wellness score
        axios.get('/score/wellness-score')
            .then(
                res=>{                   
                    this.setState({
                        wellnessScore:res.data.results.score
                    });
                }
            )
            .catch(err=>console.log('ERROR: ', err));
        axios.get('/tiles/client')
            .then(
                res=>{
                    this.setState({tiles: res.data.result})
                }
            )
            .catch(err=>console.log('ERROR: ', err))
    }

      goBack() {
        window.history.back();
      }
    
    createList=()=>{
            if(this.state.emailArr){
                return this.state.emailArr.map((item)=>{
                  console.log(item)
                    let time = item.posts[item.posts.length-1].created_at;
                    
                    time = moment.parseZone(item.created_at).local().format("MM/DD/YY h:mm A")
                    let title = item.posts[item.posts.length-1].title;
                    let picture = item.posts[item.posts.length-1].user.profile_image;
                  return(
                    <ArchivedMessages
                      username={this.props.user.username}
                      email={item}
                      title={title}
                      message={item.message}
                      tags={item.tags}
                      time={time}
                      id={item.id}
                      sender={item.sender}
                      idHandler={this.idHandler}
                      profile_pic={picture}
                       />
                    )
                });
            }
        };

    handlePageClick(e){
              axios.get(`/posts/threads/page/${e.selected+1}?archived=true`)
              .then((res)=> {
                this.setState({emailArr:res.data.threads})
                this.setState({totalPages:res.data.pages})
              })
                .catch((error)=>{
                   console.log(error);
                })
    }

    render() {
        let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
        const {user} = this.props;
        
        return(
            <div className="client-container inbox--container">
                <ClientHeader backUrl={`/member/clientDash/${user.username}/inbox`}/>

                <div className="container client-account-dash">
                    
                    <div className="row">
                        <ul className="tiles-ul col-xs-12 clearfix">
                          <h4>Archived Messages</h4>
                          {this.createList()}
                        </ul>                   
                    </div>
                    
                   { this.state.totalPages > 1 &&
                        <div className="pagination-container">
                            <ReactPaginate 
                                pageCount={this.state.totalPages}
                                pageRange={10}
                                pageMargin={5}
                                pageClassName='paginate paginate-default'
                                previousClassName='paginate paginate-previous'
                                nextClassName='paginate paginate-next'
                                onPageChange={this.handlePageClick}
                                       />
                        </div>
                 }
                </div>
                
                <div className="footer-actions">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6">
                                <button className="btn client-white" onClick={this.goBack}>Back</button>
                            </div>
                            <div className="col-xs-6 text-right">                            
                                <Link className="btn primary" type="submit" to={`/member/clientDash/${this.props.username}/inbox-newmessage`}>+ NEW MESSAGE</Link>
                                  {this.state.show
                                    ?  <Alert className="col-md-4 save-profile-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                                          <p>{this.state.alert}</p>
                                        </Alert>
                                    : null
                                  }
                            </div>
                        </div>
                    </div>
                </div>
                    
            </div>
        );
    }
};

function mapPropsToState({user}){
    return {user}
}

export default withRouter(connect(mapPropsToState)(ArchivedMessagesMain));

