import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import parseISO from "date-fns/parseISO";
import parse from 'date-fns/parse';
import format from "date-fns/format";
import isValid from 'date-fns/isValid';

// styles
import styles from './tileActivityGraph.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const TileActivityGraph = props => {
    // props
    const {data, domain} = props;

    if (data === null) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }

    const dateFormatter = date => {
        let result =  parseISO(date, new Date()).getTime();
        if (!isValid(result)) {
            result = parse(date, 'MM/dd/yy', new Date());
        }
        if (!isValid(result)) return;
        return format(result, 'MM/dd/yy');
    };

    const CustomizedDot = (props) => {
        const { cx, cy,  payload, } = props;

        if (payload.visible) {
            return (
                <svg x={cx - 4} y={cy - 4} width={8} height={8} fill="white">
                    <g transform="translate(4 4)">
                        <circle r="4" fill="#4bbc4e" />
                        <circle r="2" fill="white" />
                    </g>
                </svg>
            );
        }

        return null;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.tooltip}>
                    <p>{format(parseISO(payload[0].payload.date, new Date()).getTime(), 'MM/dd/yy')}</p>
                    <p>{`value : ${payload[0].payload.plan_value}`}</p>
                </div>
            );
        }

        return null;
    }
    const start = format(domain.start, 'MM/dd/yy');
    const end = format(domain.end, 'MM/dd/yy');
    return (
        <div>
            <div className={styles.container}>
                <ResponsiveContainer width="100%" height="100%" debounce={1}>
                    <LineChart
                        data={data}
                        margin={{
                            top: 15,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <XAxis
                            domain={[start, end]}
                            dataKey="date"
                            tickFormatter={dateFormatter}/>
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Line type="monotone" dataKey="value" stroke="#4bbc4e" dot={<CustomizedDot/>}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default TileActivityGraph;