export const coachTour = [
    {
        id: 'tour-coach-scheduleBtn',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-coach-scheduleBtn') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 25] } }]
        },
        canClickTarget: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        attachTo: { element: '.tour-coach-scheduleBtn', on: 'auto' },
        advanceOn: {selector: '.tour-coach-scheduleBtn', event: 'click'},
        text: "Working with a coach is easy. To schedule a FREE phone or video call with your coach, click here.",
        title: "Schedule a Call"
    },
    {
        id: 'tour-coach-coachBtn',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-coach-coachBtn') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        canClickTarget: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        attachTo: { element: '.tour-coach-coachBtn', on: 'auto' },
        advanceOn: {selector: '.tour-coach-coachBtn', event: 'click'},
        text: "Wellness Coaching by phone or video call is available to you at no charge. Click on the coach tile to schedule a call with your certified coach. Members who talk with a coach are more likely to reach their goals.",
        title: "Select a Coach"
    },
    {
        id: 'tour-coach-calendars',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-coach-calendars') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        canClickTarget: false,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        attachTo: { element: '.tour-coach-calendars', on: 'auto' },
        text: "Choose the timeslot that matches your schedule to talk with your coach for free.",
        title: "Select a Timeslot"
    },
    {
        id: 'tour-coach-messageBtn',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-coach-messageBtn') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 25] } }]
        },
        canClickTarget: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        attachTo: { element: '.tour-coach-messageBtn', on: 'auto' },
        advanceOn: {selector: '.tour-coach-messageBtn', event: 'click'},
        text: "To message your coach anytime, start the conversation here.",
        title: "Message your coach"
    },
    {
        id: 'tour-coach-newTopic',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-coach-newTopic') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Finish',
            }
        ],
        canClickTarget: false,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        attachTo: { element: '.tour-coach-newTopic', on: 'auto' },
        text: "Send a question or message to your coach anytime. You have unlimited messaging with your coach. Your coach will respond as soon as they are available on weekdays, or on a Monday if messaged over the weekend.",
        title: "Start a New Thread"
    },
];