import React from 'react';
import Select from "react-select";

function ReactSelectCategories({ list, label, value, name, onChange, ...props}) {

    const groups = list.map(category => {
        if (!category.subcategories || category.subcategories.length === 0) return { value: category.id, label: category.name };

        const options = [];
        options.push({ value: category.id, label: category.name });
        category.subcategories.forEach(sub => {
            options.push({value: sub.id, label: sub.name});
        });
        return { label: category.name, options };
    });
    return(
        <div className={props.className}>
            <label>{label}</label>
            <Select
                placeholder={props.placeholder}
                className='select-form-control'
                onBlur={props.onBlur}
                value={value}
                name={name}
                options={groups}
                onChange = {e => {
                    onChange(e)
                }}
                formatGroupLabel={formatGroupLabel}
                styles={categoryStyles}
            />
        </div>
    )
}
const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const categoryStyles = {
    control: styles => ({ ...styles, backgroundColor: '#fff' }),
    option: (styles, data) => {
        return {
            ...styles,
            marginLeft: data.options.filter(item => item.label === data.label).length ? 0 : 20,
        };
    },
    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
};
export default ReactSelectCategories;