import React, {useState, useEffect} from 'react';
import _ from 'lodash';

// actions
import {fetchActivePolicies, fetchPolicyVersion} from "../../../store/actions/policyActions";

// styles
import styles from './termsOfService.module.css';
import {Modal} from "react-bootstrap";
import {BasicButton} from "../../buttons/buttons";

const TermsOfService = props => {
    // props
    const {loadPolicies, horizontal} = props;
    // local
    const [policies, setPolicies] = useState([]);
    const [versions, setVersions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentPolicy, setCurrentPolicy] = useState({title: ''})
    const [currentVersion, setCurrentVersion] = useState({version_number: ''})

    // grab active policies on first render
    useEffect(() => {
        async function prepare() {
            try {
                const TOS = await fetchActivePolicies();
                const versionArray = await Promise.all(TOS.map(item => fetchPolicyVersion(item.active_version_id)));
                setVersions(_.flatten(versionArray));
                setPolicies(TOS.map(item => {
                    item.accepted = true;
                    return item;
                }));
                loadPolicies(TOS);
            } catch(e) {
                console.log(e);
            }
        }
        prepare();
    }, []);

    function showPolicyModal(policy, versionId) {
        setCurrentPolicy({title: policy.title});
        const foundVersion = versions.find(v => v.id === versionId);
        setCurrentVersion({version_number: foundVersion.version_number, text: foundVersion.text});
        setShowModal(true);
    }

    function closePolicyModal() {
        setShowModal(false);
        setTimeout(() => {
            setCurrentPolicy({title: ''});
            setCurrentVersion({version_number: ''});
        }, 1000);
    }

    return (
        <div className={horizontal ? styles.horizontal : ''}>
            {policies.map(policy => {
                return (
                    <div key={policy.id} className={`TOS-row ${styles.row}`}>
                        <label onClick={() => showPolicyModal(policy, policy.active_version_id)} className={styles.policy}>
                            {policy.title}
                        </label>
                    </div>
                )
            })}
            <Modal show={showModal} onHide={closePolicyModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentPolicy.title} <span>(version {currentVersion.version_number})</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { currentVersion.version_number ?
                        <div>
                            <div className="margin-bottom-2x" dangerouslySetInnerHTML={{__html:currentVersion.text}} />
                            <BasicButton onClick={closePolicyModal}>CLOSE</BasicButton>
                        </div>
                        : null
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default TermsOfService;