import {FETCH_SINGLE_USER} from "../actions/action_user";
import {CLEAR_SINGLE_USER} from "../actions/action_user";

export default function(state=null, action){
    switch (action.type){
        case FETCH_SINGLE_USER:
            return action.payload.data;

        case CLEAR_SINGLE_USER:
            return action.payload;
        // no default
    }
    return state;
}
