import React, {useState, useEffect, useRef} from 'react';

// styles
import styles from './messageCenter.module.css';

// actions
import {fetchAdminCoachList, fetchMessages, fetchThread} from "../../../store/actions/postsActions";

// components
import {BasicButton} from "../../buttons/buttons";
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {ErrorAlert, SecondaryInfoAlert} from "../../alerts/alerts";
import InboxList from "../inboxList/inboxList";
import ThreadBox from "../threadBox/threadBox";

const MessageCenter = props => {
    // local
    const [list, setList] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const [target, setTarget] = useState(null);
    const [secondaryTarget, setSecondaryTarget] = useState(null);
    const [mounted, setMounted] = useState(false);
    const [error, setError] = useState('');
    const [page, setPage] = useState(0);
    const pages = useRef(0);
    const [threads, setThreads] = useState([]);
    const [singleThread, setSingleThread] = useState(null);
    const isLoading = useRef(false);
    const [archived, setArchived] = useState(false);

    // set view to ready
    useEffect(() => {
        mount();
        return () => cleanup();
    }, []);

    useEffect(() => {
        if (!mounted) return;
        if (isLoading.current || page < 1) return;
        isLoading.current = true;
        getMessages();
    }, [page]);

    useEffect(() => {
        if(!mounted) return;
        setPage(1);
        if (page === 1) getMessages();
    }, [archived])

    useEffect(() => {
        if(archived) return toggleArchived();
        if (target && mounted) {
            setPage(1);
            if (page === 1) getMessages();
        }
    },[target, secondaryTarget]);

    async function mount() {
        getList();
        setMounted(true);
        window.addEventListener('scroll', detectScroll, {passive: true})
    }

    async function getList() {
        const list = await fetchAdminCoachList();
        setList(list || []);
    }

    async function handleClick(val, member) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if (val) {
            setExpanded(val.id);
            setTarget(val.username);
            if (member) setSecondaryTarget(member.username);
        } else {
            setExpanded(val);
            setTarget(null);
            setSecondaryTarget(null);
        }
    }

    async function cleanup() {
        window.removeEventListener('scroll', detectScroll);
    }

    async function getMessages() {
        try{
            const options = {override: target};
            if (secondaryTarget) options.username = secondaryTarget;
            if (archived) options.archived = true;
            const messages = await fetchMessages(page, options);
            if (page === 1) {
                setThreads(messages.threads);
            } else { setThreads([...threads, ...messages.threads]); }
            pages.current = parseInt(messages.pages)
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error)
            } else {
                setError('Unable to get messages. If this error persists, please contact your systems administrator.')
            }
        }
        finally {
            isLoading.current = false;
        }
    }

    async function selectThread(threadId, isNew=false) {
        try {
            fetchThread(threadId)
                .then(result => {
                    setSingleThread(result);
                })
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error)
            } else {
                setError('Unable to get thread. If this error persists, please contact your systems administrator.')
            }
            setSingleThread(null);
        }
    }

    function closeThreadPane() {
        setSingleThread(null);
    }

    function detectScroll() {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = document.documentElement;

        if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading.current) {
            setPage(prevState => {
                if (prevState !== pages.current){
                    return prevState + 1
                }
                return prevState;
            });
        }
    }

    function toggleArchived() {
        setArchived(!archived);
    }

    return (
        <div className="container margin-top">
            <div className="row margin-bottom-med">
                <div className="col-sm-12">
                    <nav>
                        <ol className="breadcrumb ">
                            <li className="breadcrumb-item underline "><a href="/dashboard"
                                                                          className="text-light">Dashboard</a></li>
                            <span className="arrow"></span>
                            <li className="breadcrumb-item text-light active">Message Center</li>
                        </ol>
                    </nav>
                </div>
                <div className="col-xs-6">
                    <h2 className='m:0'>Message Center</h2>
                </div>
                <div className="col-xs-6 text-right">
                    { archived ?
                        <BasicButton onClick={toggleArchived}>View Messages</BasicButton>
                        :
                        <BasicButton onClick={toggleArchived}>View Archived</BasicButton>
                    }
                </div>
            </div>
            <div className={`row`}>
                <div className="col-xs-4 col-md-3">
                    <BoxContainer className={styles.list}>
                        {list.map(item => <CoachList
                            expanded={expanded === item.id}
                            selected={secondaryTarget}
                            list={item}
                            callback={handleClick}/>)}
                    </BoxContainer>
                </div>
                <div className='col-xs-8 col-md-9'>
                    <div>
                        {/*Error Message*/}
                        <div>
                            {error ? <ErrorAlert>{error}</ErrorAlert> : null}
                        </div>
                    </div>
                    <div>
                        {/*List of Threads*/}
                        {!threads || threads.length < 1 ?
                            <div>
                                <SecondaryInfoAlert>No Messages</SecondaryInfoAlert>
                            </div>
                            : null
                        }
                        <div>
                            <InboxList
                                list={threads}
                                selectThread={selectThread}
                                active={singleThread}/>
                            <div className={`${styles.threadContainer} ${singleThread ? '' : styles.hide}`}>
                                {singleThread ?
                                    <ThreadBox
                                        thread={singleThread}
                                        user={{id: expanded}}
                                        close={closeThreadPane}
                                        refreshThread={selectThread}
                                        disableReply/>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessageCenter;

function CoachList({expanded, list, selected, callback}) {
    function toggleMain() {
        callback(list);
    }

    return (
        <div>
            <div className={`${styles.coach} ${expanded ? styles.selected : null}`} onClick={toggleMain}>{list.first_name} {list.last_name} ({list.unread})</div>
            <div style={expanded ? {display: 'block'} : {display: 'none'}}>
                {list.members.map(member => (
                    <div onClick={() => callback(list, member)} className={`${styles.member} ${selected === member.username ? styles.memberSelected : ''}`}>
                        {member.unread > 0 ? <strong>{member.first_name} {member.last_name} ({member.unread})</strong>
                            : `${member.first_name} ${member.last_name} (${member.unread})`
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}