//This component will populate the tiles via a GET request to the DB
import React from 'react';
import axios from 'axios';
import {Link, withRouter} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Redirect } from 'react-router-dom'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// styles
import './tiles.css';

// actions
import {fetchSelf} from "../../../actions/action_user";
import { setClientPathwayList } from "../../../store/actions/pathwaysActions";


let custom = true;

class Tiles extends React.Component {
    constructor() {
        super();
        this.state = {
            todos: [],
            id: '',
            totalPages:1,
            redirect: false,
            status:'',
            currentPage:1,
            descriptions:[],
            allCategories:[],
        };
        this.handleClick = this.handleClick.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.forwardToTile = this.forwardToTile.bind(this);
    }

    forwardToTile(tileId, planLength, firstPlanId){
        if(planLength > 1){
            //forward to screen where client decides which plan to select
            this.props.history.push({
                pathname: '/member/addActivity/tile/'+ tileId,
                state: { intro: false },
            });
        }else{
            //immediately forward to goal bar screen
            // this.props.fetchSinglePlan(plans[0].id);
            this.props.history.push({
                pathname: '/member/tile/'+ tileId,
                state: { planId: firstPlanId }
            });
        }
    }

    createList=()=>{
        if(this.state.todos){
            return this.state.todos.map((todos)=>{
              
                let planLength = todos.plan.length;
                let tileId = todos.tile.id;
                let firstPlanId = todos.plan[0].id;
                let description = todos.plan[0].description;

                custom = this.state.descriptions.includes(description);

                // sets the bg color based on category
                let categoryClass = 'noCategory';
                if (todos.tile.tile_category === 'what you do') {
                    categoryClass = 'wydBG';
                } else if (todos.tile.tile_category === 'what you eat') {
                    categoryClass = 'wyeBG';
                } else if (todos.tile.tile_category === 'when you eat') {
                    categoryClass = 'whenyeBG';
                }
                // sets tile class to pull in proper icon
                let tileDescription = todos.plan[0].description;
                let tileIcon = tileDescription.replace(/\s/g, '');
                if(custom === false && todos.tile.tile_category === 'what you do' && todos.plan.length === 1)tileIcon = `custom-physicalactivity`;
                if(custom === false && todos.tile.tile_category === 'what you eat' && todos.plan.length === 1)tileIcon = `custom-veggieandfruitservings`;
                if(custom === false && todos.tile.tile_category === 'when you eat' && todos.plan.length === 1)tileIcon = `custom-mealtimespacing`;
                if(tileIcon === "vegetables&fruit")tileIcon = "veggieandfruitservings";
                if(tileIcon === 'didyoufindthiscontenthelpful?' || tileIcon === 'Didyoufindthiscontenthelpful?') tileIcon ='doc';
                
                return(
                    <li className="tiles-li col-xs-12 col-sm-6">
                        <div>
                            {todos.tile.tile_category_id === 4
                                ? <Link to={todos.tile.tile_category_id === 4 ? '/member/tile/intro/'+ todos.tile.id : '/member/tile/'+ todos.tile.id }>
                                        <div className={"tile " + categoryClass}>
                                            <div className="tile-titles">
                                                <h4 className="capitalize">{todos.tile.tile_category}</h4>
                                                <p>Watch the video</p>
                                            </div>
                                            <div className="tile-icon">
                                                <div className={"the--icon icon-" + tileIcon}>
                                                        {/*<object type="image/svg+xml" data={require('../../../images/icon-' + tileIcon + '.svg')} class="the-icon">
                                                      Fallback
                                                    </object>*/}                                        
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                : <div onClick={(e)=>this.forwardToTile(tileId, planLength, firstPlanId)}>
                                        <div className={"tile " + categoryClass}>
                                            <div className="tile-titles">
                                                <h4 className="capitalize">{todos.plan && todos.plan.length > 1 ? "Activity" : todos.plan[0].description}</h4>
                                                <p className="capitalize">{todos.tile.tile_category}</p>
                                            </div>
                                            <div className="tile-icon">
                                                <div className={"the--icon icon-" + tileIcon}>
                                                        {/*<object type="image/svg+xml" data={require('../../../images/icon-' + tileIcon + '.svg')} class="the-icon">
                                                      Fallback
                                                    </object>*/}                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </li>


                )
            });
        }
    };

      setRedirect = () => {
        this.setState({
          redirect: true
        })
      }

      renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/member/onboard-assessment/' />
        }
      }

    findCustom(){
        axios.get(`/tiles/categories`)
            .then((res)=>{
                this.setState({allCategories:res.data.categories})
                  this.state.allCategories.map((categories) => {
                     axios.get(`/tiles/${categories.id}/plans`)
                        .then((res)=>{
                            this.setState({ descriptions: this.state.descriptions.concat(res.data.descriptions) }, () => {
                               
                            })
                        })
                          .catch(error=>{
                            console.log(error.message);
                        })               
                 })
            })
              .catch(error=>{
                console.log(error.message);
        })        
    }

    componentDidMount() {
        this.findCustom()
        this.props.fetchSelf()
          .then(() => this.setState({status: this.props.user.user_status_id}))
          .then(() => this.state.status === 3 ? this.setRedirect() : null)
          .then(() => {
              if (!this.props.user.start_date) this.props.setClientPathwayList()
          });
        axios.get(`/tiles/client`)
            .then((res)=>{
                //this response will be an array of objects with 2 value pairs tileTitle & planDescription
                this.setState({todos: res.data.result});
                this.setState({totalPages: 1});
            })
              .catch(error=>{
                console.log(error.message);
        })
    }

    componentDidUpdate(prevProps, prevstate){
         if(prevProps.activeTiles !== this.props.activeTiles ){
          if(this.props.activeTiles === true){
            this.setState({currentPage:1})
            axios.get(`/tiles/client`)
                .then((res)=>{
                    //this response will be an array of objects with 2 value pairs tileTitle & planDescription
                    this.setState({todos: res.data.result});
                    this.setState({totalPages: res.data.pages});
                })
                  .catch(error=>{
                    console.log(error.message);
            })
          }
          else{
            axios.get(`/tiles/client/archived/${this.state.currentPage}`)
                .then((res)=>{
                    this.setState({todos: res.data.result});
                    this.setState({totalPages: res.data.pages});
                })
                  .catch(error=>{
                    console.log(error.message);
            })
          }
         }
    }

    handlePageClick(e){
      this.setState({currentPage:e.selected+1})
      axios.get(`/tiles/client/archived/${e.selected+1}`)
      .then((res)=> {
        this.setState({todos: res.data.result});
      })
        .catch((error)=>{
           console.log(error);
        });
    }

    // is this needed?
    // handleClick = (e, todos) => {
    //     // access to e.target here
    //     let tile = []
    //     tile = todos;
    // };

    render() {
        return (
            <div>
            {this.renderRedirect()}
                <div className="row">
                    <div className="">
                        <ul  className="tiles-ul">
                            {this.createList()}
                        </ul>
                    </div>
                </div>

                    { this.state.totalPages > 1 &&
                        <div className="pagination-container client--dash">
                            <ReactPaginate
                                pageCount={this.state.totalPages}
                                pageRange={10}
                                pageMargin={5}
                                pageClassName='paginate paginate-default'
                                previousClassName='paginate paginate-previous'
                                nextClassName='paginate paginate-next'
                                onPageChange={this.handlePageClick}
                            />
                        </div>
                    }
            </div>
        );
    }
}

function mapPropsToState({user, clientPathwayList }){
    return {user, clientPathwayList }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchSelf, setClientPathwayList}, dispatch);
}

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(Tiles));
