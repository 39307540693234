import React from 'react';

// components
import {RANKS, RANKS_IDS} from "../../serverVariables/ranks";

const bronzeBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/ranks/Ranking_Bronze200x200.png';
const silverBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/ranks/Ranking_Silver200x200.png';
const goldBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/ranks/Ranking_Gold20x200.png';
const platinumBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/ranks/Ranking_Platinum200x200.png';

const RankMedal = props => {
    const {rank} = props;

    function chooseBadge() {
        let lower;
        if (isNaN(rank)) {
            lower = rank.toLowerCase();
        } else {
            lower = rank;
        }
        switch(lower) {
            case RANKS.BRONZE:
            case RANKS_IDS.BRONZE:
                return <img src={bronzeBadge} {...props} alt="bronze"/>
            case RANKS.SILVER:
            case RANKS_IDS.SILVER:
                return <img src={silverBadge} {...props} alt="silver"/>
            case RANKS.GOLD:
            case RANKS_IDS.GOLD:
                return <img src={goldBadge} {...props} alt="gold"/>
            case RANKS.PLATINUM:
            case RANKS_IDS.PLATINUM:
                return <img src={platinumBadge} {...props} alt="platinum"/>;
            default:
                return <img src={bronzeBadge} {...props} alt="badge"/>
        }
    }

    return chooseBadge();

}

export default RankMedal;