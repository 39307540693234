import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux';

// styles
import styles from './supervisorInbox.module.css';

// actions
import {fetchMessages, fetchThread} from "../../../store/actions/postsActions";

// components
import Loading from "../../Loading/Loading";
import InboxList from "../inboxList/inboxList";
import ThreadBox from "../threadBox/threadBox";
import {BasicButton} from "../../buttons/buttons";
import {ErrorAlert} from "../../alerts/alerts";

const SupervisorInbox = props => {
    // state
    const { singleCoach} = props;
    // local
    const [ready, setReady] = useState(false);
    const [error, setError] = useState('');
    const [page, setPage] = useState(0);
    const pages = useRef(0);
    const [threads, setThreads] = useState([]);
    const [singleThread, setSingleThread] = useState(null);
    const isLoading = useRef(false);
    const [archived, setArchived] = useState(false);
    const [loading, setLoading] = useState(false);
    const [endReached, setEndReached] = useState(false);

    // set view to ready
    useEffect(() => {
        mount();
        return () => cleanup();
    }, []);

    useEffect(() => {
        if (isLoading.current || page < 1) return;
        isLoading.current = true;
        getMessages();
    }, [page]);

    useEffect(() => {
        setPage(1);
        if (page === 1) getMessages();
    }, [archived])

    async function mount() {
        setPage(prevState => prevState +1);
        window.addEventListener('scroll', detectScroll, {passive: true})
        setReady(true);
    }

    async function cleanup() {
        window.removeEventListener('scroll', detectScroll);
    }

    async function getMessages() {
        setLoading(true);
        try{
            const options = {archived};
            options.override = singleCoach.username;
            const messages = await fetchMessages(page, options);
            if (page === 1) {
                setThreads(messages.threads);
            } else { setThreads([...threads, ...messages.threads]); }
            pages.current = parseInt(messages.pages);
            setLoading(false);
            if (page === pages.current){
                setEndReached(true);
            } else { setEndReached(false)}
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error)
            } else {
                setError('Unable to get messages. If this error persists, please contact your systems administrator.')
            }
        }
        finally {
            isLoading.current = false;
        }
    }

    async function selectThread(threadId, isNew=false) {
        try {
            fetchThread(threadId)
                .then(result => {
                    setSingleThread(result);
                })
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error)
            } else {
                setError('Unable to get thread. If this error persists, please contact your systems administrator.')
            }
            setSingleThread(null);
        }
    }

    function closeThreadPane() {
        setSingleThread(null);
    }

    function detectScroll() {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = document.documentElement;

        if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading.current) {
            setPage(prevState => {
                if (prevState !== pages.current){
                    return prevState + 1
                }
                return prevState;
            });
        }
    }

    function toggleArchived() {
        setArchived(!archived);
    }

    if (!ready) return (
        <div className="m-t:2 d:f j-c:c">
            <Loading/>
        </div>
    );
    return (
        <div className="container margin-top-lrg">
            <div className="row">
                <div className={`col-xs-12 ${styles.archiveContainer}`}>
                    { archived ?
                        <BasicButton onClick={toggleArchived}>View Messages</BasicButton>
                        :
                        <BasicButton onClick={toggleArchived}>View Archived</BasicButton>}
                </div>
            </div>
            <div className="row">
                {/*Error Message*/}
                <div className="col-xs-12">
                    {error ? <ErrorAlert>{error}</ErrorAlert> : null}
                </div>
            </div>
            <div className="row">
                {/*List of Threads*/}
                <div className="col-xs-12">
                    <InboxList
                        list={threads}
                        selectThread={selectThread}
                        active={singleThread}/>
                    {loading ?
                        <Loading/>
                        : null
                    }
                    {endReached ?
                        <p className='t-a:c text-info'>You're all caught up</p>
                        : null
                    }
                    <div className={`${styles.threadContainer} ${singleThread ? '' : styles.hide}`}>
                        {singleThread ?
                            <ThreadBox
                                thread={singleThread}
                                user={{id: singleCoach.id}}
                                close={closeThreadPane}
                                refreshThread={selectThread}
                                disableReply/>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps({user, singleCoach}) {
    return {user, singleCoach};
}

export default connect(mapStateToProps)(SupervisorInbox);