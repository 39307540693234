// npm modules
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from "axios";
import _ from 'lodash';

// styles
import styles from './pathwaysViewContainer.module.css';

// actions
import { resetClientPathway } from "../../store/actions/pathwaysActions";

// components
import PathwayList from './pathwaysList';
import ClientPathwayReportContainer from "../clientPathwayReport/clientPathwayReportContainer";


const PathwaysContainer = ({ singleUser, clientPathway, resetClientPathway }) => {
    // hold array of assessment objects
    const [list, setList ] = useState([]);

    // grab user assessments
    const fetchPathways = () => {
        axios.get(`/pathways/all?user_id=${singleUser.user.id}`)
            .then(data => {
                if (data && data.data) {
                    const res = data.data.result;
                    if (!Array.isArray(res)) {
                        setList([res]);
                    } else {
                        setList(res);
                    }
                }
            })
            .catch(e => console.log(e));
    };

    // on mount, get list of client's pathways
    useEffect(() => {
        fetchPathways();
    }, []);

    // on dismount, clear client pathway state
    useEffect(() => {
        return () => {
            resetClientPathway();
        }
    }, []);

    return (
        <div className="container margin-top">
            <div className="row">
                <div className="col-xs-12">
                    <h2>Pathways</h2>
                    <p>Click a Pathway to View</p>
                </div>
            </div>
            <div className="row">
                <ul className="note-ul col-xs-12 col-md-3">
                    <li className={`active-li note-li col-xs-12 ${styles.list}`}>
                        { singleUser ? <PathwayList list={list}/> : null}
                    </li>
                </ul>
                <div className="inbox-container col-xs-12 col-md-9">
                    <div className={`container ${styles.pathwaysContainer}`}>
                        {
                            clientPathway && !_.isEmpty(clientPathway) ?
                                <ClientPathwayReportContainer/>
                                :
                                <div className={styles.placeholder}>
                                    <p><i className="fas fa-angle-double-left"/></p>
                                    <p>Click a Pathway to View</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
};

const mapStateToProps = ({ singleUser, clientPathway }) => {
    return {
        singleUser,
        clientPathway
    }
};

const actions = {
    resetClientPathway,
};

PathwaysContainer.propTypes = {
    singleUser: PropTypes.shape({
        user: PropTypes.shape({
            id: PropTypes.string,
        })
    }),
    clientPathway: PropTypes.object,
    resetClientPathway: PropTypes.func,
};

export default connect(mapStateToProps, actions)(PathwaysContainer);

