// npm modules
import React, { useEffect } from "react";

const ClientPathwayFundamentals = () => {
  function getImage() {
    const width = window.innerWidth;
    const graph = document.getElementById("fundamentalGraph");
    if (width > 600) return (graph.src = 'https://treo.s3.us-east-2.amazonaws.com/assets/HabitTileCharts_LogoChart.png');
    return (graph.src = 'https://treo.s3.us-east-2.amazonaws.com/assets/HabitTileChart_Mobile375.png');
  }

  useEffect(() => {
    getImage();
    window.addEventListener("resize", getImage);
    return () => {
      window.removeEventListener("resize", getImage);
    };
  }, []);
  return (
    <div className="container">
      <div className="col-xs-12 col-sm-8 col-sm-offset-2">
        <div className="content-container">
          <h1>Fundamentals</h1>
          <p>
            The three fundamental categories of behavior change are When You
            Eat, What You Eat, and What You Do. The labeled icons in each
            fundamental category are the specific Habit Tiles you may select for
            your Habit Builder.
          </p>
          <p>
            The Habit Tiles represent the habits that have the greatest impact
            on your overall health and wellbeing, according to evidence based
            research. By working with your Treo Wellness Coach, you will set
            your personal goals for the Habit Tiles that you choose for your
            Habit Builder.
          </p>
			<div className="text-center margin-top-2x margin-bottom-2x">
				<img id='fundamentalGraph' src='' alt="Fundamentals | Treo Wellness"/>
			</div>
        </div>
      </div>
    </div>
  );
};

export default ClientPathwayFundamentals;
