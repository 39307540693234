// npm modules
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { sanitize } from 'dompurify';

// Components
import GenericTextArea from '../../genericWYSIWYG/genericWYSIWYG';
import Giphy from "../../Giphy/giphyContainer";
import PhotoContainer from "./container";

// let recipient = [];
// let filteredRecipients = [];
// let recipientFull = [];
// let filteredRecipientsFull = [];


class NewMessage extends Component{
   constructor(props){
       super(props);
           this.state = {
            reply:'',
            recipient:'',
            recipientFullName:[],
            singleRecipient:'',
            gif: null,
       };
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
   }


  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

   titleChange = (e) => {
    this.props.titleHandler(e.target.value);
    this.setState({title: e.target.value});
  };

   replyChange = (reply) => {
       const text = sanitize(reply);
       this.props.replyHandler(text);
       this.setState({text});
  };

   gifChange = (gif) => {
       const image = `<img style="width:250px;height: auto;" src=${gif.images.downsized_medium.url} alt="gif"/>`;
       this.setState({ gif: image });
   };

    displayRecipients(){
      return this.state.recipientFullName.map((item)=>{
        return(<span>{`${item}, `}</span>)
      })
    }

    // componentDidMount(){
    //  recipient = [];
    //  filteredRecipients = [];
    //  recipientFull = [];
    //  filteredRecipientsFull = [];
    // }

    componentDidUpdate(prevProps){
      if(prevProps.recipients !== this.props.recipients){
        if(this.props.recipients.length >= 1){
          this.setState({singleRecipient:`${this.props.recipients[0].title} ${this.props.recipients[0].first_name}`})
          let username = [this.props.recipients[0].username]
          this.props.recipientHandler(username);
        }
        else{
          return;
        }
      }
    }

    select(item, fullName) {
      const { recipient, recipientFull, recipientHandler } = this.props;
    
      const filteredRecipients = this.filterArray(recipient);
      recipientHandler(filteredRecipients);
    
      const filteredRecipientsFull = this.filterArray(recipientFull);
      recipientHandler(filteredRecipientsFull);
      this.setState({ recipientFullName: filteredRecipientsFull });
    }
    
    filterArray(arr) {
      const filteredArray = [];
      for (let i = 0; i < arr.length; i++) {
        const currentItem = arr[i];
        let foundCount = 0;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j] === arr[i]) {
            foundCount++;
          }
        }
        if (foundCount === 1) {
          filteredArray.push(currentItem);
        }
      }
      return filteredArray;
    }

    // select(item, fullName, ){
    //   recipient.push(item);  
    //   filteredRecipients = recipient;
    //   for(var h = 0; h < recipient.length; h++) {
    //       var currentItem = recipient[h];
    //       var foundCount = 0;
    //       // search array for item
    //       for(var i = 0; i < recipient.length; i++) {
    //           if (recipient[i] === recipient[h])
    //               foundCount++;
    //       }
    //       if(foundCount > 1) {
    //           // remove repeated item from new array
    //           for(var j = 0; j < filteredRecipients.length; j++) {
    //               if(filteredRecipients[j] === currentItem) {                
    //                   filteredRecipients.splice(j, 1);
    //                   j = j - 1;
    //               }
    //           }            
    //       }
    //   }
    //   this.props.recipientHandler(filteredRecipients);

    //   recipientFull.push(fullName);  
    //   filteredRecipientsFull = recipientFull;
    //   for(var h = 0; h < recipientFull.length; h++) {
    //       var currentItem = recipientFull[h];
    //       var foundCount = 0;
    //       // search array for item
    //       for(var i = 0; i < recipientFull.length; i++) {
    //           if (recipientFull[i] === recipientFull[h])
    //               foundCount++;
    //       }
    //       if(foundCount > 1) {
    //           // remove repeated item from new array
    //           for(var j = 0; j < filteredRecipientsFull.length; j++) {
    //               if(filteredRecipientsFull[j] === currentItem) {                
    //                   filteredRecipientsFull.splice(j, 1);
    //                   j = j - 1;
    //               }
    //           }            
    //       }
    //   }
    //   this.props.recipientHandler(filteredRecipients);
    //   this.setState({recipientFullName:filteredRecipientsFull})
    // }

    createRecipientList=()=>{
            if(this.props.recipients.length === 1){
              let style;
              if(this.props.recipients){
                  return this.props.recipients.map((item,i)=>{
                    style = "profile-image-client-inbox selected_coach";
                     return(                                                  
                        <div id={item.username} tabindex={i} className={style}>
                          <div>                                  
                            <PhotoContainer
                                image={item.profile_image ? item.profile_image : null}
                                username={item ? item.username :  null} 
                            />                             
                          </div>
                          <p>{item.title} {item.first_name}</p>
                        </div>
                     )
                  });
              }              
            }
            else{
              if(this.props.recipients){
                  return this.props.recipients.map((item,i)=>{
                    let fullName = `${item.title} ${item.first_name}`;
                    // if(filteredRecipients.includes(item.username)){
                    if(this.props.recipientFullName.includes(item.username)){
                      style = "profile-image-client-inbox selected_coach";
                    }
                    else{
                      style = "profile-image-client-inbox";
                    }

                     return(                                                  
                        <div id={item.username} tabindex={i} className={style} onClick={(e)=>this.select(item.username, fullName)}>
                          <div>                                  
                            <PhotoContainer
                                image={item.profile_image ? item.profile_image : null}
                                username={item ? item.username :  null} 

                            />                             
                          </div>
                          <p>{item.title} {item.first_name}</p>
                        </div>
                      
                     )
                  });
              }
            }
            let style;
        };

    render(){
        return(
          <div className="container client-account-dash">
        {/*display all coaches or teammates? Who is available to send to? Multiple select?*/}
            <div className="row">
                <div class="col-xs-12">
                  <h4 className="no-margin-top">SELECT A COACH TO MESSAGE</h4>
                </div>            
                <div className="recipient--container col-xs-12">
                  {this.createRecipientList()}
                </div>
            </div>    

            <div className="row">
              <div className="reply-container col-xs-12">          
                <h4 className="reply-label">To: {this.state.singleRecipient ? this.state.singleRecipient : this.displayRecipients()} </h4>                     
              </div>
            </div>

            <div className="row">
              <div className="reply-container col-xs-12">          
                <h4 className="reply-label">New Topic Title</h4>
                <textarea
                       type="text"
                       className='notes-title col-xs-12'
                       onChange={this.titleChange}
                       value={this.state.title}
                       placeholder='Topic Title'
                       />                         
              </div>
            </div>

              <div className="row">
                  <div className="col-xs-12">
                      <Giphy callback={this.gifChange}/>
                  </div>
              </div>

            <div className="row">
              <div className="reply-container col-xs-12">          
                <h4 className="reply-label">Message</h4>
                  <GenericTextArea
                      className='notes col-md-12'
                      value={this.state.reply}
                      plugins ='paste lists'
                      toolbar ='undo redo | bold italic | numlist bullist | alignleft aligncenter alignright'
                      onChangeHandler={this.replyChange}
                      addContent={this.state.gif}
                  />
              </div>
            </div>
              
          </div>
        )

    }
}

function mapStateToProps({message, user}){
    return {message, user}
}

export default withRouter(connect(mapStateToProps)(NewMessage));