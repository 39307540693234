import React from "react";

// styles
import styles from "./renderTopContentVideo.module.css";

// components
import { BoxContainer } from "../../../../containers/boxContainer/boxContainer";
import Loading from "../../../../Loading/Loading";
import { GeneralPagination } from "../../../../pagination/generalPagination/generalPagination";

const RenderTopContentVideo = (props) => {
  const { content, setPage, loading } = props;
  function secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    return `${h > 0 ? h + ":" : ""}${m > 0 ? m : 0}:${s > 9 ? s : "0" + s}`;
  }

  function changePage({ selected }) {
    const page = selected + 1;
    setPage(page);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {content.content.map((piece) => (
            <BoxContainer
              key={piece.id}
              className={`${styles.row} ${styles.listItem}`}
            >
              <div
                className={styles.image}
                style={{ backgroundImage: `url(${piece.thumbnail})` }}
              />
              <div className={`${styles.content}`}>{piece.title}</div>
              <div className={`${styles.duration}`}>
                {secondsToHms(piece.average_duration)}
              </div>
              <div className={`${styles.views}`}>{piece.views}</div>
            </BoxContainer>
          ))}
        </>
      )}
      <div className="pagination-container">
        <GeneralPagination pages={content.pages} onPageChange={changePage} />
      </div>
    </>
  );
};

export default RenderTopContentVideo;
