import React, {useState, useEffect} from 'react';
import Slider from "rc-slider";
import moment from 'moment';
import { connect } from 'react-redux';
import axios from "axios";

// actions
import {getDailyScore, setChangedScoreFlag} from "../../store/actions/wellnessScoreActions";
import {fetchClientTiles} from "../../store/actions/tileActions";
import {checkAlerts} from "../../store/actions/alertActions";

// styles
import styles from './activityLogger.module.css';

// components
import { PrimaryButton } from "../buttons/buttons";
import { ErrorAlert } from "../alerts/alerts";

// icons
import {HabitTileIcon} from "../habitTileIcon/habitTileIcon";

const ActivityLogger = props => {
    // state
    const { user, oldScore } = props;

    // props
    const { tile, plan, close } = props;
    // local
    const [ sliderValue, setSliverValue ] = useState(plan.scale_start);
    const [ selectedDate, setSelectedDate ] = useState(moment().format('YYYY-MM-DD'));
    const [ dateError, setDateError ] = useState('');
    const [disabled, setDisabled] = useState(false);

    function changeValue(val) {
        setSliverValue(val);
    }

    function gradientStyles(){
        const bar = plan.scale_end - plan.scale_start;
        const red = ((plan.minimum_point - plan.scale_start)/bar*100);
        const yellow = ((plan.maximum_point - plan.minimum_point)/bar*100);
        const green = (red+yellow);
        if(plan.status === "disabled"){
            return {
                background: `linear-gradient(to right, #333 0%,#d6d6d6 50%,#333 100%)`,
            };
        }
        else if(plan.inverted){
            return {
                background: `linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #ff3232 ${green}%, #ff3232 100%)`
            }
        }
        else{
            return {
                background: `linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #27dd1a ${green}%, #27dd1a 100%)`
            }
        }
    }

    function handleDate(e) {
        let value = e.target.value;
        if(moment(value).isValid() && moment(value).isBefore(moment().add(1, 'day'), 'day')){
            //clear any error the date message may have had
            setDateError('');
            value = moment(value);

            //make sure text inputs for today have time set to now
            if(value.isSame(moment(), 'day')){
                let milliseconds = moment().millisecond();
                let minutes = moment().minute();
                let seconds = moment().second();
                let hour = moment().hour();
                value.hour(hour).minute(minutes).seconds(seconds).millisecond(milliseconds);
            }

            setSelectedDate(value.format('YYYY-MM-DD'))
        }else{
            //set error message for date picker having wrong type of value
            setDateError('Please enter a valid date before now');
        }
    }

    async function submitActivity(){
        if(dateError){
            //prevent submission due to error in date format
            return;
        }
        // prevent double submission
        if (disabled) return;
        setDisabled(true);

        let value = sliderValue;
        if(value === -0){
            value = Math.abs(value);
        }

        let submission_date = moment(selectedDate).hour(moment().hour()).minute(moment().minute());
        //make sure time is after 2am turn over for previous days,
        //other wise it will get marked for the day previous
        if(submission_date.isBefore(moment(), 'day')){
            submission_date.hour(12);
        }
        submission_date = submission_date.format('YYYY-MM-DDTHH:mm:ss');

        // remomentize date, this time with local timezone
        submission_date = moment(submission_date).format();
        try {
            await axios.post(`/tiles/add-activity/${user.id}`, {
                activityDate:submission_date,
                planId:plan.id,
                value
            });
            setDisabled(false);
            close(oldScore);
        } catch (e) {
            setDisabled(false);
            console.log(e);
        }
    }

    function setModalHeight() {
        const modal = document.getElementById('selectionModal');
        const width = window.innerWidth;
        if (width < 776) {
            const height = window.innerHeight;
            modal.style.height = `${height - 30}px`;
        } else {
            modal.style.height = null;
        }
    }

    useEffect(() => {
        setModalHeight();
        window.addEventListener('resize', setModalHeight);
        return () => window.removeEventListener('resize', setModalHeight);
    }, []);

    useEffect(() => {
        axios.get(`/plan/metric/plan/${plan.id}/${user.username}`)
            .then(res => {
                if(res.data.result){
                    setSliverValue(res.data.result.plan_value);
                }
            });
    }, []);

    const marks = {};
    for(let i=plan.scale_start; i<=plan.scale_end; i+=plan.incrementer){
        marks[i]=i;
    }


    let handleColor = '#F9EB2E';
    if (sliderValue <= plan.minimum_point) handleColor = plan.inverted ? '#23C916' : '#ff0000';
    if (sliderValue >= plan.maximum_point) handleColor = plan.inverted ? '#ff0000' : '#23C916';
    if(plan.status === "disabled") handleColor = '#575757';

    return (
        <div id='selectionModal' className={`${styles.container} tour-log-main`}>
            <div className={styles.exit} onClick={close} data-cy="tile-exit-btn">&times;</div>
            <div className={styles.icon}>
                <HabitTileIcon tile={plan.description} size={200}/>
            </div>
            <p className={styles.group} data-cy="tile-category">{tile.tile.tile_category}</p>
            <h3 className={styles.action}>{plan.description}</h3>
            { dateError ? <ErrorAlert>{dateError}</ErrorAlert>:null }
            <input className={styles.date} type="date" value={selectedDate} onChange={handleDate} data-cy="tile-date"/>
            <div className="tour-log-slider" style={{width: '100%'}} data-cy="tile-log-slider">
                <Slider
                    className={`${styles.slider}`}
                    min={plan.scale_start}
                    max={plan.scale_end}
                    step={plan.incrementer}
                    marks={marks}
                    onChange={changeValue}
                    style={gradientStyles()}
                    value={sliderValue}
                    handleStyle={{backgroundColor: handleColor}}
                />
                <p className={styles.units}>{plan.units}</p>
            </div>
            <div className={styles.button}>
                <PrimaryButton disabled={disabled} className='tour-log-log' onClick={submitActivity} data-cy="log-progress-btn">Log Your Progress</PrimaryButton>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

const actions = {
    fetchClientTiles,
    setChangedScoreFlag,
    getDailyScore,
    checkAlerts,
}

export default connect(mapStateToProps, actions)(ActivityLogger);

