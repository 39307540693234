import {communityApi} from "../../api/communityApi";
import {GET_COMMUNITY_NOTIFICATIONS} from "../storeConstants";

//**************** Actions that involve the store *****************//
// get a list of community notifications
export const fetchCommunityNotifications = () => {
    return async (dispatch) => {
        const {data} = await communityApi().getCommunityNotifications();
        dispatch({type: GET_COMMUNITY_NOTIFICATIONS, payload: data.result});
    }
}

//**************** Actions that don't involve the store *****************//
// get a list of reactions others have made to a members victories
export async function fetchReactionFeed(reactionId, limit, userId) {
    const {data} = await communityApi().getReactions(reactionId, limit, userId);
    return data.result;
}

// get a list of all victories feed
export async function fetchVictoryFeed(orgOnly, victoryId) {
    const {data} = await communityApi().getVictories(orgOnly, victoryId);
    return data.result;
}

// react to a victory
export async function victoryReaction(victoryId, reactionTypeId) {
    const {data} = await communityApi().reactToVictory(victoryId, reactionTypeId);
    return data.result;
}

// get a list of a members victories
export async function fetchMemberVictories(victoryId, userId) {
    const {data} = await communityApi().getMemberVictories(victoryId, userId);
    return data.result;
}

// get victories for another member
export async function fetchOthersVictories(userId, victoryId) {
    const {data} = await communityApi().getOthersVictories(userId, victoryId);
    return data.result;
}

// set reactions as seen by member
export async function setReactionsSeen(reactionIds) {
    const {data} = await communityApi().updateReactionSeen(reactionIds);
    return data.result;
}

// get the reactions a member has made
export async function fetchOwnReactions(reactionId, userId) {
    const {data} = await communityApi().getOwnReactions(reactionId, userId);
    return data.result;
}

// record that a profile was viewed
export async function recordProfileView(userId) {
    const {data} = await communityApi().updateProfileView(userId);
    return data.result;
}

// get the community stats for a member
export async function fetchCommunityStats(userId) {
    const {data} = await communityApi().getCommunityStats(userId);
    return data.result;
}

// get the reactions to a single victory
export async function fetchVictoryReactions(victoryId, reactionId) {
    const {data} = await communityApi().getSingleReactions(victoryId, reactionId);
    return data.result;
}