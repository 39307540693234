import React, {useState, useEffect, useRef} from 'react';

// styles
import styles from './videoList.module.css';

// components
import {INTENSITIES} from "../../../serverVariables/intensities";
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import LoveReaction from "../../community/loveReaction/loveReaction";
import {Link} from "react-router-dom";
import _ from "lodash";
import {setContentFavorite} from "../../../store/actions/contentActions";
import {ReactComponent as WatchedIcon} from '../../../images/icons/Icon_Watched.svg';

const VideoList = props => {
    // props
    const {videos, callback} = props;
    // local
    const last = useRef();
    const loading = useRef(false);
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        window.addEventListener('scroll', _.throttle(onScroll, 500));
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    useEffect(() => {
        setFavorites(videos.filter(video => video.user_favorite).map(item => item.id));
    }, [videos]);

    function addFavorites(videoId) {
        try{  // check if already in array
            if (favorites.find(item => item === videoId)) {
                setFavorites(favorites.filter(item => item !== videoId));
                setContentFavorite(videoId);
            } else {
                // item is not in array, put it in
                setFavorites([...favorites, videoId]);
                setContentFavorite(videoId);
            }
        } catch (e) {
            console.log(e);
        }
    }

    function isElementInViewport (el) {
        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    async function onScroll() {
        if (last.current) {
            const inView = isElementInViewport(last.current);
            if (inView && !loading.current && videos) {
                loading.current = true;
                await callback();
                loading.current = false;
            }
        }
    }

    function setLevel(intensity){
        if (intensity === INTENSITIES.LOW) {
            return (
                <div className={styles.intensity}>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot}`}/>
                    <span className={`${styles.dot}`}/>
                </div>
            )
        }
        if (intensity === INTENSITIES.MODERATE) {
            return (
                <div className={styles.intensity}>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot}`}/>
                </div>
            )
        }
        if (intensity === INTENSITIES.HIGH) {
            return (
                <div className={styles.intensity}>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot} ${styles.active}`}/>
                </div>
            )
        }
    }

    const showNew = (flag) => {
        return (
            <span className={`${flag ? styles.top : ''} ${styles.new}`}>
                    <span className={styles.newDot}/>
                    New
                </span>
        )
    }

    return (
        <div className={styles.container}>
            {videos.map((video, i) => {
                return (
                    <div key={video.id} className={styles.gridItem}>
                        {i === videos.length-1 ? <div ref={last}/> : null}
                        <BoxContainer className={`${styles.video} videoItem`}>
                            <ResponsiveThumbnail url={video.thumbnail}/>
                            <Link className={styles.overlayLink} to={`/member/on-demand/video/${video.id}`}/>
                            {video.new_flag ? showNew(true) : null}
                            <div className={styles.iconContainer}>
                                <LoveReaction
                                    active={favorites.find(item => item === video.id)}
                                    callback={() => addFavorites(video.id)}
                                    color='transparent'
                                    stroke='#ffffff'
                                    animatedColor='#e5466c'
                                    animatedStroke='#transparent'
                                    className={styles.icon}/>
                            </div>
                            <div className={styles.info}>
                                <p className={styles.titleCore}>{video.format_length} Min. {video.classType.toLowerCase() === 'sprint 8' ? <>{video.classType}<span className={styles.registered}>&reg;</span></>: video.classType}</p>
                                <p className={styles.titleSub}>
                                    <span className={styles.instructor}>{video.instructor}</span>
                                    <span>Level &nbsp; {setLevel(video.intensity)}</span></p>
                                <p className={styles.titleSub}>
                                    {video.theme ? <span className={styles.theme}>{video.theme}</span> : null}
                                    Ep. {video.episode}</p>
                                {video.user_previously_viewed ? <WatchedIcon className={styles.watched}/> : null}
                            </div>
                        </BoxContainer>
                    </div>
                )
            })}
        </div>
    )
}
export default VideoList;

function ResponsiveThumbnail({url}){
    // local
    const [height, setHeight] = useState(0);
    const imageRef = useRef(null);

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    function onResize() {
        if (!imageRef.current) return;
        const width = imageRef.current.clientWidth;
        if (width > 600) {
            setHeight(width*3/4);
        } else {
            setHeight(width*5/9);
        }
    }
    return (
        <div
            ref={imageRef}
            className={styles.thumbnail}
            style={{backgroundImage: `url(${url})`, height: `${height}px`}}/>
    )
}