import React, {Component} from 'react';
import ClientHeader from "../../clientHeader/clientHeader";
import TeammatesContainer from './TeammatesContainer';
import AddTeammateFormContainer from './AddTeammateFormContainer';
import axios from 'axios';
import {Route} from 'react-router-dom';
import {fetchSelf} from "../../../actions/action_user";
import {connect} from 'react-redux';

class TeammatesMain extends Component{
    constructor(){
        super();
        this.state = {
          wellnessScore:'', 
        }
    }

    componentDidMount(){
        this.props.fetchSelf();
        axios.get('/score/wellness-score')
              .then(
                  res=>{                   
                      this.setState({
                          wellnessScore:res.data.results.score
                      });
                  }
              )
              .catch();
    }

    moveToAddTeammate(){
      this.props.history.push({
          pathname: `/member/clientDash/${this.props.user.username}/Teammates/Add`
      })
    }

    render(){
      if(this.props.user){
          return(
              <div className="client-container">
                  <ClientHeader backUrl={`/member/clientDash/${this.props.user.username}`}/>
                  <Route exact path={`/member/clientDash/${this.props.user.username}/Teammates/`} render={()=><TeammatesContainer {...this.props}/>}/>
                  <Route exact path={`/member/clientDash/${this.props.user.username}/Teammates/Add`} render={()=><AddTeammateFormContainer {...this.props}/>}/>
              </div>
          )
      }else{
          return <div> </div>
      }
    }
}


function mapStateToProps({user}){
    return{user}
}

export default connect(mapStateToProps, {fetchSelf})(TeammatesMain);
