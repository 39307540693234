import React from 'react';

import ProfileImage from './profileImage';

const  PhotoContainer = ({image}) =>{
    return(
        <div className='teammate-profile-image-container'>
            <ProfileImage image={image}/>
        </div>
    )
};

export default PhotoContainer;