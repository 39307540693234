import React from 'react';

// styles
import styles from './reactionAverages.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";
import {BoxContainer} from "../../../containers/boxContainer/boxContainer";
import LikeReaction from "../../../community/likeReaction/likeReaction";
import {REACTION_TYPES} from "../../../../serverVariables/reactionTypes";
import WowReaction from "../../../community/wowReaction/wowReaction";
import EnvyReaction from "../../../community/envyReaction/envyReaction";
import LoveReaction from "../../../community/loveReaction/loveReaction";

const ReactionAverages = props => {
    const {data} = props;

    if (!data) {
        return (
            <BoxContainer className={styles.container}>
                <GraphPlaceholder/>
            </BoxContainer>
        )
    }
    console.log(data);
    let likes, loves, wows, envies;
    const reactions = data.reactions;
    if (reactions) {
        likes = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.LIKE);
        if (likes) likes = likes.reaction_count;
        else likes = 0;

        wows = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.WOW);
        if (wows) wows = wows.reaction_count;
        else wows = 0;

        envies = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.ENVY);
        if (envies) envies = envies.reaction_count;
        else envies = 0;

        loves = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.LOVE);
        if (loves) loves = loves.reaction_count;
        else loves = 0;

    }
    return (
        <BoxContainer className={styles.container}>
            <h4 className="m:0 margin-bottom-15">Reaction Averages</h4>

            <div className='margin-bottom-15'><strong>Total Reactions:</strong>&nbsp; {data.total_reactions}</div>
            <div className={styles.reactions}>
                <div>
                    <LikeReaction
                        disabled
                        className={styles.icon}
                        color='transparent'
                        stroke='#354052'
                        animatedColor='#6bd0f3'
                        animatedStroke='#e8ecf0'/>
                    <span>{likes}</span>
                </div>
                <div>
                    <WowReaction
                        disabled
                        className={styles.icon}
                        color='transparent'
                        stroke='#354052'
                        animatedColor='#f7cb5d'
                        animatedStroke='#c78430'/>
                    <span>{wows}</span>
                </div>
                <div>
                    <EnvyReaction
                        disabled
                        className={styles.icon}
                        color='transparent'
                        stroke='#354052'
                        animatedColor='#adcb54'
                        animatedStroke='#3f4e3d'/>
                    <span>{envies}</span>
                </div>
                <div>
                    <LoveReaction
                        disabled
                        className={styles.icon}
                        color='transparent'
                        stroke='#354052'
                        animatedColor='#e5466c'
                        animatedStroke='transparent'/>
                    <span>{loves}</span>
                </div>
            </div>
            <div className={styles.lowerStats}>
                <div className={styles.stat}>
                    <div className={styles.number}>{data.reactions_per_all_members}</div>
                    <span>Avg reactions per all members</span>
                </div>
                <div className={styles.stat}>
                    <div className={styles.number}>{data.reactions_per_reaactive_members}</div>
                    <span>Avg reaction per members who reacted</span>
                </div>
                <div className={styles.stat}>
                    <div className={styles.number}>{data.average_victory_age}</div>
                    <span>Avg. victory age</span>
                </div>
            </div>
        </BoxContainer>
    )
}

export default ReactionAverages;