import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios/index";
import { Modal, Button } from "react-bootstrap";
import AddTeammate from "./AddTeammate";
import moment from "moment";
import TeammatesList from "./teammatesList";

class Teammates extends Component {
  constructor() {
    super();
    this.state = {
      teammateId: "",
      nickname: "",
      teammateSince: "",
      teammateList: [],
      show: false,
      show2: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleShow2 = this.handleShow2.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClose2 = this.handleClose2.bind(this);
    this.updateList = this.updateList.bind(this);
  }

  componentDidMount() {
    axios
      .get(`/teammates/?username=${this.props.username}`)
      .then((res) => {
        this.setState({ teammateList: res.data.results.teammates });
      })
      .catch((error) => {});
  }

  createList = () => {
    if (this.state.teammateList) {
      return this.state.teammateList.map((item, i) => {
        let date = moment(item.created_at).format("MM/DD/YY");
        return (
          <TeammatesList
            key={item.nickname}
            status={item.status}
            email={item.email}
            firstName={item.first_name}
            lastName={item.last_name}
            nickname={item.nickname}
            profileImage={item.profile_picture}
            date={date}
            teammateId={item.id}
            updateList={this.updateList}
            handleClose={this.handleClose}
          />
        );
      });
    }
  };

  handleClose() {
    this.setState({ show: false });
  }

  handleClose2() {
    this.setState({ show2: false });
  }

  showChangeHandler = (show) => {
    this.setState({ show: show });
  };

  handleShow() {
    this.setState({ show: true });
  }

  handleShow2() {
    this.setState({ show2: true });
  }

  updateList() {
    this.setState({ show: false });
    axios
      .get(`/teammates/?username=${this.props.username}`)
      .then((res) => {
        this.setState({ teammateList: res.data.results.teammates });
      })
      .catch((error) => {});
  }

  render() {
    return (
      <div className="container margin-top">
        <div className="row">
          <div className="col-sm-12">
            <h2>Teammates</h2>
            <p>Family, friends, and co-workers supporting member</p>
          </div>
        </div>

        <div className="row">
          <div className="support-team-container">
            {this.createList()}

            <div
              className="support-member add-new-support"
              onClick={() => this.setState({ show: true })}
            >
              <div className="support-img"></div>
              <div className="support-info text-center">
                Add New Teammate
                <div className="support-role">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Teammate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddTeammate
              username={this.props.username}
              handleClose={this.handleClose}
              updateList={this.updateList}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Teammates);
