//client search view
import React from 'react';
import { VictoryLine, VictoryChart, VictoryAxis, VictoryScatter } from 'victory';
import moment from 'moment';



class SparkLine extends React.Component {
    constructor(){
        super();
        this.state= {
        }
      this.handleData = this.handleData.bind(this);
      this.renderGraph = this.renderGraph.bind(this);
    }

  formatData(){
    
    let list = this.props.data.reverse();
    return this.handleData(list)
  }

  
    handleData(list){
    if(list && list.length > 0){

      let date_tracker = moment();
      let range = moment().subtract(8, 'days');

      for(let i=0; i<=list.length; i++){
        if(i === list.length && date_tracker.isSame(range, "day")){
            break;
        }else if(i === list.length){
            //handle data if element at this array index was empty
            let datapoint = {x:moment(date_tracker).format('LLL'), y:0};
            //place new data at loop index
            list.splice(i, 0, datapoint);
        }else{
            if(moment(list[i].x, 'YYYY-MM-DD').isBefore(date_tracker, 'day')){
                //handle data if element at this array index was empty
                let datapoint = {x:moment(date_tracker).format('LLL'), y:0};
                //place new data at loop index
                list.splice(i, 0, datapoint);
            }
        }

          //increment date_tracker
            if(moment(date_tracker).isAfter(range, 'day')){
              date_tracker = moment(date_tracker).subtract(1, 'days');
            }else if(moment(date_tracker).isSame(range, 'day')){
                break;
            }else if(moment(date_tracker).isBefore(range, 'day')){
                break;
            }
      }
      list = list.slice(0, 8);
      return this.renderGraph(list);
    }

  }

  renderGraph(list){
    let newArray = [];
    list.map(item=>{
      newArray.push(item.y);
    })
    let minY = Math.min(...newArray) - 20;
    let maxY = Math.max(...newArray) + 10;
      
    let newData = [];
        list.map((item, i)=>{
           if(i === 0){
            newData.push({x:item.x, y:item.y, symbol: "circle", size: 5})
           }
           else{
            newData.push(item);
           }
        })
    let styleData = newData.reverse();
    let axisStyles;
    let opacityStyles;
    this.props.clientSearchParent
      ? axisStyles = "white"
      : axisStyles = "grey"
    
    this.props.clientSearchParent
      ? opacityStyles = .0
      : opacityStyles = .9
    
        return(
            <VictoryChart
               height={55}
               domain={{ y: [maxY, minY] }}
            >
              <VictoryAxis
              height={10}
              offsetY={0}
              standalone={false}
              style={{
                axis: {stroke: axisStyles, opacity:.25},
 
                tickLabels: {fontSize: 0, stroke:axisStyles}
              }}
              />
              <VictoryAxis 
              dependentAxis 
              height={10}
              standalone={false}
              style={{
                axis: {stroke: "transparent"},
                tickLabels: {fontSize: 10, padding: 0, margin:10, stroke:axisStyles, opacity:opacityStyles}
              }}

              tickFormat={(t) => `${Math.round(t)}`}

              />
              <VictoryLine
                style={{
                  data: { stroke: "#4bbc4e" },
                  parent: { border: "none"},
                  border: "none",
                }}
                data={styleData}
                interpolation="natural"
              />
              <VictoryScatter 
                style={{ data: { fill: "#4bbc4e" } }}
                data={styleData}
                size={0}
              />
            </VictoryChart>
        );
  }

  renderData(){
    if(this.props.noData){
      return(<h4>No Activity</h4>)
    }
    else if(this.props.noRecentData){
      return(<h4>No activity in last 7 days</h4>) 
    }
    else{
      return(
        this.props.data ? this.formatData() : null
        )
    }
  }

    render() {
      
        return(
          <div className="sparkline-container">
            {this.renderData()}
          </div>
        );
    }
};

export default (SparkLine);


