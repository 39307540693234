import {
    SET_CLIENT_PATHWAY,
    SET_CLIENT_PATHWAY_LIST,
    RESET_PATHWAY,
} from "../storeConstants";

// clientPathway
export const clientPathwayReducer = (state={}, action) => {
    switch (action.type) {
        // set client pathways single object
        case SET_CLIENT_PATHWAY:
            return action.payload || {};
        // reset the client pathways object to an empty object
        case RESET_PATHWAY:
            return action.payload;
        default:
            return state;
    }
};

// clientPathwayList
export const clientPathwayListReducer = (state=[], action) => {
    switch(action.type) {
        case SET_CLIENT_PATHWAY_LIST:
            return action.payload || [];
        default:
            return state;
    }
};