import React from 'react';

import styles from './styles.module.css';

export const ButtonList = ({ className = '', list, ...props }) => (
    <ul className={`${styles.buttonList} ${className}`} {...props}>
        { list.map(item => {
            return (
                <li
                    key={item.id}
                    disabled={item.disabled}
                    className={`${item.active ? styles.active : ''} ${item.disabled ? styles.disabled : ''} ${item.className}`}
                    role='button'
                    tabIndex={0}
                    onClick={() => item.onClick ? item.onClick(item) : null}>
                    {item.text}
                </li>
            )
        })}
    </ul>
);

export const DynamicList = ({ className = '', list, update, ...props }) => {
    // eslint-disable-next-line 
    const remove = (item) => {
        const newList = list.filter(listItem => listItem.id !== item.id);
        update(newList);
    };
// eslint-disable-next-line no-unused-vars
    const add = () => update([...list, { id: Date.now(), value:'', }])

    return (
        <ul className={`${styles.buttonList} ${className}`} {...props}>
            { list.map(item => {
                return (
                    <li
                        key={item.id}
                        className={item.className}
                        tabIndex={0}
                        onClick={() => item.onClick ? item.onClick(item) : null}>
                        {item.text}
                    </li>
                )
            })}
        </ul>
    )
};