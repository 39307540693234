import React, { useState, useEffect, useCallback, useRef, } from 'react';
import randomstring from 'randomstring';

import style from './styles.module.css';

export const BasicButton = ({ className = '', children, ...props }) => (
    <button type={props.type || 'button'} tabIndex={0} className={`${style.button} ${className}`} {...props}>
        {children}
    </button>
);

export const PrimaryButton = ({ className = '', children, ...props }) => (
    <button type={props.type || 'button'} tabIndex={0} className={`${style.button} ${style.primary} ${className}`} disabled={props.disabled} {...props}>
        {children}
    </button>
);

export const PrimaryButtonSm = ({ className = '', children, ...props }) => (
    <button type={props.type || 'button'} tabIndex={0} className={`${style.button} ${style.primarySm} ${className}`} {...props}>
        {children}
    </button>
);

export const SecondaryButton = ({ className = '', children, ...props }) => (
    <button type={props.type || 'button'} tabIndex={0} className={`${style.button} ${style.secondary} ${className}`} {...props}>
        {children}
    </button>
);

export const DangerButton = ({ className='', children, ...props }) => {
    return (
        <button type={props.type || 'button'} tabIndex={0} className={`${style.button} ${style.danger} ${className}`} {...props}>
            {children}
        </button>
    )
}

export const InlineDangerButton = ({ className='', children, ...props }) => {
    return (
        <button type={props.type || 'button'} tabIndex={0} className={`${style.button} ${style.danger} ${className}`} {...props}>
            {children}
        </button>
    )
}

// options should be format [ { label: x, value: y, onClick: func }, ...]
export const ToggleButtons = ({
      className='',
      options=[],
      activeColor,
      inactiveColor,
      disabled=false,
      startOption }) => {
    const [ id ] = useState(randomstring.generate({ length: 5, charset: 'alphabetic' }));
    const active = useRef(null);
    const transition = useCallback((button) => {
        if (!button) button = active.current;
        let translate = 0;
        let width = 0;
        const els = options.map(option => {
            const el = document.getElementById(`id_${option.value}`);
            if (el) el.style.color = inactiveColor || '#354052';
            return el;
        });
        // determine how many buttons from the left the click was
        for(let el of els){
            if (el) {
                if (el.id === button.id){
                    width = el.parentNode.clientWidth;
                    el.style.color = activeColor || '#fff';
                    break;
                }
                translate += el.parentNode.clientWidth;
            }
        }

        // apply styles
        const background = document.getElementById(`${id}_toggleBackground`);
        if (background) {
            background.style.width = `${width}px`;
            background.style.transform = `translateX(${translate}px)`;
        }
    }, []);

    useEffect(() => {
        if (options.length) {
            let i = 0;
            if (startOption && startOption <= options.length) i = startOption-1;
            const el = document.getElementById(`id_${options[i].value}`);
            active.current = el;
            transition(el);
        }
    }, [transition]);

    useEffect(() => {
        window.addEventListener('resize', () => transition());
        return () => {
            window.removeEventListener('resize', transition);
        }
    }, []);
    return (
        <div className={`${style.toggle} ${className}`}>
            <div className={`${style.container} toggleContainer`}>
                <span id={`${id}_toggleBackground`} className={`${style.background} toggleButton`}/>
                {options.map(option => {
                    return (
                        <span key={option.value} className={`${style.buttons}`}>
                            <label
                                className={style.toggleText}
                                id={`id_${option.value}`}
                               onClick={e=>{
                                    if (disabled) return;
                                    if (option.onClick) option.onClick(option.value);
                                    active.current = e.target;
                                    transition(e.target);
                                }}
                               htmlFor={`_${option.value}`}>{option.label}</label>
                            <input id={`_${option.value}`} type="radio" value={option.value}/>
                        </span>
                    )
                })}
            </div>
        </div>
    )
}
