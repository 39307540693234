import mainApi from "./mainApi";

export function exportApi() {
    return {
        // get a users clients in csv data
        getClientExport: function(username) {
            return mainApi.get(`/export?username=${username}`)
        },
        // get a 14 day report of client data as an excel workbook
        getBiweeklyExport: function(userId) {
            return mainApi.get(`/export/bi-weekly?coach_id=${userId}`, { responseType: 'arraybuffer' });
        }
    }
}