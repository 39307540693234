// npm modules
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// components
import ClientHeader from "../clientHeader/clientHeader";
import axios from "axios";
import Calendar from "../CalendarView/CalendarContainer";
import { STATUSES } from "../../serverVariables/statuses";


const CoachScheduleContainer = ({ user, ...props }) => {
    const [coach, setCoach] = useState(null);

    const selectCoach = async () => {
        const coach = await axios.get(`/user/coaching-team/`)
            .catch(e => {
                console.log(e)
            });
        let username = coach.data.result[0].username;
        setCoach(username);
    };

    // check for user status
    useEffect(() => {
        if (user && user.user_status_id >= STATUSES.active_coached) {
            // user is not in onboarding
            props.history.push(`/member/clientDash/${user.username}`);
        }
    });

    // setup on mount
    useEffect(() => {
        selectCoach();
    }, []);

    return (
        <div className='client-container'>
            <ClientHeader/>
            { coach && user ?
                <Calendar
                    project={'330759c3-1bcc-4d1e-a1a4-3d1a51749f47'}
                    coach={coach}/>
                : null
            }
        </div>
    )
};

const mapStateToProps = ({ user }) => {
    return {
        user,
    }
};

export default connect(mapStateToProps, null)(CoachScheduleContainer);