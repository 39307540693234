import React, {Component} from 'react';
import './Notes.css'

export class Notes extends Component{
   constructor(props){
       super(props);
           this.state = {
               user : {},
               noteTitle: '',
               notes: ''
       };
   }

   noteTitleChange = (e) => {
    this.props.noteTitleHandler(e.target.value);
    this.setState({noteTitle: e.target.value});
  };

   notesChange = (e) => {
    this.props.notesHandler(e.target.value);
    this.setState({notes: e.target.value});
  };

    render(){
        return(
            <div className="notes-container col-md-6 col-xs-12">
                <div>
                    <label className="notes-title-label">Note Title</label>
                    <input type="text"
                           className='notes-title col-md-12'
                           placeholder='Starting Protein'
                           onChange={this.noteTitleChange}
                           value={this.state.noteTitle}
                     />
                </div>            
                <div>
                    <label className="notes-label">Notes</label>
                    <textarea type="text"
                           className='notes col-md-12'
                           placeholder='We are going to ignore the goal bar to start. 
                           I do not think Firstname is up to eating 18 servings of
                           baked chicken yet. Do not want to discourage them!'
                           onChange={this.notesChange}
                           value={this.state.notes}
                           />                            
                </div>
                <div>
                    <input id="checkBox" type="checkbox" /><span>Flag for review/feedback from Team Lead (Karlie)</span>
                </div>
                <div>
                    <button>Post Note</button>
                </div>
            </div>

        )

    }
}
