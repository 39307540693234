import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {Notes} from '../CreateTile/Notes'
import NotesHeader from './NotesHeader';
import PhotoContainer from '../../profilePhotos/container';


class NoteSingle extends Component{
    constructor(props){
        super(props);
        this.state = {
          id: null,
          newNote: false,
          notesSingleChild: true,
          noteTitle: '',
          notes: '',
          author: '',

        }
    }

    componentDidMount() {
      this.newNoteActive()
      this.setState({id:this.props.id})
    }

    pictureURL(user){
      let url_path;
      if(user){
          if(user.permission_id === 2){
            url_path = `coach/${user.username}`
          }
          else if(user.permission_id === 3){
            url_path = `admin/${user.username}`
          }
          else{
            url_path = `client/${user.username}`
          }
      }
      return(
        <PhotoContainer
          image={user ? user.profile_image : null}
          url_path={url_path}
          username={user ? user.username : null}
        /> 
        )
    }

    firstPictureURL(user){
      let url_path;
      if(user){
          if(user.permission_id === 2){
            url_path = `coach/${user.username}`
          }
          else if(user.permission_id === 3){
            url_path = `admin/${user.username}`
          }
          else{
            url_path = `client/${user.username}`
          }
      }
      return(
        <PhotoContainer
          image={user ? user.profile_image : null}
          url_path={url_path}
          username={user ? user.username : null}
        /> 
        )
    }

    firstNote() {
      let createdAtLast = moment.parseZone(this.props.lastNoteTime).local().format("MM/DD/YY h:mm A");
      let createdAtFirst = moment.parseZone(this.props.firstNoteTime).local().format("MM/DD/YY h:mm A");
      let createdAt = moment.parseZone(this.props.created_at).local().format("MM/DD/YY h:mm A");
      
      if(this.props.noteListActive === true){
        return(
          <div>
          {this.props.singleTileChild === true
            ? <div className="row">
                <div className="col-md-3">
                  <div className="profile-image">                                  
                      {this.pictureURL(this.props.user)}                                                                     
                  </div>
                </div>     
                <div className="col-md-9">
                    <NotesHeader
                      author={this.props.author} time={this.props.created_at ? createdAt : null}
                     />
                </div>
                <div className="col-md-12" style={{paddingTop:'15px'}}>
                  <p>{this.props.content}</p>
                </div>
              </div>
            : <div className="row">
                <div className="col-md-3">
                  <div className="profile-image">                                  
                    {this.pictureURL(this.props.user)}                                                                       
                  </div>
                </div>     
                <div className="col-md-9">
                    <NotesHeader
                      author={this.props.author} time={this.props.created_at ? createdAt : null}
                     />
                  <p>{this.props.content}</p>
                </div>
              </div>
            }
              </div>
          )
      }
     else if(this.state.newNoteActive === true){
      return(
          <div className="row">
            <div className="col-md-3">
              <div className="profile-image">                                  
                  <img src="http://via.placeholder.com/300x300" alt="profile"/>                                  
              </div>
            </div>     
            <div className="col-md-9">
                <NotesHeader
                  author={this.props.author} time={this.props.lastNoteTime ? createdAtLast : null}
                 />
              <p>{this.props.lastNoteContent}</p>
            </div>
          </div>
        )
      }     
      else{
        return(
          <div>
          {this.props.singleTileChild === true
            ? <div className="row">
                <div className="col-md-3">
                  <div className="profile-image">                                  
                    {this.pictureURL(this.props.user)}                                                                      
                  </div>
                </div>     
                <div className="col-md-9">
                    <NotesHeader
                      author={this.props.firstNoteAuthor} time={this.props.firstNoteTime ? createdAtFirst : null}
                     />
                </div>
                <div className="col-md-12" style={{paddingTop:'15px'}}>
                  <p>{this.props.firstNoteContent}</p>
                </div>
              </div>
            : <div className="row">
                <div className="col-md-3">
                  <div className="profile-image">                                  
                    {this.firstPictureURL(this.props.firstNoteProfileImage)}                                                                      
                  </div>
                </div>     
                <div className="col-md-9">
                    <NotesHeader
                      author={this.props.firstNoteAuthor} time={this.props.firstNoteTime ? createdAtFirst : null}
                     />
                  <p>{this.props.firstNoteContent}</p>
                </div>
              </div>
            }
              </div>
          )
      }
        
    }

    newNoteActive = (newNoteActiveValue) => {

      this.setState({
        newNoteActive:newNoteActiveValue
      })
      this.props.newNoteActive(newNoteActiveValue);

    }

     noteTitleHandler = (noteTitleValue) => {
        this.setState({
          noteTitle: noteTitleValue
        })
      };

     notesHandler = (notesValue) => {
        this.setState({
          notes: notesValue
        })
      };

     refreshNotes = (value) => {
        this.props.refreshNotes(value);
      };

    render(){

      return(
        <div className="container">
            { this.props.newNote
              ? <Notes 
                singleTileChild={this.props.singleTileChild}
                notesSingleChild={this.state.notesSingleChild}
                username={this.props.username}
                noteTitleHandler={this.noteTitleHandler}
                notesHandler={this.notesHandler}
                refreshNotes={this.refreshNotes}
                notes={this.state.notes}
                noteTitle={this.state.noteTitle}
                newNoteActive={this.newNoteActive}
                />
              : this.firstNote()
            }
        </div>

      )
    }
}

export default withRouter(NoteSingle);