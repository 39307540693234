import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios";
import {ImageUpload} from "../Account/imageUpload/imageUploadContainer";
import { Alert } from 'react-bootstrap';

let data = new FormData();

class AddTeammate extends Component{
    constructor(){
        super();
        this.state = {
          nickname:'',
          email:'',
          firstName:'',
          lastName:'',
          profile_image:'',
          show: false,
          alertStyle: true,
          alert: '',
          imgFile:null,
        }
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.setProfileImage = this.setProfileImage.bind(this);
    }

    handleDismiss() {
      this.setState({ show: false });
    }

    handleShow() {
      this.setState({ show: true });
    }

    setProfileImage(file){
      this.setState({imgFile:file})
      data = new FormData();
      data.append('image', file);
    };

    errorHandler = (error)=>{
        this.setState({error:error});
        this.setState({ show: true });
        this.setState({alert:error});
        this.setState({ alertStyle: false });
    };

    clickImg = (confirmation) => {
        if(!confirmation){
          this.setState({imgUploaded:false})
        }
        if(confirmation){
          this.setState({imgUploaded:true})
        }
    }
  
  readURI(e){
    if(e.target.files && e.target.files[0]){
      let reader = new FileReader();
      reader.onload = function(ev){
        this.setState({imageURI:ev.target.result});
      }.bind(this);
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  
  handleChange(e){
    this.readURI(e); 
    if (this.props.onChange !== undefined)
      this.props.onChange(e); 
  }

  removeImage(e){
    this.setState({imageURI:null})
    data = new FormData();
    this.setProfileImage(this.state.imageURI); 
  }

    sendInvite(e){
      e.preventDefault()
      this.setState({show:false})
      data.append('username', this.props.username);
      data.append('email', this.state.email);
      data.append('nickname', this.state.nickname);
      data.append('first_name', this.state.firstName);
      data.append('last_name', this.state.lastName);
      axios.post(`/teammates`, data)
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.props.updateList();
            }})
        .catch((error)=>{
          let alert = "error";
          if(error.response.data.err.teammate_email){
            alert = error.response.data.err.teammate_email[0]
          }
          else{
            alert = error.response.data.err
          }
          this.setState({show: true, alert: alert, alertStyle: false})
          data = new FormData();
          this.setProfileImage(this.state.imgFile)
        });
    }

    render(){
      let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
      return(
          <form onSubmit={(e)=>this.sendInvite(e)}>          
                  <div className="row margin-top">
                      <div className="row container">
                        <div className="col-md-1 col-xs-1">
                        <div className="profile-img-container" onChange={this.handleChange.bind(this)}>
                            <ImageUpload
                             image={this.state.imageURI} 
                             setProfileImage={this.setProfileImage}
                             errorHandler={this.errorHandler}
                             clickImg={this.clickImg}
                             />
                        </div>
                          <div>
                            <p onClick={this.removeImage.bind(this)}>x Remove Image</p>
                          </div>
                        </div> 
                      </div>
                      <div className="container">
                            <div className="row">
                              <div className='form-group col-md-6'>
                                  <label>NICKNAME</label>       
                                  <input name="nickname" type="text"
                                  value={this.state.nickname}
                                  onChange={(event)=>this.setState({nickname:event.target.value})}
                                  required
                                   />
                              </div>
                            </div>
                            <div className="row">
                              <div className='form-group col-md-6'>
                                  <label>FIRST NAME</label>       
                                  <input name="firstname" type="text"
                                  value={this.state.firstName}
                                  onChange={(event)=>this.setState({firstName:event.target.value})}
                                  required
                                   />
                              </div>
                            </div>
                            <div className="row">
                              <div className='form-group col-md-6'>
                                  <label>LAST NAME</label>       
                                  <input name="lastname" type="text"
                                  value={this.state.lastName}
                                  onChange={(event)=>this.setState({lastName:event.target.value})}
                                  required
                                   />
                              </div>
                            </div>
                          <div className="row">
                              <div className='form-group col-md-6'>
                                  <label>EMAIL ADDRESS</label>   
                                  <input 
                                  type="email" 
                                  value={this.state.email}
                                  onChange={(event)=>this.setState({email:event.target.value})} 
                                  required
                                  />
                              </div>
                          </div>
                      </div>
                  </div>                          
                      <button className="btn primary add--icon" type="submit">SEND INVITE</button>
                        {this.state.show
                          ?  <Alert className="col-md-4 save-profile-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                                <p>{this.state.alert}</p>
                              </Alert>
                          : null
                        }
          </form>
                  
      )
    }
}

export default withRouter(AddTeammate);