import React, {useState} from 'react';

// styles
import styles from './section_3.module.css';

// components
import FitnessImages from '../../../images/fitness.jpg';
import Jumprope from '../../../images/jump_rope.jpg';
import {PrimaryButton} from "../../buttons/buttons";

const Section_3_Intro = props => {
    // props
    const {next} = props;
    // local
    const [ready, setReady] = useState(0);
    return (
        <div className={`${ready === 2 ? styles.fadeIn : ''} ${styles.container}`}>
            <h2 className={styles.heading}><strong>MOVEMENT</strong> IS ESSENTIAL</h2>
            <img onLoad={() => setReady(prevState => prevState+1)} className={styles.image} src={FitnessImages} alt="" data-cy="actImg"/>
            <p className={styles.text}>Movement can help you maintain muscle and boost your mood. Let’s learn more about your current physical activities. </p>
            <img onLoad={() => setReady(prevState => prevState+1)} className={styles.image2} src={Jumprope} alt="" data-cy="imgRope"/>
            <div className="t-a:c">
                <PrimaryButton onClick={next} data-cy="actBtn">Next</PrimaryButton>
            </div>
        </div>
    )
}
export default Section_3_Intro;