import React from 'react';
import profileImage from '../../../images/avatar-1577909_960_720.png';

const ProfileImage = ({image}) =>{

    return(
            <div className="teammate-profile-image">

                    <img className="responsive-image" src={image ? image : profileImage} alt="Profile"/>

            </div>
    )
};

export default ProfileImage;