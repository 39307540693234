import React from 'react';
import {WellnessScoreScale} from './wellnessScoreScale';
import ProfileImage from './profileImage';

const  PhotoContainer = ({score, image, username, url_path}) =>{
    return(
        <div className='victory-container'>
            <ProfileImage image={image} url_path={url_path}/>
            <WellnessScoreScale score = {score}/>
        </div>
    )
};

export default PhotoContainer;