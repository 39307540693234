import React from 'react';
import {Route, withRouter} from 'react-router-dom';

//client imports
import AddTileActivityStep1 from '../addActivityStep1/AddTileActivityStep1';
import AddTileActivityStep2 from '../addActivityStep2/AddTileActivityStep2';

const AddTileActivity = ({match}) =>{
    return(
      <div>
          <Route
              exact
              path={match.url +'/tile/:tileId'}
              render={()=><AddTileActivityStep1 match={match}/>}
          />
          <Route
              exact
              path={match.url +'/tile/:tileId/plan/:planId'}
              render={()=><AddTileActivityStep2 match={match}/>}
          />
      </div>
    );
};

export default withRouter(AddTileActivity);