import {SET_USERNAME_SEARCH, CLEAR_USERNAME_SEARCH} from "../actions/action_user";

export  function userSearchReducer(state = null, action){
    switch (action.type){
        case SET_USERNAME_SEARCH:
            return action.payload;
        case CLEAR_USERNAME_SEARCH:
            return action.payload;
        // no default
    }
    return state;
}