import React, {useState, useEffect} from 'react';
import {Modal} from "react-bootstrap";
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

// styles
import styles from './instructors.module.css';

// actions
import {
    fetchInstructors,
    newInstructors,
    removeContentMeta,
    updateContentMeta
} from "../../../store/actions/contentActions";

// components
import {PrimaryButton, PrimaryButtonSm} from "../../buttons/buttons";
import {ReactComponent as TimesIcon} from '../../../images/times-solid.svg';
import {ReactComponent as EditIcon} from '../../../images/icons/edit.svg';
import {BoxContainer} from "../../containers/boxContainer/boxContainer";

const Instructors = props => {
    // local
    const [modal, setShowModal] = useState(false);
    const [edit, setEdit] = useState({id: '', name: '', text: '', state: 'New'});
    const [instructors, setInstructors] = useState([]);
    const [confirm, setConfirm] = useState(null);

    useEffect(() => {
        loadInstructors();
    }, []);

    async function loadInstructors() {
        try {
            const res = await fetchInstructors();
            setInstructors(res);
        } catch (e) {
            console.log(e);
        }
    }

    function showModal(id, val, text) {
        if (val) setEdit({id: id, name: val, text, state: 'Update'});
        setShowModal(true);
        setConfirm(null);
    }

    function closeModal() {
        setEdit({id: '', name: '', state: 'New'});
        setShowModal(false);
    }

    async function remove(id) {
        if (id !== confirm) {
            setConfirm(id);
            return;
        }
        try {
            await removeContentMeta(id);
            await loadInstructors();
        } catch(e) {
            console.log(e);
        }
        setConfirm(null);
        loadInstructors();
    }

    async function add() {
        try {
            await newInstructors(edit.name, edit.text);
            loadInstructors();
            closeModal();
        } catch (e) {
            console.log(e);
        }
    }

    async function update() {
        try {
            await updateContentMeta(edit.id, edit.name, edit.text);
            loadInstructors();
            closeModal();
        } catch (e) {
            console.log(e);
        }
    }

    function submitUpdate(e) {
        e.preventDefault();
        if (edit.state === 'New') {
            add();
        } else {
            update();
        }
    }
    console.log(instructors);
    return (
        <div>
            <div className="page-header-container searchForm-container">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav aria-label="breadcrumb ">
                                <ol className="breadcrumb ">
                                    <li className="breadcrumb-item underline ">
                                        <a href="/dashboard" className="text-light">
                                            Dashboard
                                        </a>
                                    </li>
                                    <span className="arrow"></span>
                                    <li className="breadcrumb-item underline ">
                                        <a href="/dashboard/Content" className="text-light">
                                            Content
                                        </a>
                                    </li>
                                    <span className="arrow"></span>
                                    <li
                                        className="breadcrumb-item text-light active"
                                        aria-current="page"
                                    >
                                        Instructors
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <h1>Instructors</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`container margin-top-25 ${styles.header}`}>
                <div></div>
                <PrimaryButton
                    className={styles.new}
                    onClick={()=>showModal()}>+ New</PrimaryButton>
            </div>

            <div className={'container'}>
                <div className={`${styles.row} ${styles.head}`}>
                    <div className={styles.name}>
                        Name
                    </div>
                    <div className={styles.classes}>
                        Classes
                    </div>
                    <div className={styles.updated}>
                        Updated
                    </div>
                    <div className={styles.buttons}/>
                </div>

                <div>
                    {instructors.map(instructor => {
                        return (
                            <div key={instructor.content_meta_option_id}>
                                <BoxContainer className={styles.row}>
                                    <div className={styles.name}>{instructor.label} {instructor.text ? `(${instructor.text})` : null}</div>
                                    <div className={styles.classes}>{instructor.class_count} classes</div>
                                    <div className={styles.updated}>{format(parseISO(instructor.updated_at), 'MM/dd/yyyy')}</div>
                                    <div className={styles.buttons}>
                                        <EditIcon onClick={() => showModal(instructor.content_meta_option_id, instructor.label, instructor.text)}/>
                                        { confirm === instructor.content_meta_option_id ?
                                            <div className={styles.confirmContainer}>
                                                <button onClick={() => remove(instructor.content_meta_option_id)} className={styles.confirm}>Confirm</button>
                                                <button onClick={() => setConfirm(null)} className={styles.cancel}>Cancel</button>
                                            </div>
                                            :
                                            <TimesIcon onClick={() => remove(instructor.content_meta_option_id)}/>
                                        }
                                    </div>
                                </BoxContainer>
                            </div>
                        )
                    })}
                </div>
            </div>

            <Modal show={modal} onHide={closeModal}>
                <BoxContainer className='padding-standard'>
                    <h4>{edit.state} Instructor</h4>
                    <form onSubmit={submitUpdate}>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                onChange={e=>setEdit({...edit, name: e.target.value})}
                                value={edit.name}
                                type="text"
                                className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label>Text</label>
                            <input
                                onChange={e=>setEdit({...edit, text: e.target.value})}
                                value={edit.text}
                                type="text"
                                className="form-control"/>
                        </div>
                        <PrimaryButtonSm onClick={submitUpdate}>Submit</PrimaryButtonSm>
                    </form>
                </BoxContainer>
            </Modal>
        </div>
    )
}
export default Instructors;