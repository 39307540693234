import React from 'react';
import HeaderAdmin from '../headerAdmin';
import HeaderCoach from '../HeaderCoach';
import AdminSubHeader from './AdminSubheader';
import AdminDisplay from './AdminDisplay';
import {fetchSelf} from "../../../actions/action_user";
import {connect} from 'react-redux';

class AdminDetailMain extends React.Component {

	componentDidMount(){
        this.props.fetchSelf();
		window.scrollTo(0, 0)
	}

    userPermission(){
        if(this.props.user){
            if(this.props.user.permission_id === 3){
                return( 
                    <div>
                        <HeaderAdmin /> 
                    </div>
                    )
            }
            else{
                return( 
                    <div>
                        <HeaderCoach /> 
                    </div>
                    )
            }
        }
    }

    render() {
        return(
            <div class="client-detail">
                {this.userPermission()}
                <AdminSubHeader username={this.props.match.params.username} />
                <AdminDisplay match={this.props.match} username={this.props.match.params.username}/>
            </div>
        );
    }
}

function mapStateToProps({user}){
    return{user}
}

export default connect(mapStateToProps, {fetchSelf})(AdminDetailMain);