import React, {useEffect, useRef} from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import _ from "lodash";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

// styles
import styles from './mailList.module.css';

// components
import {MAIL_VIEWS} from "../views";
import Loading from "../../Loading/Loading";

const MailList = props => {
    // props
    const {view, setView, messages, setPage} = props;
    // state
    const {user} = props;
    // local
    const last = useRef();

    useEffect(() => {
        window.addEventListener('scroll', _.throttle(onScroll, 500));
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    async function onScroll() {
        if (last.current) {
            const inView = isElementInViewport(last.current);
            if (inView) {
                setPage();
            }
        }
    }

    function isElementInViewport (el) {
        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span className={styles.label}>
                    {view === MAIL_VIEWS.CURRENT ? 'Inbox' : 'Archived'}
                </span>
                {view === MAIL_VIEWS.CURRENT ? <button onClick={() => setView(MAIL_VIEWS.ARCHIVED)}>Archived</button> : null}
                {view === MAIL_VIEWS.ARCHIVED ? <button onClick={() => setView(MAIL_VIEWS.CURRENT)}>Inbox</button> : null}
            </div>
            <div>
                {!messages ?
                    <div className="padding-standard"><Loading/></div>
                    :
                    <>
                        {!messages.length ?
                            <p className={styles.placeholder}>Inbox is empty</p>
                            :
                            <div>
                                {messages.map((message, i) => {
                                    let profileImg = '';
                                    if (message.posts[message.posts.length-1].user){
                                        profileImg = message.posts[message.posts.length-1].user.profile_image || 'https://treo.s3.us-east-2.amazonaws.com/assets/avatar.png';
                                    }

                                    const title = message.posts[message.posts.length-1].title;
                                    const updated = format(parseISO(message.posts[message.posts.length-1].created_at), 'MM/dd/yy');
                                    if (i === messages.length-1) {
                                        return (
                                            <Link to={`/member/clientDash/${user.username}/inbox-message/${message.id}`} className={styles.link}>
                                                <div ref={last} className={styles.row}>
                                                    <div className={styles.profile} style={{backgroundImage: `url(${profileImg})`}}>
                                                        <div className={parseInt(message.unread) === 1 ? styles.unseen : ''}/>
                                                    </div>
                                                    <div>
                                                        <p className={styles.title}>{title}</p>
                                                        <p className={styles.updated}>{updated}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }
                                    return (
                                        <Link to={`/member/clientDash/${user.username}/inbox-message/${message.id}`} className={styles.link}>
                                            <div className={styles.row}>
                                                <div className={styles.profile} style={{backgroundImage: `url(${profileImg})`}}>
                                                    <div className={parseInt(message.unread) === 1 ? styles.unseen : ''}/>
                                                </div>
                                                <div>
                                                    <p className={styles.title}>{title}</p>
                                                    <p className={styles.updated}>{updated}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}

function mapStateToProps({user}) {
    return {user}
}
export default connect(mapStateToProps)(MailList);