import React, {useState} from 'react';
import {Link} from 'react-router-dom';

// styles
import styles from './contentCategory.module.css';

// components
import {CONTENT_FORMATS} from '../../../../serverVariables/contentFormats';
import {ReactComponent as DownArrow} from '../../../../images/caret-down-solid.svg';
import {ReactComponent as PdfIcon} from '../../../../images/file-pdf-solid.svg';
import {ReactComponent as VideoIcon} from '../../../../images/play.svg';
import {ReactComponent as AudioIcon} from '../../../../images/headphones_3.svg';
import {ReactComponent as LinkIcon} from '../../../../images/external-link-alt-solid.svg';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import CategoryRow from "../categoryRow/categoryRow";

const ContentCategory = props => {
    // props
    const {provided, category, remove, onDragEnd} = props;
    // local
    const [open, setOpen] = useState(false);

    function icon(format){
        if (format === CONTENT_FORMATS.VIDEO) return <VideoIcon/>;
        if (format === CONTENT_FORMATS.AUDIO) return <AudioIcon/>;
        if (format === CONTENT_FORMATS.LINK) return <LinkIcon/>;
        if (format === CONTENT_FORMATS.PDF) return <PdfIcon/>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <Link
                    to={`/dashboard/Content/type/${category.content_type_id}/cat/${category.category_id}`}
                    className={styles.category}>
                    {category.category_name}
                </Link>
                <i {...provided.dragHandleProps} className={`fas fa-arrows-alt`}/>
                <i className="fas fa-trash" onClick={() => remove(category)}/>
            </div>
            {category.content && category.content.length > 0 ?
                <div className='margin-bottom-15'>
                    <div className={styles.contentHeading} onClick={() => setOpen(!open)}>
                        <DownArrow className={`${styles.arrow} ${open ? styles.open : ''}`}/> <span>Contents</span>
                    </div>
                    {open ?
                        <div>
                            {category.content.map(piece => (
                                <Link
                                    key={piece.title}
                                    to={`/dashboard/Content/new?content=${piece.id}`}
                                    className={styles.individual}>
                                    {icon(piece.content_format)}{piece.title}
                                </Link>
                            ))}
                        </div>
                        : null
                    }
                </div>
                : null
            }
            <div>
                <DragDropContext onDragEnd={val => onDragEnd({...val, category})}>
                    <Droppable droppableId='mainCategoryDrop'>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {category.subcategories.map((item, i) => (
                                    <Draggable key={item.category_id} draggableId={`${item.category_id}`} index={i}>
                                        {(provided) => (
                                            <div
                                                key={item.category_id}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}>
                                                <CategoryRow
                                                    to={`/dashboard/Content/type/${item.content_type_id}/sub/${item.category_id}`}
                                                    provided={provided}
                                                    title={item.category_name}
                                                    movable
                                                    removeAction={() => remove(item)}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
};

export default ContentCategory;