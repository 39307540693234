import React, {Component} from 'react';
import './GoalBar.css'
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

const Range = Slider.Range;

export class GoalBar extends Component{
   constructor(props){
       super();
           this.state = {
            value: [0, 40, 60, 100],
            planUnits: '',
            description: '',
       };
   }

   componentDidMount() {
    this.setState({
        test: null
    })
   }

 planUnitsChange = (e) => {
    this.props.planUnitsHandler(e.target.value);
    this.setState({planUnits: e.target.value});
  };

   descriptionChange = (e) => {
    this.props.descriptionHandler(e.target.value);
    this.setState({description: e.target.value});
  };

  valueChange = (value) => {
        this.setState({
            value
        })
        this.props.valueHandler(value);
    }

    render(){
        console.log(this.props.description);
        console.log(this.props.category);
        return(
            <div className="goal-container col-md-6 col-xs-12" >
                {this.props.description
                    ? null 
                    : <div>
                            <label className="custom-description-label">{this.props.category}:</label>
                            <input type="text"
                                   className='custom-description'
                                   placeholder='custom description'
                                   onChange={this.descriptionChange}
                                   value={this.state.description}
                                   />
                       </div>
                }

                <div>
                    <i className='fa fa-times disable-goal-bar'></i><span>Disable goal bar for this tile<i class="fa fa-info-circle info-disable-goal-bar"></i></span> 
                </div>
                <div className="goal-bar-container">
                  <Range
                    placement="top"
                    value={this.state.value}
                    onChange={this.valueChange}
                    allowCross={false}
                    {...this.props}
                  />
                </div>
                <div>
                <div>
                    <label className="plan-units-label">Plan Units</label>
                    <input type="text"
                           className='plan-units col-md-12'
                           placeholder='plan units'
                           onChange={this.planUnitsChange}
                           value={this.state.planUnits}
                           />
                </div> 
                </div>
            </div>
        )

    }
}
