import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// styles
import styles from './moderationContainer.module.css';

// actions
import {
    fetchImagesForReview,
    fetchNamesForReview,
    setImageStatus,
    setNicknameStatus
} from "../../../store/actions/leaderboardActions";

// components
import Nicknames from "../nicknames/nicknames";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import ImageModeration from "../images/imageModeration";

const VIEWS = {
    NICKNAMES: 'NICKNAMES',
    IMAGES: 'IMAGES',
};

const ModerationContainer = props => {
    // props
    const {user, singleCoach} = props;
    // local
    const [selector, setSelector] = useState(VIEWS.IMAGES);
    const [names, setNames] = useState([]);
    const [images, setImages] = useState([]);

    // grab the number of nicknames and images awaiting review on month
    useEffect(() => {
        fetchImages();
        fetchNames();
    }, []);

    // grab names or images when tabs are changed
    useDidMountEffect(() => {
        if (selector === VIEWS.NICKNAMES) fetchNames();
        else fetchImages();
    }, [selector]);


    async function fetchNames() {
        try {
            const userId = singleCoach ? singleCoach.id : null;
            const res = await fetchNamesForReview(userId);
            setNames(res || []);
            console.log(res);
        } catch (e) {
            console.log(e);
        }
    }

    async function fetchImages() {
        try {
            const userId = singleCoach ? singleCoach.id : null;
            const res = await fetchImagesForReview(userId);
            setImages(res || []);
            console.log(res);
        } catch(e) {
            console.log(e);
        }
    }

    async function approveOrDenyNickname(userId, status) {
        try {
            await setNicknameStatus(userId, status);
            fetchNames();
        } catch(e) {
            console.log(e);
        }
    }

    async function approveOrDenyImage(userrId, status) {
        try {
            await setImageStatus(userrId, status);
            fetchImages();
        } catch(e) {
            console.log(e);
        }
    }

    if (!user) {
        return <div/>;
    }

    return (
        <div className="container margin-top-lrg">
            <h3 className="m:0 margin-bottom-med">Moderation</h3>
            <div className={styles.selectors}>
                <button
                    onClick={() => setSelector(VIEWS.NICKNAMES)}
                    className={selector === VIEWS.NICKNAMES ? styles.active : ''}>
                    Nicknames
                    <span className={styles.count}>{names.length}</span></button>
                <button
                    onClick={() => setSelector(VIEWS.IMAGES)}
                    className={selector === VIEWS.IMAGES? styles.active : ''}>
                    Images
                    <span className={styles.count}>{images.length}</span></button>
            </div>

            <div>
                {selector === VIEWS.NICKNAMES ? <Nicknames setStatus={approveOrDenyNickname} names={names}/> : null}
                {selector === VIEWS.IMAGES ? <ImageModeration setStatus={approveOrDenyImage} images={images}/> : null}
            </div>
        </div>
    )
}
function mapStateToProps({user, singleCoach}) {
    return {user, singleCoach};
}
export default withRouter(connect(mapStateToProps)(ModerationContainer));