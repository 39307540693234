import axios from 'axios';

export const SET_COACH = 'SET_COACH';
export function setCoach(coachObj){
    return{
        type: SET_COACH,
        payload:coachObj
    };
}

//retrieves a coaches info
export const GET_COACH = 'GET_COACH';
export function getCoach(username){
    let url = `/user/coach/single/${username}`;
    let request = axios.get(url);

    return{
        type:GET_COACH,
        payload:request
    }
}

//get list of coaches in asc order from most clients assigned to least
export const GET_COACHES_ASSIGNMENTS = 'GET_COACHES_ASSIGNMENTS';
export function getCoachesAssignments(){
    let url = '/coach/assignment-count';
    let request=axios.get(url);
    return{
        type:GET_COACHES_ASSIGNMENTS,
        payload:request
    }
}
