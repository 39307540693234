import React from 'react';
import {WellnessScoreScale} from './wellnessScoreScale';
import ProfileImage from './profileImage';
import {Link} from 'react-router-dom';

const  PhotoContainer = ({score, image, username, url_path, permission, color, className, onClick}) =>{
    return(
        <div className={`${className} victory-container`} onClick={onClick}>
        	<Link to={`/${url_path}`}>
            	<ProfileImage
                    image={image}
                    username={username}
                    url_path={url_path}
                    permission={permission}
                    color={color}
                />
            	<WellnessScoreScale score = {score} />
            </Link>
        </div>
    )
};

export default PhotoContainer;