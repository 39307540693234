import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';
import {connect} from 'react-redux';
import {ContentViewContainer} from '../Admin/Content/contentViewContainer';
import axios from 'axios';

class ContentThumbnail extends Component{
    constructor(props){
        super(props);
        this.state={
            //modal
            show:false,
            modal_content:{},
            read:false,
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({show:true, read:true});
        // this.props.handleReadChange(true);
        console.log(this.props.read)
        if(!this.props.read && !this.state.read){
            axios.put(`/content/change-status`,{
                "tile_id":this.props.tileId,
                "content_id": this.props.id
            })
              .then(res => {
                console.log(res);
                this.setState({read:true})
              })
            .catch(err=>{
                console.log(err)
            });
            }
      }

    render(){
        return(
            <div>
                <div onClick={this.handleShow}>

                {this.props.singleTileChild
                    ?this.props.read || this.state.read
                        ?<div className="slider-thumbnail">
                             <img src={this.props.thumbnail} alt=""/>
                         </div>
                        :<div className="slider-thumbnail">{/*<span className="contentCheckMarkContainer"><div className="contentCheckMark"></div></span>*/}
                             <img src={this.props.thumbnail} alt=""/>
                     </div>
                
                    :<div className="slider-thumbnail">
                         <img src={this.props.thumbnail} alt=""/>
                     </div>
                }

                    <h5>{this.props.title}</h5>
                </div>
                <Modal dialogClassName='content-modal' show={this.state.show} onHide={this.handleClose}>
                    <div className='exitBtn' onClick={this.handleClose}><div>X</div></div>
                    <Modal.Body>
                        <ContentViewContainer
                            title={this.props.title}
                            content_type={this.props.content_type}
                            content={this.props.content}
                            hero_image={this.props.hero_image}
                            handleClose={this.handleClose}
                            user={this.props.user ? this.props.user : null}
                            tile={this.props.tile ? this.props.tile : null}
                            id={this.props.id}
                            inbox={this.props.inbox ? this.props.inbox : null}
                            attachContent={this.props.attachContent ? this.props.attachContent : null}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({user, tile}) =>{
    return({
        user,
        tile:tile.tile
    })
};

export default connect(mapStateToProps)(ContentThumbnail);