import React, {useState} from 'react';
import randomstring from "randomstring";

// styles
import styles from './daxkoSignup.module.css';

// components
import Loading from "../Loading/Loading";

const DaxkoSignup = props => {
    // props
    const {scope} = props;
    // local
    const [sent, setSent] = useState(false);
    const [daxkoState] = useState(
        randomstring.generate({ length: 10, charset: "alphanumeric" })
    );

    function sendToDaxko() {
        if (!sent) {
            setSent(true);
            window.location.href = `https://operations.oauth2.partners.daxko.com/authorize?response_type=code&scope=clientIntegrationId%3A${scope}&state=${daxkoState}&client_id=prod_treowellness&redirect_uri=https://habit.treowellness.com/member/clientDash`;
            return <div/>
        } else {
            return (
                <div className={styles.loadingContainer}>
                    <p>Redirecting to Login. Please Wait.</p>
                    <Loading/>
                </div>
            )
        }
    }

    return sendToDaxko();
}

export default DaxkoSignup;