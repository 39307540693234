import React, {useState, useEffect, useRef} from 'react';
import {connect} from "react-redux";
import _ from "lodash";

// styles
import styles from './communityReactions.module.css';

// actions
import {fetchReactionFeed, setReactionsSeen, fetchCommunityNotifications} from "../../store/actions/communityActions";

// components
import ClientHeader from "../clientHeader/clientHeader";
import Loading from "../Loading/Loading";
import CommunitySingleReaction from "../communitySingleReaction/communitySingleReaction";

const CommunityReactions = props => {
    // actions
    const {fetchCommunityNotifications} = props;
    // local
    const [reactions, setReactions] = useState(null);
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const endReached = useRef(false);
    const last = useRef();
    const feed = useRef();

    useEffect(() => {
        addReactions();
    }, []);

    useEffect(() => {
        feed.current = reactions;
    }, [reactions]);

    useEffect(() => {
        window.addEventListener('scroll', _.throttle(onScroll, 500));
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    function loadNotifications() {
        try {
            fetchCommunityNotifications();
        } catch (e) {
            console.log(e);
        }
    }

    async function onScroll() {
        if (last.current) {
            const inView = isElementInViewport(last.current);
            if (inView && !loading && feed.current && !endReached.current) {
                setLoading(true);
                await addReactions(feed.current);
                setLoading(false);
            }
        }
    }

    function isElementInViewport (el) {
        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    async function addReactions(oldReactions) {
        try{
            let result;
            if (oldReactions) {
                result = await fetchReactionFeed(oldReactions[oldReactions.length-1].reaction_id);
                setReactions([...oldReactions, ...result.reactions]);
            } else {
                result = await fetchReactionFeed(null, 100);
                setReactions(result.reactions);
                setReady(true);

            }
            const ids = result.reactions.map(res => res.reaction_id);
            await setReactionsSeen(ids);
            loadNotifications();
            if (!result.more) endReached.current = true;
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div>
            <div className="client-container">
                <ClientHeader backUrl={`/member/community`}/>
            </div>
            {!ready ?
                <div className="padding-standard"><Loading/></div>
                :
                <div className='container'>
                    <div className={styles.label}>Reactions</div>
                    <div>
                        {reactions.map((rec, i) => {
                            if (i === reactions.length-1) {
                                return (
                                    <div ref={last} className={styles.row}>
                                        <CommunitySingleReaction reaction={rec}/>
                                    </div>
                                )
                            }
                            return (
                                <div className={styles.row}>
                                    <CommunitySingleReaction reaction={rec}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

const actions = {
    fetchCommunityNotifications,
};

export default connect(null, actions)(CommunityReactions);