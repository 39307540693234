import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import PasswordConfirmation from './PasswordConfirmation';

class PasswordRecovery extends Component{
    renderReset=()=>{
        return(
            <ForgotPassword history={this.props.history}/>
        )
    };
    render(){
        return(
            <div>
                <Route path={this.props.match.url} exact render={this.renderReset}/>
                <Route path={this.props.match.url + '/confirmation'} render={()=><PasswordConfirmation location={this.props.location}/>}/>
            </div>
        )
    }
}

export default withRouter(PasswordRecovery);