import {gameApi} from "../../api/gameApi";

//**************** Actions that involve the store *****************//

//**************** Actions that don't involve the store *****************//

// grab the current state of a members leaderboard
export async function fetchLeaderboard(onlyOrgFilter) {
    const {data: {result}} = await gameApi().getLeaderboard(onlyOrgFilter);
    return result;
}

// grab the list of nicknames awaiting review
export async function fetchNamesForReview(userId) {
    const {data} = await gameApi().getNamesWaitingReview(userId);
    return data.result;
}

// grab the list of nicknames awaiting review
export async function fetchImagesForReview(userId) {
    const {data} = await gameApi().getImagesWaitingReview(userId);
    return data.result;
}
// take moderation action on a nickname
export async function setNicknameStatus(userId, status) {
    const {data} = await gameApi().moderateNickname(userId, status);
    return data.result;
}
// take moderation action on an image
export async function setImageStatus(userId, status) {
    const {data} = await gameApi().moderateImages(userId, status);
    return data.result;
}