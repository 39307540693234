import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {DashBoxes} from './dashBoxes';
import {MetaData} from './metaData';
import axios from "axios/index";

 class CoachDashMain extends Component{
    constructor(props){
        super(props);
           this.state = {
           	responseTime: null,
           	averageContactsPerCoach:null,
            averageTWS:null,
       };
    }

    componentDidMount() {
        axios.get(` /user/coach/single/${this.props.username}`)
        .then(res => {
            axios.get(`/score/coach/clients/score/${res.data.results.user_id}`)
            .then(res => {
                console.log(res)
                this.setState({averageTWS:Math.round(res.data.results[0].scoreAverage)})
            })
            .catch((error)=>{
               console.log(error);
            });
        })

      axios.get(`/coach/response-time?username=${this.props.username}&days=30`)
          .then(res => {
            const average = res.data.result;
            const quotient = Math.floor(average/60).toFixed(0);
            const remainder = (average % 60).toFixed(0);
            this.setState({ responseTime: `${quotient}:${remainder}`});
          }).catch(e=>console.error(e));

        axios.get(`/admin/average/coaches/contacts/${this.props.username}`)
        .then(res => {
                console.log(res)
                if(res.data.result === null){
                    this.setState({averageContactsPerCoach: 'no contact has been made'})
                }
                else{
                    this.setState({averageContactsPerCoach:Math.round(res.data.result)})
                }
        })
    }

    render(){
        // console.log(this.props.username)
    return(
        <div>
            <DashBoxes 
    		      responseTime={this.state.responseTime}
    		      averageContactsPerCoach={this.state.averageContactsPerCoach}
              averageTWS={this.state.averageTWS}
            />
            <MetaData />
        </div>
    )
    }
}

export default withRouter(CoachDashMain);
