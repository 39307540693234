import React from 'react';

const TileListHeader = () =>{
    return(
        <div className='page-header-container searchForm-container'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        [breadcrumbs]
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <h1>Tiles</h1>
                    </div>
                    <div className="col-sm-12 col-md-6 text-right">
                        <button
                            type="button"
                            className="searchForm-addNew btn primary btn btn-default">
                            + Add New Intro Tile
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TileListHeader;