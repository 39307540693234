import React, {useRef, useEffect, useState} from 'react';

// styles
import styles from './abandoned.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const Abandoned = props => {
    const container = useRef(0);
    // eslint-disable-next-line 
    const [containerState, setContainerState] = useState(containerState);

    // props
    const {data} = props;

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [data]);

    function onResize() {
        if (container.current) {
            const size =  container.current.clientWidth-175;
            setContainerState(size);
        }
    }

    if (!data) {
        return (
            <div>
                <GraphPlaceholder/>
            </div>
        )
    }
    let total = data.abandonedTotal;
    if (!total) total = 1;
    return (
        <div ref={container}>
            <div className={styles.row}>
                <div className={styles.barLabel}>Abandoned</div>
                <div className={styles.barContainer}>
                    <div style={{maxWidth: `${isNaN(parseInt(containerState)*(data.abandoned/data.abandonedTotal)) ? 0 : parseInt(containerState)*(data.abandoned/data.abandonedTotal)}px`}} className={`${styles.bar} ${styles.abandoned}`}/>
                    <span>{data.abandoned}</span>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.barLabel}>Total</div>
                <div className={styles.barContainer}>
                    <div style={{maxWidth: `${data.abandonedTotal > 0 ? containerState : 0}px`}} className={`${styles.bar} ${styles.total}`}/>
                    <span>{data.abandonedTotal}</span>
                </div>
            </div>
            <p className={styles.totalAbandoned}>Total Abandonment: {isNaN(Math.floor((data.abandoned/data.abandonedTotal)*100)) ? 0 : Math.floor((data.abandoned/data.abandonedTotal)*100)}%</p>
        </div>
    )
}

export default Abandoned;