import { wellnessScoreApi } from "../../api/wellnessScoreApi";
import {
    addDays,
    differenceInDays,
    isAfter,
    isBefore,
    endOfToday,
    isSameDay,
} from 'date-fns';

import {
    LIFETIME_SCORE,
    DAILY_SCORE,
    WELLNESS_SCORE_HISTORY,
    LIFETIME_SCORE_HISTORY,
    SCORE_CHANGED, MONTHLY_SCORE,
} from "../storeConstants";

// function to get the lifetime score of a user
export function getLifetimeScore() {
    return async dispatch => {
        const score = await wellnessScoreApi().getLifetimeScore();
        const scoreText = score ? score.data.lifetime_score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
        return dispatch({ type: LIFETIME_SCORE, payload: scoreText });
    }
}

// function to get the daily score of a user
export function getDailyScore() {
    return async dispatch => {
        const score = await wellnessScoreApi().getDailyScore();
        dispatch({ type: DAILY_SCORE, payload: score.data.results.score });
        return ({payload: score.data.results.score, ...score.data.results});
    }
}

// function to set if the score has changed
export function setChangedScoreFlag(prevScore) {
    return dispatch => dispatch({ type: SCORE_CHANGED, payload: prevScore });
}

// function to get the wellness score history of a user between 2 dates
export function getWellnessScoreHistory(username, start, end, startDate) {
    return async dispatch => {
        const history = (await wellnessScoreApi().getWellnessScoreHistory(username, start, end)).data.result;
        const parsedStart = new Date(start.substr(0, 4),parseInt(start.substr(5, 2))-1, start.substr(8, 2),);
        const parsedEnd = new Date(end.substr(0, 4),parseInt(end.substr(5, 2))-1, end.substr(8, 2),);
        let data = history.map(item => {
            const parsed = {
                month: item.date.substr(5, 2),
                year: item.date.substr(0, 4),
                day: item.date.substr(8, 2),
            };
            const point = new Date(parsed.year, parsed.month-1, parsed.day);
            return { date: point, value: item.score };
        });
        const filled = [];
        let comparer = parsedStart;
        const parsedStartDate = startDate ? {
            month: startDate.substr(5, 2),
            year: startDate.substr(0, 4),
            day: startDate.substr(8, 2)
        } : null;
        while(differenceInDays(parsedEnd, comparer) > -1) {
            if (isAfter(comparer, endOfToday()) || (parsedStartDate && isBefore(comparer, new Date(parsedStartDate.year, parsedStartDate.month-1, parsedStartDate.day)))) {
                filled.push({ date: comparer, value: null });
                comparer = addDays(comparer, 1);
                continue;
            }
            const found = data.find(item => isSameDay(item.date, comparer));
            if(!found) filled.push({ date: comparer, value: 0 });
            else filled.push({ date: comparer, value: found.value });
            comparer = addDays(comparer, 1);
        }
        dispatch({ type: WELLNESS_SCORE_HISTORY, payload: filled });
    }
}

// function to get the lifetime score history of a member
export function getLifetimeScoreHistory(date, days, userId, startDate) {
    return async dispatch => {
        let data = [];
        const history = await wellnessScoreApi().getLifetimeScoreHistory(date, days, userId);
        if (history && history.data && history.data.lifetime_graph) {
            data = history.data.lifetime_graph.map(score => {
                const parsedDate = {
                    month: score.date.substr(5, 2),
                    year: score.date.substr(0, 4),
                    day: score.date.substr(8, 2)
                };
                if (isAfter(new Date(parsedDate.year, parsedDate.month-1, parsedDate.day), endOfToday())) {
                    return { date: score.date, value: null }
                }
                const parsedStartDate = startDate ? {
                    month: startDate.substr(5, 2),
                    year: startDate.substr(0, 4),
                    day: startDate.substr(8, 2)
                } : null;
                if (parsedStartDate) {
                    if (isBefore(new Date(parsedDate.year, parsedDate.month-1, parsedDate.day), new Date(parsedStartDate.year, parsedStartDate.month-1, parsedStartDate.day))) {
                        return { date: score.date, value: null }
                    }
                }
                return { date: score.date, value: score.lifetime_score };
            })
        }
        dispatch({ type: LIFETIME_SCORE_HISTORY, payload: data });
    }
}

export function fetchMonthlyScore() {
    return async dispatch => {
        const score = await wellnessScoreApi().getMonthlyScore();
        dispatch({type: MONTHLY_SCORE, payload: score.data.results});
    }
}

//**************** Actions that don't involve the store *****************//

// function to get a members general wellness score activity
export async function fetchGeneralScoreActivity(date) {
    const res = await wellnessScoreApi().getGeneralScoreActivity(date);
    return res.data;
}

// function to get a members score streak
export async function fetchStreak() {
    const res = await wellnessScoreApi().getStreak();
    return res.data;
}

// alter the server that the habit builder button has been touched
export async function touchedHabitBuilder() {
    const res = await wellnessScoreApi().habitBuilder();
    return res.data.results;
}