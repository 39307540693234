import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Modal, Table} from "react-bootstrap";
import { format } from 'date-fns';
import axios from 'axios';

// styles
import styles from './organizationWaitlist.module.css';

// actions
import {
    getOrganizationWaitlist,
    setOrganizationWaitlistPage
} from "../../../actions/action_organizations";
import { GeneralPagination } from "../../pagination/generalPagination/generalPagination";
import { DangerButton, PrimaryButton} from "../../buttons/buttons";
import FileDownload from "js-file-download";
import {ErrorAlert} from "../../alerts/alerts";

const OrganizationWaitlist = (props) => {
    // props
    const { organization } = props;
    // state
    const { waitlist } = props;
    // actions
    const { getOrganizationWaitlist } = props;

    const [ error, setError ] = useState('');
    const [ exportList, setExportList ] = useState([]);
    const [ removeModal, setRemoveModal ] = useState(false);
    const [ tempRemove, setTempRemove ] = useState(null);

    function changePage ({ selected }) {
        const page = selected+1;
        setOrganizationWaitlistPage(page);
        getOrganizationWaitlist(page);
        window.scrollTo(0, 0);
    }

    async function removeEntry() {
        await axios.post(`/organizations/waitlist/delete/${organization.id}`, { waitlist_ids: [tempRemove.id]})
            .then(() => {
                closeRemovalModal();
                setError('');
                getOrganizationWaitlist(organization.id, waitlist.page);
            })
            .catch(err => {
                closeRemovalModal();
                if (err.response && err.response.data) {
                    setError(err.response.data);
                } else {
                    setError('Unable to remove entry from waitlist');
                }
            });
    }

    function openRemovalModal(entry) {
        setTempRemove(entry);
        setRemoveModal(true);
    }

    function closeRemovalModal() {
        setTempRemove(null);
        setRemoveModal(false);
    }

    async function exportWaitlist() {
        axios.post(`/organizations/waitlist/export/${organization.id}`, { waitlist_ids: exportList.map(entry => entry.id) })
            .then((response) => {
                const date = new Date();
                const day = date.getDate().toString();
                const month = (date.getMonth()+1).toString();
                const year = date.getFullYear().toString();
                const file_name = (`${organization.name}_${month}-${day}-${year}.csv`).trim();
                FileDownload(response.data, file_name);
            });
    }

    function addToExportList(entry) {
        setExportList([...exportList, entry]);
    }

    function removeFromExportList(id) {
        const filtered = exportList.filter(item => item.id !== id);
        setExportList(filtered);
    }

    // get waitlist on mount
    useEffect(() => {
        getOrganizationWaitlist(organization.id, waitlist.page);
    }, [getOrganizationWaitlist]);

    return (
        <div>
            <div className='row margin-top-2x'>
                <div className="col-xs-6 col-sm-8">
                    <h2 className='m:0'>Waitlist</h2>
                </div>
                <div className="col-xs-6 col-sm-4">
                    <div className={styles.exportBtn}>
                        <PrimaryButton onClick={exportWaitlist}>Export Selected</PrimaryButton>
                    </div>
                </div>
            </div>
            <div>
                <h3>{waitlist.total} Members</h3>
                <p>[ widget helpful description here ]</p>
            </div>
            <div>
                { error ? <ErrorAlert>{error}</ErrorAlert> : null }
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <Table className='m:0' striped bordered hover size="sm">
                        <thead className="tile-header">
                        <tr>
                            <th className='col-xs-1'>Select</th>
                            <th className='col-xs-2 col-sm-2'>Date Added</th>
                            <th className='col-xs-2 col-sm-2'>Code Used</th>
                            <th className='col-xs-2 col-sm-2'>Name</th>
                            <th className='col-xs-1 col-sm-2'>Email</th>
                            <th className='col-xs-1 col-sm-2'>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        { waitlist.waitlist.map(entry => (
                            <tr>
                                <td><input
                                    type="checkbox"
                                    checked={exportList.length ? exportList.find(item => item.id === entry.id) : false}
                                    onChange={e=>{
                                        if (e.target.checked) return addToExportList(entry);
                                        removeFromExportList(entry.id);
                                }}/></td>
                                <td>{format(new Date(entry.date), 'P')}</td>
                                <td>{entry.code}</td>
                                <td>{entry.name}</td>
                                <td>{entry.email}</td>
                                <td className='t-d:u hover' onClick={()=> openRemovalModal(entry)}>remove</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <div className="margin-top">
                        <GeneralPagination onPageChange={changePage} pages={waitlist.pages}/>
                    </div>
                </div>
            </div>
            <Modal show={removeModal} onHide={closeRemovalModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Waitlist Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove this entry?</p>
                </Modal.Body>
                <Modal.Footer>
                    <DangerButton onClick={removeEntry}>Remove</DangerButton>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

function mapStateToProps({ organizationWaitlist }) {
    return { waitlist: organizationWaitlist };
}

const actions = {
    getOrganizationWaitlist,
    setPage: setOrganizationWaitlistPage,
}

export default connect(mapStateToProps, actions)(OrganizationWaitlist);