import {CLEAR_NOTE} from "../actions/action_notes";
import {SET_NOTE} from "../actions/action_notes";
import {CREATE_NOTE} from "../actions/action_notes";

import {FETCH_NOTES_BY_ID} from "../actions/action_notes";

export default function (state={},action ){
    switch (action.type){
        case CLEAR_NOTE:
            console.log(action.payload);
            return Object.assign({}, action.payload);

        case SET_NOTE:
            console.log(action.payload);
            return Object.assign({}, action.payload);

        case CREATE_NOTE:
            console.log(action.payload);
            return Object.assign({}, action.payload);

        case FETCH_NOTES_BY_ID:
          //console.log(action.payload);

          if(action.payload){
            return Object.assign({}, action.payload.data.results);
          } else {
            return Object.assign({}, []);
          }
          // no default
    }
    return state;
}
