// npm modules
import React, { useState } from 'react';
import axios from 'axios';
import validate from 'validate.js';

// components
import { ButtonList } from "../lists/lists";
import Loading from "../Loading/Loading";

const constraints = {
    title: {
        presence: true,
        length: { minimum: 1, maximum: 255 },
        type: 'string',
    }
};

const PolicyNewContainer = ({ ...props }) => {


    const [ errors, setErrors ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ title, setTitle ] = useState('');

    const createNewPolicy = (e) => {
        if (e && e.preventDefault) e.preventDefault();
        // prevent double submission
        if (loading) return;
        // validate form
        const errors = {};
        const validation = validate({ title, }, constraints);
        if (validation) {
            if (validation.title) errors.title = validation.title;
            setErrors(errors);
            return;
        } else {
            setErrors(errors);
        }
        setLoading(true);
        // create new version
        axios.post(`/policy/new-policy`, {
            title,
        }).then(res => {
            const { id } = res.data.result;
            props.history.push(`/dashboard/Policy/${title.split(' ').join('-')}/${id}/new`);
        }).catch(e => console.log(e));
    };

    const buttons = [
        //{ id: 0, text: 'Create New Policy', active: true, disabled:true, },
        { id: 1, text: 'Save/Publish', onClick: createNewPolicy }, // keep save/publish button at end of list
    ];

    if (loading) {
        const offset = (window.innerHeight - 76)/2;
        return (
            <div style={{ position: 'relative', top: offset }}>
                <Loading />
            </div>
        )
    }

    return (
		<div>
			<div className="page-header-container searchForm-container">
				<div className='container'>
					<div className="row">
						<div className="col-sm-12">
							<nav>
								<ol className="breadcrumb ">
									<li className="breadcrumb-item underline ">
										<a href="/dashboard" className="text-light">Dashboard</a></li>
									<span className="arrow"/>
									<li className="breadcrumb-item underline ">
										<a href="/dashboard/Policy" className="text-light">Policy</a></li>
									<span className="arrow"/>
									<li className="breadcrumb-item text-light active">New</li>
								</ol>
							</nav>
						</div>
						<div className="col-xs-12">
							<h1>Create New Policy</h1>
						</div>
					</div>
				</div>
			</div>
		
			<div className="container margin-top-3x">
				<div className="row">
					<div className="col-xs-12 col-sm-3">
						<div className="widget-container margin-bottom-2x">
							<ButtonList list={buttons}/>
						</div>
					</div>
					<div className="col-xs-12 col-sm-9">
						<div className="widget-container margin-bottom-2x">
							<form onSubmit={e => e.preventDefault()}>
								<div className="form-group">
									<label htmlFor="policyTitle">Title</label>
									<input
										type="text"
										id='policyTitle'
										placeholder='Policy Title'
										value={title}
										onChange={e => setTitle(e.target.value)}
									/>
									{ errors.title ? errors.title.map(err => <span key={err.trim()} className='text-danger'>{err}</span>) : null }
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

        </div>
    )
};

export default PolicyNewContainer;

PolicyNewContainer.propTypes = {
};