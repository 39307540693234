// npm modules
import React, { useState, useEffect, useContext, useRef } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";
import { ShepherdTourContext } from "react-shepherd";
import { withRouter } from "react-router-dom";
import { parse as parseQuery } from "query-string";
import downloadappstore from "../../images/downloadappstore.png";
import downloadgoogleplay from "../../images/downloadgooglepllay.png";

// styles
import styles from "./clientHub.module.css";

// actions
import { setClientPathwayList } from "../../store/actions/pathwaysActions";
import { fetchFeaturedContent } from "../../store/actions/contentActions";
import { updateCoachNudge } from "../../store/actions/userActions";
import { tourUpdate } from "../../store/actions/userActions";
import { fetchDaxkoUser } from "../../store/actions/organizationActions";

// components
import TileTrack from "./tileTrack/tileTrack";
import { PrimaryLink } from "../links/links";
import { Modal } from "react-bootstrap";
import { PrimaryButton } from "../buttons/buttons";
import { TOURS } from "../../serverVariables/tours";
import { CONTENT_TYPES } from "../../serverVariables/contentTypes";

const ClientHub = (props) => {
  const tour = useContext(ShepherdTourContext);
  // props
  const { location, history } = props;
  // state
  const { user } = props;
  // actions
  const { setClientPathwayList, fetchDaxkoUser } = props;

  // local
  const [featured, setFeatured] = useState([]);
  const [showCoachModal, setShowCoachModal] = useState(false);
  const [daxkoScope] = useState(user.organization.daxko_org);
  const barcode = useRef(user.daxko_barcode);
  const [barcodeItem, setBarcodeItem] = useState(null);
  const [openThankyou, setOpenThankyou] = useState(false);

  // check if daxko connect variables are present on query string
  const daxkoCode = parseQuery(props.location.search).code;
  const daxkoState = parseQuery(props.location.search).state;

  // Function to set a cookie
  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  // Function to get a cookie
  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  // Function to check if 7 days have passed since the last dismissal
  const hasSevenDaysPassed = () => {
    const introDismissedAt = getCookie("introDismissedAt");
    if (!introDismissedAt) {
      return true; // If no dismissal date is stored, show the modal
    }
    const dismissedTime = new Date(introDismissedAt);
    const now = new Date();
    const diffTime = Math.abs(now - dismissedTime);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays >= 7; // Return true if 7 or more days have passed
  };
  // grab client pathway on mount
  useEffect(() => {
    setClientPathwayList();
  }, []);

  // grab featured content on mount
  useEffect(() => {
    fetchFeaturedContent()
      .then((content) => {
        setFeatured(content);
        const first = content.find(
          (item) => item.content_type_id === CONTENT_TYPES.ORGANIZATION
        );
        if (first) setBarcodeItem(first.category_id);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    const intro = user.tours.find((item) => item.title === "intro");

    if (intro && intro.viewed && hasSevenDaysPassed()) {
      // Delay the modal appearance by 3 seconds (3000 milliseconds)
      const timer = setTimeout(() => {
        setShow(true);
      }, 3000);

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }

    if (!intro.viewed && !tour.isActive()) {
      tour.start();
      tourUpdate(TOURS.HABITS).catch((e) => console.log(e));
    }

    if (location.state === "start-tour" && !tour.isActive()) {
      tour.start();
      if (history.location.state) {
        history.replace({
          ...history.location,
          state: "",
        });
      }
    }
  }, [user.tours, tour, tourUpdate, location.state, history]);

  // check if user has been given coach nudge
  useEffect(() => {
    if (!user || tour.isActive()) return;
    const nudge = user.coach_nudge;
    const intro = user.tours.find((item) => item.title === "intro");
    if (
      intro.viewed &&
      !nudge &&
      moment().isAfter(moment(user.start_date).add(24, "hours"))
    ) {
      setShowCoachModal(true);
      updateCoachNudge().catch((e) => console.log(e));
    }
  }, [user]);

  // check if page was loaded from a redirect from daxko and connect user
  useEffect(() => {
    if (daxkoCode && daxkoState && !barcode.current) {
      fetchDaxkoUser(daxkoCode)
        .then((res) => {
          barcode.current = res.user.daxko_barcode;
          setOpenThankyou(true);
        })
        .catch((e) => console.error(e));
    }
  }, [daxkoCode, daxkoState, user]);

  // wait for user to load into state
  if (!user || _.isEmpty(user)) {
    return <div />;
  }
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    const now = new Date();
    setCookie("introDismissedAt", now.toISOString(), 7); // Store the dismissal date in a cookie for 7 days
  };
  return (
    <div className="pos:r" id="clientHub">
      <div className={styles.bulgeContainer}>
        <div className={styles.concave} />
        <div className={styles.bulge} />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={styles.customModalDialog}
      >
        <Modal.Header closeButton style={{ border: 0 }}>
          <Modal.Title className={styles.modalTitle}>
            Now Available!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.moduleContainer}>
            <div>
              <a
                href="https://apps.apple.com/us/app/treo-wellness/id6477540317"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={downloadappstore}
                  alt="Android Store"
                  style={{ width: "280px" }}
                />
              </a>
            </div>

            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.treowellness.treo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={downloadgoogleplay}
                  alt="Android Store"
                  style={{ width: "280px" }}
                />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container client-dash ">
        <div className="row">
          {featured.map((item, i) => {
            return (
              <div key={item.category_id} className="col-xs-12">
                <div className={i === 0 ? "tour-featured-intro" : ""}>
                  <TileTrack
                    barcode={
                      i === 0 && item.category_id === barcodeItem
                        ? barcode.current
                        : null
                    }
                    daxkoScope={
                      i === 0 && item.category_id === barcodeItem
                        ? daxkoScope
                        : null
                    }
                    openThankyou={
                      i === 0 && item.category_id === barcodeItem
                        ? openThankyou
                        : null
                    }
                    setOpenThankyou={setOpenThankyou}
                    contentTypeId={item.content_type_id}
                    icon={item.tile_image_url}
                    title={item.category_name}
                    subcategories={item.subcategories}
                    content={item.content}
                    dataCy={`habitTile_${i}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <Modal show={showCoachModal} onHide={() => setShowCoachModal(false)}>
          <div className={styles.coachOverlay}>
            <h4>Talk with a personal wellness coach!</h4>
            <p>
              You are five times more likely to achieve your health goals with
              the support of a personal wellness coach! Ready to book your free
              coach consultation?
            </p>
            <div className={styles.btns}>
              <PrimaryLink
                to={`/member/clientDash/${user.username}/Coaching-Team/coaches`}
              >
                Book Now!
              </PrimaryLink>
              <PrimaryButton onClick={() => setShowCoachModal(false)}>
                Maybe Later
              </PrimaryButton>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

function mapStateToProps({ user, clientPathwayList, dailyScore }) {
  return { user, clientPathwayList, dailyScore };
}

const actions = {
  setClientPathwayList,
  fetchDaxkoUser,
};

export default withRouter(connect(mapStateToProps, actions)(ClientHub));
