import React, { useState, useEffect } from "react";
import ToggleButton from "react-toggle-button";
import validate from "validate.js";
import { isEmpty, omitBy, isNil } from "lodash";
import axios from "axios";
import moment from "moment";

// styles
import styles from "./organizationNew.module.css";

// components
import { PrimaryButton } from "../buttons/buttons";
import { BoxContainer } from "../containers/boxContainer/boxContainer";
import { stateOptions } from "../Client/ClientProfile/StatesData";
import { ErrorAlert } from "../alerts/alerts";
import NumberFormat from "react-number-format";
import OrganizationBranding from "../organizationBranding/organizationBranding";
import ReactTooltip from "react-tooltip";
import InfoIcon from "../../images/info_icon.png";
import { setOrgEmailPreference } from "../../store/actions/organizationActions";

validate.extend(validate.validators.datetime, {
  parse: function(value, options) {
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format: function(value, options) {
    const format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
    return moment.utc(value).format(format);
  },
});

// form submission constraints
const orgConstraints = {
  name: {
    presence: {
      message: "^Organization name is required",
    },
    type: "string",
    length: {
      minimum: 1,
      maximum: 255,
      tooShort: "^Organization name is required",
      tooLong: "^Organization name must be less than 255 characters",
    },
  },
  sap: {
    presence: {
      message: "^SAP account # is required",
    },
    type: "string",
    length: {
      minimum: 1,
      maximum: 255,
      tooShort: "^SAP account # is required",
      tooLong: "^SAP account # must be less than 255 characters",
    },
  },
  employees: {
    presence: false,
    numericality: {
      onlyInteger: true,
    },
  },
  channel: {
    presence: { message: "^Channel is required" },
    type: "string",
    length: {
      minimum: 1,
      tooShort: "^Sales channel is required",
      maximum: 255,
      tooLong: "^Sales channel must be less than 255 characters",
    },
  },
  startDate: {
    presence: { message: "^Start Date is required" },
    datetime: { dateOnly: true },
  },
  endDate: {
    presence: { message: "^End Date is required" },
    datetime: { dateOnly: true },
  },
};
const contactConstraints = {
  name: {
    presence: false,
    type: "string",
    length: { maximum: 255 },
  },
  phone: {
    presence: false,
    type: "string",
  },
  email: {
    presence: false,
    type: "string",
    email: { message: "^Enter a valid email" },
    length: {
      minimum: 1,
      maximum: 255,
      tooShort: "^Email is required",
      tooLong: "^Email must be less than 255 characters",
    },
  },
  position: {
    presence: false,
    type: "string",
    length: { maximum: 255 },
  },
};
const championConstraints = {
  name: {
    presence: false,
    type: "string",
    length: { maximum: 255 },
  },
  phone: {
    presence: false,
    type: "string",
  },
};
const salesConstraints = {
  name: {
    presence: { message: "^Sales Representative is required" },
    type: "string",
    length: {
      minimum: 1,
      tooShort: "^Sales Representative is required",
      maximum: 255,
      tooLong: "^Sales Representative must be less than 255 characters",
    },
  },
  phone: {
    presence: false,
    type: "string",
  },
  email: {
    presence: true,
    type: "string",
    email: true,
    length: {
      minimum: 1,
      maximum: 255,
      tooShort: "^Email is required",
      tooLong: "^Email must be less than 255 characters",
    },
  },
};
const addressConstraints = {
  address: {
    type: "string",
    presence: false,
    length: { maximum: 255 },
  },
  state: {
    presence: { message: "^State is required" },
    type: "string",
    length: {
      minimum: 1,
      tooShort: "^State is required",
      maximum: 255,
      tooLong: "^State must be less than 255 characters",
    },
  },
  city: {
    presence: { message: "^City is required" },
    type: "string",
    length: {
      minimum: 1,
      tooShort: "^City is required",
      maximum: 255,
      tooLong: "^City must be less than 255 characters",
    },
  },
  zipcode: {
    type: "string",
    presence: false,
    length: { maximum: 255 },
  },
};
const brandingConstraints = {
  headerText: {
    presence: false,
    type: "string",
    length: {
      maximum: 255,
      tooLong: "^Text must be less than 255 characters",
    },
  },
};
const emailValidation = {
  email: {
    presence: false,
    type: "string",
    email: { message: "^Enter a valid email" },
    length: {
      minimum: 1,
      maximum: 255,
      tooShort: "^Email is required",
      tooLong: "^Email must be less than 255 characters",
    },
  },
};
const communityDisplayValidation = {
  display: {
    presence: false,
    type: "string",
    length: {
      minimum: 1,
      tooShort: "^Display name is required",
      maximum: 20,
      tooLong: "^Display name must be less than 21 characters",
    },
  },
};

const OrganizationsNew = (props) => {
  const [image, setImage] = useState(null); // custom logo file for branding
  const [total, setTotal] = useState(0); // total number of seats
  const [monthly, setMonthly] = useState(total); // monthly top range value
  const [monthlyValue, setMonthlyValue] = useState(monthly); // actual value chosen for monthly available seats
  const [formState, updateState] = useState({
    name: "",
    channel: "",
    sap: "",
    employees: "",
    startDate: "",
    endDate: "",
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    contactPosition: "",
    championName: "",
    championPhone: "",
    championEmail: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    salesName: "",
    salesNumber: "",
    salesEmail: "",
    branding: false,
    headerColor: null,
    textColor: null,
    headerText: "",
    emailList: false,
    communityShowAll: true,
    communityShowOrg: false,
    communityDisplayName: "",
    wotdAccess: false,
    signUpCall: false,
    assessmentOptional: false,
  });
  const [submissionError, setSubmissionError] = useState(null);
  const [validation, updateValidation] = useState({});

  function setForm(value) {
    updateState((prevState) => ({
      ...prevState,
      ...value,
    }));
  }

  function setValidation(value) {
    updateValidation((prevState) => ({
      ...prevState,
      ...value,
    }));
  }

  const showPanel = (button, id) => {
    // grab all panels and hide
    const panels = document.querySelectorAll(`.${styles.panel}`);
    for (const panel of panels) {
      panel.classList.remove(styles.show);
    }
    // find panel is question
    const showPanel = document.getElementById(id);
    if (showPanel) showPanel.classList.add(styles.show);

    // grab all menu items
    const menu = document.querySelectorAll(`.${styles.listItem}`);
    for (const item of menu) {
      item.classList.remove(styles.active);
    }
    button.classList.add(styles.active);
  };

  const toggle = (id) => {
    const content = document.getElementById(id);
    if (content.classList.contains(styles.show)) {
      content.classList.remove(styles.show);
    } else {
      content.classList.add(styles.show);
    }
  };

  const setBranding = (state) => {
    setForm({
      branding: state.branding,
      headerColor: state.headerColor,
      textColor: state.textColor,
      headerText: state.headerText,
    });
    setImage(state.image);
  };

  const submit = async (e) => {
    e.preventDefault();
    const errors = {};
    // validate org
    const {
      name,
      channel,
      sap,
      employees,
      startDate,
      endDate,
      emailList,
      communityShowAll,
      communityShowOrg,
      communityDisplayName,
      wotdAccess,
      signUpCall,
      assessmentOptional,
    } = formState;
    if (moment(startDate).isValid()) {
      orgConstraints.endDate.datetime.earliest = moment(startDate).utc();
    }
    const orgValues = { name, channel, sap, startDate, endDate };
    if (!isNaN(parseInt(employees))) orgValues.employees = employees;
    const orgValidation = validate(orgValues, orgConstraints);
    errors.orgValidation = orgValidation;

    // validate seats
    if (!isNil(monthlyValue)) {
      if (monthlyValue > total) {
        errors.seatValidation = {
          monthly: ["Monthly seats must be less than total seats"],
        };
      } else {
        if (validation.seatValidation) {
          errors.seatValidation = null;
        }
      }
    }

    // validate contact person
    const {
      contactName,
      contactNumber,
      contactEmail,
      contactPosition,
    } = formState;
    const contactInput = {
      name: contactName,
      phone: contactNumber,
      position: contactPosition,
    };
    if (contactEmail.length > 0) contactInput.email = contactEmail;
    const contactValidation = validate(contactInput, contactConstraints);
    errors.contactValidation = contactValidation;

    // validate champion
    const { championName, championPhone, championEmail } = formState;
    const championInput = {
      name: championName,
      phone: championPhone,
      email: championEmail,
    };
    const updatedConstraints = championConstraints;
    if (championInput.email) updatedConstraints.email = emailValidation.email;
    errors.championValidation = validate(championInput, championConstraints);

    // validate address
    const { address, city, state, zip } = formState;
    const addressValidation = validate(
      {
        address,
        state,
        city,
        zipcode: zip,
      },
      addressConstraints
    );
    errors.addressValidation = addressValidation;

    // sales validation
    const { salesName, salesEmail, salesNumber } = formState;
    const salesInput = { name: salesName, phone: salesNumber };
    if (salesEmail.length) salesInput.email = salesEmail;
    const salesValidation = validate(salesInput, salesConstraints);
    errors.salesValidation = salesValidation;

    // validate branding
    const { headerText } = formState;
    const brandingValidation = validate({ headerText }, brandingConstraints);
    errors.brandingValidation = brandingValidation;

    // validate community
    if (communityShowOrg) {
      // only test display name if org is chosen
      errors.communityDisplayValidation = validate(
        { display: communityDisplayName },
        communityDisplayValidation
      );
    }
    if (!communityShowOrg && !communityShowAll) {
      errors.communityValidation = { options: ["One option must be selected"] };
    } else {
      errors.communityValidation = null;
    }
    setValidation(errors);
    if (!isEmpty(omitBy(errors, isNil))) return;

    const formData = new FormData();
    const data = {
      name,
      sap,
      employees,
      sales_channel: channel,
      start_date: startDate,
      end_date: endDate,
      total_seats: total,
      monthly_seats: monthlyValue,
      contact_name: contactName,
      contact_phone: contactNumber,
      contact_position: contactPosition,
      contact_email: contactEmail,
      champion_name: championName,
      champion_phone: championPhone,
      champion_email: championEmail,
      address,
      city,
      state,
      zip,
      sales_rep_name: salesName,
      sales_rep_phone: salesNumber,
      sales_rep_email: salesEmail,
      brand_bg_color: formState.headerColor,
      brand_text_color: formState.textColor,
      brand_text: headerText,
      community_name: communityDisplayName,
      community_all: communityShowAll,
      community_org: communityShowOrg,
      wotd_access: wotdAccess,
      wotd_signup: signUpCall,
      assessment_optional: assessmentOptional,
    };
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (isNil(data[key]) || data[key] === "") return;
      formData.append(key, data[key]);
    });
    if (image && image.name) formData.append("brand_logo", image);
    try {
      const {
        data: { result },
      } = await axios.post("/organizations/create", formData);
      await setOrgEmailPreference(result.id, emailList);
      props.history.push(`/dashboard/Organization/${result.id}/overview`);
    } catch (e) {
      setSubmissionError(true);
      console.log(e);
    }
  };

  useEffect(() => {
    setMonthly(total);
  }, [total]);

  useEffect(() => {
    if (monthlyValue > monthly) {
      setValidation({
        ...validation,
        seatValidation: {
          monthly: ["Monthly seats must be less than total seats"],
        },
      });
    } else {
      if (validation.seatValidation) {
        setValidation({ ...validation, seatValidation: null });
      }
    }
  }, [monthly, monthlyValue]);

  return (
    <div>
      <div className="page-header-container searchForm-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav>
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item underline ">
                    <a href="/dashboard" className="text-light">
                      Dashboard
                    </a>
                  </li>
                  <span className="arrow" />
                  <li className="breadcrumb-item text-light active">
                    <a href="/dashboard/Organizations">Organizations</a>
                  </li>
                  <span className="arrow" />
                  <li className="breadcrumb-item text-light active">
                    New Organization
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-12 col-md-6">
              <h1>Add Organization</h1>
            </div>
            <div className="col-sm-12 col-md-6 text-right">
              <PrimaryButton onClick={submit} type="submit">
                + Launch New Organization
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {submissionError ? (
          <ErrorAlert>
            Unable to create Organization. If this error persists please contact
            your administrator.
          </ErrorAlert>
        ) : null}
      </div>
      <BoxContainer className={`container margin-top-2x ${styles.container}`}>
        <div className={`hidden-xs hidden-sm ${styles.listMenu}`}>
          <ul className={styles.list}>
            <li
              className={`${styles.listItem} ${styles.active}`}
              onClick={(e) => showPanel(e.target, "orgInfo")}
            >
              Organization Info
              <span className="text-danger">
                <span className={styles.errorCircle} />
                required
              </span>
            </li>
            <li
              className={styles.listItem}
              onClick={(e) => showPanel(e.target, "seats")}
            >
              Seats
            </li>
            <li
              className={styles.listItem}
              onClick={(e) => showPanel(e.target, "contactPerson")}
            >
              Contact Person
              <span className="text-danger">
                <span className={styles.errorCircle} />
                required
              </span>
            </li>
            <li
              className={styles.listItem}
              onClick={(e) => showPanel(e.target, "champion")}
            >
              Treo Champion
            </li>
            <li
              className={styles.listItem}
              onClick={(e) => showPanel(e.target, "address")}
            >
              Address
              <span className="text-danger">
                <span className={styles.errorCircle} />
                required
              </span>
            </li>
            <li
              className={styles.listItem}
              onClick={(e) => showPanel(e.target, "salesRep")}
            >
              Sales Rep
              <span className="text-danger">
                <span className={styles.errorCircle} />
                required
              </span>
            </li>
            <li
              className={styles.listItem}
              onClick={(e) => showPanel(e.target, "branding")}
            >
              Branding
            </li>
            <li
              className={styles.listItem}
              onClick={(e) => showPanel(e.target, "community")}
            >
              Community
            </li>

            <li
              className={styles.listItem}
              onClick={(e) => showPanel(e.target, "settings")}
            >
              Settings
            </li>
          </ul>
        </div>
        <form onSubmit={submit} className={styles.panelMain}>
          <div id="orgInfo" className={`${styles.panel} ${styles.show} `}>
            <h3 onClick={() => toggle("orgInfo")} className={styles.heading}>
              Organization Info
            </h3>
            <div className={styles.content}>
              <div className="row flex-wrap">
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="name">
                    Organization Name{" "}
                    <span className="text-danger">* required</span>
                  </label>
                  <input
                    value={formState.name}
                    onChange={(e) => setForm({ name: e.target.value })}
                    id="name"
                    className="form-control"
                    type="text"
                  />
                  {validation.orgValidation && validation.orgValidation.name ? (
                    <div>
                      {validation.orgValidation.name.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="sales">
                    Sales Channel{" "}
                    <span className="text-danger">* required</span>
                  </label>
                  <select
                    id="sales"
                    className="form-control"
                    value={formState.channel}
                    onChange={(e) => setForm({ channel: e.target.value })}
                  >
                    <option value="" selected disabled>
                      Select a channel
                    </option>
                    <option value="corporate">Corporate</option>
                    <option value="club">Club</option>
                    <option value="clinical">Clinical</option>
                    <option value="retail">Retail</option>
                  </select>
                  {validation.orgValidation &&
                  validation.orgValidation.channel ? (
                    <div>
                      {validation.orgValidation.channel.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="account">
                    SAP Account #<span className="text-danger">* required</span>
                  </label>
                  <input
                    value={formState.sap}
                    onChange={(e) => setForm({ sap: e.target.value })}
                    id="account"
                    className="form-control"
                    type="text"
                  />
                  {validation.orgValidation && validation.orgValidation.sap ? (
                    <div>
                      {validation.orgValidation.sap.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="account">Number of employees</label>
                  <input
                    value={formState.employees}
                    onChange={(e) => setForm({ employees: e.target.value })}
                    id="employees"
                    className="form-control"
                    type="number"
                  />
                  {validation.orgValidation &&
                  validation.orgValidation.employees ? (
                    <div>
                      {validation.orgValidation.employees.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>

                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="startDate">
                    Start Date<span className="text-danger">* required</span>
                  </label>
                  <input
                    value={formState.startDate}
                    onChange={(e) => setForm({ startDate: e.target.value })}
                    id="startDate"
                    className="form-control"
                    type="date"
                  />
                  {validation.orgValidation &&
                  validation.orgValidation.startDate ? (
                    <div>
                      {validation.orgValidation.startDate.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="endDate">
                    End Date<span className="text-danger">* required</span>
                  </label>
                  <input
                    value={formState.endDate}
                    onChange={(e) => setForm({ endDate: e.target.value })}
                    id="endDate"
                    className="form-control"
                    type="date"
                  />
                  {validation.orgValidation &&
                  validation.orgValidation.endDate ? (
                    <div>
                      {validation.orgValidation.endDate.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div id="seats" className={styles.panel}>
            <h3 onClick={() => toggle("seats")} className={styles.heading}>
              Seats
            </h3>
            <div className={styles.content}>
              <div className="row">
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="totalSeats">Total Seats</label>
                  <p
                    className={`hover ${styles.infoIcon}`}
                    data-tip="Total number of seats to be made available over the lifespan of the organization"
                  >
                    <img src={InfoIcon} alt="total seat info" />
                  </p>
                  <input
                    value={total}
                    id="totalSeats"
                    min={0}
                    max={10000}
                    onChange={(e) => setTotal(parseInt(e.target.value))}
                    className="form-control"
                    type="number"
                  />
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="limitSeats">Monthly Sign Up Limit</label>
                  <p
                    className={`hover ${styles.infoIcon}`}
                    data-tip="Number of seats available for the remainder of the month"
                  >
                    <img src={InfoIcon} alt="monthly seat info" />
                  </p>
                  <input
                    value={monthlyValue}
                    onChange={(e) => setMonthlyValue(parseInt(e.target.value))}
                    id="limitSeats"
                    min={0}
                    max={monthly}
                    className="form-control"
                    type="number"
                  />
                  {validation.seatValidation &&
                  validation.seatValidation.monthly ? (
                    <div>
                      {validation.seatValidation.monthly.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div id="contactPerson" className={styles.panel}>
            <h3
              onClick={() => toggle("contactPerson")}
              className={styles.heading}
            >
              Contact Person
            </h3>
            <div className={styles.content}>
              <div className="row">
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="contactName">Name</label>
                  <input
                    value={formState.contactName}
                    onChange={(e) => setForm({ contactName: e.target.value })}
                    className="form-control"
                    type="text"
                    id="contactName"
                  />
                  {validation.contactValidation &&
                  validation.contactValidation.name ? (
                    <div>
                      {validation.contactValidation.name.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="contactNumber">Phone Number</label>
                  <NumberFormat
                    className="form-control"
                    id="contactNumber"
                    format="(###) ###-####"
                    mask=""
                    onChange={(e) =>
                      setForm({
                        contactNumber: e.target.value.replace(/\D/g, ""),
                      })
                    }
                    value={formState.contactNumber}
                  />
                  {validation.contactValidation &&
                  validation.contactValidation.phone ? (
                    <div>
                      {validation.contactValidation.phone.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="contactPosition">Position</label>
                  <input
                    value={formState.contactPosition}
                    onChange={(e) =>
                      setForm({ contactPosition: e.target.value })
                    }
                    className="form-control"
                    type="text"
                    id="contactPosition"
                  />
                  {validation.contactValidation &&
                  validation.contactValidation.position ? (
                    <div>
                      {validation.contactValidation.position.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="contactEmail">
                    Email <span className="text-danger">* required</span>
                  </label>
                  <input
                    value={formState.contactEmail}
                    onChange={(e) => setForm({ contactEmail: e.target.value })}
                    className="form-control"
                    type="text"
                    id="contactEmail"
                  />
                  {validation.contactValidation &&
                  validation.contactValidation.email ? (
                    <div>
                      {validation.contactValidation.email.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div id="champion" className={styles.panel}>
            <h3 onClick={() => toggle("champion")} className={styles.heading}>
              Treo Champion
            </h3>
            <div className={styles.content}>
              <div className="row">
                <div className="col-xs-12">
                  <div className={`bg-info ${styles.champion}`}>
                    <h3 className="m-t:0">What is a Treo Champion</h3>
                    <p>
                      The Treo champion should spend no more than 1-3 hours per
                      month on implementing the Treo program. This individual
                      should be somebody who can commit to receiving and
                      responding to correspondence from your company's dedicated
                      Treo Account Manager that includes:
                    </p>
                    <ul>
                      <li>Updates to coaching sign-ups</li>
                      <li>
                        Updates to your company BOX folder (promotional
                        material, policies, etc.)
                      </li>
                      <li>Coaching Success Metrics</li>
                      <li>
                        Requests for phone or in-person touch bases to review
                        progress & feedback
                      </li>
                      <li>
                        Forwarding questions you receive from employees about
                        Treo
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="championName">Name</label>
                  <input
                    value={formState.championName}
                    onChange={(e) => setForm({ championName: e.target.value })}
                    className="form-control"
                    type="text"
                    id="championName"
                  />
                  {validation.championValidation &&
                  validation.championValidation.name ? (
                    <div>
                      {validation.championValidation.name.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="championNumber">Phone Number</label>
                  <NumberFormat
                    className="form-control"
                    id="championNumber"
                    format="(###) ###-####"
                    mask=""
                    onChange={(e) =>
                      setForm({
                        championPhone: e.target.value.replace(/\D/g, ""),
                      })
                    }
                    value={formState.championPhone}
                  />
                  {validation.championValidation &&
                  validation.championValidation.phone ? (
                    <div>
                      {validation.championValidation.phone.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="championEmail">Email</label>
                  <input
                    value={formState.championEmail}
                    onChange={(e) => setForm({ championEmail: e.target.value })}
                    className="form-control"
                    type="text"
                    id="championEmail"
                  />
                  {validation.championValidation &&
                  validation.championValidation.email ? (
                    <div>
                      {validation.championValidation.email.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div id="address" className={styles.panel}>
            <h3 onClick={() => toggle("address")} className={styles.heading}>
              Address
            </h3>
            <div className={styles.content}>
              <div className="row">
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="addressStreet">Address</label>
                  <input
                    value={formState.address}
                    onChange={(e) => setForm({ address: e.target.value })}
                    type="text"
                    id="addressStreet"
                    className="form-control"
                  />
                  {validation.addressValidation &&
                  validation.addressValidation.address ? (
                    <div>
                      {validation.addressValidation.address.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="addressCity">
                    City <span className="text-danger">* required</span>
                  </label>
                  <input
                    value={formState.city}
                    onChange={(e) => setForm({ city: e.target.value })}
                    type="text"
                    id="addressCity"
                    className="form-control"
                  />
                  {validation.addressValidation &&
                  validation.addressValidation.city ? (
                    <div>
                      {validation.addressValidation.city.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="addressState">
                    State <span className="text-danger">* required</span>
                  </label>
                  <select
                    value={formState.state}
                    onChange={(e) => setForm({ state: e.target.value })}
                    id="addressState"
                    className="form-control"
                  >
                    <option value="" disabled>
                      Select a state
                    </option>
                    {stateOptions.map((state) => (
                      <option value={state.value}>{state.label}</option>
                    ))}
                  </select>
                  {validation.addressValidation &&
                  validation.addressValidation.state ? (
                    <div>
                      {validation.addressValidation.state.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="addressZip">Zip Code</label>
                  <input
                    value={formState.zip}
                    onChange={(e) => setForm({ zip: e.target.value })}
                    type="text"
                    id="addressZip"
                    className="form-control"
                  />
                  {validation.addressValidation &&
                  validation.addressValidation.zipcode ? (
                    <div>
                      {validation.addressValidation.zipcode.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div id="salesRep" className={styles.panel}>
            <h3 onClick={() => toggle("salesRep")} className={styles.heading}>
              Sales Rep
            </h3>
            <div className={styles.content}>
              <div className="row">
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="salesName">
                    Name <span className="text-danger">* required</span>
                  </label>
                  <input
                    value={formState.salesName}
                    onChange={(e) => setForm({ salesName: e.target.value })}
                    className="form-control"
                    type="text"
                    id="salesName"
                  />
                  {validation.salesValidation &&
                  validation.salesValidation.name ? (
                    <div>
                      {validation.salesValidation.name.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="salesNumber">Phone Number</label>
                  <NumberFormat
                    className="form-control"
                    id="salesNumber"
                    format="(###) ###-####"
                    mask=""
                    onChange={(e) =>
                      setForm({
                        salesNumber: e.target.value.replace(/\D/g, ""),
                      })
                    }
                    value={formState.salesNumber}
                  />
                  {validation.salesValidation &&
                  validation.salesValidation.phone ? (
                    <div>
                      {validation.salesValidation.phone.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label htmlFor="salesEmail">
                    Email <span className="text-danger">* required</span>
                  </label>
                  <input
                    value={formState.salesEmail}
                    onChange={(e) => setForm({ salesEmail: e.target.value })}
                    className="form-control"
                    type="text"
                    id="salesEmail"
                  />
                  {validation.salesValidation &&
                  validation.salesValidation.email ? (
                    <div>
                      {validation.salesValidation.email.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div id="branding" className={styles.panel}>
            <h3 onClick={() => toggle("branding")} className={styles.heading}>
              Branding
            </h3>
            <div className={styles.content}>
              <div className="m-b:1">
                <h5>Branding</h5>
                <ToggleButton
                  value={formState.branding}
                  onToggle={(val) => setForm({ branding: !val })}
                />
              </div>
              <OrganizationBranding
                setState={setBranding}
                validation={validation.brandingValidation}
              />
            </div>
          </div>
          <div id="community" className={styles.panel}>
            <h3 onClick={() => toggle("community")} className={styles.heading}>
              Community
            </h3>
            <div className={styles.content}>
              <div className="row">
                <div className="col-xs-12 form-group">
                  <h4>
                    Select visible communities{" "}
                    <span className="text-danger">1 required</span>
                  </h4>
                  <div className={styles.communityCheckboxes}>
                    <div className="margin-right-25">
                      <label htmlFor="allMembers">All Members</label>
                      <input
                        id="allMembers"
                        type="checkbox"
                        checked={formState.communityShowAll}
                        onChange={(e) =>
                          setForm({ communityShowAll: e.target.checked })
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="orgMembers">Org Members</label>
                      <input
                        id="orgMembers"
                        type="checkbox"
                        checked={formState.communityShowOrg}
                        onChange={(e) =>
                          setForm({ communityShowOrg: e.target.checked })
                        }
                      />
                    </div>
                  </div>
                  {validation.communityValidation &&
                  validation.communityValidation.options ? (
                    <div>
                      {validation.communityValidation.options.map((err) => (
                        <span className="d:b text-danger">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>

                {formState.communityShowOrg ? (
                  <div className="col-xs-12 col-sm-6 col-md-4 form-group">
                    <label>Display Name</label>
                    <input
                      value={formState.communityDisplayName}
                      onChange={(e) =>
                        setForm({ communityDisplayName: e.target.value })
                      }
                      className="form-control"
                      type="text"
                    />
                    {validation.communityDisplayValidation &&
                    validation.communityDisplayValidation.display ? (
                      <div>
                        {validation.communityDisplayValidation.display.map(
                          (err) => (
                            <span className="d:b text-danger">{err}</span>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div id="settings" className={styles.panel}>
            <h3 onClick={() => toggle("settings")} className={styles.heading}>
              Settings
            </h3>
            <h4>Customize the member experience</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 7,
              }}
            >
              <div className={styles.emailList}>
                <input
                  checked={formState.wotdAccess}
                  onChange={(e) =>
                    setForm({
                      ...formState,
                      wotdAccess: e.target.checked,
                      signUpCall: e.target.checked
                        ? formState.signUpCall
                        : false, // Uncheck "Sign Up Call" when "Workout of the Day Access" is unchecked
                    })
                  }
                  id="WotdAccess"
                  value={formState.wotdAccess}
                  className="form-control"
                  type="checkbox"
                />
                <label htmlFor="WotdAccess">Workout of the Day Access</label>
              </div>
              <div style={{ marginLeft: "35px" }} className={styles.emailList}>
                <span className={styles.secondaryCheckmarkLine}></span>
                <input
                  checked={formState.signUpCall}
                  onChange={(e) => {
                    setForm({
                      ...formState,
                      signUpCall: e.target.checked,
                    });
                  }}
                  id="SignUpCall" // Change the ID to be unique
                  value={formState.signUpCall}
                  className="form-control"
                  type="checkbox"
                  disabled={!formState.wotdAccess}
                />
                <label htmlFor="SignUp">Sign up Call</label>
              </div>

              <div className={styles.emailList}>
                <input
                  checked={formState.assessmentOptional}
                  onChange={(e) =>
                    setForm({
                      ...formState,
                      assessmentOptional: e.target.checked,
                    })
                  }
                  id="Access"
                  value={formState.assessmentOptional}
                  className="form-control"
                  type="checkbox"
                />
                <label htmlFor="Optional Assessment">Optional Lifestyle Assessment</label>
              </div>

              <div className={styles.emailList}>
                <input
                  checked={formState.emailList}
                  onChange={(e) => setForm({ emailList: e.target.checked })}
                  id="subscribe"
                  className="form-control"
                  type="checkbox"
                />
                <label htmlFor="subscribe">
                  Subscribe members to email lists
                </label>
              </div>
            </div>
          </div>
        </form>
      </BoxContainer>
      <ReactTooltip
        place="top"
        type="light"
        event="click"
        effect="solid"
        globalEventOff="click"
        clickable
      />
    </div>
  );
};

export default OrganizationsNew;
