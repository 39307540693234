import axios from 'axios';

export const ORGANIZATION_LIST = 'ORGANIZATION_LIST';
export const ORGANIZATIONS_PAGE = 'ORGANIZATION_PAGE';
export const SINGLE_ORGANIZATION = 'SINGLE_ORGANIZATION';
export const SINGLE_ORGANIZATION_COACH = 'SINGLE_ORGANIZATION_COACH';
export const ORGANIZATION_COACH_LIST = 'ORGANIZATION_COACH_LIST';
export const SINGLE_ORGANIZATION_CLIENTS = 'SINGLE_ORGANIZATION_CLIENTS';
export const SINGLE_ORGANIZATION_CLIENTS_PAGE = 'SINGLE_ORGANIZATION_CLIENTS_PAGE';
export const ORGANIZATION_CODES = 'ORGANIZATION_CODES';
export const ORGANIZATION_CODES_PAGES = 'ORGANIZATION_CODES_PAGE';
export const ORGANIZATION_SEAT_HISTORY_MONTHLY = 'ORGANIZATION_SEAT_HISTORY_MONTHLY';
export const ORGANIZATION_SEAT_HISTORY_TOTAL = 'ORGANIZATION_SEAT_HISTORY_TOTAL';
export const ORGANIZATION_WAITLIST = 'ORGANIZATION_WAITLIST';
export const SET_ORGANIZATION_WAITLIST_PAGE = 'SET_ORGANIZATION_WAITLIST_PAGE';

// get list of organizations for admin
export const getOrganizations = (page, search, status) => {
    let request =  new Promise(resolve => {
        axios.get(`/organizations/all/${page}?search=${search}&status=${status}`)
            .then(res => {
                return resolve(res.data.result);
            });
    });
    return { type: ORGANIZATION_LIST, payload: request };
}

// set the page number to be requested when a list of organizations is requested
export const setOrganizationPage = (page) => {
    return { type: ORGANIZATIONS_PAGE, payload: page };
}

// get a single organizations details
export const getSingleOrganization = (id) => {
    let request =  new Promise(resolve => {
        axios.get(`/organizations/single/${id}`)
            .then(res => {
                return resolve(res.data.result);
            });
    });
    return { type: SINGLE_ORGANIZATION, payload: request };
}

// get the coaches assigned to an organization
export const getOrganizationCoaches = (organizationId) => {
    let request =  new Promise(resolve => {
        axios.get(`/organizations/get/coaches/${organizationId}`)
            .then(res => {
                return resolve(res.data.result);
            });
    });
    return { type: SINGLE_ORGANIZATION_COACH, payload: request };
}

// remove an organizations coaches from state
export const resetOrganizationCoaches = () => {
    return { type: SINGLE_ORGANIZATION_COACH, payload: [] }
}

// get list of coaches available to an organization for assignment
export const getOrganizationCoachList = (organizationId) => {
    let request =  new Promise(resolve => {
        axios.get(`/organizations/available/coaches/${organizationId}`)
            .then(res => {
                return resolve(res.data.result);
            });
    });
    return { type: ORGANIZATION_COACH_LIST, payload: request };
}

// get list of clients for a single organization and add to state
export const getOrganizationClients = (query, page) => {
    const request =  new Promise(resolve => {
        axios.post(`/user/find/clients/${page}?${query}`)
            .then(res => {
                return resolve(res.data.results);
            });
    });
    return { type: SINGLE_ORGANIZATION_CLIENTS, payload: request };
}

// set the page number to be requested when a list of organization clients is requested
export const setOrganizationClientPage = (page) => {
    return { type: SINGLE_ORGANIZATION_CLIENTS_PAGE, payload: page };
}

// reset org clients
export const resetOrganizationClients = () => {
    const initialClients = {
        page: 1,
        pages: 0,
        total: 0,
        clients: []
    };
    return { type: SINGLE_ORGANIZATION_CLIENTS, payload: initialClients }
}

// get a list of codes for an organization
export const getOrganizationCodes = (organizationId, page, search, status) => {
    let url = `/organizations/get/codes/${organizationId}/${page}`;
    if (search || status) url = `${url}?`;
    if (search) url = `${url}search=${search}&`;
    if (status) url = `${url}status=${status}&`;
    const request =  new Promise(resolve => {
        axios.get(url)
            .then(res => {
                return resolve(res.data.result);
            });
    });
    return { type: ORGANIZATION_CODES, payload: request };
}

// set the page number to be requested when a list of codes is requested
export const setOrganizationCodePage = (page) => {
    return { type: ORGANIZATION_CODES_PAGES, payload: page };
}

// get the history of an organizations monthly available seats
export const getOrganizationSeatHistoryMonthly = (organizationId, date, days) => {
    const url = `/organizations/get/monthly/seat/availability/${organizationId}?date=${date}&days=${days}`;
    const request =  new Promise(resolve => {
        axios.get(url)
            .then(res => {
                return resolve(res.data.result);
            });
    });
    return { type: ORGANIZATION_SEAT_HISTORY_MONTHLY, payload: request };
}

// get the history of an organizations total seats
export const getOrganizationSeatHistoryTotal = (organizationId, date, days) => {
    const url = `/organizations/get/total/seat/availability/${organizationId}?date=${date}&days=${days}`;
    const request =  new Promise(resolve => {
        axios.get(url)
            .then(res => {
                return resolve(res.data.result);
            });
    });
    return { type: ORGANIZATION_SEAT_HISTORY_TOTAL, payload: request };
}

// get waitlist of an organization
export const getOrganizationWaitlist = (organizationId, page) => {
    const url = `/organizations/get/waitlist/${organizationId}/${page}`;
    const request =  new Promise(resolve => {
        axios.get(url)
            .then(res => {
                return resolve(res.data.result);
            });
    });
    return { type: ORGANIZATION_WAITLIST, payload: request };
}

// set the page number to be requested when a list of codes is requested
export const setOrganizationWaitlistPage = (page) => {
    return { type: SET_ORGANIZATION_WAITLIST_PAGE, payload: page };
}