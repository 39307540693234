import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";

// styles
import styles from './congratulations.module.css';

// components
import Rings from '../../../images/treo-logo-ring.png';
import Splash from '../../../images/explosion.png';

const Congratulation = props => {
    // props
    const {user, pathwayId, history} = props;
    useEffect(() => {
        setTimeout(() => history.push(`/member/clientDash/${user.username}/pathways/${pathwayId}`), 5000);
    }, []);
    return (
        <div className={styles.container}>
            <span className={styles.congratulations}>CONGRATULATIONS</span>
            <div className={styles.ringContainer}>
                <img className={styles.splash} src={Splash} alt=""/>
                <img className={styles.rings} src={Rings} alt=""/>
            </div>
            <h2 className={styles.subHeading}>YOUR JOURNEY STARTS NOW!</h2>
        </div>
    )
}

export default withRouter(Congratulation);