import React, {useEffect, useRef} from 'react';

// hooks
import useDidMountEffect from "../../../hooks/useDidMountEffect";

// styles
import styles from './likeReaction.module.css';

// images
import {ReactComponent as LikeIcon} from '../../../images/icons/Victory_Reactions_Like.svg';
import {ReactComponent as LikeFillIcon} from '../../../images/icons/Victory_Reactions_LikeFill.svg';

const LikeReaction = props => {
    // props
    const {color, stroke, animatedColor, animatedStroke, active, callback, disabled} = props;
    // local
    const icon = useRef();
    const iconFill = useRef();

    useEffect(() => {
        if (active) {
            icon.current.classList.add(styles.noAnimationFill);
            iconFill.current.classList.add(styles.noAnimationFill);
        }
    }, []);

    useDidMountEffect(() => {
        if (active) runAnimation();
        else removeAnimation();
    }, [active]);

    function runAnimation() {
        if (disabled) return;
        icon.current.classList.add(styles.animate);
        iconFill.current.classList.add(styles.animate);
    }

    function removeAnimation() {
        if (disabled) return;
        icon.current.classList.remove(styles.noAnimationFill);
        iconFill.current.classList.remove(styles.noAnimationFill);
        icon.current.classList.remove(styles.animate);
        iconFill.current.classList.remove(styles.animate);
    }

    function click() {
        if (disabled) return;
        callback(!active);
    }

    const style = {};
    if (color) style.fill = color;
    if (stroke) style.stroke = stroke;

    const filledStyle = {};
    if (animatedColor) filledStyle.fill = animatedColor;
    if (animatedStroke) filledStyle.stroke = animatedStroke;

    return (
        <div {...props} className={`${props.className} ${styles.container}`} onClick={click} data-cy="likeReactionBtn">
                <LikeFillIcon
                    ref={iconFill}
                    className={styles.iconFilled}
                    style={filledStyle}/>
                <LikeIcon
                    ref={icon}
                    className={styles.icon}
                    style={style}/>
        </div>
    )
}

export default LikeReaction;