import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import parse from "date-fns/parse";
import format from "date-fns/format";

// styles
import styles from './levelActivityGraph.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";
import {BADGE_IDS, BADGE_URLS} from "../../../../serverVariables/badges";

const LevelActivityGraph = props => {
    // props
    const {data} = props;

    if (!data) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }

    const dateFormatter = date => {
        const result =  parse(date, 'yyyy-MM-dd', new Date()).getTime();
        return format(result, 'MM/dd/yy');
    };

    const CustomizedDot = (props) => {
        const {cx, cy, payload} = props;
        if (payload.level_id) {
            let url = '';
            if (payload.level_id === BADGE_IDS.TRAILBLAZER || payload.level_id === BADGE_IDS.TRAILHEAD) url = BADGE_URLS.TRAILBLAZER;
            else if (payload.level_id === BADGE_IDS.COURAGEOUS) url = BADGE_URLS.COURAGEOUS;
            else if (payload.level_id === BADGE_IDS.EXPLORER) url = BADGE_URLS.EXPLORER;
            else if (payload.level_id === BADGE_IDS.ACCEPTING) url = BADGE_URLS.ACCEPTING;
            else if (payload.level_id === BADGE_IDS.ENTHUSIASTIC) url = BADGE_URLS.ENTHUSIASTIC;
            else if (payload.level_id === BADGE_IDS.PROACTIVE) url = BADGE_URLS.PROACTIVE;
            else if (payload.level_id === BADGE_IDS.CREATIVE) url = BADGE_URLS.CREATIVE;
            else if (payload.level_id === BADGE_IDS.PERSEVERANCE) url = BADGE_URLS.PERSEVERANCE;
            else if (payload.level_id === BADGE_IDS.TENACITY) url = BADGE_URLS.TENACITY;
            else if (payload.level_id === BADGE_IDS.DETERMINED) url = BADGE_URLS.DETERMINED;
            else if (payload.level_id === BADGE_IDS.COMMITTED) url = BADGE_URLS.COMMITTED;
            else if (payload.level_id === BADGE_IDS.DEPENDABLE) url = BADGE_URLS.DEPENDABLE;
            else if (payload.level_id === BADGE_IDS.GRIT) url = BADGE_URLS.GRIT;
            else url = BADGE_URLS.TRAILBLAZER;
            return (
                <image x={cx - 20} y={cy - 20} width={40} height={40} viewBox="0 0 1024 1024" xlinkHref={url}/>
            );
        }
        return (
            <svg></svg>
        )
    };

    return (
        <div>
            <div className={styles.container}>
                <ResponsiveContainer width="100%" height="100%" debounce={1}>
                    <LineChart
                        data={data}
                        margin={{
                            top: 15,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <XAxis
                            dataKey="date"
                            tickFormatter={dateFormatter}/>
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="total" stroke="#4BBC4E" dot={<CustomizedDot />} isAnimationActive={false}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default LevelActivityGraph;