import React, {Component} from 'react';
import {fetchSelf} from "../../../actions/action_user";
import {connect} from 'react-redux';
import {ImageUpload} from "../Account/imageUpload/imageUploadContainer";
import axios from 'axios';
import { Alert} from 'react-bootstrap';

let data = new FormData();

class EditTeammate extends Component{
    constructor(){
        super();
        this.state = {
          profile_image:'',
          nickname:'',
        }
    this.handleDismiss = this.handleDismiss.bind(this);
    }

    componentDidMount(){
      this.setState({                                   
        email:this.props.email,
        firstName:this.props.firstName,
        lastName:this.props.lastName,
        imageURI:this.props.profileImage,
        nickname:this.props.nickname,
      })
    }

    setProfileImage(file){
      data = new FormData();
      data.append('image', file);
    };

    errorHandler = (error)=>{
        this.setState({error:error});
        this.setState({ show: true });
        this.setState({alert:error});
        this.setState({ alertStyle: false });
    };

    clickImg = (confirmation) => {
        if(!confirmation){
          this.setState({imgUploaded:false})
        }
        if(confirmation){
          this.setState({imgUploaded:true})
        }
    }
  
  readURI(e){
    if(e.target.files && e.target.files[0]){
      let reader = new FileReader();
      reader.onload = function(ev){
        this.setState({imageURI:ev.target.result});
      }.bind(this);
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  
  handleChange(e){
    this.readURI(e); 
    if (this.props.onChange !== undefined)
      this.props.onChange(e); 
  }

    handleDismiss() {
      this.setState({ show: false });
    }

    modifyTeammate(e){
      e.preventDefault()
      this.setState({ show: false });
      data.append('id', this.props.teammateId);
      data.append('email', this.state.email);
      data.append('nickname', this.state.nickname);
      data.append('first_name', this.state.firstName);
      data.append('last_name', this.state.lastName);
      axios.put(`/teammates`, data)
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.props.handleClose();
                this.props.updateList();
            }})
        .catch((error)=>{
          let alert = "error";
          if(error.response.data.err.teammate_email){
            alert = error.response.data.err.teammate_email[0]
          }
          else{
            alert = error.response.data.err
          }
          this.setState({show: true, alert: alert, alertStyle: false})
        });
        data = new FormData();
    }

    deleteTeammate(e){
      e.preventDefault()
      this.setState({ show: false });
      axios.delete(`/teammates/${this.props.teammateId}`, {
        // id:this.props.teammateId
      })
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.props.handleClose();
                this.props.updateList();
            }})
        .catch((error)=>{
           
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
    }

    render(){
      
      let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
      return(
        <div className="admin-edit-teammate">
          <form onSubmit={(e)=>this.modifyTeammate(e)}>
            <div className="row">
              <div className="col-md-6">
                <div className="profile-img-container" onChange={this.handleChange.bind(this)}>
                    <ImageUpload 
                     image={this.state.imageURI} 
                     setProfileImage={this.setProfileImage}
                     errorHandler={this.errorHandler}
                     clickImg={this.clickImg}
                    />
                </div>
              </div> 
              <div className="col-md-6">
                <button className="btn delete-equip-btn delete--icon float-right" type="button" onClick={(e)=>this.deleteTeammate(e)}>DELETE TEAMMATE</button>
              </div>
            </div>
              <div className="">
                    <div className="row">
                          <div className="col-md-12">
                            <div className='form-group'>
                                <label>NICKNAME</label>       
                                <input name="nickname" type="text"
                                value={this.state.nickname}
                                onChange={(event)=>this.setState({nickname:event.target.value})}
                                 />
                            </div>
                          </div>
                    </div>
                    <div className="row">
                          <div className="col-md-12">
                            <div className='form-group'>
                                <label>FIRSTNAME</label>       
                                <input name="firstname" type="text"
                                value={this.state.firstName}
                                onChange={(event)=>this.setState({firstName:event.target.value})}
                                 />
                            </div>
                          </div>
                    </div>
                    <div className="row">
                          <div className="col-md-12">
                            <div className='form-group'>
                                <label>LAST NAME</label>       
                                <input name="nickname" type="text"
                                value={this.state.lastName}
                                onChange={(event)=>this.setState({lastName:event.target.value})}
                                 />
                            </div>
                          </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='form-group'>
                                <label>EMAIL ADDRESS</label>   
                                <input 
                                type="email" 
                                value={this.state.email}
                                onChange={(event)=>this.setState({email:event.target.value})} 
                                />
                            </div>
                        </div>
                    </div>
              </div>
              <button className="btn primary" type="submit">SAVE CHANGES</button>
              {this.state.show
                  ?  <Alert className="save-profile-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                        <p>{this.state.alert}</p>
                      </Alert>
                  : null
                }
          </form>
        </div>
        )
    }
}


function mapStateToProps({user}){
    return{user}
}

export default connect(mapStateToProps, {fetchSelf})(EditTeammate);
