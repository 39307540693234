import mainApi from "./mainApi";

export function communityApi() {
    return {
        // get reaction feed
        getReactions: function(reactionId, limit, userId) {
            let url = '/victory/member/reaction/feed/';
            if (reactionId) url += reactionId;
            if (limit) url += `?limit=${limit}`;
            if (userId) url += `?user_id=${userId}`;
            return mainApi.get(url)
        },
        // get all victory feed
        getVictories: function(orgOnly, victoryId) {
            let url = '/victory/get/all/victories/';
            if (victoryId) url += victoryId;
            if (orgOnly) url += '?org=true';
            return mainApi.get(url);
        },
        // add a reaction to a victory
        reactToVictory: function(victoryId, reactionTypeId) {
            return mainApi.put(`/victory/record/reaction/${victoryId}/${reactionTypeId}`);
        },
        // get a list of a members victories
        getMemberVictories: function(victoryId, userId) {
            let url = '/victory/get/member/victories/';
            if (victoryId) url += victoryId
            if (userId) url += `?user_id=${userId}`;
            return mainApi.get(url);
        },
        // get number of notifications waiting
        getCommunityNotifications: function() {
            return mainApi.get('/victory/unseen/reaction/count');
        },
        // get another members victories
        getOthersVictories: function(userId, victoryId) {
            let url = `/victory/profile/${userId}/`;
            if (victoryId) url += victoryId;
            return mainApi.get(url);
        },
        // mark reaction(s) as seen
        updateReactionSeen: function(reactionIds) {
            return mainApi.post('/victory/reaction/seen', {reaction_ids: reactionIds});
        },
        // get the reactions a user has made
        getOwnReactions: function(reactionId, userId) {
            let url = '/victory/member/reaction/output/feed/';
            if (reactionId) url += reactionId;
            if (userId) url+= `?user_id=${userId}`;
            return mainApi.get(url);
        },
        // record a profile view
        updateProfileView: function(userId) {
            return mainApi.put(`/user/profile/view/${userId}`);
        },
        // get community stats for member
        getCommunityStats: function(userId) {
            return mainApi.get(`/victory/community/stats/${userId}`);
        },
        // get the individual reactions of a single victory
        getSingleReactions: function(victoryId, reactionId) {
            let url = `/victory/${victoryId}/reactions`;
            if (reactionId) url += `?reaction_id=${reactionId}`;
            return mainApi.get(url);
        }
    }
}