import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

// styles
import styles from './justForYouContent.module.css';

// actions
import { fetchMemberContent } from "../../store/actions/pathwaysActions";

// components
import {ProgressBar} from "react-bootstrap";
import {ContentLink} from "../links/links";
import checkmark from "../../images/checkmark.png";
import {ReactComponent as Plus} from '../../images/circle-plus_icon.svg';
import NoodleImage from "../../images/noodle-overlay.png";
import ClientHeader from "../clientHeader/clientHeader";

const JustForYouContent = props => {
    // props
    const {match} = props;
    // state
    const {user} = props;
    // local
    const [ state, updateState ] = useState({
        content: null,
        overview: null,
        loading: true,
    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    useEffect(() => {
        fetchMemberContent().getJustForYou()
            .then(jfy => {
                const overview = jfy.content.find(item => item.overview);
                setState({ loading: false, content: jfy, overview });
            })
            .catch(e => console.log(e));
    }, []);

    if (state.loading) return <div/>;
    return (
        <>
            <ClientHeader type={'transparent'} backUrl={`/member/pathways/${user.username}`}/>
            <div>
                <div
                    style={{ backgroundImage: `url(${state.content.hero_image_url})`}}
                    className={`wellness-bg wydBG pathwaysBackground`}>
                    <div className='pathwaysOverlay'/>
                    <div className={`container client-dash ${!state.overview ? 'no-overview' : ''}`}>
                        <div className="row">
                            <div className="col-xs-12">
                                <div>
                                    <h2 className="no-margin t-t:c">{state.content.category_name}</h2>
                                    <p>{state.content.description}</p>
                                </div>
                                <div className='fullWidth'>
                                    { state.content.count > 0 ?
                                        <ProgressBar className='w:2of4' now={(state.content.complete / state.content.count)*100}/>
                                        : null
                                    }
                                    <p className="progressbar-p t-t:n">{state.content.complete} of {state.content.count}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`container client-dash ${!state.overview ? 'no-overview' : ''}`}>
                    <div className="row">
                        { state.overview ?
                            <div className="col-xs-12">
                                <ContentLink
                                    to={`/member/pathways/${user.username}/content/${state.overview.id}?pathwayId=just_for_you&lifestyleId=${state.content.category_id}&just_for_you=true`}
                                    text={state.overview.title}
                                    image={state.overview.completed ? { src: checkmark, alt: 'checkmark'} : <i className="fas fa-caret-right img"/>}
                                    background='#6BA543 no-repeat center right'
                                    color='#fff'
                                    tileImage={NoodleImage}
                                    className='video'
                                />
                            </div>
                            : null
                        }
                        {state.content.subcategories.map(category => (
                            <div className="col-xs-12 col-sm-6 contentLink">
                                <ContentLink
                                    disabled={ !category.content.length}
                                    key={category.category_id}
                                    text={category.category_name}
                                    image={category.complete === category.count && category.count > 0 ? { src: checkmark, alt: 'checkmark' } : null}
                                    tileImage={category.tile_image_url}
                                    overlay
                                    to={`${match.url}/category/${category.category_id}`}

                                >
                                    <div className='fullWidth'>
                                        { category.count > 0 ?
                                            <ProgressBar className='w:2of4' now={(category.complete / category.count)*100}/>
                                            : null
                                        }
                                        { category.count > 0 ?
                                            <p className='t-t:n'>{category.complete} of {category.count}</p>
                                            :
                                            <p className='green comingSoon'>Content Coming Soon</p>
                                        }
                                    </div>
                                </ContentLink>
                            </div>
                        ))}
                    </div>
                    <Link to={`${match.url}/add`} className={`${styles.add} tour-jfy-add`}>Add New Content <Plus/></Link>
                </div>
            </div>
        </>

    )
};

function mapStateToProps({ user }) {
    return { user };
}

export default connect(mapStateToProps)(JustForYouContent);