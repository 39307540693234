import React, {Component} from 'react';

class FileUpload extends Component {
    // eslint-disable-next-line 
    constructor(props){
        super(props);
    }


    render() {
        if(this.props.file){
            return(
                <div className="row">
                    <div className='col-sm-12'>
                        <input
                            value={this.props.file.name || this.props.file}
                            readOnly
                            placeholder={this.props.file.name || this.props.file}
                            type='text'
                        />
                        <span onClick={(e)=>{
                            this.props.changeFile(null);
                        }}>&times; Remove</span>
                    </div>
                </div>
            )
        }else{
            return(
                <div>
                    <input
                        type='file'
                        onChange={(event) =>{
                            this.props.changeFile(event.target.files[0])
                        }}
                    />
                </div>
            )
        }
    }
}

export default FileUpload;