// npm modules
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// actions
import { setClientPathway } from "../../store/actions/pathwaysActions";

// components
import ClientHeader from "../clientHeader/clientHeader";
import ClientPathwayReportContainer from "./clientPathwayReportContainer";
import _ from 'lodash';

const ClientPathwayReportRoute = props => {
    const { user } = props;
    // actions
    const { setClientPathway } = props;
    // props
    const { match } = props;

    // grab pathways id from route on mount
    useEffect(() => {
        const pathwayId = match.params.pathwayId;
        setClientPathway(pathwayId);
    }, []);

    return (
        <div className='client-container'>
            <ClientHeader backUrl={`/member/clientDash/${user.username}`}/>
            <div>
                { user && !_.isEmpty(user) ? <ClientPathwayReportContainer/> : null }
            </div>
        </div>
    )
};

function mapStateToProps({ user }) {
    return { user };
}

const actions = {
    setClientPathway,
};

export default connect(mapStateToProps, actions)(ClientPathwayReportRoute);