// npm modules
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {connect} from "react-redux";

// components
import Loading from "../Loading/Loading";
import BodyCompIcon from "../../images/icon-pathwayreport-primary_bodycomp.png";
import ActivityIcon from "../../images/icon-pathwayreport-primary_activity.png";
import BrainHealthIcon from "../../images/icon-pathwayreport-primary_brainhealth.png";
import EnduranceIcon from "../../images/icon-pathwayreport-primary_endurance.png";
import EnergyIcon from "../../images/icon-pathwayreport-primary_energy.png";
import FoodChoicesIcon from "../../images/icon-pathwayreport-primary_foodchoices.png";
import SpeedIcon from "../../images/icon-pathwayreport-primary_speed.png";
import WeightLossIcon from "../../images/icon-pathwayreport-primary_weightloss.png";
import BetterBloodPressureIcon from '../../images/icon-pathway-bbp.png';
import BetterBloodSugarIcon from '../../images/icon-pathway-bbs.png';
import {HabitTileIcon} from "../habitTileIcon/habitTileIcon";

const ClientPathwayPrimary = ({user, clientPathway}) => {
    function primaryPathway(goal) {
        switch (goal.health_goal_name) {
            case "Lasting Weight Loss":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Lasting Weight Loss
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={WeightLossIcon}
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-07.png'
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                        </div>
                    </div>
                );
            case "Build a Stronger Leaner Body":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Improved Body Composition
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={BodyCompIcon}
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-11.png'
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                        </div>
                    </div>
                );
            case "Improve Heart and Cognitive Health":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Improved Heart &amp; Cognitive Health
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={BrainHealthIcon}
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-10.png'
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                        </div>
                    </div>
                );
            case "More Energy, Less Stress":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Increased Daily Energy
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={EnergyIcon}
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-08.png'
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                        </div>
                    </div>
                );
            case "Make Physical Activity a Regular Habit":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Making Activity A Regular Habit
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={ActivityIcon}
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-12.png'
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                        </div>
                    </div>
                );
            case "Healthier Food Choices":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Making Healthier Food Choices
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={FoodChoicesIcon}
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-14.png'
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                        </div>
                    </div>
                );
            case "Improve Endurance":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Improved Endurance
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={EnduranceIcon}
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-09.png'
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                        </div>
                    </div>
                );
            case "Improve Speed and Strength":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Improved Speed &amp; Strength
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={SpeedIcon}
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-13.png'
                                    alt="Energy | Treo Wellness"
                                />
                            </div>
                        </div>
                    </div>
                );
            case "Better Blood Pressure":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Better Blood Pressure
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={BetterBloodPressureIcon}
                                    alt=""
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-10.png'
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                );
            case "Better Blood Sugar":
                return (
                    <div>
                        <h3 className="no-margin-top">
                            Habit Tiles For Better Blood Sugar
                        </h3>
                        <div className="flex-row">
                            <div className="goal-icon">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src={BetterBloodSugarIcon}
                                    alt=""
                                />
                            </div>
                            <div className="goal-tiles">
                                <img
                                    className="margin-top-2x margin-bottom"
                                    src='https://treo.s3.us-east-2.amazonaws.com/assets/pathways-habit-groups/HabitTileCharts-10.png'
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                );
            // no default
        }
    }

    function primaryResearch(goal) {
        switch (goal.health_goal_name) {
            case "Lasting Weight Loss":
                return <WeightLoss/>;
            case "Build a Stronger Leaner Body":
                return <BodyComposition/>;
            case "Improve Heart and Cognitive Health":
                return <HeartAndBrain/>;
            case "More Energy, Less Stress":
                return <MoreEnergy/>;
            case "Make Physical Activity a Regular Habit":
                return <PhysicalActivity/>;
            case "Healthier Food Choices":
                return <FoodChoices/>;
            case "Improve Endurance":
                return <Endurance/>;
            case "Improve Speed and Strength":
                return <SpeedAndStrength/>;
            case "Better Blood Pressure":
                return <BetterBloodPressure/>;
            case "Better Blood Sugar":
                return <BetterBloodSugar/>;
            // no default
        }
    }

    if (!clientPathway || _.isEmpty(clientPathway)) {
        return <Loading/>;
    }

    // no health goals were created with this assessment
    if (clientPathway.health_goals.length === 0) {
        return <div/>;
    }
    const plural =
        user.first_name && user.first_name[user.first_name.length - 1] === "s";
    return (
        <div className="container primary-pathway">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
                <div className="content-container">
                    <h1>
                        {plural ? `${user.first_name}'` : `${user.first_name}'s`} Pathway
                    </h1>
                    {primaryPathway(clientPathway.health_goals[0])}
                    <div className="primary-pathway-examples">
                        <h4>
                            Examples of some of the research findings are summarized and cited
                            below:
                        </h4>
                        {primaryResearch(clientPathway.health_goals[0])}
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps({user}) {
    return {user};
}

export default connect(mapStateToProps)(ClientPathwayPrimary);

ClientPathwayPrimary.propTypes = {
    clientPathway: PropTypes.shape({
        health_goals: PropTypes.array
    })
};

function WeightLoss() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        A review of 28 studies showed that exercise causes moderate weight
                        loss independent of dieting and that exercise conserves muscle mass
                        as weight is lost. <sup>1</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Regular physical activity is a key behavior associated with
                        long-lasting weight loss. <sup>2</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        With the exception of fruit juice, fruits and vegetables supports
                        weight loss efforts. <sup>3,4,5</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={6}/>
                    </div>
                    <div className="research-text">
                        Mealtime Spacing durations of 16 hours decreased weight in males who
                        were highly active, <sup>6</sup> and in sedentary obese adults. <sup>7</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={6}/>
                    </div>
                    <div className="research-text">
                        Mealtime Spacing for 10 hours in overweight adults resulted in a 4%
                        weight loss that was maintained one year later. <sup>8</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={1}/>
                    </div>
                    <div className="research-text">
                        Although the quality of snacks consumed is a major determinant of
                        weight, detailed food logs show that the optimum daily meal
                        frequency for weight loss is less than 4 eating occasions per day,
                        while eating more than 6 times per day is strongly associated with
                        weight gain. <sup>9</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={1}/>
                    </div>
                    <div className="research-text">
                        Over fifty-thousand individuals were studied for a year. Eating
                        fewer meals per day was associated with weight loss, and weight gain
                        occurred in proportion to the number of eating occasions beyond 3
                        meals a day. <sup>10</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={1}/>
                    </div>
                    <div className="research-text">
                        Increasing the number of eating occasions promotes weight gain in
                        some people, especially if eating is not based on sensations of
                        hunger. <sup>11</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BodyComposition() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={7}/>
                    </div>
                    <div className="research-text">
                        Sprint Intensity intervals can be performed in the same week as
                        Strength Building Activities without interfering with muscle growth
                        or improved strength. <sup>1</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={11}/>
                    </div>
                    <div className="research-text">
                        As weight is lost, exercise programs that include Strength Building
                        Activities spare the loss of muscle by half that of those who lose
                        weight and do not strength train. <sup>2,3</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={7}/>
                    </div>
                    <div className="research-text">
                        Sprint intensity interval training takes 40% less time for the same
                        degree of fat loss compared to workouts that use
                        continuous-intensity exercise. <sup>4</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={7}/>
                    </div>
                    <div className="research-text">
                        Interval training that reaches high intensity has been shown to burn
                        fat body-wide, causing less “total, abdominal, and visceral fat
                        mass.” <sup>5</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={4}/>
                    </div>
                    <div className="research-text">
                        Consuming protein <i>during regular mealtimes</i> supports muscle
                        growth & recovery, and muscle strength is maximized by consuming
                        adequate protein in combination with strength building activities. <sup>6</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Focusing on sleep quality supports weight loss in several ways, for
                        example, adequate sleep helps us avoid extra snacking opportunities
                        and helps us lose more body fat. <sup>7</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Improving your sleep habits can increase your odds of weight loss
                        success by 33%. <sup>8</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Sleep restriction increases hunger and appetite, and hinders your
                        ability to regulate your weight. <sup>9 10</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function HeartAndBrain() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        A plant-based diet helps your blood vessels direct blood flow more
                        effectively through boosting levels of nitric oxide in the blood, <sup>1</sup>
                        resulting in improved exercise capabilities. <sup>2</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Diets that feature high levels of vegetables reduce blood pressure,
                        targeting a major risk factor for cognitive decline. <sup>3</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Low-sugar diets that include plenty of vegetables predict slower
                        rates of cognitive decline. <sup>4,5</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">

                    </div>
                    <div className="research-text">
                        Refined sugar impairs memory in healthy adults <sup>6</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        In addition better blood sugar, physical activity increases the size
                        of brain regions that control learning and memory and can improve
                        memory. <sup>7</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Staying active with regular physical activity preserves brain blood
                        flow with age. <sup>8</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        As you sleep, your brain has a chance to remove toxic molecules that
                        accumulate during waking hours, and when sleep is restricted, these
                        toxins build up <sup>9</sup> and increase the risk of dementia. <sup>10</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Insufficient sleep is a risk factor for heart disease <sup>11</sup> because it worsens hormone
                        regulation, appetite, blood sugar and
                        blood pressure. <sup>12</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function MoreEnergy() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Regular physical activity increases your capacity to produce energy
                        and results in lower levels of fatigue <sup>1,2</sup> and depressed
                        mood, benefits that begin to accrue after a single bout of exercise. <sup>3</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={9}/>
                    </div>
                    <div className="research-text">
                        Specific activities that have been shown to benefit wellbeing and
                        feelings of daily energy include: listening or playing music, <sup>4</sup>{" "}
                        stretching and yoga, <sup>5,6,7</sup> mindfulness practices or
                        meditation, <sup>8,9,10</sup> spending time with family, friends
                        and pets, <sup>11</sup> and tending to plants. <sup>12,13</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Even a single night of inadequate sleep decreases your ability to
                        produce energy and manage your blood sugar levels the next day. <sup>14</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Chronic sleep deprivation strongly contributes to feelings of daily
                        fatigue but increasing the amount of sleep you get each night
                        significantly reduces feelings of fatigue and can empower you to
                        complete planned exercise sessions. <sup>15,16</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Studies show that within two weeks of adjusting one’s diet to
                        include more vegetables and fruit, individuals report a
                        significantly improved mood and vitality, and higher levels of
                        motivation. <sup>17,18</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Diets rich in vitamin C from whole-food sources improve mood for
                        those with poorer mood, reduce feelings of fatigue, and increase
                        vigor (feeling cheerful, lively and energetic). <sup>19,20</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function PhysicalActivity() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        <i>
                            The number of tracking entries and consistency of tracking
                            exercise correlates
                        </i> to how quickly physical activity will become a habit. <sup>1,2</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Focus less on long-term outcomes and more on the immediate benefits
                        of exercise that begin after the very first workout, <sup>3</sup> such as less stress, improved
                        mood and wellbeing. <sup>4,5</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Accelerate habit development by anchoring your workouts to a
                        behavior you already do regularly. <sup>6,7</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        A study on exercise habits found that the minimum amount was 4 times
                        per week for 6 weeks until exercise sessions become true habits that
                        became easy to initiate. <sup>8</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={4}/>
                    </div>
                    <div className="research-text">
                        Consuming protein during regular mealtimes supports the growth and
                        recovery of your muscles and supports an active lifestyle. <sup>9,10,11</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={11}/>
                    </div>
                    <div className="research-text">
                        Strength can be gained without reaching high levels of discomfort or
                        fatigue, provided workouts are consistently completed. <sup>12</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={9}/>
                    </div>
                    <div className="research-text">
                        Making time for daily Stress Releasing Activities can improve
                        wellbeing and help you prioritize an active lifestyle. Specific
                        activities shown to provide these benefits and enhance feelings of
                        daily energy include: listening or playing music, <sup>13</sup> stretching and
                        yoga, <sup>14,15,16</sup> mindfulness practices or
                        meditation, <sup>17,18,19,20</sup>
                        spending time with family, friends & pets, <sup>21,22</sup> and
                        tending to plants. <sup>23,24</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FoodChoices() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={3}/>
                    </div>
                    <div className="research-text">
                        Substituting sugar-sweetened beverages with healthier alternatives
                        helps you manage blood sugar and achieve a healthy body weight, <sup>1,2,3,4</sup> & this has
                        been confirmed using randomized
                        clinical trials, the highest standard of research studies. <sup>5</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={2}/>
                    </div>
                    <div className="research-text">
                        Diets that rapidly increase blood sugar result in (38%) more
                        depression symptoms, (55%) more mood disturbances, and (26%) more
                        fatigue compared to diets that are low in refined sugar. <sup>6</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        A plant-based diet helps your blood vessels direct blood flow more
                        effectively through boosting levels of nitric oxide in the blood, <sup>7</sup>
                        resulting in improved exercise capability and lower blood pressure. <sup>8</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={2}/>
                    </div>
                    <div className="research-text">
                        We often seek sweets in an attempt to raise a negative mood and low
                        energy levels, but sweets boost energy for less than an hour, then
                        leave you worse off than before. Studies show that your energy
                        levels and mood are most improved by a diet low in refined sugar. <sup>9,10,11</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={4}/>
                    </div>
                    <div className="research-text">
                        Maintain your muscle mass and enjoy the fullness-promoting benefit
                        of having protein with every meal. <sup>12</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Making a conscious decision to eat healthier and tracking what you
                        eat increase health awareness and primes your food choices. These
                        behaviors increase the value of nutrient-dense foods, so you’ll be
                        more likely to make optimal selections. <sup>13,14,15</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Endurance() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Monitoring your physical activity helps you prioritize regular
                        exercise sessions, and the number of tracking entries and
                        consistency of tracking correlates to how quickly physical activity
                        will become a habit. <sup>1,2</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        The benefits of physical activity begin after one workout. <sup>3</sup> The habit of regular
                        exercise reduces stress, and
                        improves mood and wellbeing. <sup>4,5</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        To accelerate habit development, anchor workouts to a behavior that
                        regularly occurs to help you exercise at the same time/place on a
                        set schedule. <sup>6,7</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <img src={SpeedIcon} alt="Speed Icon"/>
                    </div>
                    <div className="research-text">
                        As few as six Sprint Intensity interval workouts has been shown to
                        improve endurance <sup>8</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <img src={SpeedIcon} alt="Speed Icon"/>
                    </div>
                    <div className="research-text">
                        Interval training improves endurance despite shorter workouts than
                        endurance training <sup>9</sup> because it induces many of the exact
                        same adaptations.<sup>10,11,12</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <img src={SpeedIcon} alt="Speed Icon"/>
                    </div>
                    <div className="research-text">
                        Sprint intensity interval training is more effective at increasing
                        fitness than endurance training according to an analysis of 28
                        studies. <sup>13</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        A plant-based diet helps blood vessels direct blood flow more
                        effectively by increasing nitric oxide in the blood, <sup>14</sup> improving exercise
                        capacity. <sup>15</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Sleep strongly influences exercise motivation; when you don’t get
                        enough sleep, workout sessions feel significantly more demanding. <sup>16</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Inadequate sleep reduces the total work output of your exercise
                        session and decreases your total time till exhaustion. <sup>17,18,19,20</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Prioritizing sleep helps you maximize your training effectiveness
                        and endurance.<sup>21,22</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SpeedAndStrength() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <img src={SpeedIcon} alt="Speed Icon"/>
                    </div>
                    <div className="research-text">
                        Sprint Intensity training is a very time-effective strategy for
                        improving your speed. <sup>1,2,3,4,5</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <img src={SpeedIcon} alt="Speed Icon"/>
                    </div>
                    <div className="research-text">
                        High intensity intervals potently stimulate Growth Hormone release, <sup>6</sup>
                        resulting in fat burning and muscle growth. <sup>7</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <img src={SpeedIcon} alt="Speed Icon"/>
                    </div>
                    <div className="research-text">
                        Comparing Sprint Intensity intervals to continuous-intensity
                        training has shown that intervals take 40% less training time for
                        equal amounts of fat loss. <sup>8</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={11}/>
                    </div>
                    <div className="research-text">
                        Sprint Intensity intervals do not interfere with the ability of
                        Strength Building Activities to improve muscle size and strength. <sup>9</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={11}/>
                    </div>
                    <div className="research-text">
                        An analysis of 15 studies found that Strength Building Activities
                        that improve leg and core strength significantly improve sprint
                        performance. <sup>10</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={4}/>
                    </div>
                    <div className="research-text">
                        Protein supports muscle growth, and muscle strength is maximized by
                        strength training in combination with adequate protein intake. <sup>11</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={4}/>
                    </div>
                    <div className="research-text">
                        Protein is optimally used by your body when present at every meal. <sup>12</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Sleep has a strong influence on your daytime functioning and ability
                        to stick to exercise plans. Inadequate sleep causes you to perceive
                        your workouts as much more demanding. <sup>13</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Inadequate sleep reduces the total work output of your exercise
                        session and decreases your total time till exhaustion. <sup>14,15,16,17</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Prioritizing sleep maximizes training effectiveness and ability to
                        perform endurance exercise.<sup>18,19</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BetterBloodPressure() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Each additional vegetable or fruit serving reduces your risk of being diagnosed with high blood
                        pressure by up to about two percent. <sup>1</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Plant-packed meals improve blood vessel health by increasing antioxidants in your blood and by
                        reducing blood pressure. <sup>2</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        A fiber-rich diet improves your balance of gut bacteria and this can enhance your ability to
                        manage your blood pressure. <sup>3</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={3}/>
                    </div>
                    <div className="research-text">
                        Added sugars in the diet elevate blood pressure. <sup>4</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={3}/>
                    </div>
                    <div className="research-text">
                        Reducing added sugars reduces blood pressure within weeks and improves blood lipids and body
                        weight management as well. <sup>5</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Regular exercise gradually changes your blood vessels, helping them relax and lowering your
                        blood pressure. This is part of the reason why those who get more physical movement throughout
                        their day have better blood pressure control. <sup>6,7</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Exercise improves your blood pressure, starting from day one. In fact, a single workout can help
                        you gain better blood pressure for hours afterwards. <sup>8</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Insufficient sleep increases your perception of stress and raises your blood pressure for about
                        24 hours. <sup>9,10</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Insufficient sleep raises risks for chronic conditions by increasing appetite, blood sugar and
                        blood pressure. <sup>11</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BetterBloodSugar() {
    return (
        <div className="research-container">
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Vegetables & fruit increase fiber intake which studies confirm correlates with improved blood
                        sugar control and insulin sensitivity in both non-diabetic and diabetic individual. <sup>1</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Plant-packed meals provide fiber that supports weight loss & improves insulin
                        sensitivity. <sup>2</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={5}/>
                    </div>
                    <div className="research-text">
                        Low-sugar diets that include plenty of vegetables predict slower rates of cognitive
                        decline. <sup>3,4</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={3}/>
                    </div>
                    <div className="research-text">
                        Reducing sugar sweetened beverage intake by one serving per day decreases risk of type 2
                        diabetes. <sup>5</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={3}/>
                    </div>
                    <div className="research-text">
                        Refined sugar impairs memory in healthy adults. <sup>6</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Exercise reduces the amount of insulin your body needs to drop high blood sugar, because after
                        exercise, the muscles can pull in sugar from the blood without the need for insulin for at least
                        24 hours. <sup>7</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={8}/>
                    </div>
                    <div className="research-text">
                        Exercise ramps up metabolism as your cells convert sugars, glycogen, and fat into usable
                        energy. <sup>8</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Without adequate sleep, the insulin-releasing cells of the pancreas are stressed and don’t
                        function as well, meaning it takes more insulin for the blood sugar lower effect. <sup>9</sup>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-6">
                <div className="research-item">
                    <div className="research-icon">
                        <HabitTileIcon tile={10}/>
                    </div>
                    <div className="research-text">
                        Insufficient sleep raises risks for chronic conditions <sup>10</sup> by increasing stress, appetite, blood
                        sugar and blood pressure. <sup>11</sup>
                    </div>
                </div>
            </div>
        </div>
    );
}