// npm modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validate from "validate.js";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import _ from 'underscore';
import { Alert } from "react-bootstrap";

// actions
import {
    updateStart,
    updateSubmit,
    updateResult,
    alterQuestion,
    alterList,
    alterForm,
    resetForm,
    loadFormToState,
} from "../../actions/action_forms";

// components
import { ButtonList } from "../lists/lists";
import NewStartScreen from "./newStartScreen";
import NewQuestionScreen from "./newQuestionScreen";
import NewSubmitScreen from "./newSubmitScreen";
import NewResultScreen from "./newResultScreen";
import Loading from "../Loading/Loading";

const constraints = {
    form: {
        title: {
            presence: true,
            type: 'string',
            length: { minimum: 1, maximum: 255 }
        }
    },
    start: {
        HEADLINE: {
            type: 'string',
            presence: true,
            length: { minimum: 1, maximum: 255 }
        },
        CONTENT: {
            type: 'string',
            presence: true,
            length: { minimum: 1, maximum: 10000 }
        },
        VIDEO: {
            type: 'string',
            presence: false,
            length: { maximum: 255 }
        },
        BUTTON: {
            type: 'string',
            presence: true,
            length: { minimum: 1, maximum: 255 }
        }
    },
    question: {
        DESCRIPTION: {
            presence: false,
            type: 'string',
            length: { maximum: 255 }
        },
        HEADLINE: {
            presence: true,
            type: 'string',
            length: { minimum: 1, maximum: 255 }
        },
        LIST: {
            type: 'array',
            presence: true,
            length: { minimum: 2, tooShort: "needs to have 2 answers or more", }
        }
    },
    submit: {
        HEADLINE: {
            type: 'string',
            presence: true,
            length: { minimum: 1, maximum: 255 }
        },
        CONTENT: {
            type: 'string',
            presence: true,
            length: { minimum: 1, maximum: 10000 }
        },
        BUTTON: {
            type: 'string',
            presence: true,
            length: { minimum: 1, maximum: 255 }
        }
    },
    list: {
        value: {
            type: 'object',
            presence: true,
        },

    },
    result: {
        HEADLINE: {
            type: 'string',
            presence: true,
            length: { minimum: 1, maximum: 255 }
        },
        CONTENT: {
            type: 'string',
            presence: true,
            length: { minimum: 1, maximum: 10000 }
        },
        VIDEO: {
            type: 'string',
            presence: false,
            length: { maximum: 255 }
        },
        BUTTON: {
            type: 'string',
            presence: true,
            length: { minimum: 1, maximum: 255 }
        }
    },
};

const AssessmentNewContainer = ({
    newForm,
    alterForm,
    updateStart,
    updateSubmit,
    updateResult,
    alterQuestion,
    alterList,
    resetForm,
    loadFormToState,
    ...props
}) => {
    const [ buttons, setButtons ] = useState([
        { id: 0, text: 'Start Screen', key: 'start', active: true },
        { id: 1, text: 'Questions', key: 'questions' },
        { id: 2, text: 'Submit Screen', key: 'submit' },
        { id: 3, text: 'Result Screen', key: 'result' },
        { id: 4, className: 'save', text: 'Save/Publish', disabled: true, }, // keep save/publish button at end of list
    ]);
    const [ step, setStep ] = useState(0); // holds the step/button the user is currently viewing
    const [ loading, setLoading ] = useState(false); // holds the state of loading in the current page
    const [ fetchedForm , setFetchedForm ] = useState(null); // holds a copy of the form that is loaded from the route href
    const [ stale, setStale ] = useState(null); // holds a copy of the form on load as it was before any changes were made
    const [ capturedStale, setCapturedStale ] = useState(false); // holds a bool as to whether stale has been set at least once
    const [ addProfile, setAddProfile ] = useState(false); // holds whether the assessment will/does include profile questions
    const [ scoreKey, setScoreKey ] = useState([]); // holds the score keys for the question categories
    const [ taxonomy, setTaxonomy ] = useState([]); // holds the taxonomy categories for questions
    const [ scoreErrors, ] = useState([]); // holds any errors that arise from scoring the questions
    const [includeResult, setIncludeResult] = useState(false); // determines whether a result screen will be included in submit value

    // validate questions and lists against constraints. only update form if cascade is set to true
    const validateQuestions = (questions, cascade) => {
        // go over each question and validate
        return questions.map(question => {
            const questionValidation = validate(question, constraints.question);
            if (cascade) alterQuestion({ ...question, validation: questionValidation });
            // go over the lists in each question an validate
            const validateList = question.LIST.map(listItem => {
                const listValidation = validate(listItem, constraints.list);
                if (cascade) alterList(question, { ...listItem, validation: listValidation });
                return listValidation;
            }).filter(item => item);
            // return true if any errors were found at any depth
            if (questionValidation || validateList.length > 0) {
                return true;
            }
        }).filter(item => item).length;
     
    };

    // handler for buttons that navigate through form
    const buttonClick = (btnRef) => {
        const key = buttons[step].key;
        if (key) {
            let validation = false;
            if (key === 'questions') {
                validation = validateQuestions(newForm.questions, true);
            } else if (key === "result" && includeResult) {
                validation = validate(newForm[key], constraints[key])
            } else if (key !== "result") {
                validation = validate(newForm[key], constraints[key]);
            }
            if (key === 'start') {
                updateStart({ ...newForm[key], validation });
            } else if (key === 'submit') {
                updateSubmit({...newForm[key], validation});
            }else if (key === 'result') {
                updateResult({ ...newForm[key], validation });
            }
            if (validation) return;
        }

        const btns = buttons.map(btn => {
            // remove active
            btn.active = null;
            if (btn.id === btnRef.id) {
                // add active to clicked element
                btn.active = true;

                // 4 is the save/publish button
                if (btn.id !== 4) {
                    setStep(btn.id);
                } else {
                    submit();

                }
            }
            return btn;
        });
        setButtons(btns);
    };

    // check the question values against a score key
  // eslint-disable-next-line
    const checkScoreKey = () => {
        // grab the questions out of the new form
        const questions = newForm.questions;

        // go through each question and sum the totals into an obj
        const scoreCard = {};
        scoreKey.forEach(item => scoreCard[item.id] = { high: 0, low: 0 });
        questions.forEach(q => {
            if (q.categoryId && q.LIST) {
                if (scoreCard[q.categoryId]) {
                    scoreCard[q.categoryId].high += _.max(q.LIST.map(item => parseInt(item.value.value)));
                    scoreCard[q.categoryId].low += _.min(q.LIST.map(item => parseInt(item.value.value)));
                }
            }
        });

        // validate and add errors if any
        const invalid = [];
        scoreKey.forEach(key => {
            // find the appropriate item in the scoreCard
            // if the high and low don't fall on the edges of the key return the instance
            if (key.red_score_max > key.yellow_score_max) {
                // key is inverted
                if(scoreCard[key.id].high !== key.red_score_max) {
                    invalid.push(`Category ${key.taxonomy_name} must have a total high score of ${key.red_score_max}`);
                }
                if (scoreCard[key.id].low !== key.green_score_min) {
                    invalid.push(`Category ${key.taxonomy_name} must have a total low score of ${key.green_score_min}`);
                }
            } else {
                if(scoreCard[key.id].high !== key.green_score_max) {
                    invalid.push(`Category ${key.taxonomy_name} must have a total high score of ${key.green_score_max}`);
                }
                if (scoreCard[key.id].low !== key.red_score_min) {
                    invalid.push(`Category ${key.taxonomy_name} must have a total low score of ${key.red_score_min}`);
                }
            }
        });
        return invalid;
    };

    // submit form for creation
    async function submit(e){
        if(e) e.preventDefault();
        if (loading) return;
        // set loading to prevent duplicate form submission
        setLoading(true);
        // compose form data to be sent to backend
        const images = [];

        // add start screen fields
        const startScreen = { typeId: newForm.start.typeId, fields: [] };
        const start = newForm.start;
        if (start.HEADLINE) startScreen.fields.push({ typeId: 1, label: '', text: start.HEADLINE });
        if (start.CONTENT) startScreen.fields.push({ typeId: 2, label: '', text: start.CONTENT });
        if (start.IMAGE && start.IMAGE.name) {
            startScreen.fields.push({ typeId: 12, label: '', text: '', name: start.IMAGE.name });
            images.push(start.IMAGE)
        } else if (start.IMAGE && typeof start.IMAGE === 'string') {
            startScreen.fields.push({ typeId: 12, label: '', text: '', src: start.IMAGE });
        }
        if (start.VIDEO) startScreen.fields.push({ typeId: 13, label: '', text: '', src: start.VIDEO });
        if (start.BUTTON) startScreen.fields.push({ typeId: 3, label: '', text: start.BUTTON });

        // add questions
        const questions = newForm.questions;
        const fieldQuestions = questions.map(q => {
            const fields = [];
            if (q.IMAGE && q.IMAGE.name) {
                fields.push({ typeId: 12, label: '', text: '', name: q.IMAGE.name });
                images.push(q.IMAGE)
            } else if (q.IMAGE && typeof q.IMAGE === 'string') {
                fields.push({ typeId: 12, label: '', text: '', src: q.IMAGE });
            }
            const listField = { typeId: 4 };
            listField.label = q.HEADLINE;
            listField.text = q.DESCRIPTION;
            listField.list = q.LIST.map(item => item.value);
            listField.optional = q.optional;
            if (q.categoryId) listField.taxonomy = parseInt(q.categoryId);
            fields.push(listField);
            return { typeId: q.typeId, fields };
        });

        // add submit screen fields
        const submitScreen = { typeId: newForm.submit.typeId, fields: [] };
        const submit = newForm.submit;
        if (submit.HEADLINE) submitScreen.fields.push({ typeId: 1, label: '', text: submit.HEADLINE });
        if (submit.CONTENT) submitScreen.fields.push({ typeId: 2, label: '', text: submit.CONTENT });
        if (submit.IMAGE && submit.IMAGE.name) {
            submitScreen.fields.push({ typeId: 12, label: '', text: '', name: submit.IMAGE.name });
            images.push(submit.IMAGE)
        } else if (submit.IMAGE && typeof submit.IMAGE === 'string') {
            submitScreen.fields.push({ typeId: 12, label: '', text: '', src: submit.IMAGE });
        }
        if (submit.BUTTON) submitScreen.fields.push({ typeId: 3, label: '', text: submit.BUTTON });

        // add result screen fields
        const resultScreen = { typeId: newForm.result.typeId, fields: [] };
        const result = newForm.result;
        if (result.HEADLINE) resultScreen.fields.push({ typeId: 1, label: '', text: result.HEADLINE });
        if (result.CONTENT) resultScreen.fields.push({ typeId: 2, label: '', text: result.CONTENT });
        if (result.IMAGE && result.IMAGE.name) {
            resultScreen.fields.push({ typeId: 12, label: '', text: '', name: result.IMAGE.name });
            images.push(result.IMAGE)
        } else if (result.IMAGE && typeof result.IMAGE === 'string') {
            resultScreen.fields.push({ typeId: 12, label: '', text: '', src: result.IMAGE });
        }
        if (result.VIDEO) resultScreen.fields.push({ typeId: 13, label: '', text: '', src: result.VIDEO });
        if (result.BUTTON) resultScreen.fields.push({ typeId: 3, label: '', text: result.BUTTON });

        const data = { title: newForm.title, elements: [] };
        data.elements.push(startScreen);
        fieldQuestions.forEach(q => data.elements.push(q));
        data.elements.push(submitScreen);
        if (includeResult) data.elements.push(resultScreen);

        // submit data to b/e for creation
        const formData = new FormData();
        formData.append('title', data.title);
        if (addProfile) formData.append('profile', "true");
        formData.append('elements', JSON.stringify(data.elements));
        // attach formId if editing rather than creating entirely new form
        if (fetchedForm) formData.append('formId', fetchedForm);
        // attach any image files
        images.forEach(img => formData.append(img.name, img));
        setLoading(false);
        // send to main screen
        props.history.push('/dashboard/Assessments/')
    }

    // capture the state of the newForm var the 1st time it's changed
    useEffect(() => {
        if (capturedStale || !newForm.title) return;
        setStale(newForm);
        if (newForm.profile) setAddProfile(true);
        if (newForm.result.HEADLINE) setIncludeResult(true);
        setCapturedStale(true);
    }, [newForm]);

    // add onclick handlers to each button on newForm change
    useEffect(() => {
        buttons.forEach(button => button.onClick = buttonClick);
    }, [newForm, addProfile]);

    // validate form whenever form is changed
    useEffect(() => {
        const formValidation = validate(newForm, constraints.form);

        // whole form validation
        const startValidation = validate(newForm.start, constraints.start);
        const questionValidation = validateQuestions(newForm.questions);
        const submitValidation = validate(newForm.submit, constraints.submit);
        const resultValidation = includeResult ? validate(newForm.result, constraints.result) : false;

        // prevent submission if any validation failed
        let flag = true;
        if (!formValidation && !startValidation && !questionValidation && !submitValidation && !resultValidation && !_.isEqual(newForm, stale)) {
            flag = false;
        }

        // enable submission
        const btns = buttons.map((btn, i) => {
            if (i === buttons.length-1) btn.disabled = flag;
            return btn;
        });
        setButtons(btns);
    }, [newForm, stale, includeResult]);

    // remove form data when unmounting
    useEffect(() => {
        return () => resetForm();
    },[]);

    // set loading buttons when loading state changes
    useEffect(() => {
        const btns = buttons.map((btn, i) => {
            if (i === buttons.length-1 && loading){
                btn.text = <Loading color={'#1a1a1a'} type={'ThreeDots'} height={16} width={16}><span>Creating</span></Loading>
            } else if (i === buttons.length-1) {
                btn.text = 'Save/Publish';
            };
            return btn;
        });
        setButtons(btns);
    },[loading]);

    // grab a formId on mount if route has one
    useEffect(() => {
        if (fetchedForm) return;
        const formId = props.match.params.id;
        setFetchedForm(formId);
       if (formId) loadFormToState(formId);
    }, []);

    // grab validation keys on mount
    useEffect(() => {
        axios.get('/pathways/lifestylekey')
            .then(({ data }) => {
                setScoreKey(data.result);
            });
        axios.get('/pathways/taxonomies')
            .then(({ data }) => {
                setTaxonomy(data.result);
            })
    }, []);

    // change form state when profile questions are added/removed
    useEffect(() => {
        alterForm({ profile: addProfile });
    }, [addProfile]);

    return (
		<div>
			<div class="page-header-container searchForm-container">							
				<div className="container">		
					<div className="row">
						<div class="col-sm-12">
							<nav>
								<ol className="breadcrumb ">
									<li className="breadcrumb-item underline "><a href="/dashboard" className="text-light">Dashboard</a></li>
									<span className="arrow"/>
									<li className="breadcrumb-item underline "><a href="/dashboard/Assessments" className="text-light">Assessments</a></li>
									<span className="arrow"/>
									<li className="breadcrumb-item text-light active">Create New</li>
								</ol>
							</nav>
						</div>
						<div className="col-sm-12 col-md-8 margin-top">
							<h1>Create new assessment</h1>
						</div>
						<div className="col-sm-12 col-md-4 text-right margin-top">
							
						</div>
					</div>
				</div>					
			</div>
		
		
			<div className='container margin-top-3x'>
                <div className="row">
                    <div className="col-xs-12">
                        { props.location.state && props.location.state.duplicate ?
                            <Alert variant='primary'>Assessment Duplicated</Alert>
                            : null
                        }
                    </div>
                </div>
				<div className="row">
					<div className="col-xs-12 col-md-3">
						<div className="widget-container margin-bottom-2x">
							<ButtonList list={buttons}/>
						</div>
                        <div className="widget-container margin-bottom-2x">
                            <div className='form-group'>
                                <div className="d:f">
                                    <input
                                        checked={includeResult}
                                        onChange={e => setIncludeResult(e.target.checked)}
                                        id='resultCheckbox'
                                        style={{ width: '20px'}}
                                        className='form-controller'
                                        type="checkbox"
                                    />
                                    <label htmlFor="resultCheckbox">Include Results Screen</label>
                                </div>
                                <div className="d:f">
                                    <input
                                        checked={addProfile}
                                        onChange={e => setAddProfile(e.target.checked)}
                                        id='profileCheckbox'
                                        style={{ width: '20px'}}
                                        className='form-controller'
                                        type="checkbox"
                                    />
                                    <label htmlFor="profileCheckbox">Include Profile Questions</label>
                                </div>

                                <p>This will include the following questions: </p>
                                <ul>
                                    <li>Mobile Phone #</li>
                                    <li>Alt Phone #</li>
                                    <li>Email Address</li>
                                    <li>Gender</li>
                                    <li>Birthday</li>
                                    <li>Weight</li>
                                    <li>Height</li>
                                    <li>Timezone</li>
                                </ul>
                            </div>
                        </div>
					</div>
					<div className="col-xs-12 col-md-8 col-md-offset-1">
                        { scoreErrors.length > 0 ?
                            <div className="margin-bottom-2x">
                                { scoreErrors.map(err => <p key={err.trim} className="text-danger">{err}</p>)}
                            </div>
                            : null
                        }
						<div className="widget-container margin-bottom-2x">
							<form className="assessment-form" onSubmit={submit}>
								<div className="form-group form-header">
									<label>Name of Assessment</label>
									<input
										type="text"
										className="form-control"
										onChange={e => alterForm({ title: e.target.value })}
										value={newForm.title}/>
									{ newForm.validation && newForm.validation.title ?
										newForm.validation.title.map(err => <span className="text-danger">{err}</span>) :
										null
									}
								</div>
								{ step === 0 ? <NewStartScreen screen={newForm.start}/> : null }
								{ step === 1 ? <NewQuestionScreen taxonomy={taxonomy} questions={newForm.questions}/> : null }
                                { step === 2 ? <NewSubmitScreen screen={newForm.submit}/> : null}
								{ step === 3 ? <NewResultScreen screen={newForm.result}/> :null }
							</form>
			 			</div>
			  		</div>
				</div>
			</div>
	   </div>
    )
};

const mapStateToProps = ({ newForm }) => {
    return {
        newForm,
    }
};

const actions = {
    alterForm,
    updateStart,
    updateSubmit,
    updateResult,
    alterQuestion,
    alterList,
    resetForm,
    loadFormToState,
};

export default withRouter(connect(mapStateToProps, actions)(AssessmentNewContainer));

AssessmentNewContainer.propTypes = {
    newForm: PropTypes.shape({
        title: PropTypes.string,
        start: PropTypes.object,
        questions: PropTypes.array,
        submit: PropTypes.object,
        result: PropTypes.object,
    }),
    updateStart: PropTypes.func,
    updateSubmit: PropTypes.func,
    updateResult: PropTypes.func,
    alterQuestion: PropTypes.func,
    alterList: PropTypes.func,
    alterForm: PropTypes.func,
    resetForm: PropTypes.func,
    loadFormToState: PropTypes.func,
};