import React, {Component} from 'react';
import TeammatesList from './TeammatesList';
import axios from 'axios';
import {fetchSelf} from "../../../actions/action_user";
import {connect} from 'react-redux';
import moment from 'moment';
import { Alert} from 'react-bootstrap';
import {PrimaryButton} from "../../buttons/buttons";

class TeammatesContainer extends Component{
    constructor(){
        super();
        this.state = {
          wellnessScore:'', 
          teammates:[],
          show: false,
          alertStyle: true,
          alert: '',
        }
        this.updateList = this.updateList.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.moveToAddTeammate = this.moveToAddTeammate.bind(this);
    }

    componentDidMount(){
        this.props.fetchSelf();
        axios.get('/score/wellness-score')
              .then(
                  res=>{                   
                      this.setState({
                          wellnessScore:res.data.results.score
                      });
                  }
              )
              .catch();
        axios.get(`/teammates`)
            .then(res => {
              this.setState({teammates: res.data.results.teammates});
            })
    }

    createList=()=>{
            if(this.state.teammates.length){
                return (
                    <div className="row tour-team-list">
                        {this.state.teammates.map((item)=>{
                            let date = moment(item.created_at).format('MM/DD/YY');
                            return(
                                <div className="col-xs-12 col-sm-6">
                                    <TeammatesList
                                        key={item.nickname}
                                        status={item.status}
                                        email={item.email}
                                        firstName={item.first_name}
                                        lastName={item.last_name}
                                        nickname={item.nickname}
                                        profileImage={item.profile_picture}
                                        date={date}
                                        teammateId={item.id}
                                        updateList={this.updateList}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )
            }
        };

    updateList() {
      axios.get(`/teammates`)
          .then(res => {
            this.setState({teammates: res.data.results.teammates});
          })
            this.setState({ show: true });
            this.setState({ alert: 'success' });
            this.setState({ alertStyle: true });
    }

    handleDismiss() {
      this.setState({ show: false });
    }

    handleShow() {
      this.setState({ show: true });
    }

    moveToAddTeammate(){
      this.props.history.push({
          pathname: `/member/clientDash/${this.props.user.username}/Teammates/Add`,
      })
    }

    goBack() {
      window.history.back();
    } 

    render(){
      let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
      // eslint-disable-next-line no-unused-vars
      var scoreClass = 'low';
      if (this.state.wellnessScore >= 51 && this.state.wellnessScore < 75 ) {
          scoreClass = 'average';
      } else if (this.state.wellnessScore >= 75 && this.state.wellnessScore < 90 ) {
          scoreClass = 'high';
      } else if (this.state.wellnessScore >= 90) {
          scoreClass = 'highest';
      } 

      if(this.props.user){
          return(
              <div>
                  <div className="container client-account-dash">
                      <h3>TEAMMATES</h3>  
                      <p>Enlist Support</p>            
                      <div className="margin-top">
                          <ul  className="tiles-ul coaching-team">           
                              {this.createList()}
                          </ul>
                      </div>
                      <div className=" text-right">
                          {this.state.show
                              ?  <Alert className="save-profile-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                                  <p>{this.state.alert}</p>
                              </Alert>
                              : null
                          }
                          <PrimaryButton className="add--icon tour-team-add" onClick={()=>this.moveToAddTeammate()}>ADD TEAMMATE</PrimaryButton>
                      </div>
                  </div>
              </div>
          )
      }else{
          return <div> </div>
      }
    }
}


function mapStateToProps({user}){
    return{user}
}

export default connect(mapStateToProps, {fetchSelf})(TeammatesContainer);
