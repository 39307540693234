import React from 'react';
import Loader from 'react-loader-spinner';

const Loading = ({width=50, height=50, color='#ccc', type='Puff', children, ...props}) =>{
    return(
        <div className='flex-center' {...props}>
            {children}
            <Loader
                type={type}
                color={color}
                height={height}
                width={width}
            />
        </div>
    )
};

export default Loading;