import {
    SET_CLIENT_TILE,
    SET_CLIENT_TILE_LIST,
} from "../storeConstants";

// clientTile
export function clientTileReducer(state=null, action) {
    switch(action.type) {
        case SET_CLIENT_TILE:
            return action.payload;
        default:
            return state;
    }
}

// clientTileList
export function clientTileListReducer(state=[], action) {
    switch(action.type) {
        case SET_CLIENT_TILE_LIST:
            return action.payload;
        default:
            return state;
    }
}