import React, { useEffect, useState } from "react";
import { BoxContainer } from "../../containers/boxContainer/boxContainer";
import { DangerButton, PrimaryButton } from "../../buttons/buttons";
import Avatar from "../../../images/avatar-1577909_960_720.png";
import { InfoAlert } from "../../alerts/alerts";
import { FaEyeSlash, FaEye } from "react-icons/fa";
// dragable
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// Modal Import
import { Glyphicon, Modal, Button } from "react-bootstrap";
// redux
import { connect } from "react-redux";
// connect api
import { studioPlayerApiMap } from "../../../store/actions/studioPlayerActions";
// search filter routes
import ContentCategory from "../organizationContent/contentCategory/contentCategory";
import AddContent from "../organizationContent/addContent/addContent";
import axios from "axios";
// styles
import styles from "./organizationStudio.module.css";

import { useRef } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Loading from "../../Loading/Loading";

const OrganizationStudio = (props) => {
  // actions
  const { studioPlayerApiMap } = props;

  // props
  const { organization } = props;

  // local state
  const [studioAssignedContent, setStudioAssignedContent] = useState([]);
  const [studioCategory, setStudioCategory] = useState([]);
  const search = useRef(""); // used with stateSearch to keep track of input
  const [secondaryFilter, setSecondaryFilter] = useState({});
  const [categoryWarning, setCategoryWarning] = useState(null); // hol
  const [show, setShow] = useState(false); // show rem
  const [addCategory, setAddCategory] = useState(null); // hold temp category for addition
  const [showAddWarning, setShowAddWarning] = useState(false); // show add modal
  const [stateSearch, setStateSearch] = useState(""); // us
  const [studioModal, setStudioModal] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    organization_id: organization.id,
  });
  const [allStudioUsers, setAllStudioUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [studioSupportMessage, setStudioSupportMessage] = useState(
    organization.studio_support_message || ""
  );
  // show modal when removing studio user
  const [
    showStudioUserRemovalWarning,
    setShowStudioUserRemovalWarning,
  ] = useState(false);

  const [
    isSubmitSupportMessageResponseLoading,
    setIsSubmitSupportMessageResponseLoading,
  ] = useState(false);

  const handleShowStudioUserRemovalWarning = () => {
    setShowStudioUserRemovalWarning(true);
  };

  const handleCloseStudioUserRemovalWarning = () => {
    setShowStudioUserRemovalWarning(false);
  };

  const handleStudioSupportMessageChange = (e) => {
    setStudioSupportMessage(e.target.value);
  };

  const fetchStudioAssignedCategories = async () => {
    setIsLoading(true);
    const { getStudioAssignedCategories } = await studioPlayerApiMap();
    const {
      data: { result },
    } = await getStudioAssignedCategories(organization.id);

    setStudioAssignedContent(result);
    setIsLoading(false);
  };
  useEffect(() => {
    try {
      fetchStudioAssignedCategories();
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  }, []);

  useEffect(() => {
    try {
      retrieveAllStudioUsers();
    } catch (e) {
      console.error(e);
    }
  }, []);

  useDidMountEffect(() => {
    setTimeout(() => {
      if (stateSearch === search.current) {
        searchCategories({
          search: search.current,
          organization: secondaryFilter.organization,
          sort: secondaryFilter.sort,
        });
      }
    }, 500);
  }, [stateSearch]);

  useDidMountEffect(() => {
    searchCategories({
      search: search.current,
      organization: secondaryFilter.organization,
      sort: secondaryFilter.sort,
    });
  }, [secondaryFilter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    try {
      const { createStudioUser } = await studioPlayerApiMap();
      const {
        data: { result },
      } = await createStudioUser(formData);
      retrieveAllStudioUsers();
      setStudioModal(false);
    } catch (error) {
      console.log(error, "failed to create user");
    }
  };

  async function onDragEnd({ destination, source, category }) {
    // do nothing if no movement was made
    if (!destination) return;
    if (destination.index === source.index) return;
    if (studioAssignedContent.length <= 1) return; // can't reorder a list of 1

    let clone;
    if (!category) {
      clone = [...studioAssignedContent];
      const [removed] = clone.splice(source.index, 1);
      clone.splice(destination.index, 0, removed);
      setStudioAssignedContent(clone);
    } else {
      const found = studioAssignedContent.find(
        (item) => item.category_id === category.category_id
      );
      clone = [...found.subcategories];
      const [removed] = clone.splice(source.index, 1);
      clone.splice(destination.index, 0, removed);
      const updated = studioAssignedContent.map((cat) => {
        if (cat.category_id === found.category_id) {
          return {
            ...found,
            subcategories: clone,
          };
        } else {
          return cat;
        }
      });
      setStudioAssignedContent(updated);
    }
    const { getStudioCategorySort } = await studioPlayerApiMap();
    const response = await getStudioCategorySort(
      organization.id,
      clone.map((item) => item.assigned_id)
    );
    fetchStudioAssignedCategories();
  }

  async function searchCategories(params) {
    try {
      if (!params.sort) return;
      const { getStudioAssignableContent } = await studioPlayerApiMap();
      const searchTerm = params.search; // Assuming 'search' is the correct field
      const response = await getStudioAssignableContent(
        organization.id,
        searchTerm,
        params.assignedOrgId, // Assuming there is a field like this
        params.sort
      );

      setStudioCategory(response.data.result);
    } catch (e) {
      console.log(e);
    }
  }

  async function setAssignment(category) {
    const parent = studioCategory.find((cat) => {
      return cat.subcategories.find((sub) => sub.id === category.id);
    });
    if (
      parent &&
      !studioAssignedContent.find((cat) => cat.category_id === parent.id) &&
      !addCategory &&
      parent.content.length
    ) {
      setAddCategory({ category, parent });
      setShowAddWarning(true);
      return;
    }

    try {
      setIsLoading(true);
      const { assignStudioCategoryToOrganization } = await studioPlayerApiMap();
      const {
        data: { result },
      } = await assignStudioCategoryToOrganization(
        organization.id,
        category.id
      );

      searchCategories();
      fetchStudioAssignedCategories();

      searchCategories({
        search: search.current,
        organization: secondaryFilter.organization,
        sort: secondaryFilter.sort,
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }
  function showWaring(category) {
    setCategoryWarning(category);
    setShow(true);
  }

  function closeAddWarning() {
    setAddCategory(null);
    setShowAddWarning(false);
  }

  function closeWarning() {
    setCategoryWarning(null);
    setShow(false);
  }
  async function removeAssignment(category) {
    try {
      const { deleteStudioAssignedContent } = await studioPlayerApiMap();
      const {
        data: { result },
      } = await deleteStudioAssignedContent(
        organization.id,
        category.assigned_id
      );
      fetchStudioAssignedCategories();
      searchCategories({
        search: search.current,
        organization: secondaryFilter.organization,
        sort: secondaryFilter.sort,
      });
      closeWarning();
    } catch (e) {
      console.log(e);
    }
  }

  async function retrieveAllStudioUsers() {
    const { getAllStudioUsers } = await studioPlayerApiMap();
    const response = await getAllStudioUsers(organization.id);
    setAllStudioUsers(response.data.result);
  }

  async function removeStudioUser(username) {
    try {
      const { removeStudioUserByUsername } = await studioPlayerApiMap();
      const response = await removeStudioUserByUsername(username);
      handleCloseStudioUserRemovalWarning();
      retrieveAllStudioUsers();
    } catch (error) {
      console.error("Failed to remove user:", error);
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmitStudioSupport = async (e) => {
    e.preventDefault(); 
    try {
      setIsSubmitSupportMessageResponseLoading(true);
      const {
        data: { result },
      } = await axios.post(
        `/organizations/update/${formData.organization_id}`,
        {
          studio_support_message: studioSupportMessage,
        }
      );
      setIsSubmitSupportMessageResponseLoading(false);
    } catch (error) {
      setIsSubmitSupportMessageResponseLoading(false);
      console.log(error, "Failed to submit support message");
    }
  };

  return (
    <div className="margin-top-2x">
      <h2>Content</h2>

      <div className={styles.container}>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        ) : (
          <div className={styles.list}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="mainCategoryDrop">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {studioAssignedContent.map((item, i) => (
                      <Draggable
                        key={item.category_id}
                        draggableId={`${item.category_id}`}
                        index={i}
                      >
                        {(provided) => (
                          <div
                            key={item.category_id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <ContentCategory
                              provided={provided}
                              category={item}
                              remove={showWaring}
                              onDragEnd={onDragEnd}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
        {/* sidebar */}
        <div className={styles.sidebar}>
          <div style={{ marginBottom: "20px" }}>
            <label>Support Message</label>
            <textarea
              name="studio_support_message"
              value={studioSupportMessage}
              onChange={handleStudioSupportMessageChange}
              placeholder="Enter Support Message"
              className="form-control"
              rows="3"
            />

            {isSubmitSupportMessageResponseLoading ? (
              <div
                style={{
                  padding: "20px",
                  marginRight: "30px",
                  color: "lightgray",
                }}
              >
                Loading...
              </div>
            ) : (
              <button
                onClick={handleSubmitStudioSupport}
                className="btn btn-primary"
                style={{ marginTop: "10px" }}
              >
                Submit Support Message
              </button>
            )}
          </div>

          <BoxContainer className={styles.boxes}>
            {allStudioUsers.length === 0 ? (
              <div>
                <InfoAlert>
                  Click below to assign Studio User to organization
                </InfoAlert>
              </div>
            ) : null}
            {allStudioUsers.length > 0
              ? allStudioUsers.map((item) => (
                  <CoachTile
                    key={item.email}
                    coach={item}
                    handleShowStudioUserRemovalWarning={
                      handleShowStudioUserRemovalWarning
                    }
                    showStudioUserRemovalWarning={showStudioUserRemovalWarning}
                    handleCloseStudioUserRemovalWarning={
                      handleCloseStudioUserRemovalWarning
                    }
                    remove={() => removeStudioUser(item.email)}
                  />
                ))
              : null}
          </BoxContainer>
          <BoxContainer className={styles.studioUserBtnContainer}>
            <PrimaryButton onClick={() => setStudioModal(true)}>
              + Studio User
            </PrimaryButton>
          </BoxContainer>
          <h5 className="m:0 margin-bottom-15">Add Content Tiles</h5>
          <AddContent
            assignCategory={setAssignment}
            categories={studioCategory}
            search={search}
            setStateSearch={setStateSearch}
            setSecondaryFilter={setSecondaryFilter}
            assigned={studioAssignedContent}
          />
        </div>

        <Modal show={studioModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Form Submission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={handleSubmit}
              style={{ display: "grid", gap: "10px" }}
            >
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                placeholder="First Name"
                className="form-control"
              />
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                placeholder="Last Name"
                className="form-control"
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="form-control"
              />

              <div className={styles.passwordContainer}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  className="form-control"
                />
                <div
                  onClick={togglePasswordVisibility}
                  className={styles.togglePassword}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>

              <Button type="submit" variant="primary">
                Submit
              </Button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setStudioModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={closeWarning}>
          <Modal.Header closeButton>
            <h4>WARNING</h4>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to remove this category?</p>
          </Modal.Body>
          <Modal.Footer>
            <DangerButton onClick={closeWarning} className="margin-right-25">
              Cancel
            </DangerButton>
            <PrimaryButton onClick={() => removeAssignment(categoryWarning)}>
              Confirm
            </PrimaryButton>
          </Modal.Footer>
        </Modal>
        <Modal show={showAddWarning} onHide={closeAddWarning}>
          <Modal.Header closeButton>
            <h4>WARNING</h4>
          </Modal.Header>
          <Modal.Body>
            {addCategory ? (
              <div>
                <p>
                  The parent category{" "}
                  <strong>{addCategory.parent.category_name}</strong> hasn't
                  been added to this organization yet. Adding{" "}
                  <strong>{addCategory.category.category_name}</strong> will
                  assign <strong>{addCategory.parent.category_name}</strong> and
                  all of the content listed below.
                </p>
                <ul>
                  {addCategory.parent.content.map((item) => (
                    <li>{item.title}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <DangerButton onClick={closeAddWarning} className="margin-right-25">
              Cancel
            </DangerButton>
            <PrimaryButton
              onClick={() => {
                const addCopy = { ...addCategory.category };
                setShowAddWarning(false);
                setAddCategory(null);
                setAssignment(addCopy);
              }}
            >
              Confirm
            </PrimaryButton>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
const actions = {
  studioPlayerApiMap,
};

export default connect(null, actions)(OrganizationStudio);

// Modal for confirming removal
function RemoveUserModal({
  showStudioUserRemovalWarning,
  handleClose,
  handleConfirm,
  coachName,
}) {
  return (
    <Modal show={showStudioUserRemovalWarning} onHide={handleClose}>
      <Modal.Header closeButton>
        <h4>WARNING</h4>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to remove {coachName}?</p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="btn btn-secondary">
          Cancel
        </button>
        <button onClick={handleConfirm} className="btn btn-danger">
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function CoachTile({
  coach,
  remove,
  handleShowStudioUserRemovalWarning,
  showStudioUserRemovalWarning,
  handleCloseStudioUserRemovalWarning,
}) {
  return (
    <div>
      <RemoveUserModal
        showStudioUserRemovalWarning={showStudioUserRemovalWarning}
        coachName={`${coach.first_name} ${coach.last_name}`}
        handleConfirm={remove}
        handleClose={handleCloseStudioUserRemovalWarning}
      />
      <div className={styles.coachTile}>
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            src={coach.profile_image || Avatar}
            alt={`${coach.first_name} profile`}
          />
        </div>
        <div className={styles.infoContainer}>
          <h4 className={styles.name}>
            {coach.first_name} {coach.last_name}
          </h4>
        </div>
        <div className={styles.buttonContainer}>
          <span
            className={`${styles.remove} hover`}
            onClick={handleShowStudioUserRemovalWarning}
          >
            <Glyphicon glyph="glyphicon glyphicon-remove-circle" /> Remove
          </span>
        </div>
      </div>
    </div>
  );
}
