import React, {useState, useEffect} from 'react';

// styles
import styles from './availableContent.module.css';
import {ReactComponent as DownArrow} from "../../../../images/caret-down-solid.svg";

const AvailableContent = props => {
    // props;
    const {category, isOverflown, assigned, search, checkOverflow, assignCategory} = props;
    // local
    const [open, setOpen] = useState(false);

    // alert the parent whenever the open state changes
    useEffect(() => {
        if (checkOverflow) checkOverflow();
    }, [open]);

    function addHighlight(string) {
        const match = search.current || '';
        const foundMatch = string.indexOf(match);
        if (foundMatch > -1) {
            return (
                <span>
                    {string.substr(0, foundMatch)}
                        <span className={styles.highlight}>{string.substr(foundMatch, match.length)}</span>
                    {string.substr(foundMatch+match.length)}
                </span>
            )
        } else {
            return string;
        }
    }

    const found = assigned.find(item => item.category_id === category.id);
    if ( found && category.subcategories.filter(sub => found.subcategories.map(x => x.category_id).includes(sub.id)).length === category.subcategories.length) {
        return null;
    }

    return (
        <div className={`${isOverflown ? 'm-r:2' : ''} ${styles.container}`}>
            <div className={`${styles.line} ${open ? styles.open : ''}`}/>
            <div className={styles.heading}>
                <div className={styles.thumbnail}>
                    {category.image ? <img src={category.image} alt=""/> : null}
                </div>
                <h4 onClick={() => setOpen(!open)} className={styles.text}>
                    {addHighlight(category.category_name)}
                    <DownArrow className={`${styles.arrow} ${open ? styles.open : ''}`}/>
                </h4>
                {assigned.find(item => item.category_id === category.id) ? null : <span className={styles.headBtn}><button className={styles.addBtn} onClick={() => assignCategory(category)}>Add +</button></span>}
            </div>
            <div className={`${styles.subs} ${open ? styles.open : ''}`}>
                {category.subcategories.map(sub => {
                    if (assigned.find(item => (item.subcategories.find(cat => cat.category_id === sub.id)))) return null
                    return (
                        <div key={sub.id} className={styles.subcategory}>
                            <span className={styles.name}>{addHighlight(sub.category_name)}</span>
                            <button className={styles.addBtn} onClick={() => assignCategory(sub)}>Add +</button>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AvailableContent;