import React, {useEffect, useRef} from 'react';
import _ from 'lodash';

// styles
import styles from './victoryFeed.module.css';


// components
import VictoryTile from "../../victoryTile/victoryTile";
import Loading from "../../Loading/Loading";

const VictoryFeed = props => {
    // props
    const {victories, fetchVictories, reachedEnd, setReaction} = props;
    // local
    const last = useRef();
    const loading = useRef(false);
    const feed = useRef(victories);

    useEffect(() => {
        feed.current = victories;
    }, [victories]);

    useEffect(() => {
        window.addEventListener('scroll', _.throttle(onScroll, 500));
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    async function onScroll() {
        if (last.current) {
            const inView = isElementInViewport(last.current);
            if (inView && !loading.current && feed.current) {
                loading.current = true;
                await fetchVictories(feed.current);
                loading.current = false;
            }
        }
    }

    function isElementInViewport (el) {
        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    return (
        <div className={styles.container}>
            {!victories ? <Loading/> : null}
            {victories && victories.length < 1 ? <div className={styles.placeholder}>Habit building in progress. Check back later to see others achievements!</div> : null}
            {victories && victories.length > 1 ?
                <>
                    {victories.map((victory, i) => {
                        if (i === victories.length-1) {
                            return (
                                <div ref={last} key={victory.victory.id}>
                                    <VictoryTile victory={victory} changeReaction={setReaction}/>
                                    {loading && !reachedEnd ? <Loading height={25} width={25} /> : null}
                                    {reachedEnd ? <p className='t-a:c'>You're all caught up!</p> : null}
                                </div>
                            )
                        }
                        return (
                            <div key={victory.victory.id}><VictoryTile changeReaction={setReaction} victory={victory}/></div>
                        )
                    })}
                </>
                : null
            }
        </div>
    )

}


export default VictoryFeed;
