import {FETCH_PLAN} from "../actions/action_tile";
import {CLEAR_PLAN} from "../actions/action_tile";


export default function(state={plan:null},action ){
    switch (action.type){
        case FETCH_PLAN:
            console.log(action.payload.data.plan);
            return Object.assign({}, state, {plan:action.payload.data.plan});
        // no default

        case CLEAR_PLAN:
            return Object.assign({}, state, {plan:action.payload});
        // no default
    }
    return state;
}
