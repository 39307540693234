import { wotdApi } from "../../api/wotdApi";

// fetch a list of all wotd categories
export async function fetchtWotdAllCategories() {
  const {
    data: { result },
  } = await wotdApi().getWotdAllCategories();
  return result;
}

export async function fetchtSingleWotdCategoryVideo(categoryId, currentTime) {
  const data = await wotdApi().getWotdSelectedCategory(categoryId, currentTime);
  return data;
}

export async function fetchWotdOrgAccess(orgId) {
  const data = await wotdApi().getWotdOrgAccess(orgId);
  return data;
}

// generic wotd routes

//route will return the name and id of the categories 
export async function fetchWotdGenericVideos() {
  const {
    data: { result },
  } = await wotdApi().getWotdGenericVideos();
  return result
}

export async function fetcWotdGenericSelectedVideoSprint8(categoryId) {
  const {data : {result}} = await wotdApi().getWotdGenericSelectedVideoSprint8(categoryId)
  return result
}

export async function fetcWotdGenericSelectedVideoVirtualActive(categoryId) {
  const {data : {result}} = await wotdApi().getWotdGenericSelectedVideoVirtualActive(categoryId)
  return result
}


export async function fetcWotdGenericSelectedVideoTrainerLed(categoryId) {
  const {data : {result}} = await wotdApi().getWotdGenericSelectedVideoTrainerLed(categoryId)
  return result
}



