import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// styles
import styles from "./viewsGraph.module.css";

// components
import { COLORS } from "../colors";
import { GraphPlaceholder } from "../../../graphPlaceholder/graphPlaceholder";
import { ToggleButtons } from "../../../buttons/buttons";
import parse from "date-fns/parse";
import format from "date-fns/format";

const ViewsGraph = (props) => {
  // props
  const {
    contentData,
    categoryData,
    getContentData,
    getCategoryData,
    categories,
  } = props;
  // local
  const [toggle, setToggle] = useState("content");

  const options = [
    {
      label: "Content",
      value: "Content",
      onClick: () => {
        setToggle("content");
        getContentData();
      },
    },
    {
      label: "Category",
      value: "Category",
      onClick: () => {
        setToggle("category");
        getCategoryData();
      },
    },
  ];
  // this code refactored to remove warning // both if statement returns same component?
  // function showGraph() {
  //     if ((!contentData && toggle === 'content') ||
  //         ((!categoryData && toggle === 'category' ||
  //             toggle === 'category' && !categories))){
  //         return (
  //             <div className={styles.container}>
  //                 <GraphPlaceholder/>
  //             </div>
  //         )
  //     } else {
  //         return <Graph {...props} toggle={toggle} categories={categories}/>
  //     }
  // }

  function showGraph() {
    if (!contentData && toggle === "content") {
      return (
        <div className={styles.container}>
          <GraphPlaceholder />
        </div>
      );
    }

    if (
      (!categoryData && toggle === "category") ||
      (toggle === "category" && !categories)
    ) {
      return (
        <div className={styles.container}>
          <GraphPlaceholder />
        </div>
      );
    }

    return <Graph {...props} toggle={toggle} categories={categories} />;
  }

  return (
    <div>
      <div className="margin-bottom-15">
        <ToggleButtons
          inactiveColor="#fff"
          activeColor="#272727"
          className={styles.toggle}
          options={options}
        />
      </div>
      {showGraph()}
    </div>
  );
};

export default ViewsGraph;

function Graph({ contentData, categoryData, toggle, categories, label }) {

  //local
  const [active, setActive] = useState({});
  const [categoriesCopy, setCategoriesCopy] = useState([]);
  console.log(categories)
  useEffect(() => {
    if (!categoryData || !categories) return;

    // create hashmap in order to sort the category data by views
    const countKeeper = {};
    categories.forEach(
      (cat) => (countKeeper[cat.name] = { category: cat, val: 0 })
    );
    // accumulate all views of different data points
    categoryData.forEach((dataPoint) => {
      Object.keys(dataPoint).forEach((point) => {
        if (typeof dataPoint[point] === "number") {
          if (countKeeper[point])
            countKeeper[point].val = countKeeper[point].val + dataPoint[point];
        }
      });
    });

    // transform hashmap into array for sorting
    const transformedObjToArray = Object.keys(countKeeper).map((key) => ({
      name: key,
      value: countKeeper[key],
    }));
    transformedObjToArray.sort(compare);

    // transform sorted array back to hashmap
    const updatedActive = {};
    [...transformedObjToArray]
      .slice(0, 10)
      .forEach((item) => (updatedActive[item.name] = true));
    setActive(updatedActive);
    const copy = transformedObjToArray.map((cat) => ({
      ...cat.value.category,
      views: cat.value.val,
    }));
    setCategoriesCopy(copy);
  }, [categoryData, categories]);
  console.log(categoriesCopy)
  // functions
  function compare(a, b) {
    if (a.value.val < b.value.val) {
      return 1;
    }
    if (a.value.val > b.value.val) {
      return -1;
    }
    return 0;
  }

  function handleMouseClick(e, name) {
    const { checked } = e.target;
    const temp = { ...active };
    temp[name] = checked;
    setActive(temp);
  }

  const CustomTooltip = ({ active, payload, label }) => {
    payload = payload.sort((a, b) => {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      return 0;
    });
    if (active && payload && payload.length) {
      return (
        <div className={styles.tooltip}>
          <p>{label}</p>
          {payload.map((item) => (
            <p style={{ color: item.stroke }}>
              {item.dataKey}: {item.value}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const dateFormatter = (date) => {
    const result = parse(date, "yyyy-MM-dd", new Date()).getTime();
    return format(result, "MM/dd/yy");
  };

  return (
    <div>
      <div className={styles.container}>
        {toggle === "content" ? (
          <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <LineChart
              data={contentData}
              margin={{
                top: 15,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis dataKey="date" tickFormatter={dateFormatter} />
              <YAxis
                label={{ value: label, angle: -90, position: "insideLeft" }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey={label}
                stroke="#4BBC4E"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <LineChart
              data={categoryData}
              margin={{
                top: 15,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis dataKey="date" tickFormatter={dateFormatter} />
              <YAxis
                label={{ value: label, angle: -90, position: "insideLeft" }}
              />
              <Tooltip content={<CustomTooltip />} />
              {categoriesCopy.map((cat, i) => {
                if (!active[cat.name]) return null;
                return (
                  <Line
                    key={cat.id}
                    type="monotone"
                    dataKey={cat.name}
                    stroke={COLORS[i]}
                    dot={false}
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
      {toggle === "category" ? (
        <div className={styles.categories}>
          <table id="table" className="table content-table">
            <thead>
              <tr>
                <th scope="col">Category</th>
                <th scope="col" className="t-t:c">
                  {label}
                </th>
              </tr>
            </thead>
            <tbody>
              {categoriesCopy.map((cat, i) => (
                <tr key={`cat_${cat.id}`}>
                  <td>
                    <input
                      id={`cat_${cat.name}${i}`}
                      type="checkbox"
                      onChange={(event) => handleMouseClick(event, cat.name)}
                      checked={active[cat.name]}
                    />
                    <label htmlFor={`cat_${cat.name}${i}`}>{cat.name}</label>
                  </td>
                  <td>{cat.views}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}
