import React from 'react'
import moment from 'moment'


export const SingleTileStats = ({plan_average, last_activity, best, worst, recordedBest, recordedWorst, units, diff, goalDiff}) => {
  return(
    <div>
      <h4>Highlights</h4>

      <div className='highlights'>
        <span>Last Recorded Activity</span>
        <span style={{float:"right"}}>{last_activity} {units}</span>
        <hr></hr>
        <p>{goalDiff}</p>
      </div>

      <div className='highlights'>
        <span>Average Activity</span>
        <span style={{float:"right"}}>{Math.round(plan_average)} {units}</span>
        <hr></hr>
        <p>{diff}</p>
      </div>

      <div className='highlights'>
        <span>Best Activity</span>
        <span style={{float: "right"}}>{best} {units}</span>
        <hr></hr>
        <p>Recorded on {moment(recordedBest).format('MM/DD/YY')}</p>
      </div>

      <div className='highlights'>
        <span>Worst Activity</span>
        <span style={{float:"right"}}>{worst} {units}</span>
        <hr></hr>
        <p>Recorded on {moment(recordedWorst).format('MM/DD/YY')}</p>
      </div>

    </div>
  )
}
