import mainApi from "./mainApi";

export function assessmentsApi() {
    return {
        // get a list of assessments for a given member
        getAssessments: function(userId) {
            let url = '/assessment/list';
            if (userId) url += `?user_id=${userId}`;
            return mainApi.get(url);
        },
        // get an assessment from the backend
        getAssessment: function() {
            return mainApi.get(`/assessment/get`);
        },
        // get a taken assessment from the server
        getTakenAssessment: function(pathwayId, userId) {
            return mainApi.get(`/assessment/single?pathway_id=${pathwayId}&user_id=${userId}`)
        },
        // set the health goal of a user
        setHealthGoal: function(healthGoalId) {
            return mainApi.put(`/assessment/set/healthgoal/${healthGoalId}`);
        },
        // record the answer to a question in an assessment
        recordAnswer: function(pathwayId, questionId, answerId) {
            return mainApi.put(`/assessment/record/answer/${pathwayId}/${questionId}/${answerId}`);
        },
        // record an attempted start of assessment
        recordAssessmentAttempt: function(pathwayId) {
            return mainApi.post(`/assessment/attempt/${pathwayId}`);
        },
        // get legacy assessment answer
        getAnswer: function(fieldId) {
            return mainApi.get(`/forms/response?fieldId=${fieldId}`);
        },
        getAssessmentReminder: function(userId){
            return mainApi.post(`/assessment/reminder/${userId}`)
        }

    }
}