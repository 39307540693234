import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// actions
import { setClientTile, resetClientTile } from "../../store/actions/tileActions";

// components
import ClientTrends from "./clientTrends";
import Loading from "../Loading/Loading";
import PlanSelection from "./planSelection";

const ClientTrendsContainer = props => {
    // state
    const { tile } = props;
    // actions
    const { setClientTile, resetClientTile, } = props;
    // props
    const { match } = props;
    //local
    const [ plan, setPlan ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    // on mount, check if a tile is already present and
    // if it's the same tile that's being viewed.
    useEffect(() => {
        const tileId = match.params.tileId;
        if (!tile) {
            setClientTile({ id: tileId })
        } else if (tileId === tile.tile.id){
            setLoading(false);
        }

        // no tile is in state or it doesn't match the current screen

        return () => {
            // remove tile from state on dismount
            resetClientTile();
        }
    }, []);

    useEffect(() => {
        if (tile && tile.plan.length === 1) setPlan(tile.plan[0]);
        if (tile) { setLoading(false) }
    }, [tile]);

    if (loading || !tile) {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,}}>
                <Loading/>
            </div>
        )
    }

    if (plan) {
        return (
            <div>
                <ClientTrends tile={tile} plan={plan}/>
            </div>
        )
    }
    return (
        <div>
            <PlanSelection tile={tile} setPlan={setPlan}/>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        user: state.user,
        tile: state.clientTile,
    }
}

const actions = {
    setClientTile,
    resetClientTile,
}
export default connect(mapStateToProps, actions)(ClientTrendsContainer);