import React, { useState} from 'react';
import {
    isWithinInterval,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    isSameMonth,
    isSameDay,
    addDays,
    format,
    addMonths,
    subMonths,
} from 'date-fns';

// styles
import styles from './activityCalendar.module.css';

const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];


const ActivityCalendar = props => {
    // props
    const { events, callback } = props;
    // local
    const [ date, setDate ] = useState(new Date());

    function renderDays() {
        const monthStart = startOfMonth(date);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                let highlightClass = '';
                for (let activity of events) {
                    const activityStart = {
                        year: activity.start.substr(0, 4),
                        month: parseInt(activity.start.substr(5, 2)) - 1,
                        day: activity.start.substr(8, 2),
                    };
                    const activityEnd = {
                        year: activity.end.substr(0, 4),
                        month: parseInt(activity.end.substr(5, 2)) - 1,
                        day: activity.end.substr(8, 2),
                    };
                    const start = new Date(activityStart.year, activityStart.month, activityStart.day);
                    const end = new Date(activityEnd.year, activityEnd.month, activityEnd.day);
                    if (isSameDay(start, end) && isSameDay(day, start)) {
                        highlightClass = `${styles.cap} ${styles.capSingle}`;
                        break;
                    }
                    if (isWithinInterval(day, { start, end})){
                        if (isSameDay(day, start) && !isSameDay(start, end)) {
                            highlightClass = `${styles.cap} ${styles.capStart}`;
                            break;
                        }

                        if (isSameDay(day, end) && !isSameDay(start, end))  {
                            highlightClass = `${styles.cap} ${styles.capEnd}`;
                            break;
                        }
                        highlightClass = styles.cap;
                        break;
                    }
                }
                days.push(
                    <div
                        className={`${styles.cell} ${
                            !isSameMonth(day, monthStart)
                                ? styles.hidden
                                : ""
                        }`}
                        key={day}
                    >
                        <span className={`${styles.number} ${highlightClass}`}>{formattedDate}</span>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className={styles.row} key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return (
            <div>
                {rows}
            </div>
        )
    }

    function nextMonth() {
        const update = addMonths(date, 1);
        setDate(update);
        if (callback) callback(update);
    }

    function prevMonth() {
        const update = subMonths(date, 1);
        setDate(update);
        if (callback) callback(update);
    }

    return (
        <div className={styles.container} data-cy="activity-calender">
            <div className={styles.dateHeader}>
                <span className={styles.dateYear}>{months[date.getMonth()]} {date.getFullYear()}</span>
                <span className={styles.dateNav}>
                    <i onClick={prevMonth} className="fas fa-chevron-left"/>
                    <i onClick={nextMonth} className="fas fa-chevron-right"/>
                </span>
            </div>
            <div className={styles.weekHeader}>
                <span className={styles.weekday}>S</span>
                <span className={styles.weekday}>M</span>
                <span className={styles.weekday}>T</span>
                <span className={styles.weekday}>W</span>
                <span className={styles.weekday}>T</span>
                <span className={styles.weekday}>F</span>
                <span className={styles.weekday}>S</span>
            </div>
            <div>{renderDays()}</div>
        </div>
    )
}

export default ActivityCalendar;