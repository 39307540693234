// npm modules
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// actions
import { addQuestion, bulkAlterQuestions, } from "../../actions/action_forms";

// components
import SingleQuestion from "./singleQuestion";
import { BasicButton } from "../buttons/buttons";

const NewQuestionScreen = ({ questions, taxonomy, addQuestion, bulkAlterQuestions }) => {

    // function that is called when a draggable object is released in a droppable area.
    // reorders questions and calls appropriate functions to show reordering
    const onDragEndQuestions = ({ destination, source }) => {
        // do nothing if no movement was made
        if(!destination) return;
        if(destination.index === source.index) return;

        // dispatch front end actions to show temporary reordering
        const clone = [...questions];
        const [removed] = clone.splice(source.index, 1);
        clone.splice(destination.index, 0, removed);
        bulkAlterQuestions(clone);
    };

    return (
        <div>
            <h2>Assessment Questions</h2>
            <div>
                <DragDropContext onDragEnd={onDragEndQuestions}>
                    <Droppable droppableId='questionDropArea'>
                        { provided => (
                            <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                { questions.map((question, i) => {
                                    return (
                                        <Draggable key={question.id} draggableId={`${question.id}`} index={i}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    key={question.id}>
                                                    <SingleQuestion taxonomy={taxonomy} length={questions.length} num={i+1} question={question}/>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
                <BasicButton className="btn primary" onClick={addQuestion}>+ Add Question</BasicButton>
            </div>
        </div>
    )
};

const actions = {
    addQuestion,
    bulkAlterQuestions,
};

export default connect(null, actions)(NewQuestionScreen);

NewQuestionScreen.propTypes = {
    questions: PropTypes.array.isRequired,
    bulkAlterQuestions: PropTypes.func,
    addQuestion: PropTypes.func,
    taxonomy: PropTypes.object,
};