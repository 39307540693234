import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

// actions
import {fetchAssessmentList} from "../../../store/actions/assessmentActions";

// components
import ClientHeader from "../../clientHeader/clientHeader";
import ClientAssessmentListContainer from "../../clientAssessmentList/clientAssessmentListContainer";

const ClientAssessmentsMain = ({ user, ...props }) => {
    // local
    const [assessments, setAssessments] = useState([]);

    // grab assessments on first render
    useEffect(() => {
        async function prepare() {
            try {
                const res = await fetchAssessmentList();
                setAssessments(res);
                console.log(res);
            } catch(e) {
                console.log(e);
            }
        }

        prepare();
    }, []);
    return (
        <div className="client-container">
            <ClientHeader backUrl={`/member/clientDash/${user.username}`}/>
            <div className="container client-account-dash">
                <ClientAssessmentListContainer user={user} assessments={assessments}/>
            </div>
            <div className="footer-actions">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6">
                            <button className="btn client-white" onClick={props.history.goBack}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps({ user }){
  return {
    user
  }
}

export default connect(mapStateToProps)(ClientAssessmentsMain);
