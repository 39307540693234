import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {Button, Navbar, Nav, NavDropdown, MenuItem} from 'react-bootstrap';
import {connect} from 'react-redux';
import {fetchSelf} from "../../actions/action_user";
import '../header.css';
import '../treo.css';
import PhotoContainer from '../profilePhotos/container';

// let token = localStorage.getItem("key");
// let currentUser = localStorage.getItem("user");
let currentUser = "Coach";

//currentUser will be user from localStorage, 
class HeaderCoach extends React.Component {

  constructor() {
    super();
    if(currentUser) {
      this.state={loggedIn: true, unread: 0};
    }
    else {
      this.state={loggedIn: false, unread: 0};
    }
      this.logOutUser=this.logOutUser.bind(this)
  }

  componentDidMount(){
    this.props.fetchSelf() 
     .then(()=>{
        axios.get(`/posts/user/${this.props.user.username}/unread`)
          .then((res)=>{
            if(res.data.result > 0)this.setState({unread:res.data.result});
          })
     }) 
    }

handleSelect(eventKey, event) {
        event.preventDefault();
        alert(`selected ${eventKey}`);
    }
    
    logOutUser(e) {
      e.preventDefault();
        axios.get('/logout')
            .then(res=>{
                if(res.status === 200) window.location.assign('/');
            })
            .catch()
      }

  render() {
    return (
      <Navbar inverse collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand className="visible-lg">
               <Link to='/dashboard'><img src={require('./header-logo.png')} alt='Treo Logo' /></Link>
              </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <li>
                <Link to='/dashboard/analytics/user'>
                    Dashboard
                </Link>
            </li>
            <li>
                <Link to='/dashboard/ClientSearch'>
                    Members
                </Link>
            </li>
            <li>
                <Link to='/dashboard/Inbox'>
                    {this.state.unread < 1 ? null : <span><div className="coach-header-unread-notification">{this.state.unread}</div></span>}Messaging
                </Link>
            </li>
            <li>
                <Link to='/dashboard/moderation'>
                    Moderation
                </Link>
            </li>
          </Nav>
            
            <Navbar.Form pullRight className="admin-profile">
                  <NavDropdown eventKey="4" title="Profile" id="nav-dropdown" className="inline-block">
                      <MenuItem eventKey="4.1">
                          <Link to='/dashboard/Account/Info'>                        
                                Profile
                          </Link>                  
                      </MenuItem>
                      <MenuItem eventKey="4.2">                          
                          <form className="logout-form" onClick={this.logOutUser}>
                              <Button type="submit">Log Out</Button>
                          </form>
                      </MenuItem>
                  </NavDropdown>                      
                  <Link to='/dashboard/Account/Info' className="inline-block">                        
                      <div className="admin-profile-image">
                        <PhotoContainer
                            image={this.props.user ? this.props.user.profile_image : null}
                            username={this.props.user ? '' :  null} 
                            url_path={`dashboard/Account/Info`}                           
                        />
                      </div>
                  </Link>
	          </Navbar.Form>
            

          </Navbar.Collapse>
      </Navbar>
    );
  }
}

function mapStateToProps({user}){
    return{user}
}

export default connect(mapStateToProps, {fetchSelf})(HeaderCoach);