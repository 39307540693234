import mainApi from "./mainApi";

export function gameApi() {
    return {
        // get current badge hierarchy
        getBadges: function() {
            return mainApi.get('/game/level/hierarchy');
        },
        // get current badge level and next
        getCurrentBadge: function() {
            return mainApi.get('/game/current/badges');
        },
        // get the current leaderboard
        getLeaderboard: function(onlyOrgFilter) {
            return mainApi.get(`/game/leaderboard${onlyOrgFilter ? "?org=true" : ''}`);
        },
        // retrieve how to score rules
        getHowToScore: function() {
            return mainApi.get('/game/howtoscore');
        },
        // get a list of nicknames awaiting review
        getNamesWaitingReview: function(userId) {
            let url = '/coach/review/nickname/list';
            if (userId) url += `?user_id=${userId}`;
            return mainApi.get(url);
        },
        // get a list of nicknames awaiting review
        getImagesWaitingReview: function(userId) {
            let url = '/coach/review/profile-image/list';
            if (userId) url += `?user_id=${userId}`;
            return mainApi.get(url);
        },
        // accept of reject a nickname
        moderateNickname: function(userId, status) {
            return mainApi.put(`/coach/review/nickname/${userId}/${status}`)
        },
        // accept of reject an image
        moderateImages: function(userId, status) {
            return mainApi.put(`/coach/review/profile-image/${userId}/${status}`)
        },
    }
}