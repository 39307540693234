import React from 'react';

// styles
import styles from './victoryFeed.module.css';

// components
import VictoryTile from "../../../victoryTile/victoryTile";
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const VictoryFeed = props => {
    const {victories, ended, loadMore} = props;

    if (!victories) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }
    return (
        <div className={styles.victories}>
            {victories.map(victory => {
                return (
                    <div key={victory.victory.id}><VictoryTile victory={victory}/></div>
                )
            })}
            {!ended && victories.length ?
                <div>
                    <button onClick={() => loadMore(victories[victories.length-1].victory.id)} className={styles.more}>Load More</button>
                </div>
                : null
            }
        </div>
    )
}

export default VictoryFeed;