import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import NoteList from './NoteList';
import NoteSingle from './NoteSingle';
import axios from 'axios';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactPaginate from 'react-paginate';

import {fetchNotesById, fetchNotesByUsername, clearNote} from "../../../actions/action_notes";
let firstNoteContent;
let firstNoteTime;
let lastNoteTime;
let lastNoteContent;
let firstNoteAuthor;
let firstNoteUserName;
// eslint-disable-next-line no-unused-vars
let firstNoteProfileImage;
let user;



class NotesMain extends Component{
    constructor(){
        super();
           this.state = {
               notesList: [],
               id: null,
               content: '',
               created_at: null,
               listStyle: true,
               newNote: false,
               notesMainChild: true,
               noteListActive: false,
               newNoteActive: false,
               updateList: false,
               showNoteContainer: false,
               totalPages:1,
               pageNumber:1,
               notes:[],
               firstNote:false,
               user:'',
       };
    }


    componentDidMount() {
      this.fetchNotes();
    }

    componentWillUnmount(){
      this.props.clearNote(this.props.notes);
    }

    handlePageClick=(e)=>{
      this.setState({pageNumber:e.selected+1}, () => {
        this.fetchNotes();
        this.createList();
      })
    }

     // refreshNotes = (value) => {
     //    this.fetchNotes()
     //      .then(()=>{
     //        this.createList();
     //      })
     //    // this.createListSingle();
     //  };

   createList=()=>{
          if(this.props.notes){
              return this.props.notes.map((item, i)=>{

                if(i === 0) {
                  firstNoteContent = this.props.notes[i].content;
                  firstNoteTime = this.props.notes[i].created_at;
                    axios.get(`/user/single/id/${item.author}`)
                    .then(res => {
                      user = res.data.user
                      firstNoteAuthor = `${res.data.user.title} ${res.data.user.first_name}`;
                      firstNoteUserName = res.data.user.username;
                      firstNoteProfileImage = res.data.user.profile_image;
                    })
                        .catch(err=>console.log(err)); 

                }
                if(i === this.props.notes.length - 1){
                  lastNoteContent = this.props.notes[i].content;
                  lastNoteTime = this.props.notes[i].created_at;
                }

                     return(

                           <NoteList
                           id={this.props.notes[i].id}
                           title={this.props.notes[i].title}
                           content={this.props.notes[i].content}
                           user_id={this.props.notes[i].user_id}
                           created_at={this.props.notes[i].created_at}
                           idHandler={this.idHandler}
                           style={this.state.listStyle}
                           newNoteActive={this.state.newNoteActive}
                           updateList={this.state.updateList}
                           tile_id={this.props.notes[i].tile_id}
                           plan_id={this.props.notes[i].plan_id}
                            />

                     )
              });
          }
      };

    createListSingle=()=>{
          if(this.props.notes){
            return(
                 <NoteSingle
                 user={this.state.user}
                 firstNoteProfileImage={user}
                 firstNoteUserName={firstNoteUserName}
                 firstNoteAuthor={firstNoteAuthor}
                 firstNoteTime={firstNoteTime}
                 firstNoteContent={firstNoteContent}
                 lastNoteTime={lastNoteTime}
                 lastNoteContent={lastNoteContent}
                 notesList={this.state.notesList}
                 username={this.props.username}
                 id={this.state.id}
                 content={this.state.content}
                 created_at={this.state.created_at}
                 newNote={this.state.newNote}
                 notesMainChild={this.state.notesMainChild}
                 noteListActive={this.state.noteListActive}
                 newNoteActive={this.newNoteActive}
                 refreshNotes={this.refreshNotes}
                 author={this.state.author}
                 authorUserName={this.state.authorUserName}
                 profileImage={this.state.profileImage}
                 singleTileChild={this.props.singleTileChild}
                />  
              )
          }
      };



     idHandler = (idValue, listStyle, noteListActive) => {
        this.setState({noteListActive: noteListActive})
        this.setState({newNote: false})
        this.setState({listStyle: false})
        this.setState({id: idValue}, () => {
          axios.get(`/notes/${this.props.username}/note-id/${this.state.id}`)
                  .then(res => {
                    console.log(res.data)
                    this.setState({content:res.data.note.content})
                    this.setState({created_at:res.data.note.created_at})
                    axios.get(`/user/single/id/${res.data.note.author}`)
                    .then(res => {
                      this.setState({author:`${res.data.user.title} ${res.data.user.first_name}`})
                      this.setState({authorUserName:res.data.user.username})
                      this.setState({profileImage:res.data.user.profile_image})
                      this.setState({user:res.data.user})
                    })
                        .catch(err=>console.log(err));  
                  })
                      .catch(err=>console.log(err));
        })

      };

      newNote() {
        // this.setState({listStyle: false});
        this.setState({showNoteContainer: true}, () => {this.setState({newNote: !this.state.newNote})});
      }

    newNoteActive = (newNoteActiveValue) => {
      this.setState({
        newNoteActive:newNoteActiveValue,
        listStyle:newNoteActiveValue

      }, () => {
        console.log(this.state.listStyle)
      })
        axios.get(`/notes/list/${this.props.username}/page/1`)
        .then(res => {
          this.setState({notesList: res.data.results.notes});
        })
            .catch(err=>console.log(err));
            this.setState({newNote: false});
            this.setState({updateList: true});
    };

    fetchNotes(){
      if(this.props.tileId){
        this.props.fetchNotesById(this.props.tileId, this.props.username, this.state.pageNumber);
      }
      else{
        this.props.fetchNotesByUsername(this.props.username, this.state.pageNumber);
      }
    }

    clearNotes(){
      this.props.clearNote(this.props.notes);
    }

    showNotes(){
      if(this.props.notes){
        if(this.props.notes.length > 0 && this.state.showNoteContainer === false){
          this.setState({showNoteContainer:true})
          this.setState({notes:this.props.notes}, () => {console.log(this.state.notes)})
          return;
        }
      }
    }

    render(){
      this.showNotes()
      let cls = !this.props.singleTileChild  ? "container margin-top" : null;
      return(
          <div className={cls}>
            <div className="row">
                <div className="col-sm-6">
                    <h2>Coaching Notes</h2>
                    <p>These notes are private and not visible to the member.</p>
                </div>
                <div className="col-sm-6 text-right">
                    <button className="btn primary" onClick={()=>this.newNote()}>START NEW NOTE</button>
                </div>
            </div>
            <div className="list-container">
              <ul className="note-ul col-md-3 col-xs-12">
                {this.createList()}
              </ul>
            </div>
            {this.state.showNoteContainer
              ? <div className="note-container col-md-9 col-xs-12">
                {this.createListSingle()}
                </div>
                
              :null
            }
         
               { this.props.pages > 1 &&
                    <div className="pagination-container">                        
                        <ReactPaginate
                            pageCount={this.props.pages}
                            pageRange={10}
                            pageMargin={5}
                            pageClassName='paginate paginate-default'
                            previousClassName='paginate paginate-previous'
                            nextClassName='paginate paginate-next'
                            onPageChange={this.handlePageClick}
                        />                        
                    </div>
                }
        </div>
      )
    }
}

const mapStateToProps = ({tile, notes, pages}) => {
  return {
    tile: tile.tile,
    notes: notes.notes,
    pages: notes.pages
  }
};

function mapDispatchToProps(dispatch){
  return bindActionCreators({fetchNotesById, fetchNotesByUsername, clearNote}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotesMain));
