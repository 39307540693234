import React from 'react'
import styles from './DailyWotd.module.css';

const DailyWotdFooter = () => {
  return (
    <div>
         <h1 className={styles.footerTitle}>IT'S FREE!</h1>
         <h4 className={styles.footerSubTitle}>NO DOWNLOAD OR SIGN-UP REQUIRED</h4>
         <h6 className={styles.footerStandardRates}>Standard data rates may apply.</h6>
    </div>
  )
}

export default DailyWotdFooter