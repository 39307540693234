import React from 'react';
import './dashBoxes.css';

export class DashBoxes extends React.Component {

	render() {
		return(
            <div className="container margin-top-lrg">
                <div className="row">
                    <div className="col-xs-12 col-sm-4"> 
                        <div className="widget-container text-center">
                            <h2 className="dashBox-text">{this.props.averageContactsPerCoach}</h2>
                            <h4 className="dashBox-text">Ave of monthly total member contacts per coach</h4>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="widget-container text-center">
                            {/*<h2 className="dashBox-text">{this.props.responseTime}</h2>*/}
                            {/*<p className="dashBox-text">hours : minutes</p>*/}
                            <h2>[5]</h2>
                            <h4 className="dashBox-text">Average Messaging Response Time</h4>
                        </div>
                  </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="widget-container text-center">
                            <h2 className="dashBox-text">{this.props.averageTWS}</h2>
                            <h4 className="dashBox-text">Average TWS</h4>                    
                        </div>
                    </div>
                </div>
            </div>
			);
	}
};
