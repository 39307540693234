//client detail view
import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

import ClientHeader from "../../clientHeader/clientHeader";
import ClientSubHeader from '../clientDetail/ClientSubHeader';
import ClientDisplay from '../ClientDisplay/ClientDisplay';
import axios from 'axios';
import LinkList from "../../links/linkList/linkList";

class ProfileNav extends React.Component {

    constructor(props){
        super();
        this.state ={
            wellnessScore:'', 
            tiles:[]
        }
    }


    componentDidMount(){
        //get client wellness score
        axios.get('/score/wellness-score')
            .then(
                res=>{                   
                    this.setState({
                        wellnessScore:res.data.results.score
                    });
                }
            )
            .catch(err=>console.log('ERROR: ', err));
        axios.get('/tiles/client')
            .then(
                res=>{
                    this.setState({tiles: res.data.result})
                }
            )
            .catch(err=>console.log('ERROR: ', err))                
    }
    
    render() {
        const {match, user} = this.props;
        // sets the bg color based on wellness score
        // eslint-disable-next-line no-unused-vars
        var scoreClass = 'low';
        if (this.state.wellnessScore >= 51 && this.state.wellnessScore < 75 ) {
            scoreClass = 'average';
        } else if (this.state.wellnessScore >= 75 && this.state.wellnessScore < 90 ) {
            scoreClass = 'high';
        } else if (this.state.wellnessScore >= 90) {
            scoreClass = 'highest';
        }

        const links = [
            { href: `${match.url}`, label: 'Account' },
            { href: `${match.url}/details`, label: 'Details' },
            { href: `${match.url}/notifications`, label: 'Notifications' },
            { href: `${match.url}/gym`, label: 'Gym' },
            { href: `${match.url}/equipment`, label: 'Equipment' },
            { href: `${match.url}/settings`, label: 'Game Settings' },
        ];
        
        return(
            <div className="client-container">                            
                <ClientHeader backUrl={`/member/clientDash/${user.username}`}/>
                <ClientSubHeader />
                <div className="container client-dash margin-top-3x">
                    <div className="row">
                        <div className="col-xs-12 col-md-3">
                            <div className='margin-bottom-med'>
                                <LinkList className='profileNav-links tour-profile-dropdown' links={links}/>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-9">
                            <ClientDisplay match={this.props.match} username={this.props.match.params.username} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({user}){
    return {user}
}

export default withRouter(connect(mapStateToProps)(ProfileNav));