import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import { connect } from "react-redux";

// styles
import styles from "./brightcovePlayer.module.css";

// actions
import { checkAlerts } from "../../store/actions/alertActions";
import {
  getDailyScore,
  setChangedScoreFlag,
} from "../../store/actions/wellnessScoreActions";

// components
import {
  recordDailyWotdVideo,
  recordViewChunk,
  recordWotdVideo,
} from "../../store/actions/contentActions";
import useDidMountEffect from "../../hooks/useDidMountEffect";

const BrightcovePlayer = (props) => {
  // props
  const { videoId, id, identifier, offset, wotdView, organizationId ,colorRed } = props;

  // actions
  const { checkAlerts, setChangedScoreFlag } = props;
  // local
  const player = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const currentTime = useRef(0);
  const ended = useRef(false);
  const [session, setSession] = useState(
    `${identifier}-${new Date().getTime()}`
  );
  const interval = useRef(null);
  const [initialView, setInitialView] = useState(false);
  const duration = useRef(0);
  const hasChangedMusicText = useRef(false);

  useDidMountEffect(() => {
    if (!player && player.current) return;
    player.current.player.load();
    setSession(`${identifier}-${new Date().getTime()}`);
  }, [id]);

  useDidMountEffect(() => {
    notify(0, 0, 0);
  }, [session]);

  // changes the color of the play button for daily wotd
  useEffect(() => {
    if (colorRed) {
      const addOrUpdateStyleElement = () => {
        const styleElement = document.createElement('style');
  
        const cssRule = `
          .video-js:hover .vjs-big-play-button  {
            background-color: #E02926 !important;
          }
        `;
  
        styleElement.appendChild(document.createTextNode(cssRule));
        const existingStyleElement = document.getElementById('custom-css');
        if (existingStyleElement) {
          existingStyleElement.innerHTML = cssRule;
        } else {
          styleElement.id = 'custom-css';
          document.head.appendChild(styleElement);
        }
      };
  
      addOrUpdateStyleElement();
    }
  }, [colorRed]);
  



  useEffect(() => {
    const timer = setInterval(() => {
      if (hasChangedMusicText.current) {
        clearInterval(timer);
      } else {
        changeMusicText();
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const notify = useCallback(
    async (start, duration, total) => {
      try {
        if (wotdView) {
          if (colorRed) {
            // records new daily wotd video
        await recordDailyWotdVideo(
              id,
              start,
              duration,
              session,
              total,
            );
          } else {
            // records first wotd videos
          await recordWotdVideo(
              id,
              start,
              duration,
              session,
              total,
              organizationId
            );
          }
        } else {  
          const res = await recordViewChunk(
            id,
            start,
            duration,
            session,
            total
          );
          if (res.alert_waiting) {
            checkAlerts();
            getDailyScore();
            setChangedScoreFlag(
              parseInt(res.daily_score.score.replace(",", ""))
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [id, session, wotdView, colorRed]
  );
  

  useEffect(() => {
    if (!isPlaying && interval.current) {
      clearInterval(interval.current);
      interval.current = null;
      return;
    }
    if (ended.current && interval.current) {
      clearInterval(interval.current);
      interval.current = null;
      return;
    }
    if (!ended.current && interval.current === null && isPlaying) {
      if (!initialView) {
        notify(0, 0, 0);
        setInitialView(true);
      }
      const seconds = 5;
      interval.current = setInterval(() => {
        if (player.current !== null) {
          currentTime.current = player.current.player.currentTime();
        }
        notify(
          Math.ceil(currentTime.current) - seconds,
          seconds,
          duration.current
        );
      }, seconds * 1000);
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [isPlaying, ended, notify]);

  useEffect(() => {
    if (!isReady) return;
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [isReady]);

  function resize() {
    let video = document.getElementsByClassName("video-js");
    if (video.length) video = video[0];
    const ratio = 0.5625;
    video.style.height = `${video.clientWidth * ratio}px`;
  }

  function handleStateChange(state) {
    if (duration.current === 0 || isNaN(duration.current))
      duration.current = state.duration;
    if (!state.paused && !isPlaying) setIsPlaying(true);
    currentTime.current = state.currentTime;
    if (state.ended) {
      setIsPlaying(false);
      ended.current = true;
    } else if (!state.ended && ended.current) {
      ended.current = false;
    }
  }

  function getState() {
    return {
      currentTime: player.current.player.currentTime(),
      duration: player.current.player.duration(),
      ended: player.current.player.ended(),
    };
  }

  function changeMusicText() {
    const buttons = document.getElementsByClassName("vjs-menu-item-text");
    if (buttons) {
      for (let button of buttons) {
        if (button.innerHTML.toLowerCase() === "en-us (main)") {
          button.innerHTML = "Music On";
        } else if (button.innerHTML.toLowerCase() === "en (alternate)") {
          button.innerHTML = "Music Off";
          hasChangedMusicText.current = true;
        }
      }
    }
  }

  function onSuccess({ ref }) {
    ref.on("play", play);
    ref.on("pause", pause);
    ref.on("ended", end);
    ref.on("ready", () => setIsReady(true));
  }

  function play() {
    setIsPlaying(true);
    ended.current = false;
    const state = getState();
    const params = {
      ...state,
      paused: false,
    };
    handleStateChange(params);

    // change menu item text
    changeMusicText();
  }

  function pause() {
    setIsPlaying(false);
    const state = getState();
    const params = {
      ...state,
      paused: true,
    };
    handleStateChange(params);
  }

  function end() {
    setIsPlaying(false);
    ended.current = true;
    const state = getState();
    const params = {
      ...state,
      paused: true,
    };
    handleStateChange(params);
    if (document.exitFullscreen) {
      if (document.fullscreenElement !== null) {
        document.exitFullscreen();
      }
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }


  return (
    <div
      className={`${styles.videoContainer} ${offset ? styles.offset : ""}`}
      data-cy="bcPlayer"
    >
      <ReactPlayerLoader
        ref={player}
        videoId={`ref:${videoId}`}
        accountId="6415528524001"
        playerId={"EgpN82vN6"}
        options={{ playsinline: true }}
        onSuccess={onSuccess}
      />
      <span style={{display: 'none'}} data-cy="timeDuration">{duration.current}</span>
    </div>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

const actions = {
  checkAlerts,
  setChangedScoreFlag,
};

export default connect(mapStateToProps, actions)(BrightcovePlayer);
