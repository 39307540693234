// npm modules
import React, { useState, useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import { Link} from 'react-router-dom';
import { connect } from 'react-redux';

// styles
import './logIn.css';
import styles from './login.module.css';

// actions
import { login, checkLogin, } from "../store/actions/authActions";

// components
import Logo from '../images/logo.png';
import Loader from 'react-loader-spinner';
import { STATUSES } from "../serverVariables/statuses";
import TermsOfService from "./signUp/termsOfService/termsOfService";
import {useWindowSize} from "../hooks/useWindowSize";

const Login = (props) => {
    // state
    const { appState, user } = props;
    // props
    const { location, history } = props;
    // actions
    const { login, checkLogin } = props;

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [policies, setPolicies] = useState([]);
    const {height} = useWindowSize();
    const tosRef = useRef();
    const formRef = useRef();

    //when appState is updated check status
    useEffect(() => {
        if (appState.isLoggedIn && appState.appReady && window.location.href.lastIndexOf('localhost') > 0) {
            const { from } = location.state || { from : null };
            if (from) {
                history.push(from);
            }
            if (location.state && location.state.linkTo) {
                history.push(location.state.linkTo);
                return;
            }
        }
    }, [ appState, history, location ]);

    // check if a user is logged in when mounting
    useEffect(() => {
        checkLogin()
            .then(() => setLoading(false));
    }, []);

    useEffect(() => {
        document.body.style.background = '#fff';
        return () => document.body.style.background = null;
    },[policies]);

    // forward a user to the appropriate place
    useEffect(() => {
        if (appState.appReady && !appState.isLoggedIn && location && location.state && location.state.from && location.state.from.search) {
            history.push({search: location.state.from.search});
        }
        if (location.state && window.location.href.lastIndexOf('localhost') > 0) return;
        if (user && appState.isLoggedIn && appState.appReady) {
            if(user.permission_id === 1){
                if(user.user_status_id < STATUSES.onboarding_coach_consult_pending){
                    //client is in onboarding
                    history.push({
                        pathname : `/member/onboard-assessment`,
                    });
                }else{
                    // Forward to dashboard
                    let url = { pathname: `/member/clientDash/${user.username}` };

                    if (location.state && location.state.from && location.state.from.search){
                        url.search = location.state.from.search;
                    }

                    if (location.state && location.state.from) {
                    const fromPathname = location.state.from.pathname;

                    // Check if the URL matches /account/notifications
                    const pattern = /.*\/account\/notifications/gm;
                    const isNotifications = pattern.test(fromPathname);

                    // Check if the URL matches /member/clientDash/{user.email}/assessments
                    const patternAssessment = /\/member\/clientDash\/[^/]+\/assessments/;
                    const isAssessments = patternAssessment.test(fromPathname);

                    if (isNotifications) {
                        url = location.state.from;
                    } else if (isAssessments) {
                        url = location.state.from;
                    }
                    }
                    history.push(url);
                }
            }else if(user.permission_id === 2){
                history.push({
                    pathname: '/dashboard/analytics/user'
                });
            }else{
                history.push({
                    pathname:'/dashboard/analytics/user'
                });
            }
        }
    }, [ appState ]);


    function logInUser(e) {
        e.preventDefault();
        login(email, password)
            .catch(error => {
                setError('Invalid email or password');
        });
    }

    if(loading){
        return (
            <div style={{height}} className='flex-vertically'>
                <Loader
                    type="Puff"
                    color="#354052"
                    height="100"
                    width="100"
                />
            </div>
        )
    }

    return (
        <div className={styles.background}>
            <div className="login-bg">
                <div ref={formRef} className="login container">
                    <div className='signupHeader'>
                        <a href="https://www.treowellness.com">
                            <img className="login-logo m-r:-2" src={Logo} alt='Treo Logo'/>
                        </a>
                    </div>

                    <h1>Habit Builder</h1>
                    <form onSubmit={logInUser}>
                        <div>
                            <label htmlFor="email"><b>Email</b></label>
                            <input type="text"
                                   className='loginUser'
                                   name="email"
                                   placeholder='Email'

                                   value={email}
                                   onChange={(e) => setEmail(e.target.value)}
                                   required/>
                        </div>
                        <div className="margin-top">
                            <label htmlFor="psw"><b>Password</b></label>
                            <input type="password"
                                   className="loginPass"
                                   name="password"
                                   placeholder='Password'
                                   value={password}
                                   onChange={e => setPassword(e.target.value)}
                                   required/>
                        </div>
                        <div className="row margin-top m-b:1">
                            <div className="col-xs-6">
                                <button className="btn primary" type="submit" onClick={logInUser}>Login</button>
                            </div>
                            <div className="col-xs-6 text-right">
                                <Link to={'/password-reset'} className='link forgot-password'>
                                    Forgot Password
                                </Link>
                            </div>
                        </div>
                        <div className='m-b:1'>Don't have an account? <Link to='/signup' className='link underlined'>Sign Up!</Link></div>
                        <div className="margin-top-lrg">
                            { error ?
                                <Alert className="col-md-6 login" bsStyle='danger'
                                       onDismiss={() => setError('')}>
                                    <p>{ error }</p>
                                </Alert>
                                : null
                            }
                        </div>
                        <div className="row margin-top-2x">
                            <div className="col-xs-12">
                                <p>Access to the Treo Habit Tracker is only available to Treo Wellness members. Please email us for more information on how to become a Treo member.</p>
                                <a href="mailto:info@treowellness.com" className='link underlined'>
                                    More information
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
                <div ref={tosRef} className={styles.footer}>
                    <TermsOfService loadPolicies={setPolicies} horizontal/>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps({ appState, user }) {
    return {
        appState,
        user,
    }
}

const actions = {
    login,
    checkLogin,
}
export default connect(mapStateToProps, actions)(Login);
