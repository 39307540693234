import React from 'react';
import { connect } from 'react-redux';

// styles
import styles from './CoachDetailMain.module.css';

// actions
import { fetchSelf } from "../../../actions/action_user";
import { fetchCoach } from "../../../store/actions/userActions";

// components
import HeaderAdmin from '../headerAdmin';
import HeaderCoach from '../HeaderCoach';
import CoachSubHeader from './coachSubheader';
import CoachDisplay from './CoachDisplay';
import LinkList from "../../links/linkList/linkList";

class CoachDetailMain extends React.Component {

	componentDidMount(){
        this.props.fetchSelf();
		window.scrollTo(0, 0)
        const username = this.props.match.params.username;
		this.props.fetchCoach(username)
            .catch(e => {
                console.log(e);
            });
	}

    userPermission(){
        if(this.props.user){
            if(this.props.user.permission_id === 3){
                return( 
                    <div>
                        <HeaderAdmin /> 
                    </div>
                    )
            }
            else{
                return( 
                    <div>
                        <HeaderCoach /> 
                    </div>
                    )
            }
        }
    }

    render() {
        const { match } = this.props;
        const links = [
            { href: `${match.url}/overview`, label: 'Coach Summary' },
            { href: `${match.url}/CoachClientSearch`, label: 'Members' },
            { href: `${match.url}/calendar`, label: 'Calendar' },
            { href: `${match.url}/inbox`, label: 'Messaging' },
            { href: `${match.url}/notes`, label: 'Notes' },
            { href: `${match.url}/moderation`, label: 'Moderation' },
        ];
	    if (!this.props.user || !this.props.singleCoach) return <div/>;
        return(
            <div class="client-detail">
                {this.userPermission()}
                <CoachSubHeader username={this.props.match.params.username} />
                <div className='container'>
                    <div className={styles.mainContent}>
                        <div className={`margin-top-lrg ${styles.links}`}>
                            <LinkList links={links}/>
                        </div>
                        <div className={styles.content}>
                            <CoachDisplay match={this.props.match} username={this.props.match.params.username}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({user, singleCoach}){
    return{
        user,
        singleCoach,
    }
}

const actions = {
    fetchSelf,
    fetchCoach
}

export default connect(mapStateToProps, actions)(CoachDetailMain);