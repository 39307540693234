import mainApi from "./mainApi";

export function authApi() {
    return {
        // check if a user has appropriate auth credentials saved in browser
        checkLogin: async function() {
            return mainApi.put('/login/check');
        },
        login: async function(email, password) {
            return mainApi.post(`/login/`, { username: email, password });
        },
        logout: function() {
            return mainApi.get('/logout');
        },
        recordSession: function(seconds, sessionId) {
            return mainApi.post(`/facts/record/session/${sessionId}/${seconds}  `);
        }
    }
}