import React from 'react';

/*
*General Error message that display and alert
* if a message is provided to the error it will show up
* default to a danger message
* may send in a prop to change style called error_type
*/

const Error = ({message, type}) =>{
    if(message && typeof message !== 'object'){
        return(
            <div className={type? `alert alert-${type}` : 'alert alert-danger'} role='alert'>
                {message}
            </div>
        )
    }else{
        return <div></div>
    }

};

export default Error;