// npm modules
import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";

// actions
import { fetchSelf } from "../../actions/action_user";
import {fetchCalendars} from "../../store/actions/calendarActions";

// styles
import styles from './calendarContainer.module.css';

// components
import Loading from "../Loading/Loading";
import Footer from "../Footer/Footer";
import Error from "../ErrorMessage/Error";
import { STATUSES } from "../../serverVariables/statuses";
import {ErrorAlert} from "../alerts/alerts";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, // control if loading ui appears
      message: null,
      type: null,
      confirm: null,
      pathway: [],
      errors:{},
      calendarUrl: null
    };
  }
  componentDidMount() {
    let that = this;
    window.addEventListener(
        'message',
        function(e) {
          if (that.isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
            that.handleSubmission();
          }
        }
    );

    fetchCalendars(this.props.user.coach_id)
        .then(res => {
          const primary = res.find(item => item.primary);
          this.setState({ calendarUrl: primary.url, loading: false });
        })
        .catch(() => {
          this.setState({errors: {calendar: 'The app was unable to find the calendar of your coach. Please contact your coach through the in app messaging inbox.'}})
        });
  }

  handleSubmission = async () => {
    // change user from onboarding to pending if they are in onboarding
    const { user_status_id, username } = this.props.user;
    let url = '';
    if (user_status_id >= STATUSES.active_coached) {
      url = `/user/change-status/${STATUSES.active_coached}/username/${username}`;
    } else { url = `/user/change-status/${STATUSES.onboarding_coach_consult_pending}/username/${username}`; }
    await axios
        .put(url)
        .catch(e => console.log(e));
    // grab users most recent pathway
    const pathways = await axios
        .get("/pathways/all")
        .catch(e => console.log(e));
    this.setState({ pathway: pathways.data.result[0], confirm: true });
    window.scrollTo(0, 0);
  };

  confirmTimeslot = async (e) => {
    e.preventDefault();
    const { user, timeslot } = this.state;
    if (user.phone.toString().length !== 10) {
      this.setState({ errors: { phone: 'Phone must be 10 digits' } });
      return;
    }
    user.phone = user.phone.toString();
    this.setState({ loading: true, errors: {} });
    await this.handleSubmission();
    await this.state.widget.timekitCreateBooking({...user, where: user.method }, timeslot)
        .catch(e => console.log(e));
    this.setState({ confirm: true, loading: false });
  };

  isCalendlyEvent(e) {
    return e.data.event &&
        e.data.event.indexOf('calendly') === 0;
  };

  render() {
    const slot = this.state.timeslot;
    if (this.state.loading) return <Loading/>

    if(this.state.errors && this.state.errors.calendars) {
      return (
          <ErrorAlert>{this.state.errors.calendars}</ErrorAlert>
      )
    }

    if (slot) {
      return (
          <div>
            <div className='t-a:c'>
              <h1>{slot.start.format("LL")}</h1>
              <h4>
                {slot.start.format("LT")} - {slot.end.format("LT")}
              </h4>
              <p>
                <span>with</span>
              </p>
              <h4>{slot.resources[0].name}</h4>
            </div>
            <div className={styles.confirmFormContainer}>
              <form className={styles.confirmForm} onSubmit={this.confirmTimeslot}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                      onChange={e => this.setState({ user: {...this.state.user, name: e.target.value }})}
                      value={this.state.user.name}
                      className="form-control"
                      type="text"
                      id="name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                      readOnly
                      value={this.state.user.email}
                      className="form-control"
                      type="text"
                      id="email"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                      className="form-control"
                      type="tel"
                      id='phone'
                      value={this.state.user.phone}
                      onChange={e => this.setState({ user: {...this.state.user, phone: e.target.value }})}
                      required
                      autoComplete={false}
                  />
                  { this.state.errors.phone ? <p className='text-danger'>{this.state.errors.phone}</p> : null }
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Preferred Contact Method</label>
                  <select
                      required
                      className='form-control'
                      id="preferredMethod"
                      value={this.state.user.method}
                      onChange={e => this.setState({ user: { ...this.state.user, method: e.target.value}})}>
                    <option value="">Select Preferred Method</option>
                    <option value="phone">Phone</option>
                    <option value="video">Video</option>
                  </select>
                </div>
                <button className='btn primary margin-top' type='submit'>
                  { this.state.loading ? <Loading color="#fff" height="25" width="25"/> : 'Confirm'}
                </button>
              </form>
            </div>
          </div>
      );
    }
    return (
        <div>
          <Error message={this.state.message} type={this.state.type} />
          <div className="container">
            <div className={styles.calendlyContainer}>
              <Calendly url={this.state.calendarUrl}/>
            </div>
            <div className="footer-link">
              <Footer />
            </div>
          </div>
          <div className="footer-actions">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 t-a:c">
                  {this.state.confirm ? (
                      <button
                          className="btn primary"
                          onClick={() =>
                              this.props.history.push(
                                  `/member/clientDash/${this.props.user.username}/pathways/${this.state.pathway.id}`
                              )
                          }
                      >
                        View Pathway Report
                      </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
const mapStateToProps = ({ user }) => {
  return {
    user
  };
};

export default withRouter(connect(mapStateToProps, { fetchSelf })(Calendar));

const Calendly = ({ url }) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
        'src',
        'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
  }, []);
  return (
      <div
          className="calendly-inline-widget"
          data-url={url}
          style={{ minWidth: '320px', height: '100%' }}
      />
  );
};