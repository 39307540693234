import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ClientGoalBar from './clientGoalBar'

class AddTileActivityStep2 extends Component{
    constructor(props){
        super(props);
        this.state ={}
        this.goBackToTile=this.goBackToTile.bind(this);
    }

    createGoalBar(){
        if(this.props.plan) return <ClientGoalBar 
                                    plan={this.props.plan[0]} 
                                    goBackToTile={this.goBackToTile}
                                    intro={this.props.location.state.intro}
                                    />;
        return null;
    }

    goBackToTile(){
        this.props.history.push(`/member/clientDash/${this.props.user.username}/habits`);
    }


    render(){
        return(
            <div>
                {this.createGoalBar()}
            </div>
            );
    }
}

function mapStateToProps({plan, user}){
    return{
        plan:plan.plan,
        user
    }
}

export default withRouter(connect(mapStateToProps)(AddTileActivityStep2));