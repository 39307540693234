import {GET_COACHES_ASSIGNMENTS} from "../actions/action_coach";

export default function(state=null, action){
    switch (action.type){

        case GET_COACHES_ASSIGNMENTS:
            return action.payload.data.result;
        // no default
    }
    return state;
}
