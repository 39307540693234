import {
    ORGANIZATION_LIST,
    ORGANIZATIONS_PAGE,
    SINGLE_ORGANIZATION,
    SINGLE_ORGANIZATION_COACH,
    ORGANIZATION_COACH_LIST,
    SINGLE_ORGANIZATION_CLIENTS,
    ORGANIZATION_CODES,
    ORGANIZATION_CODES_PAGES,
    ORGANIZATION_SEAT_HISTORY_MONTHLY,
    ORGANIZATION_SEAT_HISTORY_TOTAL,
    ORGANIZATION_WAITLIST, SET_ORGANIZATION_WAITLIST_PAGE,
} from "../actions/action_organizations";
import { isAfter } from 'date-fns';

const initialList = {
    page: 1,
    pages: 0,
    total: 0,
    organizations: []
}
// reducer that loads a list of orgs into state and keeps track of pages
export const organizationListReducer = (state=initialList, action) => {
    switch (action.type) {
        case ORGANIZATION_LIST:
            return {
                page: state.page,
                pages: action.payload.pages,
                total: action.payload.count,
                organizations: action.payload.organizations,
            };
        case ORGANIZATIONS_PAGE:
            return {
                ...state,
                page: action.payload,
            }
        default:
            return state;
    }
}

// reducer that loads a single org into state
export const organizationReducer = (state=null, action) => {
    switch(action.type) {
        case SINGLE_ORGANIZATION:
            return action.payload;
        case SINGLE_ORGANIZATION_COACH:
            return {
                ...state,
                coaches: action.payload,
            }
        default:
            return state;
    }
}

// reducer that holds a list of coaches available to an organization
export const organizationAvailableCoachesReducer = (state=[], action) => {
    switch(action.type) {
        case ORGANIZATION_COACH_LIST:
            return action.payload;
        default:
            return state;
    }
}

const initialClients = {
    page: 1,
    pages: 0,
    total: 0,
    clients: []
};

// reducer that holds a list of clients in an organization
export const organizationClientsReducer = (state = initialClients, action) => {
    switch(action.type) {
        case SINGLE_ORGANIZATION_CLIENTS:
            return {
                page: state.page,
                pages: action.payload.pages,
                total: action.payload.count,
                clients: action.payload.users,
            }
        default:
            return state;
    }
}

const initialCodes = {
    codes: [],
    pages: 1,
    page: 1,
    total: 0,
}
// reducer that holds a list of codes for an organization
export const organizationCodesReducer = (state=initialCodes, action) => {
    switch(action.type) {
        case ORGANIZATION_CODES:
            return {
                codes: action.payload.codes,
                pages: action.payload.pages,
                total: action.payload.count,
                page: state.page,
            }
        case ORGANIZATION_CODES_PAGES:
            return{
                ...state,
                page: action.payload,
            }
        default:
            return state;
    }
}

// reducer that holds the seat history for a given organization
export const seatHistoryReducer = (state=[], action) => {
    switch(action.type) {
        case ORGANIZATION_SEAT_HISTORY_MONTHLY:
            return action.payload.map(item => {
                if (isAfter(new Date(item.date), new Date())){
                    return { date: item.date, value: null }
                } else {
                    return { date: item.date, value: item.monthly_taken, value2: item.monthly_seats, value3: item.monthly_available }
                }
            });
        case ORGANIZATION_SEAT_HISTORY_TOTAL:
            return action.payload.map(item => {
                if (isAfter(new Date(item.date), new Date())){
                    return { date: item.date, value: null }
                } else {
                    return { date: item.date, value: item.total_taken, value2: item.total_seats, value3: item.total_available }
                }
            });
        default:
            return state;
    }
}

const initialWaitlist = {
    waitlist: [],
    pages: 0,
    total: 0,
    page: 1
}
// reducer that holds the waitlist for a given organization
export const organizationWaitlistReducer = (state=initialWaitlist, action) => {
    switch(action.type) {
        case ORGANIZATION_WAITLIST:
            return {
                ...state,
                waitlist: action.payload.waitlist,
                pages: action.payload.pages,
                total: action.payload.count,

            };
        case SET_ORGANIZATION_WAITLIST_PAGE:
            return {
                ...state,
                page: action.payload,
            }
        default:
            return state;
    }
}