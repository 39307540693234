import {CLEAR_ACTIVE_CONTENT, SET_ACTIVE_CONTENT} from "../actions/action_content";

export default function(state=null, action){
    switch (action.type){

        case SET_ACTIVE_CONTENT:
            return action.payload;

        case CLEAR_ACTIVE_CONTENT:
            return action.payload;
        // no default

    }
    return state;
}
