import React from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// actions
import {checkAlerts} from "../../../store/actions/alertActions";
import {fetchSelf} from "../../../actions/action_user";
import {getDailyScore, setChangedScoreFlag} from "../../../store/actions/wellnessScoreActions";

// components
import ClientHeader from "../../clientHeader/clientHeader";
import NewMessage from './NewMessage';


class NewMessageMain extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      title:'',
      reply:'',
      username:'',
      recipients:[],
      show: false,
      alertStyle: true,
      alert: '',
      //use this for recipient if more than one
      selectedRecipient:[],
      selected:false,
	  // catch multiple submits
	  hasSent:null,
    }
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

   componentDidMount(){
    this.props.fetchSelf();
    if(this.props.user){
      axios.get(`/user/coaching-team?username=${this.props.user.username}`)
        .then((res)=>{
          this.setState({recipients:res.data.result})
          })
        .catch((error)=>{
           console.log(error);
        });
    }
   }

   componentDidUpdate(prevProps){
    if(prevProps.user !== this.props.user){
      axios.get(`/user/coaching-team?username=${this.props.user.username}`)
        .then((res)=>{
          this.setState({recipients:res.data.result})
          })
        .catch((error)=>{
           console.log(error);
        });
    }
    else{
      return;
    } 
   }

  recipientHandler = (recipient) => {
    this.setState({selectedRecipient:recipient})
  }

  selectedHandler = (selected) => {
    this.setState({selected:selected})
  }

  titleHandler = (title) => {
    this.setState({title:title})
  }

  replyHandler = (reply) => {
    this.setState({reply:reply})
  }

  send(){
	  // disable multiple submits		
	  this.setState({ hasSent: true});
	  
      let finalRecipients = this.state.selectedRecipient.filter(recipient => !!recipient);
      if(finalRecipients.length === 0){
        this.setState({ show: true });
        this.setState({alert:'This user is not found'});
        this.setState({ alertStyle: false });
		this.setState({ hasSent: false});
      }
      if(this.state.title === '' || this.state.reply === ''){
        alert('do not leave title or message blank please');
		this.setState({ hasSent: false});
        return;
      }
      axios.post(`/posts/new-message`, { 
      	title:this.state.title,
      	text:this.state.reply,
      	recipients:finalRecipients,
      })
        .then((res)=>{
            if(res.status === 200){
                if (res.data.alert_waiting) {
                    this.props.checkAlerts();
                    this.props.setChangedScoreFlag(parseInt(res.data.daily_score.score.replace(',', '')));
                    this.props.getDailyScore();
                }
                this.setState({ selectedRecipient: [], hasSent: false });
                this.props.history.push({
                    pathname: `/member/clientDash/${this.props.user.username}/inbox`,
                })
            }})
        .catch((error)=>{
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
		    // disable multiple submits		
	  	    this.setState({ hasSent: false});
        })
  }
      goBack() {
        window.history.back();
      }

  refresh(){
    // if(this.props.message.posts === undefined){
    //   document.location.replace(`/clientDash/${this.props.username}/inbox`);
    // }
  }


  render() {
    this.refresh()
    let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
      const {user} = this.props;
    return (
          <div className="client-container">
              <ClientHeader backUrl={`/member/clientDash/${user.username}/inbox`}/>
              <div className="container">
                  <NewMessage 
                  recipients={this.state.recipients}
                  username={this.state.username}
                  titleHandler={this.titleHandler}
                  replyHandler={this.replyHandler}
                  recipientHandler={this.recipientHandler}
                  selectedHandler={this.selectedHandler}
                  />               
            
                  <div className="container margin-top">
                      <div className="row">
                        <div className="col-md-3">
                        {this.state.show
                          ?  <Alert className="col-md-4 save-profile-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                                <p>{this.state.alert}</p>
                              </Alert>
                          : null
                        }
                          <input disabled={this.state.hasSent} className="btn primary add--icon" type="submit" value="SEND MESSAGE" onClick={()=>this.send()}/>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
    );
  }
}

function mapStateToProps({user}){
    return {user}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchSelf, checkAlerts, getDailyScore, setChangedScoreFlag}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewMessageMain))