import React from'react';
import {Route} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import Account from "../ClientProfile/Account";
import Details from "../ClientProfile/Details";
import Gym from "../ClientProfile/Gym";
import Equipment from "../ClientProfile/Equipment";
import Notifications from "../ClientProfile/notifications";
import PersonalGoal from "../ClientProfile/personalGoal/personalGoal";

const ClientDisplay = ({match, username}) =>{
    return(
        <div>
            <Route
            	exact
                path={match.url}
                render={()=><Account match={match} username={username} />}
            />
            <Route
                path={match.url+"/details"}
                render={()=><Details {...withRouter} match={match} username={username}/>}
            />
            <Route
                path={match.url+"/gym"}
                render={()=><Gym {...withRouter} match={match} username={username}/>}
            />
            <Route
                path={match.url+"/equipment"}
                render={()=><Equipment {...withRouter} match={match} username={username}/>}
            />
            <Route
                path={match.url+"/notifications"}
                render={()=><Notifications {...withRouter} match={match} username={username}/>}
            />
            <Route
                path={match.url+"/settings"}
                render={()=><PersonalGoal {...withRouter} match={match} username={username}/>}
            />
        </div>
    )
};

export default withRouter(ClientDisplay);