import {RESPONSE_CHANGE} from "../actions/action_assessments";
import {RESET_RESPONSES} from "../actions/action_assessments";

export default function (state=[], action){
    switch (action.type) {
        case RESPONSE_CHANGE:
            console.log(action.payload);
            //check if response was already added
            if(state.filter(item=>item.question_id === action.payload.question_id).length > 0){
                //go through each response and replace the one that's changed
                return state.map(response=>{
                   if(action.payload.question_id === response.question_id){
                       return action.payload;
                   }else{
                       return response;
                   }
                });

            }

            //if nothing changed
            return [...state, action.payload];

        case RESET_RESPONSES:
            return action.payload
        // no default
    }
    return state;
}
