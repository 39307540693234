import React from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import {Alert} from 'react-bootstrap';
import {bindActionCreators} from 'redux';

// actions
import {setCoach} from "../../../actions/action_coach";
import {getDailyScore} from "../../../store/actions/wellnessScoreActions";
import {checkAlerts} from "../../../store/actions/alertActions";

// components
import ReplyMain from './ReplyMain';
import PhotoContainer from '../../profilePhotos/container';
import ContentSlider from '../../contentDisplay/contentSlider';
import {PrimaryButton} from "../../buttons/buttons";

// let pageNumber = 1;
let title;
let filteredRecipients = [];
let allRecipients = [];
let participantsArr = [];
let firstNames = [];

// let testRecipients = ["briannew", null]

class SingleMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentArr: [
                {img: "http://via.placeholder.com/200x150", text: "Sleep related content title goes here"},
                {img: "http://via.placeholder.com/200x150", text: "Other stuff goes here"}
            ],
            emailArr: [],
            showReply: false,
            reply: '',
            recipient: '',
            archiveId: null,
            //use this for recipient if more than one
            selectedRecipient: [],
            participants: [],
            messageId: null,
            show: false,
            hideReplyBtn: false,
            // catch multiple submits
            hasSent: null,
        }
        this.goBack = this.goBack.bind(this)
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    handleDismiss() {
        this.setState({show: false});
    }

    handleShow() {
        this.setState({show: true});
    }

    componentDidMount() {
        console.log('SingleMessage')
        //This is for when multiple recipients is implemented
        axios.get(`/user/coaching-team?username=${this.props.username}`)
            .then((res) => {
                this.setState({recipient: res.data.result})
            })
            .catch((error) => {
                console.log(error);
            });

        
            this.getThread()
        
    }

    createRecipientList = () => {
        if (this.state.recipient) {
            return this.state.recipient.map((item, i) => {
                allRecipients.push(this.state.recipient[i].username);
                filteredRecipients = allRecipients.filter(function (item, pos) {
                    return allRecipients.indexOf(item) === pos;
                })
                return (
                    <div className="row">
                        <div className="col-md-1">
                            <div className="profile-image">
                                <img src="http://via.placeholder.com/150x150" alt="profile "/>
                                {this.props.recipient}
                            </div>
                        </div>
                    </div>
                )
            });
        }
    };

    getThread() {
        participantsArr = [];
        firstNames = [];
        this.createRecipientList()
        axios.get(`/posts/thread/id/${this.props.message.id}`)
            .then((res) => {
                this.setState({messageId: res.data.posts[res.data.posts.length - 1].id})
                this.setState({title: res.data.posts[0].title})
                this.setState({archiveId: res.data.posts[0].id})
                this.setState({emailArr: res.data.posts})
                title = this.state.emailArr[0].title;
                let tempArr = res.data.participants.filter(recipient => !!recipient);
                tempArr.map((item, i) => {
                    participantsArr.push(item.username)
                    i === tempArr.length - 1
                        ? firstNames.push(`${item.first_name} ${item.last_name}, `)
                        : firstNames.push(`${item.first_name} ${item.last_name}, `)
                })
            })
            .catch((error) => {
                console.log(error);
            });

        axios.put(`/posts/read/thread/${this.props.message.id}/user/${this.props.username}`)
            .then((res) => {
            })
            .catch((error) => {
                console.log(error);
            });

    }

    recipientHandler = (recipient) => {
        this.setState({selectedRecipient: recipient})
    }

    replyHandler = (reply) => {
        this.setState({reply: reply})
    }

    send() {
        // disable multiple submits
        this.setState({hasSent: true});

        if (participantsArr.length === 0) {
            this.setState({show: true});
            this.setState({alert: 'This user is not found'});
            this.setState({alertStyle: false});
            return;
        }
        if (this.state.reply === '') {
            alert('do not leave message blank please');
            // disable multiple submits
            this.setState({hasSent: false});
            return;
        }
        axios.post(`/posts/new-message`, {
            post_id: this.state.messageId,
            title: this.state.title,
            text: this.state.reply,
            recipients: participantsArr,

        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.alert_waiting) {
                        this.props.checkAlerts();
                        this.props.getDailyScore();
                    }
                    
                        this.getThread()
                    
                    this.setState({showReply: false, hideReplyBtn: false, reply: "", hasSent: false})
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({show: true});
                this.setState({alert: 'error'});
                this.setState({alertStyle: false});
                this.setState({showReply: false, hideReplyBtn: false, hasSent: false})
            });
    }

    archiveMessage() {
        axios.put(`/posts/thread/${this.props.message.id}/user/${this.props.username}/status/1`)
            .then((res) => {

                if (res.data.result === true) {
                    this.props.history.push({
                        pathname: `/member/clientDash/${this.props.username}/inbox`,
                    })
                }
            })

            .catch((error) => {
                console.log(error);
            });
    }

    unArchiveMessage() {
        axios.put(`/posts/thread/${this.props.message.id}/user/${this.props.username}/status/0`)
            .then((res) => {

                if (res.data.result === true) {
                    this.props.history.push({
                        pathname: `/member/clientDash/${this.props.username}/inbox`,
                    })
                }
            })

            .catch((error) => {
                console.log(error);
            });
    }

    createEmailList = () => {
        if (this.state.emailArr) {
            return this.state.emailArr.map((item, i) => {
                let url_path;
                if (item.user) {
                    if (item.user.permission === 2) {
                        url_path = `member/clientDash/${this.props.username}/coaching-team/coach/${item.username}`
                    } else if (item.user.permission === 3) {
                        url_path = `member/clientDash/${this.props.username}/coaching-team/coach/${item.username}`
                    } else {
                        url_path = `member/clientDash/${this.props.username}/account`
                    }
                }
                let time;
                time = moment.parseZone(item.created_at).local().format("MM/DD/YY h:mm A")


                return (

                    <div className="inbox--container single-message ">

                        <div className="row">
                            <div className="col-xs-3 col-md-2">
                                {/*currently no way to show username of sender*/}
                                <div className="profile-image">
                                    <PhotoContainer
                                        image={item.user ? item.user.profile_image : null}
                                        username={item.user ? item.user.username : null}
                                        url_path={url_path ? url_path : null}
                                        permission={item.user ? item.user.permission : null}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-9 col-md-10">
                                <h4>{title}</h4>
                                Sent {time}
                                {this.props.message.status === 1
                                    ? <p className="archive-link icon-archive"
                                         onClick={() => this.unArchiveMessage()}> UNARCHIVE</p>
                                    : <p className="archive-link icon-archive"
                                         onClick={() => this.archiveMessage()}> ARCHIVE</p>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 message--body clearfix">
                                <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                            </div>
                        </div>
                        {item.post_attachments
                            ? <div className="row">
                                <div>
                                    <ContentSlider content={item.post_attachments}/>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                )
            });
        }
    };

    showReply() {
        this.setState({showReply: !this.state.showReply, hideReplyBtn: !this.state.hideReplyBtn})
        window.scrollTo(0, 0)
    }

    moveToNewMessage() {
        this.props.history.push({
            pathname: `/member/clientDash/${this.props.username}/inbox-newreply`,
        })
    }

    goBack() {
        window.history.back();
    }

    render() {
        let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
        
            this.createRecipientList()
        
        return (
            <div>
                <div className="container client-account-dash">
                    {this.state.showReply
                        ? <ReplyMain
                            recipientHandler={this.recipientHandler}
                            filteredRecipients={filteredRecipients}
                            send={this.send.bind(this)}
                            recipient={this.state.recipient}
                            replyHandler={this.replyHandler}
                            username={this.props.username}
                        />
                        : null
                    }
                    {this.state.show
                        ? <Alert className="col-md-4 save-profile-alert" bsStyle={changeAlertStyle}
                                 onDismiss={this.handleDismiss}>
                            <p>{this.state.alert}</p>
                        </Alert>
                        : null
                    }
                    <p><strong>Sent To:</strong> {firstNames.map((item) => {
                        return (`${item}`)
                    })}You </p>
                    {this.createEmailList()}
                </div>
                <div className="container">
                    <div className='row general-container'>
                        {this.props.message.status === 1 || this.state.hideReplyBtn
                            ? <div className="text-right">
                                <PrimaryButton disabled={this.state.hasSent} onClick={() => this.send()}>SEND MESSAGE
                                </PrimaryButton>
                            </div>
                            : <div className="text-right">
                                <PrimaryButton onClick={() => this.showReply()}>REPLY
                                </PrimaryButton>
                            </div>
                        }
                    </div>
                </div>
            </div>

        );
    }
}


function mapPropsToState({user}) {
    return {user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setCoach, checkAlerts, getDailyScore}, dispatch);
}

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(SingleMessage));