import React, {Component, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';

// actions
import {fetchCalendars, recordSchedule} from "../../../store/actions/calendarActions";
import {fetchCoach} from "../../../store/actions/userActions";

// components
import PhotoContainer from "../Inbox/container";
import {BasicButton, PrimaryButton} from "../../buttons/buttons";
import {Modal} from "react-bootstrap";

class SingleCoach extends Component{
    constructor(){
        super();
        this.state = {
          coachId: '',
          coachFirstName: '',
          coachLastName: '',
          coachList: [],
          calendars: [],
          modal: {show: false, url: '', calendarId: null}
        };
    }

    componentDidMount(){

      let username = this.props.match.params.username;

      this.props.fetchCoach(username);
      console.log('here');

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let username = this.props.match.params.username;
        if (prevProps.singleCoach !== this.props.singleCoach) {
            if(this.props.singleCoach.permission_id){
                if(this.props.singleCoach.permission_id === 2){
                    axios.get(`/user/coach/single/${username}`)
                        .then((res => {
                            this.setState({coachList:res.data.results})
                            return res.data.results;
                        }))
                        .then((res) => {
                            fetchCalendars(res.id)
                                .then(calendars => this.setState({calendars}));
                        })
                }
                else{
                    axios.get(`/user/admin/single/${username}`)
                        .then((res => {
                            console.log(res)
                            this.setState({coachList:res.data.results})
                        }))
                        .catch((error)=>{
                            console.log(error);
                        });
                }
            }
            else{
                axios.get(`/user/coach/single/${username}`)
                    .then((res => {
                        console.log(res)
                        this.setState({coachList:res.data.results})
                    }))
            }
        }
    }

    createList=()=>{
        if(this.state.coachList.qualifications){
            return this.state.coachList.qualifications.map((item, i)=>{
                  return(
                    <li key={i}>{item.qualification}</li>
                    )

            });
        }
    };

    goBack() {
      window.history.back();
    } 

  refresh(){
    if(Object.keys(this.props.singleCoach).length === 0){
      document.location.replace(`/member/clientDash/${this.props.username}/profile`);
    }
  }

  hideModal = () => {
        this.setState({modal: {show: false, url: ''}});
  }

  showModal = (url) => {
        this.setState({modal: {show: true, url}});
  }

    render(){
        if (!this.props.singleCoach) {
            return <div/>
        }
      return(
        <div>
            <div className="container client-account-dash coach-profile">
                <div className="row">
                    <ul className="tiles-ul">

                        <li className="tiles-li col-xs-12 col-sm-6">
                            
                                <div className={"tile"}>
                                    <div className="tile-titles">
                                        <h4>{this.state.coachList.first_name}</h4>
                                        <p>{this.state.coachList.title}</p>
                                    </div>
                                    <div className="tile-icon coach-single">
                                        <div className="profile-image">
                                            <PhotoContainer
                                                image={this.state.coachList ? this.state.coachList.profile_image : null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            
                        </li>
                    </ul>
                </div>

                <div>
                    <h4>Schedule Call</h4>
                    <div className={this.state.calendars.length > 0 ? 'tour-coach-calendars d:i-b' : ''}>
                        {this.state.calendars.map(calendar => {
                            console.log(calendar);
                            if (calendar.primary) return null;
                            return <PrimaryButton onClick={()=>this.setState({modal: {show: true, url: calendar.url, calendarId: calendar.id}})} className='m-r:2'>{calendar.duration} min</PrimaryButton>
                        })}
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <h4 className='margin-top'>ABOUT {this.state.coachList.first_name}</h4>
                        <p>{this.state.coachList.biography}</p>

                        <h4 className="margin-top">QUALIFICATIONS</h4>
                        <ul>{this.createList()}</ul>
                    </div>
                </div>
            </div>
            <div className="footer-actions">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6">
                            <button className="btn client-white" onClick={this.goBack}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={this.state.modal.show} onHide={this.hideModal}>
                <Modal.Body>
                    <div style={{height: '500px'}}>
                        <Calendly
                            calendarId={this.state.modal.calendarId}
                            coachId={this.state.coachList ? this.state.coachList.id : null}
                            url={this.state.modal.url}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BasicButton onClick={this.hideModal}>Close</BasicButton>
                </Modal.Footer>
            </Modal>
        </div>
      );
    }
}

function mapPropsToState({singleCoach}){
    return {singleCoach}
}

const actions = {
    fetchCoach,
};

export default withRouter(connect(mapPropsToState, actions)(SingleCoach));

const Calendly = ({url, coachId, calendarId}) => {
    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute(
            'src',
            'https://assets.calendly.com/assets/external/widget.js'
        );
        head.appendChild(script);
    }, []);

    function isCalendlyEvent(e) {
        return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
    }

    function handleCalendlyEvent(e){
        if(isCalendlyEvent(e)) {
            if (e.data.event === 'calendly.event_scheduled'){
                let uuid = e.data.payload.event.uri.lastIndexOf('events/');
                uuid = e.data.payload.event.uri.substr(uuid+7);
                try {
                    recordSchedule(coachId, uuid, calendarId);
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener("message", handleCalendlyEvent);

        return () => window.removeEventListener('message', handleCalendlyEvent)
    }, []);


    return (
        <div
            className="calendly-inline-widget"
            data-url={url}
            style={{ minWidth: '320px', height: '100%' }}
        />
    );
};

