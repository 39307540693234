import React from 'react';
import SelectSearch, {fuzzySearch} from "react-select-search";

const SearchableDropdown = props => {
    const {options, value, onChange, name, placeholder, onBlur} = props;
    return (
        <div className="searchable">
            <SelectSearch
                onBlur={onBlur}
                filterOptions={fuzzySearch}
                search
                onChange={onChange}
                options={options}
                value={value}
                name={name || null}
                placeholder={placeholder || ""} />
        </div>
    )
}

export default SearchableDropdown;