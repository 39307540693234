export const teammatesTour = [
    {
        id: "step-open-nav",
        arrow: false,
        attachTo: {element: ".tour-open-nav", on: "top"},
        advanceOn: {selector: '.tour-open-nav', event: 'click'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if (document.querySelector(".tour-open-nav")) {
                        clearInterval(interval);
                        resolve();
                    }
                    if (count > 25) {
                        clearInterval(interval);
                    }
                }, 200);
            });
        },
        text: [
            'Clicking the "More" button will reveal other parts of the app you may navigate to.',
        ],
        title: 'Click the "More" button!',
        canClickTarget: true,
    },
    {
        id: 'tour-team-tile',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if (document.querySelector('.tour-teamBtn') || count > 25) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        scrollTo: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        advanceOn: {selector: '.tour-teamBtn', event: 'click'},
        attachTo: {element: '.tour-teamBtn', on: 'top'},
        title: 'Teammates Tile',
        text: "Teammates are a great way to add encouragement to your habit tracking. Your teammate doesn't need to be part of the Treo platform. To add friends as teammates<br><strong>click the Teammates tile.<strong/>",
        when: {
            show: function () {
                console.log(this);
            }
        }
    },
    {
        id: 'tour-team-add',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if (document.querySelector('.tour-team-add') || count > 25) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        scrollTo: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        advanceOn: {selector: '.tour-team-add', event: 'click'},
        attachTo: {element: '.tour-team-add', on: 'auto'},
        title: 'Add a Teammate',
        text: 'To add a teammate to your list<br><strong>click the add button<strong/>',
    },
    {
        id: 'tour-team-img',
        attachTo: {element: '.tour-team-img', on: 'auto'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if (document.querySelector('.tour-team-img') || count > 25) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        scrollTo: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        title: 'Teammate Image',
        text: "First let's add an image.<br><strong>click the avatar circle<strong/> to add an image. If you don't want to, this isn't required.",
    },
    {
        id: 'tour-team-firstName',
        attachTo: {element: '.tour-team-firstName', on: 'auto'},
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        popperOptions: {modifiers: [{name: "focusAfterRender", enabled: false}]},
        text: "Enter the first name of your teammate.",
        title: "First Name",
        when: {
            show() {
                customShow(this);
            },
            hide() {
                customHide();
            },
            complete() {
                customComplete();
            },
            cancel() {
                customComplete();
            }
        }
    },
    {
        id: 'tour-team-lastName',
        attachTo: {element: '.tour-team-lastName', on: 'auto'},
        buttons: [
            {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-primary',
                text: 'Back',
            },
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        popperOptions: {modifiers: [{name: "focusAfterRender", enabled: false}]},
        text: "Enter the last name of your teammate.",
        title: "Last Name",
        when: {
            show() {
                customShow(this);
            },
            hide() {
                customHide();
            },
            complete() {
                customComplete();
            },
            cancel() {
                customComplete();
            }
        }
    },
    {
        id: 'tour-team-email',
        attachTo: {element: '.tour-team-email', on: 'auto'},
        buttons: [
            {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-primary',
                text: 'Back',
            },
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            },
        ],
        popperOptions: {modifiers: [{name: "focusAfterRender", enabled: false}]},
        text: "Enter the email of your teammate.",
        title: "Email",
        when: {
            show() {
                customShow(this);
            },
            hide() {
                customHide();
            },
            complete() {
                customComplete();
            },
            cancel() {
                customComplete();
            }
        }
    },
    {
        id: 'tour-team-nickname',
        attachTo: {element: '.tour-team-nickname', on: 'auto'},
        buttons: [
            {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-primary',
                text: 'Back',
            },
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        popperOptions: {modifiers: [{name: "focusAfterRender", enabled: false}]},
        text: "Enter the nickname of your teammate. This is how this person will appear in the habit builder.",
        title: "Nickname",
        when: {
            show() {
                customShow(this);
            },
            hide() {
                customHide();
            },
            complete() {
                customComplete();
            },
            cancel() {
                customComplete();
            }
        }
    },
    {
        id: 'tour-team-send',
        advanceOn: {selector: '.tour-team-send', event: 'click'},
        attachTo: {element: '.tour-team-send', on: 'auto'},
        buttons: [
            {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-primary',
                text: 'Back',
            }
        ],
        scrollTo: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        title: 'Send Your Invite',
        text: "Clicking <strong>Send Invite</strong> will send an email to your teammate. Once they've accepted they'll receive weekly updates on your progress so they can cheer you on!<br>Select <strong>Back</strong> if you need to change anything.",

    },
    {
        id: 'tour-team-list',
        advanceOn: {selector: '.tour-team-list', event: 'click'},
        attachTo: {element: '.tour-team-list', on: 'auto'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    console.log(count);
                    if (document.querySelector(".tour-team-list")) {
                        clearInterval(interval);
                        resolve();
                    }
                    if (count > 50) {
                        clearInterval(interval);
                    }
                }, 200);
            });
        },
        scrollTo: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        title: 'List of Teammates',
        text: "Once you've added a teammate they will show up here. <strong>Click on the teammate you just added.</strong>",
    },
    {
        id: 'tour-team-final',
        advanceOn: {selector: '.tour-team-final', event: 'click'},
        attachTo: {element: '.tour-team-final', on: 'auto'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if (document.querySelector('.tour-team-final') || count > 25) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Finish',
            }
        ],
        scrollTo: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        title: 'Editing your Teammates',
        text: "You're all done. From here you can edit details about your teammate if you wish. Once they've accepted you'll start to get weekly congratulation ",
    }
];

function makeOverlayPath({width, height, x = 0, y = 0, r = 0}) {
    const {innerWidth: w, innerHeight: h} = window;

    return `M${w},${h}\
            H0\
            V0\
            H${w}\
            V${h}\
            Z\
            M${x + r},${y}\
            a${r},${r},0,0,0-${r},${r}\
            V${height + y - r}\
            a${r},${r},0,0,0,${r},${r}\
            H${width + x - r}\
            a${r},${r},0,0,0,${r}-${r}\
            V${y + r}\
            a${r},${r},0,0,0-${r}-${r}\
            Z`;
}

function getScrollParent(element) {
    if (!element) {
        return null;
    }

    const isHtmlElement = element instanceof HTMLElement;
    const overflowY =
        isHtmlElement && window.getComputedStyle(element).overflowY;
    const isScrollable = overflowY !== 'hidden' && overflowY !== 'visible';

    if (isScrollable && element.scrollHeight >= element.clientHeight) {
        return element;
    }

    return getScrollParent(element.parentElement);
}

function getVisibleHeight(element, scrollParent) {
    if (!element) return;
    const elementRect = element.getBoundingClientRect();
    let top = elementRect.y || elementRect.top;
    let bottom = elementRect.bottom || top + elementRect.height;

    if (scrollParent) {
        const scrollRect = scrollParent.getBoundingClientRect();
        const scrollTop = scrollRect.y || scrollRect.top;
        const scrollBottom = scrollRect.bottom || scrollTop + scrollRect.height;

        top = Math.max(top, scrollTop);
        bottom = Math.min(bottom, scrollBottom);
    }

    const height = Math.max(bottom - top, 0); // Default to 0 if height is negative

    return {y: top, height};
}

function customShow(that) {
    const modal = document.querySelector('.shepherd-modal-overlay-container');
    if (modal) modal.style.display = 'none';
    const targetElement = that.getTarget();
    if (!targetElement) return;
    const scrollParent = getScrollParent(targetElement);
    const {y, height} = getVisibleHeight(targetElement, scrollParent);
    const {x, width, left} = targetElement.getBoundingClientRect();
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const path1 = document.createElementNS("http://www.w3.org/2000/svg", 'path');
    path1.setAttribute('d', makeOverlayPath({width, height, x: (x || left), y, r: 5}));
    svg.appendChild(path1);
    svg.classList.add('tour-custom-modal');
    window.addEventListener("scroll", function () {
        if (!targetElement) return;
        const {y, height} = getVisibleHeight(targetElement, scrollParent);
        const {x, width, left} = targetElement.getBoundingClientRect();
        path1.setAttribute('d', makeOverlayPath({width, height, x: (x || left), y, r: 5}));
    });
    targetElement.addEventListener("resize", function () {
        if (!targetElement) return;
        const {y, height} = getVisibleHeight(targetElement, scrollParent);
        const {x, width, left} = targetElement.getBoundingClientRect();
        path1.setAttribute('d', makeOverlayPath({width, height, x: (x || left), y, r: 5}));
    });
    targetElement.addEventListener("focusin", function () {
        svg.style.fill = 'transparent';
    });
    targetElement.addEventListener("focusout", function () {
        svg.style.fill = null;
    });
    document.body.appendChild(svg);
}

function customHide() {
    const svg = document.querySelector('.tour-custom-modal');
    if (svg) svg.remove();
    const modal = document.querySelector('.shepherd-modal-overlay-container');
    if (modal) modal.style.display = null;
}

function customComplete() {
    const svg = document.querySelector('.tour-custom-modal');
    if (svg) svg.remove();
    const modal = document.querySelector('.shepherd-modal-overlay-container');
    if (modal) modal.style.display = null;
}