import axios from 'axios';

//get all the data on a single tile plus it's plans
export const SET_NOTE = 'SET_NOTE';
export function setNote(noteObj){
    return{
        type: SET_NOTE,
        payload:noteObj
    };
}

export const CLEAR_NOTE = 'CLEAR_NOTE';

export function clearNote(noteObj){
  let clear = noteObj = [];
    return{
        type: CLEAR_NOTE,
        payload:clear
    };
}

export const CREATE_NOTE = 'CREATE_NOTE';
export function createNote(noteObj){
    let url = '/notes/create';
    let noteRequest = axios.post(url, noteObj);

    return{
        type:CREATE_NOTE,
        payload:noteRequest
    };
}

//fetches all notes based on id
export const FETCH_NOTES_BY_ID = 'FETCH_NOTES_BY_ID';
export function fetchNotesById(tileId, username, pageNumber){
  console.log('fetching notes with tileId');
  let url = `/notes/list/${username}/tile/${tileId}/page/${pageNumber}`;
  let notesByIdRequest = axios.get(url);

  return {
    type: FETCH_NOTES_BY_ID,
    payload: notesByIdRequest
  }
}

//fetches all notes based on username
export function fetchNotesByUsername(username, pageNumber){
  console.log('fetching notes with username');

  let url = `/notes/list/${username}/page/${pageNumber}`;
  let notesByUsernameRequest = axios.get(url);

  return {
    type: FETCH_NOTES_BY_ID,
    payload: notesByUsernameRequest
  }
}
