import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import startOfToday from "date-fns/startOfToday";

// styles
import styles from './contentAnalytics.module.css';

// actions
import {
    fetchCategoryDurationData,
    fetchCategoryViewsData, fetchContentDurationData,
    fetchContentViewsData,
    fetchContentWatchTime, fetchContentWatchTimeData,
    fetchLeastWatchedCategory,
    fetchMostWatchedCategory,
    fetchMostWatchedContent,
    fetchPathwaysViews, fetchPopularContentList,
    fetchTotalContentViews
} from "../../../store/actions/analyticsActions";
import {fetchContentTypes, fetchHierarchicalCategories} from "../../../store/actions/contentActions";

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import ViewsGraph from "./viewsGraph/viewsGraph";
import {ReactComponent as TimesIcon} from "../../../images/times-circle.svg";
import {ReactComponent as DownArrow} from "../../../images/caret-down-solid.svg";
import TierDropdown from "../../dropdowns/tierDropdown/tierDropdown";
import SearchableDropdown from "../../dropdowns/searchableDropdown/searchableDropdown";
import {GeneralPagination} from "../../pagination/generalPagination/generalPagination";
import Loading from "../../Loading/Loading";
import {PrimaryButton} from "../../buttons/buttons";

const VIEWS = 'VIEWS';
const DURATION = 'DURATION';
const WATCHTIME = 'WATCHTIME';


const ContentAnalytics = props => {
    // props
    const {filters} = props;
    // local
    const [date, setDate] = useState({
        start: subDays(startOfToday(), 30),
        end: new Date()
    });
    const [views, setViews] = useState(null);
    const [watchTime, setWatchTime] = useState(null);
    const [topCategory, setTopCategory] = useState(null);
    const [mostWatched, setMostWatched] = useState(null);
    const [pathway1, setPathway1] = useState(null);
    const [pathway5, setPathway5] = useState(null);
    const [leastWatched, setLeastWatched] = useState(null);
    const [categories, setCategories] = useState(null);
    const [types, setTypes] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [viewsSelection, setViewsSelection] = useState(VIEWS);
    const [viewContentData, setViewsContentData] = useState(null);
    const [watchTimeContentData, setWatchTimeContentData] = useState(null);
    const [durationContentData, setDurationContentData] = useState(null);
    const [viewsCategoryData, setViewsCategoryData] = useState(null);
    const [watchTimeCategoryData, setWatchTimeCategoryData] = useState(null);
    const [durationCategoryData, setDurationCategoryData] = useState(null);
    const [page, setPage] = useState(1);
    const [content, setContent] = useState({content: [], pages: 0});
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState({
        title: '',
        category: null,
        contentType: null,
        sort: 'views-desc',
        limit: 10,
    });
    const [query, setQuery] = useState({
        start: date.start,
        end: date.end,
        organizationId: filters.organization,
        statusId: filters.status,
        gender: filters.gender,
        ageGroup: filters.ageGroup,
        coach: filters.coach,
        rank: filters.rank,
    });

    // on first render
    useEffect(() => {
        runQuery();
    }, [query]);

    useEffect(() => {
        runSearch();
    }, [page]);

    useDidMountEffect(() => {
        const newQuery = {
            start: date.start,
            end: date.end,
            organizationId: filters.organization,
            statusId: filters.status,
            gender: filters.gender,
            ageGroup: filters.ageGroup,
            coach: filters.coach,
        };
        setQuery(newQuery);
    }, [date, filters]);

    useDidMountEffect(() => {
        getCategoryOptions();
    }, [search.contentType]);

    useDidMountEffect(() => {
        setPage(1);
        if (page === 1) runSearch();
    }, [search.sort, query]);

    // functions
    function runQuery() {
        setViewsSelection(VIEWS);
        getViews();
        getWatchTime();
        getTopCategory();
        getMostWatched();
        getPathwaysStats();
        getLeastWatched();
        getContentViews();
        getCategoryViews();
        getCategories();
        getContentTypes();
    }

    async function runSearch(e) {
        if (e && e.preventDefault) e.preventDefault();
        try {
            const criteria = {page, ...search, category: search.category ? search.category.value : null};
            const list = await fetchPopularContentList(query, criteria);
            setContent({content: list.content && Array.isArray(list.content) ? list.content : [], pages: list.pages});
            setLoading(false);
            console.log(list);
        } catch (e) {
            console.log(e);
        }
    }

    async function getCategories() {
        try {
            const unflattened = await fetchHierarchicalCategories(search.contentType);
            const result = _.flatten(unflattened);
            const all = result.reduce((acc, current) => {
                if (current.subcategories && current.subcategories.length) {
                    return [...acc, current, ...current.subcategories];
                }
                return [...acc, current];
            }, []);
            setCategories(all);
            setCategoryOptions([{id: '', name: 'All Categories', subcategories: []}, ...unflattened]);
        } catch (e) {
            console.log(e);
        }
    }

    async function getCategoryOptions() {
        try {
            const unflattened = await fetchHierarchicalCategories(search.contentType);
            setCategoryOptions([{id: '', name: 'All Categories', subcategories: []}, ...unflattened]);
        } catch (e) {
            console.log(e);
        }
    }

    async function getContentTypes() {
        try {
            const tempTypes = await fetchContentTypes()
            const reshaped = tempTypes.map(type => ({
                name: type.type,
                value: type.id,
            }));
            setTypes([{name: 'All Content Types', value: ''}, ...reshaped]);
        } catch (e) {
            console.log(e);
        }
    }

    async function getViews() {
        try {
            const allViews = await fetchTotalContentViews(query);
            setViews(allViews);
        } catch (e) {
            console.log(e);
        }
    }

    async function getWatchTime() {
        try {
            let time = await fetchContentWatchTime(query);
            console.log(time)
            time = Math.floor(time/60); // minutes
            if (time < 60) time = `${time}min`;
            else {
                let hours = Math.floor(time/60);
                if (hours < 1) hours = 1;
                time = `${hours}hr ${time%60}min`;
            }
            setWatchTime(time);
        } catch (e) {
            console.log(e);
        }
    }

    async function getTopCategory() {
        try {
            const category = await fetchMostWatchedCategory(query);
            setTopCategory(category || '-');
        } catch (e) {
            console.log(e);
        }
    }

    async function getMostWatched() {
        try {
            const content = await fetchMostWatchedContent(query);
            setMostWatched(content || '-');
        } catch (e) {
            console.log(e);
        }
    }

    async function getPathwaysStats() {
        try {
            const pathwayStats = await fetchPathwaysViews(query);
            setPathway1(pathwayStats.one_pathways_watched);
            setPathway5(pathwayStats.five_pathways_watched);
        } catch (e) {
            console.log(e);
        }
    }

    async function getLeastWatched() {
        try {
            const least = await fetchLeastWatchedCategory(query);
            setLeastWatched(least || '-');
        } catch (e) {
            console.log(e);
        }
    }

    function toggleViewsData(val){
        setViewsSelection(val);
    }

    async function getContentViews() {
        try {
            const views = await fetchContentViewsData(query);
            setViewsContentData(views);
        } catch (e) {
            console.log(e);
        }
    }

    async function getContentWatchTime() {
        try {
            const time = await fetchContentWatchTimeData(query);
            const transformed = time.map(point => ({date: point.date, minutes: Math.ceil(point.total_duration/60)}));
            setWatchTimeContentData(transformed);
        } catch (e) {
            console.log(e);
        }
    }

    async function getContentDuration() {
        try {
            const time = await fetchContentDurationData(query);
            const transformed = time.map(point => ({date: point.date, minutes: Math.ceil(point.average_duration/60)}));
            setDurationContentData(transformed);
        } catch (e) {
            console.log(e);
        }
    }

    async function getCategoryViews() {
        try {
            const views = await fetchCategoryViewsData(query);
            setViewsCategoryData(views);
        } catch (e) {
            console.log(e);
        }
    }

    async function getCategoryWatchTime() {
        try {
            let time = await fetchCategoryViewsData(query);
            time = time.map(item => {
                const copy = {...item};
                const updated = {date: copy.date};
                delete copy.date;
                Object.keys(copy).forEach(key => updated[key] = Math.ceil(copy[key]));
                return updated;
            })
            console.log(time);
            setWatchTimeCategoryData(time);
            console.log(time);
        } catch (e) {
            console.log(e);
        }
    }

    async function getCategoryDuration() {
        try {
            let time = await fetchCategoryDurationData(query);
            time = time.map(item => {
                const copy = {...item};
                const updated = {date: copy.date};
                delete copy.date;
                Object.keys(copy).forEach(key => updated[key] = Math.ceil(copy[key]/60));
                return updated;
            })
            setDurationCategoryData(time);
        } catch (e) {
            console.log(e);
        }
    }

    function handleDate(e, range) {
        const val = e.target.value;
        let parsedDate = parse(val, 'yyyy-MM-dd', new Date());
        if (range === 'start') {
            parsedDate = startOfDay(parsedDate);
            return setDate({...date, start: parsedDate});
        } else {
            parsedDate = endOfDay(parsedDate);
            return setDate({...date, end: parsedDate});
        }
    }

    function changePage ({ selected }) {
        const page = selected+1;
        setPage(page);
    }

    function secondsToHms(d) {
        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);
        return `${h > 0 ? h+':' : ''}${m > 0 ? m : 0}:${s > 9 ? s : '0'+s}`;
    }

    return (
        <div>
            <h3 className={styles.heading}> Content Analytics</h3>
            <form className={styles.filter}>
                <div>
                    <label>Choose Date Range</label>
                    <div className={`form-group ${styles.dateRange}`} data-cy="date-range-content">
                        <input
                            max={format(subDays(date.end, 1), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.start, 'yyyy-MM-dd', {})}
                            onChange={e => handleDate(e, 'start')}
                            onKeyDown={e => e.preventDefault()}
                        />
                        <span className='p-r:1 p-l:1'>to</span>
                        <input
                            min={format(addDays(date.start, 1), 'yyyy-MM-dd')}
                            max={format(new Date(), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.end, 'yyyy-MM-dd')}
                            onChange={e => handleDate(e, 'end')}
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>

                </div>
            </form>
            <div className={styles.viewsRow}>
                <BoxContainer className={`${styles.boxesOuter} ${styles.inner}`} data-cy="view-content">
                    <div className={styles.mainLabel}>{views}</div>
                    <div className={styles.subLabel}>Views</div>
                </BoxContainer>
                <BoxContainer className={`t-a:c ${styles.boxesOuter} ${styles.inner}`} data-cy="watch-time-content">
                    <div className={styles.mainLabel}>{watchTime}</div>
                    <div className={styles.subLabel}>Watch Time</div>
                </BoxContainer>
                <BoxContainer className={`${styles.boxesOuter} ${styles.inner}`} data-cy="category-content">
                    <div className={topCategory && topCategory.length > 20 ? styles.tertiaryLabel : styles.secondaryLabel}>{topCategory}</div>
                    <div className={styles.subLabel}>Top Category</div>
                </BoxContainer>
                <BoxContainer className={`t-a:c ${styles.boxesOuter} ${styles.inner}`} data-cy="most-watch-content">
                    <div className={mostWatched && mostWatched.length > 20 ? styles.tertiaryLabel : styles.secondaryLabel}>{mostWatched}</div>
                    <div className={styles.subLabel}>Most Watched</div>
                </BoxContainer>
            </div>

            <BoxContainer className={styles.pathwayRow} data-cy="watched-box-content">
                <div className={styles.inner}>
                    <div className={styles.secondaryLabel}>{pathway1}</div>
                    <div className={styles.subLabel}>Watched 1 Pathway</div>
                </div>
                <div className={styles.inner}>
                    <div className={styles.secondaryLabel}>{pathway5}</div>
                    <div className={styles.subLabel}>Watched 5+ Pathway</div>
                </div>
                <div className={styles.inner}>
                    <div className={styles.secondaryLabel}>{leastWatched}</div>
                    <div className={styles.subLabel}>Least Watched Category</div>
                </div>
            </BoxContainer>

            <div className={styles.contentViewsRow}>
                <BoxContainer className={styles.views} data-cy="graph-content">
                    <div className='margin-bottom-15'>
                        <span
                            onClick={() => {
                                toggleViewsData(VIEWS);
                                getContentViews();
                            }}
                            className={`${styles.tab} ${viewsSelection === VIEWS ? styles.active : ''}`}>
                            Views
                        </span>
                        <span
                            onClick={() => {
                                toggleViewsData(WATCHTIME);
                                getContentWatchTime();
                            }}
                            className={`${styles.tab} ${viewsSelection === WATCHTIME ? styles.active : ''}`} data-cy="watch-graph-content">
                            Watch Time
                        </span>
                        <span
                            onClick={() => {
                                toggleViewsData(DURATION);
                                getContentDuration();
                            }}
                            className={`${styles.tab} ${viewsSelection === DURATION ? styles.active : ''}`} data-cy="avg-graph-content">
                            Avg. Duration
                        </span>
                    </div>
                    <div>
                        {viewsSelection === VIEWS ?
                            <ViewsGraph
                                label='views'
                                contentData={viewContentData}
                                categoryData={viewsCategoryData}
                                getContentData={getContentViews}
                                getCategoryData={getCategoryViews}
                                categories={categories}/>
                            : null}

                        {viewsSelection === WATCHTIME ?
                            <ViewsGraph
                                label='minutes'
                                contentData={watchTimeContentData}
                                categoryData={watchTimeCategoryData}
                                getContentData={getContentWatchTime}
                                getCategoryData={getCategoryWatchTime}
                                categories={categories}/>
                            : null}

                        {viewsSelection === DURATION ?
                            <ViewsGraph
                                label='minutes'
                                contentData={durationContentData}
                                categoryData={durationCategoryData}
                                getContentData={getContentDuration}
                                getCategoryData={getCategoryDuration}
                                categories={categories}/>
                            : null}
                    </div>
                </BoxContainer>
            </div>

            <BoxContainer className={styles.topContentRow} data-cy="top-content">
                <h4 className='m:0 margin-bottom-25'>Top Content</h4>
                <form className={styles.search}>
                    <div className={`form-group ${styles.searchText}`}>
                        <input
                            value={search.title}
                            onChange={e => setSearch({...search, title: e.target.value})}
                            className={`form-control`}
                            type="text"
                            placeholder='Search Content'/>
                        <TimesIcon className={styles.times} onClick={() => setSearch({...search, title: ''})}/>
                    </div>
                    <div className="form-group">
                        <SearchableDropdown
                            options={types}
                            onChange={val => setSearch({...search, category: null, contentType: val})}
                            value={search.contentType}
                            placeholder='All Content Types'
                        />
                    </div>
                    <div className="form-group">
                        <TierDropdown
                            placeholder='All Categories'
                            className={styles.categoryDD}
                            name='categories'
                            list={categoryOptions}
                            value={search.category}
                            onChange={val => {
                                if(!val.value){
                                    setSearch({...search, category: null});
                                } else {
                                    setSearch({...search, category: val})
                                }
                            }}
                        />
                    </div>
                    <PrimaryButton className={styles.submit} onClick={runSearch}>Search</PrimaryButton>
                </form>
                <div className={styles.row}>
                    <div
                        className={`${styles.content} ${styles.headers}`}>Content</div>
                    <div
                        className={`${search.sort === 'access-asc' || search.sort === 'access-desc' ? styles.active : ''} cur:p ${styles.duration} ${styles.headers}`}
                        onClick={() => setSearch({...search, sort: search.sort ==='access-desc' ? 'access-asc' : 'access-desc'})}>
                        Access
                        <DownArrow className={`${styles.arrow} ${search.sort === 'access-asc' ? styles.up : ''}`}/>
                    </div>
                    <div
                        className={`${search.sort === 'duration-asc' || search.sort === 'duration-desc' ? styles.active : ''} cur:p ${styles.duration} ${styles.headers}`}
                        onClick={() => setSearch({...search, sort: search.sort ==='duration-desc' ? 'duration-asc' : 'duration-desc'})}>
                        Avg. Duration
                        <DownArrow className={`${styles.arrow} ${search.sort === 'duration-asc' ? styles.up : ''}`}/>
                    </div>
                    <div
                        className={`${search.sort === 'views-asc' || search.sort === 'views-desc' ? styles.active : ''} cur:p ${styles.views} ${styles.headers}`}
                        onClick={() => setSearch({...search, sort: search.sort ==='views-desc' ? 'views-asc' : 'views-desc'})}>
                        Views
                        <DownArrow className={`${styles.arrow} ${search.sort === 'views-asc' ? styles.up : ''}`}/>
                    </div>
                </div>
                { loading ?
                    <Loading/>
                    :
                    <>
                        {content.content.map(piece => (
                            <BoxContainer key={piece.id} className={`${styles.row} ${styles.listItem}`}>
                                <div className={styles.image} style={{backgroundImage: `url(${piece.thumbnail})`}}/>
                                <div className={`${styles.content}`}>{piece.title}</div>
                                <div className={`${styles.access}`}>{piece.access}</div>
                                <div className={`${styles.duration}`}>{secondsToHms(piece.average_duration)}</div>
                                <div className={`${styles.views}`}>{piece.views}</div>
                            </BoxContainer>
                        ))}
                    </>
                }
                <div className="pagination-container">
                    <GeneralPagination pages={content.pages} onPageChange={changePage}/>
                </div>
            </BoxContainer>
        </div>
    )
}

export default ContentAnalytics;