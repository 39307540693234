import React, {useState} from 'react';

// styles
import styles from './onDemadFilter.module.css';

// components
import {ReactComponent as LengthIcon} from "../../images/icons/Icons_Filter_FitnessPlayer_Length.svg";
import {ReactComponent as InstructorIcon} from "../../images/icons/Icons_Filter_FitnessPlayer_Instructor.svg";
import {ReactComponent as ClassTypeIcon} from "../../images/icons/Icons_Filter_FitnessPlayer_ClassType.svg";
import {ReactComponent as LevelIcon} from "../../images/icons/Icons_Filter_FitnessPlayer_Level.svg";
import {ReactComponent as EquipmentIcon} from "../../images/icons/Icons_FilterFitnessPlayer_Equipment_Equipment.svg";
import {ReactComponent as CategoryIcon} from "../../images/icons/Icons_Filters_Type.svg";
import {StandardDropdown} from "../dropdowns/standardDropdown/standardDropdown";
import ToggleButton from "react-toggle-button";

const OnDemandFilter = props => {
    // props
    const {filters, close, callback, reset, submit, values={}, instructorLabel, collectionsLabel, showNew, showCollections} = props;
    // local
    const [form, setForm] = useState({
        classType: values.classType,
        length: values.length,
        level: values.level,
        instructor: values.instructor,
        equipment: values.equipment,
        favorite: values.favorite,
        isNew: values.isNew,
        tags: values.tags,
        collections: values.collections
    });

    function renderClassTypes() {
        if (filters.classTypes) {
            let classTypeOptions = [
                {value: '', label: 'All Class Types',},
            ];
            filters.classTypes.options.forEach(option => classTypeOptions.push({
                value: option.content_meta_option_id,
                label: option.content_meta_option.toLowerCase() === 'sprint 8' ? <>{option.content_meta_option}&reg;</> : option.content_meta_option,
            }))
            return (
                <div className={styles.dropdown}>
                    <StandardDropdown
                        providedValue={form.classType}
                        Icon={ClassTypeIcon}
                        options={classTypeOptions}
                        value={form.classType}
                        callback={val => setForm({...form, classType: val})}/>
                </div>
            )
        }
    }

    function renderLengths() {
        if (filters.lengths) {
            let lengthOptions = [
                {value: '', label: 'All Lengths',},
            ];
            filters.lengths.options.forEach(option => lengthOptions.push({
                value: option.content_meta_option_id,
                label: option.content_meta_option,
            }))
            return (
                <div className={styles.dropdown}>
                    <StandardDropdown
                        providedValue={form.length}
                        Icon={LengthIcon}
                        options={lengthOptions}
                        value={form.length}
                        callback={val => setForm({...form, length: val})}/>
                </div>
            )
        }
    }

    function renderLevels() {
        if (filters.intensities) {
            let levelOptions = [
                {value: '', label: 'All Levels',},
            ];
            filters.intensities.options.forEach(option => levelOptions.push({
                value: option.content_meta_option_id,
                label: option.content_meta_option,
            }))
            return (
                <div className={styles.dropdown}>
                    <StandardDropdown
                        providedValue={form.level}
                        Icon={LevelIcon}
                        options={levelOptions}
                        value={form.level}
                        callback={val => setForm({...form, level: val})}/>
                </div>
            )
        }
    }

    function renderInstructors() {
        const label = instructorLabel || 'Instructors'
        if (filters.instructors) {
            let instructorOptions = [
                {value: '', label: `All ${label}`,},
            ];
            filters.instructors.options.forEach(option => instructorOptions.push({
                value: option.content_meta_option_id,
                label: option.content_meta_option,
            }))
            return (
                <div className={styles.dropdown}>
                    <StandardDropdown
                        providedValue={form.instructor}
                        Icon={InstructorIcon}
                        options={instructorOptions}
                        value={form.instructor}
                        callback={val => setForm({...form, instructor: val})}/>
                </div>
            )
        }
    }

    function renderEquipment() {
        if (filters.equipment) {
            let equipmentOptions = [
                {value: '', label: 'All Equipment',},
            ];
            filters.equipment.options.forEach(option => equipmentOptions.push({
                value: option.content_meta_option_id,
                label: option.content_meta_option,
            }))
            return (
                <div className={styles.dropdown}>
                    <StandardDropdown
                        providedValue={form.equipment}
                        Icon={EquipmentIcon}
                        options={equipmentOptions}
                        value={form.equipment}
                        callback={val => setForm({...form, equipment: val})}/>
                </div>
            )
        }
    }

    function renderTags() {
        if (filters.tags) {
            let tagOptions = [
                {value: '', label: 'All Tags',},
            ];
            filters.tags.options.forEach(option => tagOptions.push({
                value: option.content_meta_option_id,
                label: option.content_meta_option,
            }));
            return (
                <div className={styles.dropdown}>
                    <StandardDropdown
                        providedValue={form.tags}
                        Icon={CategoryIcon}
                        options={tagOptions}
                        value={form.tags}
                        callback={val => setForm({...form, tags: val})}/>
                </div>
            )
        }
    }

    function save() {
        const copy = {};
        Object.keys(form).filter(key => form[key] && form[key].value).forEach(key => copy[key] = form[key]);
        if (callback) callback(copy);
        if (submit) submit(true);
    }

    return (
        <div className={styles.container}>
            <button className={styles.close} type='button' onClick={close}>&times;</button>
            <p className={styles.heading}>FILTER</p>
            <div>
                {renderLengths()}
                {renderTags()}
                {renderInstructors()}
                {renderClassTypes()}
                {renderLevels()}
                {renderEquipment()}
                <div className={styles.myFavorites}>
                    <span>MY FAVORITES</span>
                    <ToggleButton
                        value={form.favorite ? form.favorite.value : false}
                        onToggle={val => {
                            setForm({...form, favorite: {value: !val}})
                        }}
                        colors={{
                            active: {
                                base: '#4bbc4e',
                                hover: 'rgb(177, 191, 215)',
                            }
                        }}/>
                </div>
                {
                    showCollections ?
                        <div className={styles.myFavorites}>
                            <span className='t-t:u'>{collectionsLabel || 'COLLECTIONS'}</span>
                            <ToggleButton
                                value={form.collections ? form.collections.value : false}
                                onToggle={val => {
                                    setForm({...form, collections: {value: !val}})
                                }}
                                colors={{
                                    active: {
                                        base: '#4bbc4e',
                                        hover: 'rgb(177, 191, 215)',
                                    }
                                }}/>
                        </div>
                        : null
                }
                {
                    showNew ?
                        <div className={styles.myFavorites}>
                            <span>NEW</span>
                            <ToggleButton
                                value={form.isNew ? form.isNew.value : false}
                                onToggle={val => {
                                    setForm({...form, isNew: {value: !val}})
                                }}
                                colors={{
                                    active: {
                                        base: '#4bbc4e',
                                        hover: 'rgb(177, 191, 215)',
                                    }
                                }}/>
                        </div>
                        : null
                }
                <div className={styles.btns}>
                    <button
                        type='button'
                        onClick={reset}
                        className={styles.btnReset}>Reset</button>
                    <button
                        className={styles.btnSubmit}
                        onClick={save}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default OnDemandFilter;