import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";
import './Step2.css'

class Step2 extends Component{
   constructor(props){
       super();
           this.state = {
               user : {},
       };
   }

    moveToStep3(category, description){
        let currentPath = this.props.location.pathname,
            parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
        this.props.history.push({
            pathname: parentPath+'/step-3',
            state: {
                category:category,
                description:description,
                planStatus:'active',
            }
        })
    }


    componentDidMount() {
        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({user:res.data.results});
            })
    }

    render(){
        return(
            <div>
                <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <h2>Add New Tile</h2>
                        <p>Select new tile for {this.state.user.firstName}</p>
                    </div>
                    <div className="col-md-9 col-xs-12">
                        <ul className="progressbar">
                            <li className="active">Step 1</li>
                            <li className="active">Step 2</li>
                            <li>Step 3</li>
                            <li>Step 4</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="tileDescriptionsContainer">
                        <div className="tileDescriptions" onClick={()=>this.moveToStep3(this.props.location.state.category,'Veggie &amp; Fruit Servings')}>
                            <h4>{this.props.location.state.category}</h4>
                            <p>Veggie &amp; Fruit Servings</p>
                        </div>
                        <div className="tileDescriptions" onClick={()=>this.moveToStep3(this.props.location.state.category,'Sweetened Foods')}>
                            <h4>{this.props.location.state.category}</h4>
                            <p>Sweetened Foods</p>
                        </div>
                        <div className="tileDescriptions" onClick={()=>this.moveToStep3(this.props.location.state.category,'Sweetened Drinks')}>
                            <h4>{this.props.location.state.category}</h4>
                            <p>Sweetened Drinks</p>
                        </div>
                        <div className="tileDescriptions" onClick={()=>this.moveToStep3(this.props.location.state.category,'Protein Servings')}>
                            <h4>{this.props.location.state.category}</h4>
                            <p>Protein Servings</p>
                        </div>
                        <div className="tileDescriptions" onClick={()=>this.moveToStep3(this.props.location.state.category,'Mulitiple Servings of Main Course')}>
                            <h4>{this.props.location.state.category}</h4>
                            <p>Mulitiple Servings of Main Course</p>
                        </div>
                        <div className="tileDescriptions" onClick={()=>this.moveToStep3(this.props.location.state.category)}>
                            <h4>{this.props.location.state.category}</h4>
                            <p>Description Text</p>
                        </div>
                    </div>
                    <button>Save &amp; Next Step</button>
                </div>
            </div>
        )

    }
}

export default withRouter(Step2);