import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// styles
import styles from './Profile.module.css';
import './Profile.css';

// actions
import {updateDemoMember} from "../../../store/actions/userActions";
import {fetchSingleUser} from "../../../actions/action_user";

// components
import ClientDisplay from '../../Client/ClientDisplay/ClientDisplay';
import LinkList from "../../links/linkList/linkList";
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {ErrorAlert, SaveAlert} from "../../alerts/alerts";


const Profile = props => {
    // props
    const {username, singleUser, match} = props;
    // actions
    const {fetchSingleUser} = props;
    // local
    const [mounted, setMounted] = useState(false);
    const [demo, setDemo] = useState(singleUser.user.demo_member);
    const [ saving, setSaving ] = useState(false);
    const [ finishedSaving, setFinishedSaving ] = useState(false);
    const [error, setError] = useState(null);

    // function to save a demo account status
    async function saveDemo(val) {
        if (val) { val = 1; }
        else { val = 0; }
        try {
            setSaving(true);
            await updateDemoMember(singleUser.user.user_id, val);
            setFinishedSaving(true);
            fetchSingleUser(singleUser.user.username);
        } catch (e) {
            if (e.response && e.response.data) {
                console.table(e.response.data)
                setError(e.response.data.error)
            } else {
                setError('Unable to save member demo status');
            }
        }
    }

    // grab demo state on mount
    useEffect(() => {
        if (mounted) saveDemo(demo);
        setMounted(true);
    }, [demo]);

    const links = [
        { href: `${match.url}`, label: 'Account' },
        { href: `${match.url}/details`, label: 'Details' },
        { href: `${match.url}/notifications`, label: 'Notifications' },
        { href: `${match.url}/gym`, label: 'Gym' },
        { href: `${match.url}/equipment`, label: 'Equipment' },
        { href: `${match.url}/settings`, label: 'Game Settings' },
    ];

    return(
    <div>
        <div className="row margin-top-lrg">
            <div className="col-xs-12">
                <div className="container">
                    {error ?
                        <div className="margin-bottom-med">
                            <ErrorAlert>{error}</ErrorAlert>
                        </div>
                        : null
                    }
                    <div>
                        <div className='margin-bottom-med'>
                            <LinkList forceDropdown className='profileNav-links' links={links}/>
                        </div>
                    </div>
                    <div className='margin-bottom-med'>
                        <BoxContainer className={`padding-standard ${styles.demoContainer}`}>
                            <input
                                className={styles.demoInput}
                                id='demo'
                                type="checkbox"
                                checked={demo}
                                onChange={e => setDemo(e.target.checked)}/>
                            <label className={styles.demoLabel} htmlFor='demo'>Demo Account</label>
                            {saving ? <SaveAlert complete={finishedSaving}/> : null}
                        </BoxContainer>
                    </div>
                    <div>
                        <ClientDisplay username={username} />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

function mapStateToProps({singleUser}) {
    return {singleUser};
}

const actions = {
    fetchSingleUser,
};

export default withRouter(connect(mapStateToProps, actions)(Profile));