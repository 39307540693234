//circular progress bar component
import React from 'react';
import { VictoryPie } from 'victory';
import { VictoryAnimation } from 'victory';
import { VictoryLabel } from 'victory';

export class VictoryProgress extends React.Component {
  constructor() {
    super();
    this.state = {
      percent: 0, data: this.getData(0) 
    };
  }

  componentDidMount() {
    let percent = 75;
    this.setStateInterval = window.setInterval(() => {
      // percent += (Math.random() * 25);
      percent = (percent > 102) ? 0 : percent;
      this.setState({
        percent, data: this.getData(percent)
      });
    }, 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.setStateInterval);
  }

  getData(percent) {
    return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
  }

  render() {
    return (
      <div className="progress-container">
        <svg viewBox="0 0 400 400" width="130px" height="130px">

          <VictoryPie
            standalone={false}
            animate={{ duration: 1000 }}
            width={400} height={400}
            data={this.state.data}
            innerRadius={140}
            cornerRadius={25}
            labels={() => null}
            style={{
              data: { fill: (d) => {
                const color = d.y > 50 ? "green" : "red"; 

                return d.x === 1 ? color : "transparent";
              }
              }
            }}
          />
          <VictoryAnimation duration={1000} data={this.state}>
            {(newProps) => {
              return (
                <VictoryLabel
                  textAnchor="middle" verticalAnchor="middle"
                  x={200} y={200}
                  // text={`${Math.round(newProps.percent)}%`}
                  // text={"http://via.placeholder.com/100x100"}
                  text= "img"
                  style={{ fontSize: 45 }}
                />
              );
            }}
          </VictoryAnimation>
        </svg>
      </div>
    );
  }
}


