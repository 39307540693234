import mainApi from "./mainApi";

export function studioPlayerApi() {
    return {
        getStudioAssignedCategories: async function(organizationId) {
            return mainApi.get(`/studio/get/assigned/content/${organizationId}/`);
        },
        deleteStudioAssignedContent: async function(organizationId, assignedId) {
            return mainApi.delete(`/studio/delete/assigned/content/${organizationId}/${assignedId}`);
        },
        assignStudioCategoryToOrganization: async function(organizationId, categoryId) {
            return mainApi.post(`/studio/assign/category/${organizationId}/${categoryId}`);
        },
        removeStudioUserByUsername: async function(username) {
            // Sends a PUT request to the endpoint for user removal
            return mainApi.put(`/user/remove-user/${username}`);
        },
        createStudioUser: async function(userData) {
            // userData is expected to be an object containing first_name, last_name, email, password, and organization_id
            return mainApi.post(`/studio/new-studio`, userData);
        },
        // Return all studio users for given organization
        getAllStudioUsers: async function (organizationId){
            return mainApi.get(`/studio/user/list/${organizationId}`)

        },
        //Retrieve list of assignable content
        getStudioAssignableContent: async function (organizationId, search, assignedOrgId, sort) {
            let url = `/studio/get/assignable/content/${organizationId}`;
            let params = [];
            if (search) params.push(`search=${encodeURIComponent(search)}`);
            if (assignedOrgId) params.push(`filter_org_id=${assignedOrgId}`);
            if (sort) params.push(`sort=${sort}`);
            if (params.length) url += `?${params.join('&')}`;
            return mainApi.get(url);
        },

        getStudioCategorySort: async function (organizationId, categories){
            return mainApi.post(`/studio/sort/categories/${organizationId}`, categories);
        }
        
    }
}



