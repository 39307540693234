export const jfyTour = [
    {
        id: 'tour-jfy-intro',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-jfyBtn') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        advanceOn: {selector: '.tour-jfyBtn', event: 'click'},
        attachTo: { element: '.tour-jfyBtn', on: 'auto' },
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        text: `The Just For You section is found in Your Pathway. <strong>Click Your Pathway Button.</strong>`,
        title: "Go to Your Pathway"
    },
    {
        id: 'tour-jfy-tile',
        attachTo: {element: '.tour-jfy-tile', on: 'auto'},
        advanceOn: {selector: '.tour-jfy-tile', event: 'click'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-jfy-tile') || count > 35){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        scrollTo: true,
        text: `This Just For You section allows you to add a series of videos that may be of special interest to you. <strong>Click the Just For You Tile.</strong>`,
        title: "Select Just For You",
    },
    {
        id: 'tour-jfy-add',
        attachTo: {element: '.tour-jfy-add', on: 'auto'},
        advanceOn: {selector: '.tour-jfy-add', event: 'click'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-jfy-add') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        scrollTo: true,
        text: "Let’s add some content that interests you. <strong>Click the Add New Content button.</strong>",
        title: "Add new content"
    },
    {
        id: 'tour-jfy-categories',
        attachTo: {element: '.tour-jfy-categories', on: 'auto'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-jfy-categories') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        scrollTo: true,
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        text: "These are the different categories of content available to you. <strong>Click</strong> on 1 or more to add a new category to your Pathway’s content. Once you’ve selected your categories of interest, <strong>Click Next.</strong>",
        title: "Content Categories"
    },
    {
        id: 'tour-jfy-back',
        attachTo: {element: '.tour-jfy-back', on: 'auto'},
        advanceOn: {selector: '.tour-jfy-back', event: 'click'},
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 7,
        scrollTo: true,
        canClickTarget: true,
        text: "Once you’ve selected all the categories you would like for now, <strong>Click the back arrow</strong> to return to your Pathway’s dashboard. You can come back and add or remove Just For You content at anytime.",
        title: "Complete selection"
    },
    {
        id: 'tour-jfy-content',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-jfy-add') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Finish',
            }
        ],
        scrollTo: true,
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        text: "That's it. You're all done! Any content you selected will show up here.",
        title: "Your Content"
    },
];