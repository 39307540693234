import React  from 'react';

// styles
import styles from './reactionFeed.module.css';

// components
import Loading from "../../Loading/Loading";
import CommunitySingleReaction from "../../communitySingleReaction/communitySingleReaction";

const ReactionFeed = props => {
    // props
    const {reactions} = props;

    if (!reactions) {
        return (<Loading/>);
    }

    if (reactions.length < 1) {
        return (
            <div className={styles.placeholder}>
                Help others achieve their goals by sending support in the Community tab!
            </div>
        )
    }

    return (
        reactions.map(rec => (
            <div>
                <CommunitySingleReaction reaction={rec}/>
            </div>
        ))
    )
}

export default ReactionFeed;