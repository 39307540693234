import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import validate from "validate.js";
import { isEmpty } from "lodash";
import axios from 'axios';

// styles
import styles from './waitlist.module.css';

// components
import { PrimaryButton } from "../../buttons/buttons";
import { ErrorAlert } from "../../alerts/alerts";

const constraints = {
    firstName: {
        type: 'string',
        presence: '^First name is required',
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: '^First name is required',
            tooLong: '^First name must be less than 255 character',
        }
    },
    lastName: {
        type: 'string',
        presence: '^Last name is required',
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: '^Last name is required',
            tooLong: '^Last name must be less than 255 character',
        }
    },
    email: {
        type: 'string',
        presence: '^Email is required',
        email: '^Enter a valid email',
    },
};

const Waitlist = (props) => {
    // props
    const { orgId, code, setPage, setFirstName } = props;

    // state
    const [ validation, setValidation ] = useState({});
    const [ formState, setForm ] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const [ error, setError ] = useState('');

    function submit(e) {
        e.preventDefault();
        const errors = {};
        const { firstName, lastName, email } = formState;
        const validation = validate({
            firstName, lastName, email
        }, constraints);
        if (validation) {
            errors.firstName = validation.firstName;
            errors.lastName = validation.lastName;
            errors.email = validation.email;
        }
        Object.keys(errors).map(key => {
            if (!errors[key]) delete errors[key];
        });
        setValidation(errors);
        if (!isEmpty(errors)) return;

        // create user
        axios.post(`/organizations/add/waitlist/${orgId}/${code}`, {
            first_name: firstName,
            last_name: lastName,
            email,
        }).then(() => {
            setFirstName(firstName);
            setPage(4);
        }).catch(e => {
            if (e.response.data.error === 'There are no seats available.'){
                setError('');
                setPage(3); // wait list
            } else {
                setError(e.response.data.error);
            }
        })
    }

    return (
        <div>
            <div>
                { error ?
                    <ErrorAlert>{error}</ErrorAlert> : null
                }
            </div>
            <form onSubmit={submit}>
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        value={formState.firstName}
                        onChange={e => setForm({...formState, firstName: e.target.value})}
                        onBlur={e => {
                            const problems = validate({ firstName: e.target.value}, { firstName: constraints.firstName });
                            if (problems) {
                                setValidation({ ...validation, firstName: problems.firstName })
                            } else {
                                const copy = Object.assign({}, validation);
                                delete copy.firstName;
                                setValidation(copy);
                            }
                        }}
                        type="text"
                        className="form-control"
                        id="firstName"/>
                    { validation.firstName ?
                        <div>
                            { validation.firstName.map(err=> <span key={err} className="d:b text-danger">{err}</span>)}
                        </div>
                        : null
                    }
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        value={formState.lastName}
                        onChange={e => setForm({...formState, lastName: e.target.value})}
                        onBlur={e => {
                            const problems = validate({ lastName: e.target.value}, { lastName: constraints.lastName });
                            if (problems) {
                                setValidation({ ...validation, ...problems })
                            }else {
                                const copy = Object.assign({}, validation);
                                delete copy.lastName;
                                setValidation(copy);
                            }
                        }}
                        type="text"
                        className="form-control"
                        id="lastName"/>
                    { validation.lastName ?
                        <div>
                            { validation.lastName.map(err=> <span key={err} className="d:b text-danger">{err}</span>)}
                        </div>
                        : null
                    }
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        value={formState.email}
                        onChange={e => setForm({...formState, email: e.target.value})}
                        onBlur={e => {
                            const problems = validate({ email: e.target.value}, { email: constraints.email });
                            if (problems) {
                                setValidation({ ...validation, ...problems })
                            }else {
                                const copy = Object.assign({}, validation);
                                delete copy.email;
                                setValidation(copy);
                            }
                        }}
                        type="email"
                        className="form-control"
                        id="email"/>
                    { validation.email ?
                        <div>
                            { validation.email.map(err=> <span key={err} className="d:b text-danger">{err}</span>)}
                        </div>
                        : null
                    }
                </div>
                <div className="t-a:c">
                    <button type='button' tabIndex={0} className={styles.back} onClick={() => setPage(1)}>Back</button>
                    <PrimaryButton type='submit'>Sign Up</PrimaryButton>
                </div>
            </form>
        </div>
    )
}

export default withRouter(Waitlist);