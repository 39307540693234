import React, { useState, useEffect, useRef } from 'react';

// styles
import styles from './organizationBranding.module.css';

// components
import {ImageThumbnailDetailed} from "../imageThumbnail/imageThumbnailDetailed";
import {SketchPicker} from "react-color";
import Phone from "../../images/phone-header.png";

const OrganizationBranding = (props) => {
    const { setState, state } = props;
    const [ image, setImage ] = useState(state ? state.image : null); // custom logo file for branding
    const [ tempImage, setTempImage ] = useState(null); // custom logo url data for branding preview
    const [ mounted, setMounted ] = useState(false);

    const [ showHeaderColor, setShowHeaderColor ] = useState(false); // toggle for color pallet in branding
    const [ showTextColor, setShowTextColor ] = useState(false); // toggle for color pallet in branding
    const showHeader = useRef(false);
    const showText = useRef(false);
    const setHeader = (val) => {
        setShowHeaderColor(val);
        showHeader.current = val;
    }
    const setText = (val) => {
        setShowTextColor(val);
        showText.current = val;
    }

    const [ formState, updateState ] = useState({
        branding: state ? state.branding : false,
        headerColor: state ? state.headerColor : '',
        textColor: state ? state.textColor : '',
    });

    function setForm(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    function detectClick(e){
        if (!document.getElementById('colorPickers').contains(e.target)){
            if (showHeader.current || showText.current) {
                setHeader(false);
                setText(false);
            }
        }
    }

    useEffect(() => {
      window.addEventListener('click', detectClick);
        return () => window.removeEventListener('click', detectClick);
    }, []);

    // report to parent if set
    useEffect(() => {
        if (setState && mounted) {
            setState({ image, ...formState });
        }
        setMounted(true);
    }, [ formState, image ]);

    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <div className="form-group">
                        <label>Logo</label>
                        <p className='m:0'>Logo should be have a 1:1 - 4:1 ratio</p>
                        <p className='m:0'>Recommended sized (pixels)</p>
                        <ul><li>400x400</li><li>400x100</li></ul>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <ImageThumbnailDetailed setImage={setImage} image={image} setTemporary={setTempImage}/>
                            </div>
                        </div>
                        <div id='colorPickers' className={`row ${styles.colorContainer}`}>
                            <div className='col-xs-12 col-sm-6'>
                                <label className='d:b' htmlFor='headerColor'>Header Color Background</label>
                                <div
                                    onClick={()=>setHeader(!showHeaderColor)}
                                    style={{backgroundColor: formState.headerColor}}
                                    className={styles.color}/>
                                <input
                                    className={styles.colorText}
                                    value={formState.headerColor}
                                    type="text"
                                    readOnly
                                    id='headerColor'
                                    onClick={()=>setHeader(!showHeaderColor)}/>
                                {
                                    showHeaderColor ?
                                        <SketchPicker
                                            color={formState.headerColor || '#ffffff'}
                                            onChangeComplete={color => {
                                                setForm({ headerColor: color.hex })
                                            }}/>
                                        : null
                                }
                            </div>
                            <div className='col-xs-12 col-sm-6'>
                                <label className='d:b' htmlFor='textColor'>Text Color</label>
                                <div
                                    onClick={()=>setText(!showTextColor)}
                                    style={{backgroundColor: formState.textColor}}
                                    className={styles.color}/>
                                <input
                                    className={styles.colorText}
                                    value={formState.textColor}
                                    type="text"
                                    readOnly
                                    id='textColor'
                                    onClick={()=>setText(!showTextColor)}/>
                                {
                                    showTextColor ?
                                        <SketchPicker
                                            color={formState.textColor || '#fff'}
                                            onChangeComplete={color => {
                                                setForm({ textColor: color.hex })
                                            }}/>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 d:f j-c:c">
                    <div className={styles.phoneContainer}>
                        <div className='pos:r'>
                            <img className={styles.phone} src={Phone} alt="iphone case"/>
                            <div
                                style={{ backgroundColor: formState.headerColor}}
                                className={styles.phoneHeader}>
                                <div className={styles.phoneText}>
                                    { tempImage ? <img className={styles.phoneLogo} src={tempImage} alt="temporary logo"/> : null }
                                    <span
                                        style={formState.textColor ? { color: formState.textColor } : null}
                                        className={styles.phoneIcon}>
                                        PROFILE<i className="far fa-user-circle"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OrganizationBranding;