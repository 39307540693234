import React, {Component} from 'react';
import axios from 'axios';

export class ContentViewContainer extends Component{
    constructor(props){
        super(props);
        this.state={send_error:''}
    }

    sendContentToClient =(content_id, tile_id)=>{
        axios.post('/content/assign', {content_id, tile_id})
            .then(()=>this.props.handleClose())
            .catch(err=>this.setState({send_error:err.response.data.error}));
    };

    attachContent=()=>{
        this.props.attachContent({id:this.props.id, title:this.props.title});
        this.props.handleClose();
    };

    renderContent=()=>{
      if(this.props.content_type==='blog'){
          return(
              <div>
                  <div className="hero-image">
                      {this.props.hero_image ? <img src={this.props.hero_image} alt="hero"/>:null}
                  </div>
                  <div className="modal-actions">
                      {this.props.intro ? null : <button className='btn show-button' onClick={this.props.handleClose}>Back</button>}
                      {this.props.tile &&
                      this.props.user &&
                      this.props.user.permission_id > 1 &&
                      this.props.id ? <button className='btn show-button' onClick={()=>this.sendContentToClient(this.props.id, this.props.tile.id)}>Send to Client</button> : null}
                      {this.props.inbox && this.props.user ? <button className='btn show-button' onClick={this.attachContent}>Attach</button>:null}
                      {this.state.send_error ? <span>{this.state.send_error}</span> : null}
                  </div>
                  <div className="modal-copy">
                      <h2 className="no-margin-top">{this.props.title}</h2>
                      <div dangerouslySetInnerHTML={{__html:this.props.content}}></div>
                  </div>
              </div>
          )
      }
      else if(this.props.content_type === 'file'){
          return(
              <div>
                  <div className="embedded-image">
                      <img src={this.props.content} alt="content"/>
                  </div>
                  <div className="modal-actions">
                      {this.props.intro ? null : <button className='btn show-button' onClick={this.props.handleClose}>Back</button>}
                      {this.props.tile &&
                      this.props.user &&
                      this.props.user.permission_id > 1 &&
                      this.props.id ? <button className='btn show-button' onClick={()=>this.sendContentToClient(this.props.id, this.props.tile.id)}>Send to Client</button> : null}
                      {this.props.inbox && this.props.user ? <button className='btn show-button' onClick={this.attachContent}>Attach</button>:null}
                      {this.state.send_error ? <span>{this.state.send_error}</span> : null}
                  </div>
                  <div className="modal-copy">
                      <h2 className="no-margin-top">{this.props.title}</h2>
                      <div dangerouslySetInnerHTML={{__html:this.props.description}}></div>
                  </div>
              </div>
          )
      } 
        else if(this.props.content_type === 'link' || this.props.content_type === 'pathway'){
          return(
              <div>
                  <iframe className="vimeo-iframe" src={'https://player.vimeo.com/video/' + this.props.content} width="100%" height="450" frameborder="0" title={this.props.title}webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>                                            
                  <div className="modal-actions">
                      {this.props.intro ? null : <button className='btn show-button' onClick={this.props.handleClose}>Back</button>}
                      {this.props.tile &&
                      this.props.user &&
                      this.props.user.permission_id > 1 &&
                      this.props.id ? <button className='btn show-button' onClick={()=>this.sendContentToClient(this.props.id, this.props.tile.id)}>Send to Client</button> : null}
                      {this.props.inbox && this.props.user ? <button className='btn show-button' onClick={this.attachContent}>Attach</button>:null}
                      {this.state.send_error ? <span>{this.state.send_error}</span> : null}
                  </div>
                  <div className="modal-copy">
                      <h2 className="no-margin-top">{this.props.title}</h2>
                      <div dangerouslySetInnerHTML={{__html:this.props.description}}></div>
                  </div>
              </div>
          )
      }
    };


    render(){
        return(<div>
            {this.renderContent()}
            </div>)

    }
}