import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';

// styles
import styles from './clientHeaderMinimal.module.css';

// components
import PhotoContainer from "../profilePhotos/container";
import {RANKS} from "../../serverVariables/ranks";
import {fetchUnreadInbox} from "../../store/actions/postsActions";
import BackButton from '../BackButton';

const ClientHeaderMinimal = props => {
    // props
    const {close} = props;
    // state
    const {user} = props;
    const [unread, setUnread] = useState(0);

    let rankBorder = '';
    const rank = user.game.rank_name.toLowerCase();
    if (rank === RANKS.BRONZE) rankBorder = styles.bronzeRank;
    if (rank === RANKS.SILVER) rankBorder = styles.silverRank;
    if (rank === RANKS.GOLD) rankBorder = styles.goldRank;
    if (rank === RANKS.PLATINUM) rankBorder = styles.platinumRank;

    useEffect(() => {
        fetchUnreadInbox(user.username)
            .then((res)=>{
                if(res > 0) setUnread(res);
            })
            .catch(e => console.log(e));
    }, []);

    return (
        <div className={styles.container}>
            <BackButton to={close} color="#4bbc4e"  />
            <Link className={styles.mail} to={`/member/clientDash/${user.username}/inbox`}>
                <i className={`fa fa-envelope fa-lg`}>{unread ? <span className={styles.unread}>{unread}</span> : null}</i>
            </Link>
            <PhotoContainer
                onClick={close}
                className={`${styles.profile} ${rankBorder}`}
                image={user.profile_image}
                username={user.username}
                url_path={`/member/clientDash/${user.username}/account`}
            />
        </div>
    )
}

function mapStateToProps({user}) {
    return {user};
}

export default connect(mapStateToProps)(ClientHeaderMinimal);