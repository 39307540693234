import React from "react";

// styles
import styles from "./topContent.module.css";

// components
import { ReactComponent as TimesIcon } from "../../../../../images/times-circle.svg";
import TierDropdown from "../../../../dropdowns/tierDropdown/tierDropdown";
import { PrimaryButton } from "../../../../buttons/buttons";
import { BoxContainer } from "../../../../containers/boxContainer/boxContainer";
import {ReactComponent as DownArrow} from "../../../../../images/caret-down-solid.svg";
import RenderTopContentVideo from "../renderTopContentVideo/RenderTopContentVideo";

const TopContent = (props) => {
  const {content, runSearch, categoryOptions, search, setSearch, page, setPage , loading} = props
  return (
    <BoxContainer className={styles.topContentRow} data-cy='wotd-top-content'>
      <h4 className="m:0 margin-bottom-25">Top Content</h4>
      <form className={styles.search}>
        <div className={`form-group ${styles.searchText}`}>
          <input
            value={search.title}
            onChange={(e) => setSearch({ ...search, title: e.target.value })}
            className={`form-control`}
            type="text"
            placeholder="Search Content"
          />
    
           <TimesIcon
            className={styles.times}
            onClick={() => setSearch({ ...search, title: "" })}
          />  
       
        </div>

        <div className="form-group">
          <TierDropdown
            placeholder="All Categories"
            className={styles.categoryDD}
            name="categories"
            list={categoryOptions}
            value={search.category}
            onChange={(val) => {
              if (!val.value) {
                setSearch({ ...search, category: null });
              } else {
                setSearch({ ...search, category: val });
              }
            }}
          />
        </div>
        <PrimaryButton className={styles.submit} onClick={runSearch}>
          Search
        </PrimaryButton>
      </form>
      <div></div>
      <div className={styles.row}>
        <div className={`${styles.content} ${styles.headers}`}>Content</div>
        <div
          className={`${
            search.sort === "duration-asc" || search.sort === "duration-desc"
              ? styles.active
              : ""
          } cur:p ${styles.duration} ${styles.headers}`}
          onClick={() =>
            setSearch({
              ...search,
              sort:
                search.sort === "duration-desc"
                  ? "duration-asc"
                  : "duration-desc",
            })
          }
        >
          Avg. Duration
          <DownArrow
            className={`${styles.arrow} ${
              search.sort === "duration-asc" ? styles.up : ""
            }`}
          />
        </div>
        <div
          className={`${
            search.sort === "views-asc" || search.sort === "views-desc"
              ? styles.active
              : ""
          } cur:p ${styles.views} ${styles.headers}`}
          onClick={() =>
            setSearch({
              ...search,
              sort: search.sort === "views-desc" ? "views-asc" : "views-desc",
            })
          }
        >
          Views
          <DownArrow
            className={`${styles.arrow} ${
              search.sort === "views-asc" ? styles.up : ""
            }`}
          />
        </div>
      </div>
      <RenderTopContentVideo content={content}  page={page} setPage={setPage} loading={loading}/>
    </BoxContainer>
  );
};

export default TopContent;
