// npm modules
import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

// actions
import { updateSubmit } from "../../actions/action_forms";
import InputImage from "../imageThumbnail/imageThumbnail";

const NewSubmitScreen = ({ screen, updateSubmit }) => {
    return (
        <div>
            <h2>Submit Screen</h2>
            <div className='form-group'>
                <label htmlFor="headline">Headline</label>
                <input
                    onChange={e => updateSubmit({ ...screen, typeId: 10, HEADLINE: e.target.value })}
                    value={screen.HEADLINE}
                    type="text"
                    id="headline"
                    className="form-control"
                    placeholder='Headline Text'/>
                { screen.validation && screen.validation.HEADLINE ?
                    screen.validation.HEADLINE.map(err => <span className="text-danger">{err}</span>):
                    null
                }
            </div>
            <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                    onChange={e => updateSubmit({  ...screen, typeId: 10, CONTENT: e.target.value })}
                    value={screen.CONTENT}
                    id="description"
                    rows="3"
                    className='form-control'
                    placeholder='Page description'/>
                { screen.validation && screen.validation.CONTENT ?
                    screen.validation.CONTENT.map(err => <span className="text-danger">{err}</span>):
                    null
                }
            </div>
            <div className="form-group">
                <label htmlFor="image">Image</label>
                <InputImage setImage={file => updateSubmit({ ...screen, typeId: 10, IMAGE: file })} image={screen.IMAGE }/>
            </div>
            <div className="form-group">
                <label htmlFor="button">Button Label</label>
                <input
                    onChange={e => updateSubmit({ ...screen, typeId: 10, BUTTON: e.target.value })}
                    value={screen.BUTTON}
                    type="text"
                    id="button"
                    className="form-control"
                    placeholder='Page button'/>
                { screen.validation && screen.validation.BUTTON ?
                    screen.validation.BUTTON.map(err => <span className="text-danger">{err}</span>):
                    null
                }
            </div>
        </div>
    )
};

const actions = {
    updateSubmit,
};

export default connect(null, actions)(NewSubmitScreen);

NewSubmitScreen.propTypes = {
    screen: propTypes.shape({
        HEADLINE: propTypes.string,
        CONTENT: propTypes.string,
        IMAGE: propTypes.object,
        BUTTON: propTypes.string,
    }),
    updateSubmit: propTypes.func
};

NewSubmitScreen.defaultProps = {
    screen: {},
    updateSubmit: propTypes.func,
};