import React from "react";
// styles
import styles from "./analyticsCard.module.css";
// components
import { BoxContainer } from "../../../../containers/boxContainer/boxContainer";
import Loading from "../../../../Loading/Loading";

const AnalyticsCard = (props) => {
  const { title, data } = props;
  return (
    <BoxContainer className={styles.cardContainer}>
      {data && data != "0:0NaN"? (
        <>
          <h2 className={styles.mainLabel}>{data}</h2>
          <p className={styles.subLabel}>{title}</p>
        </>
      ) : (
          <Loading />
      )}
    </BoxContainer>
  );
};

export default AnalyticsCard;
