import {contentApi} from "../../api/contentApi";
import {CONTENT_META_TYPES} from "../../serverVariables/contentMetaTypes";

//**************** Actions that involve the store *****************//

//**************** Actions that don't involve the store *****************//

// fetch a list of all categories related to pathways content
export async function fetchContentCategories() {
    const {data: {result}} = await contentApi().getAllCategories();
    return result;
}

// fetch a single piece of content
export async function fetchContent(id) {
    const {data: {result}} = await contentApi().getContent(id);
    return result;
}

// fetch a list of the different content types a category can be in
export async function fetchContentTypes() {
    const {data: {types}} = await contentApi().getContentTypes();
    return types;
}

// fetch a list of the different statuses content can have
export async function fetchContentStatuses() {
    const {data: {statuses}} = await contentApi().getStatuses();
    return statuses;
}

// fetch categories based on a contentType
export async function fetchContentTypeCategories(contentType, params={}) {
    const {search} = params;
    const organization_id = params.orgId;
    const {data: {result}} = await contentApi().getCategories(contentType, {search, organization_id});
    return result;
}

// fetch categories based on a contentType in hierarchical form
export async function fetchHierarchicalCategories(contentType) {
    const {data: {result}} = await contentApi().getHierarchicalCategories(contentType);
    return result;
}

// fetch subcategories based on a contentType
export async function fetchSubcategories(subcategory) {
    const {data: {result}} = await contentApi().getSubcategories(subcategory);
    return result;
}

// fetch the content of a given category
export async function fetchCategoryContent(category, filters) {
    const {data: {result}} = await contentApi().getCategoryContent(category, filters);
    return result;
}

// save the sorted order of a category list
export async function saveCategoryOrder(contentType, sortedList) {
    const {data: {result}} = await contentApi().saveCategoryOrder(contentType, sortedList);
    return result;
}

// save the sorted order of a category list
export async function saveContentOrder(contentType, sortedList) {
    const {data: {result}} = await contentApi().saveContentOrder(contentType, sortedList);
    return result;
}

// save or create a given category's metadata and images
export async function saveCategoryMeta(formData, category, create=false) {
    let result;
    if (create) {
        // create new category
        const {data} = await contentApi().createCategory(category, formData);
        result = data.result;
    } else {
        // update already existing category
        const {data} = await contentApi().saveCategory(category, formData);
        result = data.result;
    }
    return result;
}

// remove a category
export async function removeCategory(category) {
    const {data: {result}} = await contentApi().deleteCategory(category);
    return result;
}

// get the subcategories for the featured content type
export async function fetchFeaturedContent() {
    const {data: {result}} = await contentApi().getFeaturedContent();
    return result;
}

// get a single category
export async function fetchCategory(category) {
    const {data: {result}} = await contentApi().getCategory(category);
    return result;
}

// get single piece of FEATURED content
export async function fetchFeaturedSingle(contentId) {
    const {data: {result}} = await contentApi().getFeaturedSingle(contentId);
    return result;
}

// get tags for a given content type
export async function fetchTags(contentType) {
    const {data: {tags}} = await contentApi().getTags(contentType);
    return tags;
}

// create a new tag
export async function newTag(tag, contentType) {
    const {data: {result}} = await contentApi().createTag(tag, contentType);
    return result;
}

// update a tag
export async function changeTag(tag, contentType) {
    const {id, name} = tag;
    const {data: {result}} = await contentApi().updateTag(id, name, contentType);
    return result;
}

// remove a tag
export async function removeTag(tagId) {
    const {data: {result}} = await contentApi().deleteTag(tagId);
    return result;
}

// fetch all content for a given tag, regardless of category
export async function fetchTaggedContent(tagId) {
    const {data: {result}} = await contentApi().getContentByTag(tagId);
    return result;
}

// fetch all tags for a given category
export async function fetchCategoryTags(category) {
    const {data: {result}} = await contentApi().getCategoryTags(category);
    return result;
}

// fetch users content stats for a given content type
export async function fetchUserContentStats(userId, contentTypeId) {
    const {data: {result}} = await contentApi().getContentStats(userId, contentTypeId);
    return result;
}

// fetch all the organizations a category is assigned to
export async function fetchAssignedOrgs(categoryId) {
    const {data: {result}} = await contentApi().getAssignedOrgs(categoryId);
    return result;
}

// search all content
export async function searchAllContent(page=1, params) {
    const {data: {result}} = await contentApi().searchContent(page, params);
    return result;
}

// record a content view chunk
export async function recordViewChunk(contentId, start, duration, session, total) {
    const {data: {result}} = await contentApi().recordContentViewChunk(contentId, start, duration, session, total);
    return result;
}

// record first wotd content
export async function recordWotdVideo(contentId, start, duration, session, total, organizationId) {
    const {data: {result}} = await contentApi().WotdVideoChunk(contentId, start, duration, session, total, organizationId);
    return result;
}

// record new daily wotd
export async function recordDailyWotdVideo(contentId, start, duration, session, total) {
    const {data: {result}} = await contentApi().recordDailyWotdVideoChunk(contentId, start, duration, session, total);
    return result;
}



// get a list of instructors
export async function fetchInstructors(search, sort) {
    const {data} = await contentApi().getMetaContentType(CONTENT_META_TYPES.TRAINER, search, sort);
    return data.result;
}

// record a new instructor
export async function newInstructors(label, text) {
    const {data} = await contentApi().newMetaContentType(CONTENT_META_TYPES.TRAINER, label, text);
    return data.result;
}

// record a new equipment
export async function newEquipment(label) {
    const {data} = await contentApi().newMetaContentType(CONTENT_META_TYPES.EQUIPMENT, label);
    return data.result;
}


// get a list of equipment
export async function fetchEquipment(search, sort) {
    const {data} = await contentApi().getMetaContentType(CONTENT_META_TYPES.EQUIPMENT, search, sort);
    return data.result;
}

// record a new class type
export async function newClassType(label) {
    const {data} = await contentApi().newMetaContentType(CONTENT_META_TYPES.CLASS_TYPES, label);
    return data.result;
}


// get a list of class types
export async function fetchClassTypes(search, sort) {
    const {data} = await contentApi().getMetaContentType(CONTENT_META_TYPES.CLASS_TYPES, search, sort);
    return data.result;
}

// get a list of intensities
export async function fetchIntensities(search, sort) {
    const {data} = await contentApi().getMetaContentType(CONTENT_META_TYPES.INTENSITY, search, sort);
    return data.result;
}

// get a list of class lengths
export async function fetchClassLengths(search, sort) {
    const {data} = await contentApi().getMetaContentType(CONTENT_META_TYPES.CLASS_LENGTH, search, sort);
    return data.result;
}

// update an content meta record
export async function updateContentMeta(id, label, text) {
    const {data} = await contentApi().updateMetaContentType(label, id, text);
    return data.result;
}

// delete a content meta record
export async function removeContentMeta(id) {
    const {data} = await contentApi().deleteMetaContentType(id);
    return data.result;
}

// Schedule Class of the Week Content
export async function setScheduledContent(id, start, end) {
    const {data} = await contentApi().scheduleContent(id, start, end);
    return data.result;
}

// Fetch scheduled Class of the Week Content
export async function fetchScheduledContent(start, end) {
    const {data} = await contentApi().getScheduled(start, end);
    return data.result;
}

// Update Class of the Week Event
export async function updateScheduledContent(eventId, contentId, start, end) {
    const {data} = await contentApi().updateScheduled(eventId, contentId, start, end);
    return data.result;
}

// delete Class of the Week Event
export async function deleteScheduledContent(eventId) {
    const {data} = await contentApi().deleteScheduled(eventId);
    return data.result;
}

// fetch Class of the Week Event
export async function fetchClassOfTheWeek(categoryId) {
    const {data} = await contentApi().getClassOfTheWeek(categoryId);
    return data.result;
}

// fetch on demand fitness filter info
export async function fetchOnDemandFilters(categoryId) {
    const {data} = await contentApi().getOnDemandFilters(categoryId);
    return data.result;
}

// fetch on demand fitness content list
export async function fetchOnDemandContent(categoryId, filters) {
    const {data} = await contentApi().getFitnessContent(categoryId, filters);
    return data.result;
}

// set a piece of content as favorite or not
export async function setContentFavorite(contentId, collection) {
    const {data} = await contentApi().favoriteContent(contentId, collection);
    return data.result;
}

// fetch the history of a content category for a user
export async function fetchUserContentHistory(userId, sort) {
    const {data} = await contentApi().getContentHistory(userId, sort);
    return data.result;
}

// fetch the collections within a category
export async function fetchCategoryCollections(categoryId, filters) {
    const {data} = await contentApi().getContentCollections(categoryId, filters);
    return data.result;
}

// fetch the instructors available to a collections category
export async function fetchCollectionsInstructors(categoryId) {
    const {data} = await contentApi().getInstructors(categoryId);
    return data.result;
}


// fetch list of categoris for dropdown
export async function fetchClassCategoriesDropdown() {
    const {data} = await contentApi().getClassCategoriesDropdown();
    return data.result;
}

// post class to class of the day clander
export async function fetchPostClassofTheDayToCalander(contentId, start, end) {
    const {data} = await contentApi().postClassofTheDayToCalander(contentId, start, end);
    return data.result;
}

// delete class of the day clander
export async function fetchDeleteClassoftheDay(eventId) {
    const {data} = await contentApi().deleteClassoftheDay(eventId);
    return data.result;
}
// fetch list of classes in class of the day 
export async function fetchClassofTheDayClasses(start, end) {
    const {data} = await contentApi().getClassList(start, end);
    return data.result;
}

// edit class in class of the day
export async function fetchEditClassoftheDay(eventId, contentId, start, end) {
    const {data} = await contentApi().editClassoftheDay(eventId, contentId, start, end);
    return data.result;
}